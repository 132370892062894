<template>
  <dash-layout>
    <template slot="dash-title">
      <span v-if="project.name">
        {{ project.name }} {{ project.description }}
        <!-- <v-chip
          small
          color="green"
          text-color="white"
          style="margin-bottom: 16px; margin-left: -8px;"
        >
          {{ project.pro_intro }}
        </v-chip> -->
      </span>        
      <span v-else>Loading...</span>
    </template>
    
    <template slot="dash-body">

      <v-tabs show-arrows v-model="tab" class="mb-4">
        <v-tab :ripple="false" :ref="'btnProjectDonation'">Donation</v-tab>
        <v-tab :ripple="false" :ref="'btnDonors'">Donors</v-tab>
        <v-tab :ripple="false" :ref="'btnElectionProperties'">Elections</v-tab>
         <!-- <v-tab :ripple="false" :ref="'btnProgression'" v-show="user_role == 'Admin'" >Progression</v-tab> -->
          <v-tab :ripple="false" :ref="'btnProgression'" v-show="user_role == 'Admin'" >Offline Donations</v-tab>
        
        <!-- <v-tab 
          :ripple="false" 
          :ref="'btnElectionFiles'"
          :disabled="id == 'new'"
          v-show="activeElection.role == 'Auditor' || activeElection.role == 'Uploader'"
        >Election Files</v-tab> -->
      </v-tabs>

      <transition name="component-fade" mode="out-in">
        <keep-alive>
          <component v-bind:is="tabs[activeTab].component" :donationid="id"></component>
        </keep-alive>
      </transition>

    </template>
  </dash-layout>

</template>

<script>
  import {mapGetters} from 'vuex'
  import DashLayout from '../../containers/Layout'
  import AuditProject from './AuditProject.vue'
  import ProjectElectionList from './ProjectElectionList.vue'
  import DonorsList from './DonorsList.vue'
  // import FundingProgression from './FundingProgression'
  import ManuallyDonation from './ManuallyDonation'

  export default {
    name: 'DonationLayout',
    props: ['name'],

    data() {
      return {
        currentTabComponent: '',
        activeTab: 0,
        project: [],
        elections: [],
        user_role:'',
        tabs: [
          { name: 'Donation', component: 'AuditProject'},
          { name: 'Donors', component: 'DonorsList'},
          { name: 'Elections', component: 'ProjectElectionList'},
          { name: 'Offline Donations',component:'ManuallyDonation'}
          // { name:'Progression',component:'FundingProgression' }
        ]
      }
    },

    async mounted() {
      this.user_role = JSON.parse(localStorage.getItem('user')).role;  
      await this.$store.dispatch('getDonors', this.name)
      await this.$store.dispatch('getproject', this.name)
      // await this.$store.dispatch('getfundring',this.name)
      this.project = this.activeProject && this.activeProject.project
      this.elections = this.activeProject && this.activeProject.elections
    },

    computed: {
      ...mapGetters(['activeProject','donors', 'auditActiveJobLoading', 'activeAuditJob', 'createPaymentSessionLoading', 'electionLoading']),
      tab: {
        get () {
          return this.activeTab
        },
        set (value) {
          this.activeTab = value
        }
      }
    },

    components: {
      DashLayout,
      AuditProject,
      ProjectElectionList,
      DonorsList,
      ManuallyDonation
      // FundingProgression
    },

    methods: {

    },
    watch: {
    }
  }
</script>
