import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = 'sys-config/action-specs/v3'

const ACTION_TOOLS = 'sys-config/action-tools'
const UPDATE_TOOLS = 'sys-config/update-action-tools'
const DELETE_TOOLS = 'sys-config/delete-action-tools'
const state = {
  auditTypes: [],
  auditTypesLoading: false,
  activeAuditType: {},
  actionLoadingTools: false,
  actionTools: [],
  addtoolsLoading:false,
  statusTools:[],
  statustoolLoading:false
}


const getters = {
  auditTypes: state => {
    return state.auditTypes
  },
  auditTypesLoading: state => {
    return state.auditTypesLoading
  },
  activeAuditType: state => {
    return state.activeAuditType
  },
  actionLoadingTools: state => {
    return state.actionLoadingTools
  },
  actionTools: state => {
    return state.actionTools
  },
  addtoolsLoading: state => {
    return state.addtoolsLoading
  },
  statusTools:state =>{
    return state.statusTools
  },
  statustoolLoading: state =>{
    return state.statustoolLoading
  }
  
}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response))
    .catch(error => {
      handleApiError(error,'');
      handler(context, error['response'])
    })
}

function post(context, url, data, handler) {
  return api().post(url, data).then(response => handler(context, response, data))
    .catch(error => {
      handleApiError(error,'');
      handler(context, error['response'], data)
    })
}

function put(context, url, data, handler) {
  return api().put(url, data).then(response => handler(context, response, data))
    .catch(error => {
      handleApiError(error,'');
      handler(context, error['response'], data)
    })
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response))
    .catch(error => {
      handleApiError(error,'');
      handler(context, error['response'])
    })
}

function handleAuditTypesLoaded(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
  context.state.auditTypesLoading = false
  if (data['error']) {
    context.commit('apiError', data['error'])
    logApiService(obj, data['error'], EventName.Action_specs, EventType.auditTypesLoad)
    context.commit('apiError', elections['error'])
  } else {
    logApiService(obj, 'Fetch Audit Types', EventName.Action_specs, EventType.auditTypesLoad)
    context.commit('auditTypesLoaded', data)
  }

  return data
}

function handleDeleteTools(context, response, info){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: info
  }
  const data = response['data']['data']
  if(data['error']){
    context.commit('apiError', data['error'])
    logApiService(obj, data['error'], EventName.Action_specs, EventType.deleteTools)
    context.commit('apiError', elections['error'])
  }else{
    logApiService(obj,'Delete tool', EventName.Action_specs, EventType.deleteTools)
    context.commit('deleteToolsSetting', data)
  }
}

function handleAuditTypeLoaded(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
  
  if (data['error']) {
    context.commit('apiError', data['error'])
    logApiService(obj, data['error'], EventName.Action_specs, EventType.auditTypeLoad)
  } else {
    logApiService(obj, 'Fetch Audit Type', EventName.Action_specs, EventType.auditTypeLoad)
    context.commit('auditTypeLoaded', data)
  }
  return data
}

function handleAddTools(context,response, info){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: info
  }
  const data = response['data']['data']
  context.state.addtoolsLoading = false;
  if(data['error']){
    logApiService(obj, data['error'], EventName.Action_specs, EventType.addTools)
    context.commit('apiError', data['error'])
  }else{
    logApiService(obj, 'Tool save', EventName.Action_specs, EventType.addTools)
    context.commit('addToolsSetting', data)
  }
}

function handleActionTools(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
    context.state.actionLoadingTools = false;
  if (data['error']) {
    logApiService(obj, data['error'], EventName.Action_specs, EventType.actionTools)
    context.commit('apiError', data['error'])
  } else {
    logApiService(obj, 'Fetch Action', EventName.Action_specs, EventType.actionTools)
    context.commit('loadedAction', data)
  }
}

function handleToolsStatus(context, response){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
  context.state.statustoolLoading = false;
  if (data['error']) {
    context.commit('apiError', data['error'])
    // logApiService(obj, data['error'], EventName.Action_specs, EventType.toolsStatus)
  } else {
    // logApiService(obj, 'Fetch Action Tools Data', EventName.Action_specs, EventType.toolsStatus)
    context.commit('loadedActionToolsData', data)
  }
}

const actions = {
  loadAuditTypes(context) {
    if (context.state.auditTypesLoading) return;

    context.state.auditTypesLoading = true
    return get(context, URL, handleAuditTypesLoaded)
  },
  loadactiontools(context) {
    if (context.state.actionLoadingTools) return;
    context.state.actionLoadingTools = true
    return get(context, ACTION_TOOLS, handleActionTools)
  },
  loadToolsStatus(context){
    if (context.state.statustoolLoading) return;
    context.state.statustoolLoading = true;
    return get(context, ACTION_TOOLS, handleToolsStatus)
  },
  loadAuditType(context, type) {
    return get(context, URL + '/' + type, handleAuditTypeLoaded)
  },
  addtools(context,payload){
    if(context.state.addtoolsLoading) return;

    context.state.addtoolsLoading = true
    //UPDATE_TOOLS
    if(payload['row_number'])
    {
      return post(context,UPDATE_TOOLS,payload,handleAddTools)
    }else{
      return post(context,ACTION_TOOLS,payload,handleAddTools)
    }
  },
  deleteTools(context,payload){
    return post(context,DELETE_TOOLS,payload,handleDeleteTools)
  }
}


const mutations = {
  auditTypesLoaded(state, data) {
    state.auditTypes = []
    for (let i = 0; i < data.length; i++) {
      state.auditTypes.push(data[i])
    }
  },
  loadedAction(state, data) {
    state.actionTools = []
    for (let i = 0; i < data.length; i++) {
      state.actionTools.push(data[i])
    }
  },
  loadedActionToolsData(state, data){
    state.statusTools = []
    for (let i = 0; i < data.length; i++) {
      state.statusTools.push(data[i])
    }
  },
  auditTypeLoaded(state, data) {
    state.activeAuditType = data
  },
  addToolsSetting(state,data){
    state.actionTools = []
    for (let i = 0; i < data.length; i++) {
      state.actionTools.push(data[i])
    }
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Tool save successfully'
    });
  },
  deleteToolsSetting(state,data){
    state.actionTools = []
    for (let i = 0; i < data.length; i++) {
      state.actionTools.push(data[i])
      context.commit('apiError', elections['error'])
    }
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Delete tool successfully'
    });
  }

}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'ActionSpecsStore'
}
