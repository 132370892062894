<template>
  <dash-layout>
    <template slot="dash-title" v-if="$route.params.id">Edit Job Settings</template>
    <template slot="dash-title" v-else>Create Job Settings</template>

    <template slot="dash-body">
    <v-card max-width="100%">

      <v-card-title class="mb-6">

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-btn @click="addRow" color="blue-grey darken-1" v-if="editAbleRow === null"> Add </v-btn>

      </v-card-title>

      <v-container grid-list-xl fluid>
        <v-layout row wrap>

          <v-data-table
                  :headers="headers"
                  :items="itemsWithSno"
                  class="elevation-1"
                  loading="false"
                  style="width: 100%;"
          >

            <template v-slot:item.argument="{ item }">
              <span v-if="editAbleRow != null && editAbleRow === item.index">
                <v-text-field
                        label="Argument"
                        v-model="setting.argument"
                ></v-text-field>
              </span>
              <span v-else>{{item.argument}}</span>
            </template>

            <template v-slot:item.data_type="{ item }">
              <span v-if="editAbleRow != null && editAbleRow === item.index">
                <v-select
                        :items="types"
                        label="Data Type"
                        v-model="setting.data_type"
                ></v-select>
              </span>
              <span v-else>
                {{item.data_type}}
              </span>

            </template>

            <template v-slot:item.value="{ item }">
              <span v-if="editAbleRow != null && editAbleRow === item.index">
                <v-text-field
                        label="Value"
                        v-model="setting.value"
                ></v-text-field>
              </span>
              <span v-else>{{item.value}}</span>

            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn icon @click="makeEditAble(item)" v-if="editAbleRow === null">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-btn icon v-if="editAbleRow === null" @click="deleteSetting(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>

              <v-btn icon v-if="editAbleRow != null && editAbleRow === item.index" @click="abortAction(item)">
                <v-icon>mdi-close</v-icon>
              </v-btn>

              <v-btn icon v-if="editAbleRow != null && editAbleRow === item.index" @click="saveSettings(item)">
                <v-icon>mdi-check</v-icon>
              </v-btn>

            </template>
          </v-data-table>
        </v-layout>
      </v-container>

      <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        outlined
      >
        Return
      </v-btn>
    </v-card-actions>
    </v-card>
    </template>

  </dash-layout>
</template>

<script>
  import DashLayout from '../../containers/Layout'

  export default {
    name: 'CreateJobSettings',
    components: {
      DashLayout
    },

    data: () => ({
      editAbleRow: null,
      setting: {
      },
      types: ['Text', 'Number'],
      headers: [
        { text: 'Argment', value: 'argument', sortable: false, width: '20%'},
        { text: 'Data Type', value: 'data_type', sortable: false, width: '20%'},
        { text: 'Value', value: 'value', sortable: false, width: '40%'},
        { text: 'Actions', value: 'actions', sortable: false, width: '20%'},
      ],
      settings: [
        {
          id: 1,
          argument: "ElectionId",
          value: "Collier County FL, 2020 Presidential Primary Election",
          data_type: "Text",
          description: "Election ID description"
        },
      ],
    }),

    computed: {
      itemsWithSno() {
        return this.settings.map((d, index) => ({ ...d, index: index }))
      }
    },

    methods: {

      addRow() {
        const setting = { argument: "", value: "", data_type: "", description: "" }
        this.setting = Object.assign({}, setting)
        this.settings.push(this.setting)
        this.editAbleRow = this.settings.length - 1
      },

      makeEditAble(item) {
        // console.log('ei', item.index)
        this.setting = Object.assign({}, item)
        this.editAbleRow = item.index
      },

      saveSettings(item) {
        this.settings[item.index] = this.setting
        if (item.id) {
          // replace existing item
        } else {
          // push and save item
        }
        this.editAbleRow = null
        // rest for saving setting
      },

      abortAction(item) {
        if (item.id) {
          this.editAbleRow = null
        } else {
          // delete last added element
          this.settings.pop()
          this.editAbleRow = null
        }
      },

      deleteSetting(item) {
        this.settings.splice(item.index, 1)
      }
    }
  }
</script>
