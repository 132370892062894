<template>
  <dash-layout>
    <template slot="dash-title" v-if="id != 'new'">
      <span v-if="projectData.pro_intro">
        {{ projectData.pro_intro }}
        <!-- <v-chip
          small
          color="green"
          text-color="white"
          style="margin-bottom: 16px; margin-left: -8px;"
        >
          {{ isProjectData.role }}
        </v-chip> -->
      </span>        
      <span v-else>Loading...</span>
    </template>
    <template slot="dash-title" v-else>Something went wrong</template>
    <!-- <template slot="dash-title">New Project</template> -->
    <template slot="dash-body">

      <!-- <v-tabs show-arrows v-model="tab" class="mb-4">
        <v-tab :ripple="false" :ref="'btnElectionProperties'">Project Properties</v-tab>
        <v-tab 
          :ripple="false" 
          :ref="'btnElectionFiles'"
          :disabled="id == 'new'"
          v-show="isProjectData.role == 'Auditor' || isProjectData.role == 'Uploader'"
        >Election Files</v-tab>
        <v-tab 
          :ripple="false" 
          :ref="'btnUploadedFiles'"
          :disabled="id == 'new'"
          v-show="isProjectData.role == 'Auditor' || isProjectData.role == 'Uploader'"
        >File Upload</v-tab>
        <v-tab 
          :ripple="false" 
          :ref="'btnRequestFiles'"
          :disabled="id == 'new'"
          v-show="isProjectData.role == 'Auditor' || isProjectData.role == 'Uploader'"
        >Upload Requests</v-tab>
        <v-tab
          :ripple="false" 
          :ref="'btnUsers'"
          :disabled="id == 'new'"
          v-show="isProjectData.role == 'Auditor'"
        >Users</v-tab>
      </v-tabs> -->

      <transition name="component-fade" mode="out-in">
        <keep-alive>
          <component v-bind:is="tabs[activeTab].component" :projectId="id">
            <!-- <div>Hello</div> -->
          </component>
        </keep-alive>
      </transition>

    </template>
  </dash-layout>

</template>

<script>
  import {mapGetters} from 'vuex'
  import DashLayout from '../../containers/Layout.vue'
  // import projectData from './projectdata.json'
  import ProjectElectionList from './ProjectElectionList'
  import Overview from './Overview.vue'
//   import Upload from './UploadSets'
//   import FileUpload from './FileUpload'
//   import Request from './RequestFiles'
//   import ElectionUsers from './ElectionUsers'

  export default {
    name: 'ProjectLayout',
    props: ['id'],

    data() {
      return {
        currentTabComponent: '',
        activeTab: 0,
        projectData: [],
        isProjectData: [],
        tabs: [
          { name: 'Project Properties', component: 'ProjectElectionList' },
          // { name: 'Election Files', component: 'Upload'},
          // { name: 'File Upload', component: 'FileUpload'},
          // { name: 'Upload Requests', component: 'Request'},
          // { name: 'Users', component: 'ElectionUsers'},
        ]
      }
    },

    async mounted() {
      await this.$store.dispatch('getproject', this.id)
      setTimeout(() => {
        this.projectData = this.activeProject.project
      }, 1000);
    },


    computed: {
      ...mapGetters(['projects', 'projectLoading', 'activeProject', 'user']),
      
      tab: {
        get () {
          return this.activeTab
        },
        set (value) {
          this.activeTab = value
        }
      }
    },

    components: {
      DashLayout,
      Overview,
      ProjectElectionList,
    //   Upload,
    //   FileUpload,
    //   Request,
    //   ElectionUsers,
    },

    methods: {

    },

    watch: {
      // isProjectData(){
      // }
    }
  }
</script>
