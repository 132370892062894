<template>
  <div>
    <v-dialog v-model="fileDeleteDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>Delete {{ selectedFile.file_name }} ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="fileDeleteDialog = false">Cancel</v-btn>
          <v-btn outlined color="error" @click="confirmDelete" :disabled="waiting">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <FileUploadDialog ref="uploadDialog" />
    <FileAdoptDialog ref="FileAdoptDialog" />

    <v-card max-width="100%">
      <v-card-title class="mb-6">
        <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
          clearable></v-text-field>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, ttprops }">
            <v-btn @click="refreshList()" dark color="blue-grey darken-1" class="ml-2" v-bind="ttprops" v-on="on">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh List</span>
        </v-tooltip>

        <!--
        <v-btn @click="$refs.uploadDialog.show()" dark color="blue-grey darken-1" class="ml-2">
          <v-icon left>mdi-plus</v-icon> Upload File
        </v-btn>
         -->
        <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" @click="adoptFile()" dark color="blue-grey darken-1" class="ml-2">
          <v-icon left>mdi-checkbox-marked-outline</v-icon> Adopt File
        </v-btn>
      </v-card-title>


      <v-container grid-list-xl fluid>
        <v-layout row wrap>

          <v-data-table :headers="headers" :items="files" :search="search" class="elevation-1" :loading="uploadSetLoading"
            style="width: 100%;">
            <template v-slot:[`item.file_type`]="{ item }">
              {{ item.file_type || '-' }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-chip :color="getStatusColor(item.status)" dark style="color: black;">{{ item.status || '-' }}</v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">

              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, ttprops }">
                  <v-btn icon @click.prevent="editFile(item)" v-bind="ttprops" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit File</span>
              </v-tooltip>

              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, ttprops }">
                  <v-btn icon v-if="item.status != 'Uploading'" @click="deleteUploadSet(item)" v-bind="ttprops" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Upload File</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DashLayout from '../../containers/Layout'
import FileUploadDialog from './FileUploadDialog'
import FileAdoptDialog from './FileAdoptDialog'

export default {
  name: 'UploadSetContainer',
  components: {
    DashLayout,
    FileUploadDialog,
    FileAdoptDialog
  },
  computed: {
    ...mapGetters(['uploadSets', 'activeUploadSet', 'activeElection', 'uploadSetLoading', 'user'])
  },
  async mounted() {
    this.files = []
    this.$store.dispatch('loadUploadSets', this.activeElection.id)
    this.rets = await this.$store.dispatch('getJobArgSpecs')
    this.userValue = this.user
  },
  data() {
    return {
      userValue: {},
      isHidden: false,
      search: '',
      uploading: false,
      files: [],
      headers: [
        { text: 'Name', value: 'file_name', },
        { text: 'Type', value: 'file_type', },
        { text: 'Etag', value: 'etag', },
        { text: 'Size', value: 'size', },
        // { text: 'Started', value: 'start', },
        // { text: 'Completed', value: 'end', },
        { text: 'Status', value: 'status', },
        { text: 'Actions', value: 'actions', },
      ],
      showDialog: false,
      selectedFile: {},
      fileDeleteDialog: false,
      waiting: false,
      rets: {},
    }
  },
  methods: {
    getStatusColor(status) {
      if (status === 'Uploaded' || status === 'Adopted') return 'light-green accent-3'
      else if (status === 'Aborted') return 'orange darken-1'
      else return 'blue darken-1'
    },

    deleteUploadSet(item) {
      this.selectedFile = item
      this.fileDeleteDialog = true
    },

    confirmDelete() {
      this.waiting = true
      this.$store.dispatch('DeleteUploadSet', this.selectedFile.id)
      this.waiting = false
      this.fileDeleteDialog = false
    },
    refreshList() {
      this.files = []
      this.$store.dispatch('loadUploadSets', this.activeElection.id)
    },
    async adoptFile() {
      this.$store.commit('setLoading', true)
      const ret = await this.$store.dispatch('loadAdoptableFiles', this.activeElection.id)

      // console.log("rets------>>>>.",rets);
      if (!ret['error'] && !this.rets['error']) {
        this.$refs.FileAdoptDialog.show({}, this.rets)
      }
      this.$store.commit('setLoading', false)
    },
    async editFile(item) {
      const rets = await this.$store.dispatch('getJobArgSpecs')
      this.$refs.FileAdoptDialog.show(item, rets)
    },
  },
  watch: {
    uploadSets() {
      this.files = []
      for (let i = 0; i < this.uploadSets.length; i++) {
        let file = {
          id: this.uploadSets[i].id,
          status: this.uploadSets[i].status,
          file_name: this.uploadSets[i].file_name,
          file_type: this.uploadSets[i].file_type,
          etag: this.uploadSets[i].etag,
        }

        file.start = this.uploadSets[i].created_at?.replace('T', ' ')
        file.end = this.uploadSets[i].last_modified?.replace('T', ' ')

        if (this.uploadSets[i].size >= 1024 * 1024 * 1024) {
          const size = Math.round(this.uploadSets[i].size * 100 / (1024 * 1024 * 1024)) / 100
          file.size = size.toString() + 'GB'
        }
        else if (this.uploadSets[i].size >= 1024 * 1024) {
          const size = Math.round(this.uploadSets[i].size * 100 / (1024 * 1024)) / 100
          file.size = size.toString() + 'MB'
        }
        else if (this.uploadSets[i].size >= 1024) {
          const size = Math.round(this.uploadSets[i].size * 100 / (1024)) / 100
          file.size = size.toString() + 'KB'
        }
        else {
          const size = Math.round(this.uploadSets[i].size * 100) / 100
          file.size = size.toString() + 'B'
        }

        this.files.push(file)
      }
    },
  }
}
</script>
