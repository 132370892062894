<template>
  <v-row class="justify-end pr-4">
    <v-spacer></v-spacer>
    <v-dialog v-model="dialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{id > 0 ? 'Edit User' : 'Invite User'}}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12" sm="12" md="12" v-if="id===0">
                <v-text-field
                        v-model="first_name"
                        label="First Name"
                        prepend-icon="mdi-account"
                        validate-on-blur
                        persistent-hint
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12" v-if="id===0">
                <v-text-field
                        v-model="last_name"
                        label="Last Name"
                        prepend-icon="mdi-account-circle"
                        validate-on-blur
                        persistent-hint
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                        prepend-icon="mdi-email"
                        v-model="email"
                        label="Email"
                        validate-on-blur
                        persistent-hint
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-select
                        :items="ElectionRoles"
                        v-model="role"
                        label="Role"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text :disabled="!isValid" @click="save">Save
          <v-progress-circular
                    v-if="waiting"
                    :width="3"
                    color="green"
                    indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'ElectionUserDialog',

    data() {
      return {
        id: 0,
        dialog: false,
        first_name: '',
        last_name: '',
        email: '',
        role: 'Observer',
        ElectionRoles: ['Observer','Uploader','Auditor'],
        waiting: false,
      }
    },

    computed: {

      ...mapGetters([
          'activeElection', 'activeElectionUser'
      ]),

      isValid: function() {
        if(this.id > 0) {
          return !(this.first_name.length === 0 || this.last_name.length === 0 || this.email === 0)
        }
        else {
          return !(this.first_name.length === 0 || this.last_name.length === 0 || this.email.length === 0)
        }
      },
    },

    methods: {
      show(user) {
        if(user.id){
          this.id = user.id
          this.first_name = user.first_name
          this.last_name = user.last_name
          this.email = user.email
          this.role = user.role          
        }
        else {
          this.id = 0
          this.first_name = ''
          this.last_name = ''
          this.role = 'Observer'
          this.email = ''
        }
        this.dialog = true
      },

      async save() {
        this.waiting = true
        let payload = {
          id: this.id,
          election_id: this.activeElection.id,
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          role: this.role,
        }

        await this.$store.dispatch('saveElectionUser', payload)
        this.waiting = false
        this.dialog = false        
      },
    },

    watch: {
    }

  }
</script>