<template>
  <div>
    <div v-html="markdownToHtml" :key="markdown"></div>
    <div>
      {{ markdowncontent && markdowncontent.text_msg }}
    </div>
    <div class="spinner-container" v-if="loader">
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HomePage',

  computed: {
    ...mapGetters(['markdowncontent', 'homeContentLoading', 'isUpdatemarkdown']),
  },

  async mounted() {
    await this.$store.dispatch('checkmarkdowncontent')
    this.markdownHTML = localStorage.getItem('mardowm-html')
  
    setTimeout(async () => {
      if(!!this.markdownHTML){
      this.$store.commit('setLoading', false)
      }else{
        this.$store.commit('setLoading', true)
        this.checkstatus()
      }
  
      if(!this.$store.getters.isUpdatemarkdown){
        this.$store.commit('setLoading', true)
        this.checkstatus()
      }else{
      this.$store.commit('setLoading', false)
      }
    }, 4000)

    
  },
  data() {
    return {
      markdown: '',
      loader: false,
      markdownHTML: undefined,
    }
  },
  methods: {
    async checkstatus(){
      await this.$store.dispatch('loadmarkdowncontent')
      this.loader = true
      setTimeout(() => {
        this.markdown = this.$store.getters.markdowncontent.text_msg
        this.loader = false
        localStorage.setItem('mardowm-html', marked(this.markdown,{ sanitize: false }))
        this.$store.commit('isUpdatemarkdowncontent',false)
        this.$store.commit('setLoading', false)

      }, 10000)

    }
  },
  computed: {
    markdownToHtml() {
      if(!this.markdownHTML){
        return marked(this.markdown,{ sanitize: false })
      }else{
        this.markdown = localStorage.getItem('mardowm-html')
        return this.markdown
      }   
    },
  },
}
</script>

<style>
.spinner-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 35px;
}
.spinner {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #ccc;
  border-top-color: #666;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
