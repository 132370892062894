import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/elections'

const state = {
  rootElections: [],
  activeRootElection: {},
  rootElectionLoading: false
}


const getters = {
  rootElections: state => {
    return state.rootElections
  },
  activeRootElection: state => {
    return state.activeRootElection
  },
  rootElectionLoading: state => {
    return state.rootElectionLoading
  },

}

function get(context, url, handler) {
  api().get(url)
  .then(response => handler(context, response))
  .catch((error) => handleApiError(error, ''))
}

function handleElectionsLoad(context, response) {
  const elections = response['data']
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  context.state.rootElectionLoading = false
  if (elections['error']) {
    context.commit('apiError', elections['error'])
    logApiService(obj, elections['error'], EventName.Root_election, EventType.electionsLoadRoot)
  } else {
    logApiService(obj, 'Fetch Root Elections', EventName.Root_election, EventType.electionsLoadRoot)
    context.commit('rootElectionsLoaded', elections)
  }
}

function handleGetElection(context, response) {
  const election = response['data']
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  if (election['error']) {
    context.commit('apiError', election['error'])
    logApiService(obj, election['error'], EventName.Root_election, EventType.getElection)
  } else {
    context.commit('setActiveRootElection', election)
    logApiService(obj, 'Fetch Active Root Election', EventName.Root_election, EventType.getElection)
  }
}

const actions = {
  loadRootElections(context, id) {
    if (context.state.rootElectionLoading) {
      return
    }

    context.state.rootElectionLoading = true
    get(context, URL, handleElectionsLoad)
  },

  getRootElection(context, id) {
    get(context, URL + '/' + id, handleGetElection)
  },

}


const mutations = {
  rootElectionsLoaded(state, elections) {
    state.rootElections = []
    for (let i = 0; i < elections.length; i++) {
      state.rootElections.push(elections[i])
    }
  },

  setActiveRootElection(state, election) {
    state.activeRootElection = election
  },

}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'RootElectionStore'
}
