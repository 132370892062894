<template>
  <div>
    <v-overlay :value="waiting">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-dialog v-model="dialog" max-width="90%" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ operation_name }}</span>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-textarea id="operation_log_screen" label="Operation logs" :value="operation_logs" rows="20"
            :readonly="true"></v-textarea>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeLogs()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="settingDialog" max-width="90%" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Stage Setting</span>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-row>
            <v-col cols="12" md="6">
              <v-select :items="operations" label="Operation Name" menu-props="auto" v-model="file_name" item-text="name"
                item-value="id" @change="argumentChanged()"></v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeSettingDialog()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="stageSettingDialog" max-width="90%" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Stage Setting</span>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <h1>Work-in-Progress</h1>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeSetting()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="helpDialog" width="500">
      <v-card>
        <v-card-title class="headline">
          {{ helpTitle }}
        </v-card-title>

        <v-card-text>
          {{ helpText }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-card max-width="100%">
      <v-card-title class="mb-6 cardTitle">
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, ttprops }">
            <v-btn elevation="2" class="helpClass" icon outlined @click="helpDialog = true" v-bind="ttprops" v-on="on">
              <v-icon>mdi-help</v-icon>
            </v-btn>
          </template>
          <span>Help Dialog</span>
        </v-tooltip>

        <v-tabs v-model="selectedGroup" @change="onGroupChange" next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline">
          <v-tab v-for="group in auditTypes.slice(0, 1)" :key="group.id">
            {{ group.name }}
          </v-tab>
        </v-tabs>

        <v-container class="m-0">
          <v-layout row wrap>
            <v-divider></v-divider>
          </v-layout>
        </v-container>

        <v-container v-show="activeAuditJob.role == 'Auditor'" class="mr-0 ml-0">
          <v-layout row wrap>
            <!-- <v-spacer></v-spacer>
            <v-spacer></v-spacer> -->
            <v-btn dark id="refreshStatusBtn" color="blue-grey darken-1 mr-1" @click="refreshstatus()">
              <v-icon left>mdi-refresh</v-icon> REFRESH STATUS
            </v-btn>
            <v-btn
            v-if="userValue.role == 'Observer'? isHidden : !isHidden"
              dark
              id="resetBtn"
              color="blue-grey darken-1"
              :disabled="selectedOperations.length <= 1"
              @click="resetSelectedOperations()"
            >
              <v-icon left>mdi-refresh</v-icon> RESET
            </v-btn>
            <v-btn
            v-if="userValue.role == 'Observer'? isHidden : !isHidden"
              dark
              id="RunAllBtn"
              color="blue-grey darken-1"
              :disabled="selectedOperations.length <= 1"
              @click="launchSelectedOperations()"
              class="ml-1"
            >
              <v-icon left>mdi-play</v-icon> Run ALL
            </v-btn>
            <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" dark color="blue-grey darken-1" id="clearPriorBtn" class="ml-1">
              <v-checkbox v-model="clear_results" id="clear_results_check" outlined></v-checkbox> Clear Prior Results
            </v-btn>
            <!-- <v-btn v-if="pipelineFile" @click="CreatePipeline()"  :disabled="disablePipeline" dark color="blue-grey darken-1" class="ml-1">
               Create Pipeline Status 
            </v-btn> -->
          </v-layout>
        </v-container>
      </v-card-title>

      <v-container grid-list-xl fluid>
        <v-layout row wrap>
          <v-data-table v-model="selectedOperations" :headers="headers" :items="operations"
            :loading="jobOperationsLoading" item-key="operation" class="elevation-1" style="width: 100%" show-select
            hide-default-footer :single-expand="singleExpand" :expanded.sync="expanded" show-expand :items-per-page="50">
            <template v-slot:item.running="{ item }">
              <v-btn v-if="(item.status == 'built' || item.status == 'SUCCESS' || item.status == 'built-new') &&
                item.name == itm_name
                " text small v-on:click="launchOperationforce(item)"
                :disabled="activeAuditJob.role != 'Auditor'"><v-icon>mdi-play</v-icon></v-btn>
              <v-btn v-else-if="(item.status == 'built' || item.status == 'SUCCESS' || item.status == 'built-new') &&
                item.name != itm_name
                " text small v-on:click="refreshforce(item)"
                :disabled="activeAuditJob.role != 'Auditor'"><v-icon>mdi-refresh</v-icon></v-btn>

              <v-btn v-else-if="item.status == 'RUNNING'" @click="stopOperation(item)"><v-icon>mdi-stop</v-icon></v-btn>
              
              <v-btn v-else-if="item.status == 'None' || item.status == 'unbuilt-notready'"
                :disabled="true"><v-icon>mdi-play</v-icon></v-btn>

              <v-btn v-else-if="item.status == 'unbuilt-ready'" text small @click="launchOperation(item)"
                :disabled="activeAuditJob.role != 'Auditor'"><v-icon>mdi-play</v-icon></v-btn>

              <v-btn v-else-if="item.status == 'STOPPED' || item.status == 'FAILURE'" text small
                v-on:click="resetOperation(item)"
                :disabled="activeAuditJob.role != 'Auditor'"><v-icon>mdi-refresh</v-icon></v-btn>
              <!-- <v-btn @click="stopOperation(item)"><v-icon>mdi-stop</v-icon></v-btn> -->
              <!-- <v-btn v-else-if="item.status=='unbuilt-notready'" text small @click="resetOperation(item)" :disabled="activeAuditJob.role != 'Auditor'"><v-icon>mdi-refresh</v-icon></v-btn> -->
            </template>
            <!-- <template v-slot:item.status="{ item }">
               <div v-if="item.status == 'Running'">built</div>
               <div v-else-if="item.status == 'Not Ready'">unbuilt-ready</div>
               <div v-else>{{ item.status }}</div>
            </template> -->
            <template v-slot:item.progress="{ item }">
              <v-progress-linear
                  height="25"
                  :value="item.progress"
                  :color="item.status === 'built' ? 'green' : 'amber'"
                >
                  <template v-slot="{ value }">
                    <strong>{{ Math.ceil(value) }}%</strong>
                  </template>
                </v-progress-linear>
            </template>

            <template v-slot:item.reports="{ item }">
              <div class="d-flex"  :class="item.status == 'built' || item.status == 'RUNNING' || item.status == 'STOPPED'
                ? 'active-report'
                : 'deactive-report'
                ">
                <v-menu v-if="item.reports.length > 0 && item.status != 'unbuilt-ready'" :rounded="'b-lg'" offset-y>
                  <template v-slot:activator="{ attrs, on }">
                    <!-- <v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    >
                      <v-img  v-bind="attrs"  v-on="on" src="../../assets/images/report.png"></v-img> -->
                    <!-- </v-btn> --> 
                    <v-icon v-bind="attrs" v-on="on">mdi-file-multiple</v-icon>
                  </template>
                  <v-list color="grey darken-3">
                    <v-list-item v-for="(report, index) in item.reports" :key="index" link>
                      <v-list-item-title v-text="report.text" @click="openLink(report)"></v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                </v-menu>
                <!-- <div v-else>Not Ready</div> -->
              </div>
            </template>
            <template v-slot:item.downloads="{ item }">
              <div class="d-flex" :class="item.status == 'built' ? 'active-report' : 'deactive-report'" >
                <v-menu v-if="item.downloads && item.status != 'unbuilt-ready'&& item.downloads.length > 0" :rounded="'b-lg'" offset-y>
                  <template v-if="userValue.role == 'Observer'? isHidden : !isHidden" v-slot:activator="{ attrs, on }">
                    <!-- <v-btn
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    >
                     <v-img src="../../assets/images/download.png"></v-img>
                    </v-btn> -->
                    <v-icon v-bind="attrs" v-on="on">mdi-download</v-icon>
                  </template>
                  <v-list color="grey darken-3">
                    <v-list-item v-for="(results, index) in item.downloads" :key="index" link>
                      <v-list-item-title v-text="results.text" @click="openResult(results)"></v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                  </v-list>
                </v-menu>
                <!-- <div v-else>Not Ready</div> -->
              </div>
            </template>

            <!-- <template v-slot:item.settings="{ item }">
              <v-btn text small><v-icon>mdi-weather-sunny</v-icon></v-btn>
            </template>mdi-information-outline -->

            <template v-slot:item.logs="{ item }">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, ttprops }">
                  <v-btn text small @click="viewLogs(item)" :disabled="item.status == 'unbuilt-notready'" v-bind="ttprops"
                    v-on="on"><v-icon>mdi-eye</v-icon></v-btn>
                </template>
                <span>View Logs</span>
              </v-tooltip>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <!-- <v-icon>mdi-information-outline</v-icon> -->
              <td :colspan="headers.length">
                <p style="white-space: pre-wrap">{{ item.help }}</p>
              </td>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, ttprops }">
                  <v-btn icon @click.prevent="stageSettings(item)" v-bind="ttprops"
                    v-on="on"> <v-icon>mdi-cog</v-icon> </v-btn>
                </template>
                <span>Stage Settings</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-layout>
        <ActionSettingDialog ref="ActionSettingDialog" />
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'
import axios from 'axios'
import ActionSettingDialog from './ActionSettingDialog'

export default {
  name: 'AuditActions',
  components: {
    vSelect,
    ActionSettingDialog,
  },

  computed: {
    ...mapGetters([
      'activeElection',
      'activeAuditJob',
      'jobOperations',
      'jobOperationsLoading',
      'refstatusLoading',
      'activeOperation',
      'auditTypes',
      'user',
      'statusInformation',
      'argStages',
    ]),
  },
  mounted() {
    this.groupId = 'ballot_image_audit'
    this.userValue = this.user
  },
  data() {
    return {
      userValue: {},
      isHidden: false,
      expanded: [],
      singleExpand: true,
      waiting: false,
      dialog: false,
      helpDialog: false,
      stageSettingDialog: false,
      helpTitle: '',
      helpText: '',
      groupId: '',
      lock: false,
      operation_name: '',
      operation_id: '',
      operation_logs: '',
      log_timer: null,
      itm_name: null,
      file_name: null,
      selectedGroup: {},
      pipelineFile: false,
      operations: [],
      settingDialog: false,
      disablePipeline: false,
      refesh: 0,
      selectedOperations: [],
      clear_results: false,
      headers: [
        { text: 'Stage', value: 'name', sortable: false, width: '15%' },
        { text: 'Action', value: 'running', sortable: false, width: '5%' },
        { text: 'Status', value: 'status', sortable: false, width: '10%' },
        // { text: 'Percent', value: 'percent', sortable: false, width: '15%' },
        { text: 'Progress', value: 'progress', sortable: false, width: '15%' },
        // { text: 'Result', value: 'result', sortable: false, width: '5%'},
        // { text: 'Info', value: 'info', sortable: false},
        { text: 'Reports', value: 'reports', sortable: false, width: '15%' },
        { text: 'Downloads', value: 'downloads', sortable: false, width: '15%' },
        // { text: 'Settings', value: 'settings', sortable: false, width: '5%'},
        { text: 'Logs', value: 'logs', sortable: false, width: '10%' },
        { text: 'Help', value: 'data-table-expand', width: '5%' },
        { text: 'Settings', value: 'actions', width: '20%' },
      ],
    }
  },
  methods: {
    async onGroupChange(selectedGroupId) {
      if (this.refesh == 0) {
        let dataF = await this.$store.dispatch('Refeshforcefully', this.activeAuditJob.id)
        if (dataF.error) {
          this.pipelineFile = true;
        }
      } else {
        await this.$store.dispatch('RefreshStatus', this.activeAuditJob.id)
        this.refesh = 0
      }
      //let operations = this.auditTypes[selectedGroupId].operations
      this.groupId = this.auditTypes[selectedGroupId].id
      this.group = this.auditTypes[selectedGroupId].id
      this.helpTitle = this.auditTypes[selectedGroupId].name
      this.helpText = this.auditTypes[selectedGroupId].description

      let operations = this.statusInformation
      this.operations = []
      for (let i = 0; i < operations.length; i++) {
        this.operations.push({
          operation: operations[i].value,
          name: operations[i].name,
          help: '',
          running: false,
          status: operations[i].status,
          percent: 0,
          progress: 0,
          // result: 'N/A',
          reports: operations[i].reports,
          downloads: operations[i].downloads,
          settings: '',
          info: '',
          operation_id: operations[i].operation_id,
          id: operations[i].id,
          logs: operations[i].logs,
        })
      }
    },

    async resetSelectedOperations() {
      for (let i = 0; i < this.selectedOperations.length; i++) {
        await this.resetOperation(this.selectedOperations[i])
      }
    },
    async argumentChanged() {
      // console.log('val',this.file_name)
    },
    async refreshforce(item) {
      this.itm_name = item.name
      this.waiting = true
      if (item.id != '' && item.id != undefined) {
        const ret = await this.$store.dispatch('ResetJobOperation', item.id)
      }
      this.lock = true
      this.waiting = false
      return true
    },
    async CreatePipeline() {
      this.disablePipeline = true
      this.waiting = true
      await this.$store.dispatch('CreatePipeline', this.activeAuditJob.id)
      let dataF = await this.$store.dispatch('Refeshforcefully', this.activeAuditJob.id)
      if (dataF.error) {
        this.pipelineFile = true;
      }
      this.waiting = false
      this.disablePipeline = false
    },
    async resetOperation(operation) {
      this.itm_name = operation.name
      this.waiting = true
      if (operation.id != '' && operation.id != undefined) {
        const ret = await this.$store.dispatch('ResetJobOperation', operation.id)
      }
      this.waiting = false
      return true
    },

    async launchSelectedOperations() {
      for (let i = 0; i < this.selectedOperations.length; i++) {
        await this.launchOperation(this.selectedOperations[i])
      }
    },
    async launchOperation(operation) {
      this.waiting = true
      const payload = {
        job_id: this.activeAuditJob.id,
        group_id: this.groupId,
        operation: operation.operation,
        action: true,
        force_rebuild: true,
      }
      if (this.clear_results) {
        payload['clear_results'] = this.clear_results
      }
      const ret = await this.$store.dispatch('LaunchJobOperation', payload)
      // this.refesh = 1;
      // await this.onGroupChange(0)

      this.waiting = false
      this.itm_name = ''

      return true
    },
    async launchOperationforce(operation) {
      this.waiting = true
      const payload = {
        job_id: this.activeAuditJob.id,
        group_id: this.groupId,
        operation: operation.operation,
        action: true,
        force_rebuild: true,
      }

      if (this.clear_results) {
        payload['clear_results'] = this.clear_results
      }
      const ret = await this.$store.dispatch('LaunchJobOperation', payload)
      // this.refesh = 1;
      // await this.onGroupChange(0)
      this.waiting = false
      this.itm_name = ''
      return true
    },
    // async refreshSelectedOperations() {
    //    for(let i = 0; i < this.operations.length; i++) {
    //     await this.refreshstatus(this.operations[i])
    //   }
    // },
    async refreshstatus() {
      this.waiting = true
      this.refesh = 0
      this.itm_name = ''

      await this.onGroupChange(0)
      this.waiting = false
      return true
    },

    async stopOperation(operation) {
      console.log(operation,'operation');
      this.waiting = true
      if (operation.id != '') {
        const ret = await this.$store.dispatch('StopJobOperation', operation.id)
        console.log('ret',ret.value);
      }
      //this.refesh = 1;
      //await this.onGroupChange(0)
      this.waiting = false
      return true
    },

    openLink(report) {
      window.open(report.url, '_blank')
    },
    openResult(results) {
      window.open(results.url, '_blank')
    },
    viewLogs(item) {
      this.operation_name = item.name
      this.operation_id = item.operation_id
      if (this.operation_id != undefined) {
        this.operation_logs = ''
        this.log_timer = setInterval(this.updateLogs, 1500)
      } else {
        if (item.logs != undefined) {
          this.operation_logs = item.logs
        }
      }

      this.dialog = true
      this.operations.filter((item) => {
        if (item.status == 'RUNNING') {
          this.scrollLogToBottom()
          return true
        }
      })
    },
    closeSettingDialog() {
      this.settingDialog = false
    },
    closeLogs() {
      clearInterval(this.log_timer)
      this.dialog = false
    },
    async stageSettings(item) {
      // await this.$store.dispatch('loadArgSpecsStages')
      // if(argStages.length > 0){
      //   for(let i=0;i<argStages.length;i++){

      //   }
      // }
      // console.log(item);
      // this.stageSettingDialog = true
      this.overlay = true

      this.$store.commit('argStagesLoaded', [])
      const args = await this.$store.dispatch('loadArgSpecsStages')
      const payload = {
        job_id: this.activeAuditJob.id,
      }
      let ret = await this.$store.dispatch('getActionFile', payload)

      this.overlay = false
      // this.settingDialog = true;
      this.$refs.ActionSettingDialog.show(item, this.operations)
    },
    closeSetting() {
      this.stageSettingDialog = false
    },
    async updateLogs() {
      const url = 'https://server.auditengine.org/logs/operation-logs/' + this.operation_id + '/log.txt'
      //await fetch(url).then(response => {console.log('res',response)});
      // console.log('res',res.json)
      // const operation_log =  await this.$store.dispatch('getAutitLog', url)
      // if(this.operation_logs != operation_log){
      //     this.operation_logs = operation_log
      //     var textarea = document.getElementById('operation_log_screen')
      //     textarea.scrollTop = textarea.scrollHeight;
      //   }
      axios
        .get(url, {
          crossDomain: true,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        })
        .then((response) => {
          if (this.operation_logs != response.data) {
            this.operation_logs = response.data
            var textarea = document.getElementById('operation_log_screen')
            textarea.scrollTop = textarea.scrollHeight
          }
        })
        .catch((e) => {
          console.log('error', e)
        })
    },
    scrollLogToBottom() {
      if (this.operation_logs != '') {
        var textarea = document.getElementById('operation_log_screen')
        textarea.scrollTop = textarea.scrollHeight
      }
    },
  },
  watch: {
    // statusInformation(){
    //   for(let i = 0; i < this.statusInformation.length; i++) {
    //     for(let j = 0; j < this.operations.length; j++){
    //       if (this.operations[j].operation == this.statusInformation[i].value && this.groupId == this.jobOperations[i].group_id) {
    //         this.operations[j].running = this.statusInformation[i].running
    //         this.operations[j].status = this.statusInformation[i].status
    //         // this.operations[j].info = this.statusInformation[i].description
    //         this.operations[j].operation_id = this.statusInformation[i].operation_id
    //         this.operations[j].id = this.statusInformation[i].id
    //         this.operations[j].reports = this.statusInformation[i].reports
    //         this.operations[j].downloads = this.statusInformation[i].downloads
    //       }
    //     }
    //   }

    // },
    jobOperations() {
      for (let i = 0; i < this.jobOperations.length; i++) {
        for (let j = 0; j < this.operations.length; j++) {
          if (
            this.operations[j].operation == this.jobOperations[i].operation &&
            this.groupId == this.jobOperations[i].group_id
          ) {
            this.operations[j].running = this.jobOperations[i].running
            this.operations[j].progress = this.jobOperations[i].progress
            this.operations[j].status = this.jobOperations[i].status
            // this.operations[j].info = this.jobOperations[i].description
            this.operations[j].operation_id = this.jobOperations[i].uid
            this.operations[j].id = this.jobOperations[i].id
            // this.operations[j].reports = this.jobOperations[i].reports
            //unbuilt-ready ilt-notready uilt'
          }
        }
      }
    },
  },
}
</script>

<style>
#operation_log_screen {
  font-family: 'Courier New', Courier, monospace;
}

.deactive-report button,
.deactive-report div {
  pointer-events: none;
  cursor: not-allowed;
}

.deactive-report div {
  padding: 5px;
}

.v-menu__content {
  max-height: 500px;
}

.cardTitle {
  position: relative;
}

.helpClass {
  position: absolute;
  right: 14px;
  bottom: 151px;
}
</style>