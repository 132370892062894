<template>
  <v-row dense class="reduce-row-gaps">
    <v-col cols="12">
      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field
            v-model="search"
            outlined
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap>
            <v-data-table
              :headers="headers"
              :items="alluploadRequest"
              :search="search"
              class="elevation-1"
              :loading="alluploadRequestLoading"
              loading-text="Upload request  List Loading ..."
              style="width: 100%;"
            > 

             <template v-slot:[`item.id`]="{ item }">
                <a :href="`https://${host}/upload/${item.id}/${item.election.state+'_'+item.election.county.replace(' ','').replace('-','').replace('.','')}`" target="__blank">https://{{host}}/upload/{{item.id}}/{{item.election.state+'_'+item.election.county.replace(' ','').replace('-','').replace('.','')}}</a>
              </template> 
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'UserRequest',

  components: {},

  computed: {
    ...mapGetters(['alluploadRequest','alluploadRequestLoading','uploadAllRequestsLoaded']),
  },

  mounted() {
    this.$store.dispatch('loadAllUploadRequest')
  },

  data() {
    return {
      search: '',
      host:'engine.auditengine.org',
      headers: [
        { text: 'Election', align: 'start', sortable: true, value: 'election.name', width: '10%' },
        { text: 'Contact', align: 'start', sortable: true, value: 'name', width: '10%' },
        { text: 'Email', align: 'start', sortable: true, value: 'email', width: '10%' },
        { text: 'Expiration', align: 'start', sortable: true, value: 'valid_through', width: '10%' },
        { text: 'Link', align: 'start', sortable: false, value: 'id', width: '10%' }
      ],
      selectedPermits: [],
    }
  },
  methods: {
   
  },

  watch: {
    
  },
}
</script>

