<template>
  <v-container>
  <v-row dense class="reduce-row-gaps">
    <!-- <v-container>
      <template>
     <v-progress-linear
        color="amber"
        height="25"
        :value="!isNaN(this.activeProject.project.donation_target) && !isNaN(this.activeProject.project.received_donation) ? (Number(this.activeProject.project.received_donation)/Number(this.activeProject.project.donation_target)*100) : 0"
      >
        <template v-slot="{ value }">
          <strong>{{ value.toFixed(2) }} %</strong>
        </template>
      </v-progress-linear>
      </template>
    </v-container> -->
    <!-- <h1>Full detail {{ auditActiveJobLoading ? 'Loading...' : JSON.stringify(activeAuditJob) }}</h1> -->
    
    <v-dialog v-model="accessabilityDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Select any one option:</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-radio-group
                v-model="radios"
                column
                class="radioBtn"
              >
                <v-radio
                  label="Keep my donation private."
                  color="primary"
                  value="0"
                ></v-radio>
                <v-radio
                  label="Please list me as a donor in this project."
                  color="primary"
                  value="1"
                ></v-radio>
              </v-radio-group>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="close()">Close</v-btn>
          <v-btn color="blue darken-1" outlined @click="confirmDonation()">Confirm Donation
            <v-progress-circular
              indeterminate
              color="primary"
              v-if="createPaymentSessionLoading"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="instructionDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Instruction</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                Please consider contributing by check if you are donating $1,000 or more.
              </v-col>
              <v-col cols="12">
                To donate by check, please make checks payable to Citizens Oversight, and note the project you want to earmark.
              </v-col>
              <v-col cols="12">
                Please mail to: <br>
                Citizens Oversight <br>
                771 Jamacha Rd. #148 <br>
                El Cajon, CA 92019 <br>
              </v-col>
              <v-col cols="12">
                Please note: we appreciate getting your donation in check form because we do not have to pay credit card processing fees!
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="close()">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col cols="12" class="offset-md-2">
    <form @submit.prevent="checkDonation">
      <v-card :loading="auditActiveJobLoading" class="" max-width="1200">
        <v-card-text>
          <h3 v-if="this.activeProject.project && this.activeProject.project.donation_target != 0">Fundraising Goal : $ {{this.activeProject && this.activeProject.project.donation_target}}</h3>
          <template slot="progress">
            <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
          </template>
          <!-- <v-btn icon @click.prevent="backProject()" class="mb-1">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>Back -->
        </v-card-text>
        <v-card-title>
          <span class="headline">
            {{ this.activeProject && this.activeProject.project.description ? this.activeProject.project.description : '' }}
          </span>
        </v-card-title>
        <v-card-text>
            <!-- <pre>{{ this.activeProject && this.activeProject.project.pro_intro ? this.activeProject.project.pro_intro : '' }}</pre> -->
              <div v-html="markdownToHtml"></div>
        </v-card-text>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-container>
                  <radial-progress-bar
                    class="mx-auto"
                    :diameter="300"
                    :completed-steps="completedSteps"
                    :total-steps="totalSteps"
                  >
                    <h3 v-if="totalSteps != 0">Fundraising Goal: $ {{ totalSteps }}</h3>
                    <h3>Contributions Received: $ {{ completedSteps }}</h3>
                  </radial-progress-bar>
                </v-container>
              </v-col>
              <v-col cols="6">
                <v-card-title>
                  <span class="">Your Contribution : </span>
                </v-card-title>
                <div>
                  <v-btn class="donateValue" color="white" outlined @click="handlePrice(500)">$ 500</v-btn>
                  <v-btn class="donateValue" color="white" outlined @click="handlePrice(250)">$ 250</v-btn>
                  <v-btn class="donateValue" color="white" outlined @click="handlePrice(100)">$ 100</v-btn>
                  <v-btn class="donateValue" color="white" outlined @click="handlePrice(50)">$ 50</v-btn>
                  <v-btn class="donateValue" color="white" outlined @click="handlePrice(25)">$ 25</v-btn>
                  <v-btn class="donateValue" color="white" outlined @click="handlePrice(10)">$ 10</v-btn>
                </div>
                <v-text-field
                  label="Amount"
                  type="number"
                  min="0"
                 @keypress="isNumber($event)"
                 v-on:keyup="handleChange($event)"
                  v-model="price"
                  
                  outlined
                  >
                </v-text-field>
                <v-checkbox v-model="selected" label="Make this a recurring monthly contribution until you cancel it."></v-checkbox>
               <!-- <v-checkbox v-model="selected" label="Object" :value="checkboxObjectValue"></v-checkbox> -->
                <!-- <v-spacer></v-spacer> -->
                  <!-- v-model="ex8" -->
                <p class="highlight">
                  Please consider contributing by check if you are donating 
                  <strong>$1,000</strong> 
                  or more. <b class="click font-weight-bold" @click="openDialog">Click here for instructions.</b>
                </p>
                <p class="highlight">
                  Citizens Oversight is a 501(c)3 nonprofit organization. Your donation is tax deductible.
                </p>
                <!-- <p >
                  Click here for instructions.
                </p> -->
                <v-btn color="blue darken-1" type="submit" outlined
                  >{{ !createPaymentSessionLoading ? 'CheckOut' : '' }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </form>
    </v-col>

    <!-- <v-col cols="12">
      <v-card max-width="100%">
        <v-card-title>
          <span class="headline">Election</span>
        </v-card-title>
        <v-card-title class="mb-6">
          <v-text-field
            v-model="search"
            outlined
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap>

             <v-data-table
              :headers="headers"
              :items="elections"
              :search="search"
              class="elevation-1"
              :loading="electionLoading"
              loading-text="Election Data Loading ..."
              style="width: 100%;"
            >
              <template v-slot:[`item.county`]="{ item }">
                  {{item.county}}, {{item.state}}, {{item.country}}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn icon @click.prevent="openElection(item)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>

                <v-btn v-if="item.role == 'Auditor'" icon @click.prevent="deleteElection(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col> -->
  </v-row>
  </v-container>
</template>


<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import RadialProgressBar from 'vue-radial-progress'
import marked from 'marked';
export default {
  name: 'AuditsDetail',
  components: {
    RadialProgressBar,
  },
  mounted() {
    
  },
  data() {
    return {
      selected: '',
      price: 0,
      completedSteps: 0,
      totalSteps: 0,
      instructionDialog: false,
      accessabilityDialog: false,
      radios: null,
     // stripeAPIToken: 'pk_test_7SB2QMpnvPKhyqVlDQNYgaLG',
     stripeAPIToken:'pk_live_8SN1v2fgMje93cz4CQCokc6m',
      stripe: '',
      sessionId:null,
      search: '',
    }
  },
  computed: {
    ...mapGetters(['activeProject', 'auditActiveJobLoading', 'activeAuditJob', 'createPaymentSessionLoading', 'electionLoading']),
    paramsid() {
      this.completedSteps = this.activeProject.project && this.activeProject.project.received_donation ? this.activeProject.project.received_donation : 0
      this.totalSteps = this.activeProject.project && this.activeProject.project.donation_target ? this.activeProject.project.donation_target : 0
      return this.$route.params.name
    },
    markdownToHtml(){
      return marked(this.activeProject && this.activeProject.project.pro_intro ? this.activeProject.project.pro_intro : '')
    }
  },
  watch: {
    // paramsid() {
    //   this.$store.dispatch('setAuditJob', 28)
    //   this.$store.dispatch('loadOpenAuditJobs')
    // },
  },
  async mounted() {
    await this.$store.dispatch('getproject',this.$route.params.name)
    console.log(this.activeProject.project);
    this.includeStripe('js.stripe.com/v3/', function(){
        this.configureStripe();
    }.bind(this));
    //this.getStripePublishableKey()
    this.completedSteps = this.activeProject.project && this.activeProject.project.received_donation ? this.activeProject.project.received_donation : 0
    this.totalSteps = this.activeProject.project && this.activeProject.project.donation_target ? this.activeProject.project.donation_target : 0 
  },
  methods: {
    includeStripe( URL, callback ){
        let documentTag = document, tag = 'script',
            object = documentTag.createElement(tag),
            scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.src = '//' + URL;
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
        scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe(){
        this.stripe = Stripe(this.stripeAPIToken);            
    },
    backProject() {
      this.$router.push('/projects');
    },
    handlePrice(donation) {
      this.price = donation
    },
    openDialog() {
      this.instructionDialog = true
    },
    async processToCheckout(){
      if(this.radios) {
          let payload = JSON.stringify({
              project_id: this.$route.params.name,
              price: this.price,
              host: window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2],
              access: this.radios
          });

          const res_pay_data = await this.$store.dispatch('genrateProjectPaymentSession',payload)
          if(res_pay_data.sessionId)
          {
            return this.stripe.redirectToCheckout({ sessionId: res_pay_data.sessionId })
          }
      }
    },
    handleChange(evt){
      evt = (evt) ? evt : window.event
      var code = (evt.which) ? evt.which : evt.keyCode
      if(code == 189 || this.donationauditprice < 0) {
        this.donationauditprice = 0
        return false
      }
      return true      
    },
    isNumber($evt){
      if($evt.keyCode != 45) {
        return true
      } else {
        $evt.preventDefault();
      }
    },
    /*processToCheckout() {
      console.log("this", this.radios)
      if(this.radios) {
        fetch(`${process.env.VUE_APP_ROOT_API}/project-donation`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            project_id: this.$route.params.name,
            price: this.price,
            host: window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2],
            access: this.radios
          }),
        })
          .then(result => result.json())
          .then(data => {
            // Redirect to Stripe Checkout
            return this.stripe.redirectToCheckout({ sessionId: data.sessionId })
          })
          .then(res => {
            this.$route.push(`/projects/success/${this.projectId}?session_id=${data.sessionId}`)
          })
      } else {
        Vue.notify({
          group: 'messages',
          type: 'error',
          duration: 5000,
          text: "Please Choose any one option."
        })
      }
     /* this.$store.dispatch('genratePaymentSession', {
        price: this.price,
        host: window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2],
      })*/
   // },
    checkDonation() {
      if (this.price <= 0) {
        Vue.notify({
          group: 'messages',
          type: 'error',
          duration: 2000,
          text: "Invalid amount. Please enter an amount greater than 0."
        });
      } else {
        if (1000 <= this.price) {
          this.instructionDialog = true
        } else {
          this.accessabilityDialog = true
          // this.processToCheckout();
        }
      }
    },
    confirmDonation() {
      if(!this.radios) {
        Vue.notify({
          group: 'messages',
          type: 'error',
          duration: 5000,
          text: "Please Choose any one option."
        })
      } else {
        this.processToCheckout();
      }
    },
    close() {
      this.instructionDialog = false
      this.accessabilityDialog = false
      this.price = 0
    },

    /*getStripePublishableKey() {
      this.stripe = Stripe(
       'pk_live_8SN1v2fgMje93cz4CQCokc6m'
       //'pk_test_7SB2QMpnvPKhyqVlDQNYgaLG'
      )
    },*/
  },
}
</script>

<style>
h3{
  float: right;
}
button.v-btn.v-btn--depressed.v-btn--flat.v-btn--outlined.theme--dark.v-size--default.blue--text.text--darken-1 {
    float: right;
    margin-right: 10px;
    margin-top: 10px;
}

.theme--dark.v-btn.donateValue {
  left: 5px;
  margin: 5px;
}

.v-application p.highlight{
  margin: 10px;
}
.v-application b.click{
  margin: 10px;
}
.v-application b.click:hover{
  cursor: pointer;
}
.v-input--selection-controls.radioBtn{
    margin-top: 0px !important;
    padding-top: 0px !important;
}

</style>
