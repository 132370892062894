<template>
  <v-row dense class="reduce-row-gaps">
    <v-dialog v-model="JobDialog" persistent max-width="80%">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Markdown</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-col cols="12">
              <v-text-field v-model="mark_title" label="Title" outlined></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <vue-simplemde :highlight="true" v-model="md_text" />
            </v-col>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="closeDialog">Close</v-btn>
          <v-btn color="blue darken-1" @click="markdownfileupdate()" outlined>
            {{ !markdownFileLoading ? 'Update' : '' }}
            <v-progress-circular indeterminate color="primary" v-if="markdownFileLoading"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- filuploading error-->
    <v-dialog v-model="fileUploadeErrorDialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>The type of file already exist. Please try again after deleting the file.</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="fileUploadeErrorDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- upload  -->
    <v-dialog v-model="Markdowndialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Upload markdown</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>

              <v-col cols="12">
                <v-text-field v-model="title" label="Title" outlined></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <vue-dropzone ref="myVueDropzone" id="dropzone" class="transparent-dropzone vue-dropzone-multiple"
                  @vdropzone-sending="sendingEvent" @vdropzone-success="vsuccess" @vdropzone-file-added="fileAdded"
                  @vdropzone-files-added="filesAdded" @vdropzone-removed-file="fileRemoved" :options="dropzoneOptions">
                </vue-dropzone>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="closemarkdownDialog">Close</v-btn>
          <v-btn color="blue darken-1" @click="save()" outlined>
            Add
            <v-progress-circular indeterminate color="primary" v-if="markdownFilesLoading"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
            clearable></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn @click="UploadMarkdown()" color="blue-grey darken-1"> Upload New Markdown </v-btn>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap>
            <v-data-table :headers="headers" :items="markdownfile" :search="search" class="elevation-1"
              :loading="markdownFilesLoading" loading-text="Markdown Files Loading ..." style="width: 100%;">

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="editJobPermission(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Markdown Job Permission</span>
                </v-tooltip>

                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="downloadFile(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>Markdown Download File</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import VueSimplemde from 'vue-simplemde'
import hljs from 'highlight.js';
window.hljs = hljs;
export default {
  name: 'MarkDownFiles',
  components: {
    vueDropzone: vue2Dropzone,
    VueSimplemde
  },

  computed: {
    ...mapGetters([
      'getUsersList',
      'getUsersLoading',
      'getUserUpdateLoading',
      'auditJobs',
      'auditJobLoading',
      'updateAuditDonationLoading',
      'job_perm',
      'jobpermission',
      'JobrequestLoading',
      'markdownFiles',
      'loadmarkdownfiles',
      'markdownFilesLoading',
      'singleMarkdownFile',
      'markdownFileLoading',
      'singleMarkdownfileLoad'
    ]),
  },

  mounted() {
    //this.$store.dispatch('loadUsers')
    //this.$store.dispatch('loadAuditJobs')
    this.access_token = JSON.parse(localStorage.getItem('user')).access_token
    this.dropzoneOptions.headers['Authorization'] = 'Bearer ' + this.access_token
    this.$store.dispatch('loadmarkdownfiles')
  },

  data() {
    return {
      value: 'Hello world!',
      search: '',
      id: '',
      file_name: '',
      file_type: '',
      flag: '',
      size: '',
      md_text: '',
      mark_title: '',
      headers: [
        { text: 'ID', value: 'id', width: '10%' },
        { text: 'Title', align: 'start', sortable: true, value: 'title', width: '10%' },
        { text: 'File Name', align: 'start', sortable: true, value: 'file_name', width: '20%' },
        { text: 'File Type', align: 'start', sortable: false, value: 'file_type', width: '20%' },
        { text: 'Size', align: 'start', sortable: false, value: 'size', width: '20%' },
        { text: 'Last Modification', align: 'start', sortable: false, value: 'last_modified', width: '10%' },
        { text: 'Actions', value: 'actions', width: '10%' },
      ],
      markdownfile: [],
      selectedPermits: [],
      JobDialog: false,
      job_permission: '',
      waiting: false,
      permissionStates: null,
      title: '',
      markdownFileLoading: false,
      Markdowndialog: false,
      fileSelected: false,
      fileUploading: false,
      fileUploadeErrorDialog: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_ROOT_API + '/markdown-settings/upload',
        headers: { 'Authorization': '' },
        thumbnailWidth: 200,
        timeout: 3600000, // 3600s
        maxFilesize: 5,  //5MB
        addRemoveLinks: true,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>UPLOAD HERE",
        maxFiles: 4,
        parallelUploads: 4,
        uploadMultiple: false,
        autoProcessQueue: false,
        acceptedFiles: '.md',
      },
      fileContent: null,
      fileToRender: "",
      rawContent: null
    }
  },
  created: function () {

  },
  methods: {
    async editJobPermission(item) {
      this.md_text = 'Rendering....'
      this.JobDialog = true;
      const mark_data = await this.$store.dispatch('loadmarkdownbyid', item.id)
      this.md_text = mark_data.markdoen_content
      this.mark_title = mark_data.title
    },
    downloadFile(item) {
      window.location.href = item.url
    },
    async markdownfileupdate() {

      if (this.md_text != '') {
        this.markdownFileLoading = true
        let payload = {
          'id': this.singleMarkdownFile.id,
          'markdown_content': this.md_text,
          'title': this.mark_title,
          'file_name': this.singleMarkdownFile.file_name
        }
        await this.$store.dispatch('saveMarkdownRequest', payload)
        this.$store.dispatch('loadmarkdownfiles')
        this.JobDialog = false
        this.markdownFileLoading = false
        this.md_text = ''
      } else {
        Vue.notify({
          group: 'messages',
          type: 'error',
          text: 'Markdown File Required'
        })
      }
    },
    permittedJob(JobId, permission) {
      for (let i = 0; i < this.permissionStates.length; i++) {
        if (this.permissionStates[i].job_id == JobId) {
          this.permissionStates[i].permission = permission
        }
      }
    },
    closeDialog() {

      this.JobDialog = false;
    },
    UploadMarkdown() {
      this.Markdowndialog = true;
      this.fileSelected = false
    },
    closemarkdownDialog() {
      this.Markdowndialog = false
    },
    close() {
      this.dialog = false
      this.$refs.myVueDropzone.removeAllFiles()
    },
    async save() {

      /*for(let i = 0; i < this.jobFiles.length; i ++){
        if(this.jobFiles[i].file_type == this.file_type) {
          this.fileUploadeErrorDialog = true
          return
        }
      }*/
      this.markdownFilesLoading = true
      this.uploading = true
      this.$refs.myVueDropzone.processQueue();
    },
    sendingEvent(file, xhr, formData) {
      formData.append('title', this.title)
      formData.append('file_type', 'MarkDown')
    },
    fileAdded(file) {
      this.filesSelected = true
    },
    filesAdded(file) {
      this.filesSelected = true
    },
    fileRemoved(file, error, xhr) {
      const files = this.$refs.myVueDropzone.getAcceptedFiles()
      if (files.length == 0) {
        this.filesSelected = false
      }
    },
    vsuccess(file, response) {
      Vue.notify({
        group: 'messages',
        type: 'success',
        text: file.name + ' uploaded!'
      })
      this.$store.dispatch('loadmarkdownfiles')
      this.uploading = false
      this.Markdowndialog = false
      this.markdownFilesLoading = false
    },


  },

  watch: {
    markdownFiles() {
      this.markdownfile = []
      for (let i = 0; i < this.markdownFiles.length; i++) {
        let item = {
          id: this.markdownFiles[i].id,
          file_name: this.markdownFiles[i].file_name,
          file_type: this.markdownFiles[i].file_type,
          size: this.markdownFiles[i].size,
          url: this.markdownFiles[i].url,
          last_modified: this.markdownFiles[i].last_modified,
          title: this.markdownFiles[i].title
        }

        if (this.markdownFiles[i].size >= 1024 * 1024 * 1024) {
          const size = Math.round(this.markdownFiles[i].size * 100 / (1024 * 1024 * 1024)) / 100
          item.size = size.toString() + ' GB'
        }
        else if (this.markdownFiles[i].size >= 1024 * 1024) {
          const size = Math.round(this.markdownFiles[i].size * 100 / (1024 * 1024)) / 100
          item.size = size.toString() + ' MB'
        }
        else if (this.markdownFiles[i].size >= 1024) {
          const size = Math.round(this.markdownFiles[i].size * 100 / (1024)) / 100
          item.size = size.toString() + ' KB'
        }
        else {
          const size = Math.round(this.markdownFiles[i].size * 100) / 100
          item.size = size.toString() + ' B'
        }
        this.markdownfile.push(item)
      }
    }
  },
}
</script>

<style>
.jobPermitted {
  padding: 0 !important;
}

.vue-dropzone-multiple {
  display: block !important;
}
</style>
<style>
@import '~simplemde-theme-base/dist/simplemde-theme-base.min.css';
</style>