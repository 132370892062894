<template>
  <dash-layout>
    <template slot="dash-title">Audit Jobs</template>
    <template slot="dash-body">
      <v-row dense class="reduce-row-gaps">
        <v-dialog v-model="auditTypeDeleteDialog" max-width="500px" persistent>
          <v-card>
            <v-card-title>Are you sure deleting {{ auditTypeName }} ?</v-card-title>
            <v-card-actions class="pa-8">
              <v-spacer></v-spacer>
              <v-btn class="btn-outline" outlined @click="auditTypeDeleteDialog = false">Cancel</v-btn>
              <v-btn color="error" outlined @click="deleteAuditTypeConfirm" :disabled="overlay">
                Delete
                <v-progress-circular v-if="overlay" :width="3" color="error" indeterminate></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="auditTypeConfirmDialog" max-width="500px" persistent>
          <v-card>
            <v-card-title style="word-break: break-word;">Are you sure you want to close without {{ active_audit_type.id ?
              'Updating' : 'Adding' }}?</v-card-title>
            <v-card-actions class="pa-8">
              <v-spacer></v-spacer>
              <v-btn class="btn-outline" outlined @click="auditTypeConfirmDialog = false">Cancel</v-btn>
              <v-btn color="error" outlined @click="con_dialog" :disabled="overlay">
                Ok
                <v-progress-circular v-if="overlay" :width="3" color="error" indeterminate></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="auditTypeDialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">{{ active_audit_type.id ? 'Update Audit Type' : 'Add New Audit Type' }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field label="Name" v-model="auditTypeName" outlined></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field label="ID" v-model="auditTypeId" outlined
                      :readonly="!!active_audit_type.id"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox label="Premium" v-model="auditTypePremium" outlined></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                    <v-checkbox label="Show" v-model="auditTypeShow" outlined></v-checkbox>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea class="text-area" clearable clear-icon="cancel" label="Help" v-model="auditTypeHelp"
                      outlined></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" outlined @click="closeDialog">Close</v-btn>
              <v-btn color="blue darken-1" outlined @click="saveAuditType" :disabled="saveDisabled">
                {{ active_audit_type.id ? 'Update' : 'Save' }}
                <v-progress-circular indeterminate color="primary" v-if="overlay"></v-progress-circular>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-col cols="12">
          <v-card max-width="100%">
            <v-card-title class="mb-6">
              <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
                clearable></v-text-field>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>

              <v-btn @click="editAuditType({})" color="blue-grey darken-1"> New Audit Type </v-btn>
            </v-card-title>
            <v-container grid-list-xl fluid>
              <v-layout row wrap>
                <v-data-table :headers="headers" :items="audit_types" :search="search" class="elevation-1"
                  :loading="audit_typesLoading" loading-text="Audit Types Loading ..." style="width: 100%">
                  <template v-slot:[`item.premium`]="{ item }">
                    <v-checkbox :input-value="item.premium == 'TRUE' ? true : false" readonly></v-checkbox>
                  </template>

                  <template v-slot:[`item.show`]="{ item }">
                    <v-checkbox :input-value="item.show == 'TRUE' ? true : false" readonly></v-checkbox>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, ttprops }">
                        <v-btn icon @click.prevent="editAuditType(item)" v-bind="ttprops" v-on="on">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Audit Type</span>
                    </v-tooltip>

                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, ttprops }">
                        <v-btn icon @click.prevent="deleteAuditType(item)" v-bind="ttprops" v-on="on">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Delete Audit Type</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-layout>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </dash-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import DashLayout from '../../containers/Layout'

export default {
  name: 'AuditTypesEditor',

  components: {
    DashLayout,
  },

  computed: {
    ...mapGetters(['audit_types', 'active_audit_type', 'audit_typesLoading']),
    saveDisabled: function () {
      if (!this.active_audit_type.id) {
        for (let i = 0; i < this.audit_types.length; i++) {
          if (this.audit_types[i].id == this.auditTypeId.trim()) {
            return true
          }
        }
      }
      return this.auditTypeId.length == 0 || this.auditTypeName.length == 0 || this.overlay
    },
  },

  mounted() {
    this.$store.dispatch('load_audit_types')
  },

  data() {
    return {
      overlay: false,
      search: '',
      auditTypeDialog: false,
      auditTypeDeleteDialog: false,
      auditTypeId: '',
      auditTypeName: '',
      auditTypePremium: false,
      auditTypeShow: true,
      auditTypeHelp: '',
      auditTypeConfirmDialog: false,
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name', width: '15%' },
        { text: 'ID', value: 'id', width: '10%' },
        { text: 'Premium', value: 'premium', width: '5%' },
        { text: 'Show', value: 'show', width: '5%' },
        { text: 'Help', value: 'help', width: '55%' },
        { text: 'Actions', value: 'actions', width: '10%' },
      ],
    }
  },
  methods: {
    editAuditType(item) {
      this.$store.commit('setActive_audit_type', item)
      this.auditTypeDialog = true
    },
    deleteAuditType(item) {
      this.$store.commit('setActive_audit_type', item)
      this.auditTypeDeleteDialog = true
    },
    async saveAuditType() {
      this.overlay = true
      let payload = {
        id: this.auditTypeId.trim(),
        name: this.auditTypeName.trim(),
        premium: this.auditTypePremium ? 'TRUE' : 'FALSE',
        show: this.auditTypeShow ? 'TRUE' : 'FALSE',
        help: this.auditTypeHelp,
      }

      if (this.active_audit_type.id) {
        await this.$store.dispatch('update_audit_type', payload)
      } else {
        await this.$store.dispatch('save_audit_type', payload)
      }
      await this.$store.dispatch('load_audit_types')
      await this.$store.dispatch('loadArgSpecs')

      this.auditTypeDialog = false
      this.overlay = false
    },
    closeDialog() {
      this.auditTypeConfirmDialog = true

    },
    con_dialog() {
      this.auditTypeConfirmDialog = false
      this.auditTypeDialog = false
    },
    async deleteAuditTypeConfirm() {
      this.overlay = true
      await this.$store.dispatch('delete_audit_type', this.auditTypeId)
      await this.$store.dispatch('loadArgSpecs')
      this.auditTypeDeleteDialog = false
      this.overlay = false
    },
  },

  watch: {
    active_audit_type(data) {
      this.auditTypeId = data.id ? data.id : ''
      this.auditTypeName = data.name ? data.name : ''
      this.auditTypePremium = data.premium == 'TRUE' ? true : false
      this.auditTypeShow = data.show == 'TRUE' ? true : false
      this.auditTypeHelp = data.help ? data.help : ''
    },
    auditTypeName(auditTypeName) {
      this.auditTypeId = auditTypeName
        .toLowerCase()
        .replace(/[^\w\s]/gi, '')
        .replaceAll(' ', '_')
    },
  },
}
</script>
<style>
.v-text-field .v-label {
  top: 8px !important;
}
</style>