<template>
  <v-card>
    <v-navigation-drawer v-model="drawer" app width="260" :disable-resize-watcher="false">
      <v-list>
        <v-list-item class="justify-center mt-12 mb-6">
          <v-list-item-content>
            <v-list-item-title class="headline text-center">
              <a href="/"
                ><v-img max-height="150" max-width="250" src="../../assets/logos/AuditEngineLogo.png"></v-img
              ></a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      {{ auditJobLoading ? 'Loading.......' : ''}}

      <v-list nav dense class="mt-5">
        <v-list-item
          v-for="link in auditJobs"
          :key="link.id"
          link
          :ripple="false"
          active-class="highlighted"
          :class="[activeAuditJob && activeAuditJob.id == link.id ? 'nav-selected' : '', 'text-center', 'd-block']"
          @click="changeActiveData(link.id)"
        >
          <v-list-item-content class="pt-0">
            <v-list-item-title class="text-center" style="font-size: 1rem;line-height: 2rem;">{{
              link.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DonationNavbar',
  computed: {
    ...mapGetters(['user', 'getRouteInfo', 'getDrawer', 'auditJobs', 'auditJobLoading', 'activeAuditJob']),
    drawer: {
      get() {
        return this.$store.state.component.drawer
      },
      set(val) {
        this.$store.commit('setDrawer', val)
      },
    },
  },
  mounted() {
    this.$store.dispatch('loadOpenAuditJobs')
  },
  data() {
    return {
    }
  },
  methods: {
    changeActiveData(id) {
      // this.$store.dispatch('setAuditJob', id);
      this.$router.push(`/public/audits/${id}`);
    }
  },
  watch: {
    $route(to, from) {},
    user() {},
  },
}
</script>

<style>
.nav-selected .v-icon {
  color: #b39ddb !important;
}
.nav-selected .v-list-item__title {
  color: #b39ddb !important;
}
.nav-selected::before {
  opacity: 0.08 !important;
}
</style>
