<template>
  <div>
  <v-row dense>
    <v-col md="6">
      <v-card class="reduce-row-gaps">
        <v-divider></v-divider>
        <v-card-text class="picker-popup">
          <v-row>
            <v-col cols="12">
              <v-text-field
                      v-model="name"
                      label="Election Name"
                      outlined
                      :readonly="editNotAllowed"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                      v-model="e_type"
                      :items="['General', 'Primary', 'Presidential Preference Primary', 'Special', 'Municipal']"
                      label="Election Type"
                      outlined
                      :readonly="editNotAllowed || projectId > 0"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-dialog
                      ref="dateDialog"
                      v-model="dateModal"
                      :return-value.sync="date"
                      persistent
                      width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                          v-model="date"
                          label="Election Date"
                          readonly
                          outlined
                          v-if="editNotAllowed || projectId > 0"
                  ></v-text-field>
                  <v-text-field
                          v-model="date"
                          label="Election Date"
                          readonly
                          outlined
                          v-on="on"
                          v-else
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" scrollable dark color="grey">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" outlined @click="dateModal = false">Cancel</v-btn>
                  <v-btn text color="primary" outlined @click="$refs.dateDialog.save(date)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="4">
              <v-select
                      v-model="privacy"
                      :items="['Public', 'Open', 'Closed']"
                      label="Privacy"
                      outlined
                      :readonly="editNotAllowed || projectId > 0"
              ></v-select>
            </v-col>           
            <v-col cols="12">
              <v-select
                      v-model="country"
                      :items="countries"
                      item-text="name"
                      item-value="code"
                      label="Election Country"
                      outlined
                      :readonly="editNotAllowed || projectId > 0"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                      v-if="country == 'US'"
                      v-model="US_state"
                      :items="US_states"
                      @change="changeUSState"
                      item-text="name"
                      item-value="value"
                      label="Election State"
                      outlined
                      :readonly="editNotAllowed || projectId > 0"
              ></v-select>
              <v-text-field
                      v-else
                      v-model="state"
                      label="Election State"
                      outlined
                      :readonly="editNotAllowed"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select
                    v-if="country == 'US'"
                    v-model="county"
                    :items="jurisdictions"
                    label="Election Jurisdiction"
                    outlined
                    :readonly="editNotAllowed || projectId > 0"
                ></v-select>
              <v-text-field
                    v-else
                    v-model="county"
                    label="Election Jurisdiction"
                    outlined
                    :readonly="editNotAllowed || projectId > 0"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                      clearable
                      clear-icon="cancel"
                      label="Description"
                      v-model="description"
                      outlined
                      :readonly="editNotAllowed"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-show="!editNotAllowed" class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="checkElection" :disabled="!canSave">
            {{ projectId > 0 ? 'Update' : 'Create' }}
            <v-progress-circular
                    v-if="saving"
                    :width="3"
                    color="green"
                    indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

  <v-dialog
      v-model="confirmDialog"
      width="800"
    >
      <v-card>
        <v-card-title class="headline warning">
          Please confirm election properties.
        </v-card-title>

        <v-card-text>
          <v-list subheader two-line flat>
             <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Election Date: {{ this.date }}</v-list-item-title>
              </v-list-item-content>
             </v-list-item>

             <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Election Path: {{ this.electionPath }}</v-list-item-title>
              </v-list-item-content>
             </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="saveElection"
          >
            Confirm
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="confirmDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
  import {mapGetters} from 'vuex'
  import { AbbrCountries } from './../../services/AbbrCountries.js'
  import { AbbrStates } from './../../services/AbbrStates.js'

  export default {
    name: 'ProjectElectionOverview',
    props: ['projectId'],

    components: {

    },

    computed: {
      ...mapGetters(['elections', 'activeElection', 'electionLoading', 'user']),
      canSave: function() {
        if(this.country === 'US') {
          return !(this.name.length == 0 || this.county.length == 0 || this.saving)
        }
        else {
          return !(this.name.length == 0 || this.state.length == 0 || this.county.length == 0 || this.saving)
        }
      },
      editNotAllowed: function() {
        return this.projectId > 0 && this.activeElection.role != 'Auditor'
      }
    },

    async mounted() {
      if (this.projectId > 0) {
        this.$store.commit('setLoading', true)
        this.$store.commit('setActiveElection', {})
        let ret = await this.$store.dispatch('getElection', this.projectId)       
        this.$store.commit('setLoading', false)
      }
      else {
        this.country = 'US'
        this.jurisdictions = USjurisdictions['CA']
        this.county = this.jurisdictions[0]
      }
    },

    data() {
      return {
        search: '',
        confirmDialog: false,
        electionPath: '',
        id: 0,
        name: '',
        e_type: 'General',
        privacy: 'Public',
        country: 'US',
        state: '',
        US_state: 'CA',
        county: '',
        date: new Date().toISOString().substr(0, 10),
        description: '',
        role: 'Oberver',
        dateModal: false,
        saving: false,
        countries: AbbrCountries,
        US_states: AbbrStates,
        jurisdictions: [],
      }
    },
    methods: {
      editElection(election) {
        this.id = election.id
        this.name = election.name
        this.e_type = election.e_type
        this.privacy = election.privacy
        this.country = election.country
        this.state = election.state
        this.US_state = election.state
        this.jurisdictions = USjurisdictions[election.state]
        this.county = election.county
        this.date = election.date
        this.description = election.description
        this.role = election.role
      },
      changeUSState(state) {
        this.jurisdictions = USjurisdictions[state]
        if(this.jurisdictions.length > 0)this.county = this.jurisdictions[0]
      },
      checkElection() {
        if(this.projectId > 0) {
          this.saveElection()
        } else {
          this.confirmDialog = true
          if(this.country == 'US') {
            this.electionPath = this.country + '/' + this.US_state + '/' + this.country + '_' + this.US_state + '_' + this.county + '_' + this.e_type + '_'  + this.date.replace(/-/g, "")
          }
          else {
            this.electionPath = this.country + '/' + this.state + '/' + this.country + '_' + this.state + '_' + this.county + '_' + this.e_type + '_'  + this.date.replace(/-/g, "")
          }          
        }
      },
      async saveElection() {
        this.confirmDialog = false
        this.saving = true
        let election = {
          name: this.name,
          e_type: this.e_type,
          privacy: this.privacy,
          country: this.country,
          county: this.county,
          date: this.date,
          description: this.description
        }

        if(this.country == 'US') {
          election.state = this.US_state
        }
        else {
          election.state = this.state
        }

        if(this.id > 0)election['id'] = this.id
        const response = await this.$store.dispatch('saveElection', election)
        this.saving = false
        if(response.id > 0) {
          this.$router.push('/elections/' + response.id)
        }        
      },
    },

    watch: {
      activeElection (val) {
        if (Object.keys(val).length > 0) {
          this.editElection(val)
        }
      }
    }
  }
</script>
