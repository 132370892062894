<template>
  <v-row align="center" justify="center">
    <v-dialog v-model="editDialog" max-width="50%" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-if="editedIndex > -1" v-model="editedItem.argument" label="Name" readonly></v-text-field>
                <v-select v-else :items="availableArguments" label="Name" menu-props="auto" v-model="editedItem.argument"
                  :rules="[rules.required]" @change="argumentChanged"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-if="editedItem.choices.length > 0" :items="editedItem.choices" label="Value"
                  v-model="editedItem.value" :rules="[rules.required]"></v-select>
                <v-text-field v-else v-model="editedItem.value" label="Value" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea label="Help" v-model="editedItem.help" readonly></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="closeEdit">Cancel</v-btn>
          <v-btn color="blue darken-1" outlined @click="updateItem" :disabled="editedItem.value.length == 0">{{
            formSubmitText }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="70%" persistent>
      <v-card max-width="100%">

        <v-card-title>
        </v-card-title>

        <v-container grid-list-xl fluid>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="file_name" label="File Name" :rules="[rules.required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select :items="file_types" label="File Type" v-model="file_type" item-text="description"
                item-value="value" readonly></v-select>
            </v-col>
          </v-row>

          <v-tabs v-model="tab" @change="groupChanged" dark>
            <v-tab v-for="(group, index) in argGroups" :key="index">
              {{ group.group }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(jobSetting, index) in jobSettings" :key="index">
              <v-card flat>
                <v-card-text>
                  <v-data-table :headers="headers" :items="jobSetting.arguments"
                    :loading="jobFileLoading || jobArgSpecsLoading" :search="search" sort-by="calories"
                    class="arguments-table" :footer-props="{
                      'items-per-page-options': [5, 10, 20, 50]
                    }" :items-per-page="5">
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title></v-toolbar-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
                          clearable></v-text-field>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <!-- <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="newItem"
                        >Add Argument</v-btn> -->
                      </v-toolbar>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, ttprops }">
                          <v-btn icon @click="editItem(item)" v-bind="ttprops" v-on="on">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Edit Item</span>
                      </v-tooltip>

                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, ttprops }">
                          <v-btn icon @click="deleteItem(item)" v-bind="ttprops" v-on="on">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Item</span>
                      </v-tooltip>

                      <v-tooltip top color="primary">
                        <template v-slot:activator="{ on, ttprops }">
                          <v-btn icon @click="mergeItem(item)" v-bind="ttprops" v-on="on">
                            <v-icon>mdi-merge</v-icon>
                          </v-btn>
                        </template>
                        <span>Merge Item</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="close">
            Close
          </v-btn>
          <v-btn outlined @click="saveJobSettingFile"
            :disabled="file_name.length == 0 || jobSettings.length == 0 || waiting">
            Adopt All
            <v-progress-circular indeterminate color="primary" v-if="waiting"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AdoptedJobSettingDialog',
  components: {
  },

  data: () => ({
    data: [],
    dialog: false,
    file_name: '',
    old_file_name: '',
    file_type: 'JOB',
    isUpdate: false,
    waiting: false,
    search: '',
    file_types: [
      { value: 'JOB', description: 'Job Configuration' },
      { value: 'EIF', description: 'Election Information' },
      { value: 'BOF', description: 'Ballot Options' },
      { value: 'STC', description: 'Manual Styles to Contests' },
    ],
    types: [],
    editDialog: false,
    headers: [
      { text: 'Argument', value: 'argument', width: '40%', },
      { text: 'Value', value: 'value', sortable: false, width: '40%', },
      { text: 'Actions', value: 'actions', sortable: false, width: '15%', },
    ],
    rules: {
      required: value => !!value || 'Required.',
    },

    jobSettings: [],
    availableArguments: [],
    editGroup: -1,
    editedIndex: -1,
    editedItem: {
      argument: '',
      choices: [],
      format: '',
      help: '',
      multi: '',
      required: '',
      type: '',
      value: '',
    },
    defaultItem: {
      argument: '',
      choices: [],
      format: '',
      help: '',
      multi: '',
      required: '',
      type: '',
      value: '',
    },

    // tab groups
    tab: 0,
    argGroups: [],
  }),

  computed: {
    ...mapGetters(['activeJobFile', 'activeAdoptedFile', 'jobFileLoading', 'jobArgSpecs', 'jobArgSpecsLoading', 'activeAuditJob', 'user', 'setActiveAdoptedFiles']),
    formTitle() {
      return this.editedIndex === -1 ? 'New Argument' : 'Edit Argument'
    },
    formSubmitText() {
      return this.editedIndex === -1 ? 'Add' : 'Save'
    }
  },

  mounted() {
  },

  methods: {
    groupChanged() {
      this.availableArguments = []
      const specGroup = this.argGroups[this.tab]
      const group = this.jobSettings[this.tab]
      for (let i = 0; i < specGroup.arguments.length; i++) {

        let exist = false
        for (let j = 0; j < group.arguments.length; j++) {
          if (specGroup.arguments[i].argument == group.arguments[j].argument) {
            if (!specGroup.arguments[j].multi) exist = true
          }
        }
        if (!exist) this.availableArguments.push(specGroup.arguments[i].argument)
      }
    },
    argumentChanged() {
      const selectedArgGroup = this.argGroups[this.tab]
      for (let i = 0; i < selectedArgGroup.arguments.length; i++) {
        if (this.editedItem.argument == selectedArgGroup.arguments[i].argument) {
          this.editedItem.choices = selectedArgGroup.arguments[i].choices
          this.editedItem.format = selectedArgGroup.arguments[i].format
          this.editedItem.help = selectedArgGroup.arguments[i].help
          this.editedItem.multi = selectedArgGroup.arguments[i].multi
          this.editedItem.required = selectedArgGroup.arguments[i].required
          this.editedItem.type = selectedArgGroup.arguments[i].type
          this.editedItem.value = selectedArgGroup.arguments[i].value
        }
      }
    },
    show(item) {
      this.argGroups = this.jobArgSpecs
      this.jobSettings = []

      this.dialog = true
      if (item.name) {
        this.isUpdate = true
        this.file_name = item.name
        this.old_file_name = item.name
        this.file_type = item.type
        let temp = [];

        this.activeAdoptedFile.map((adopt, index) => {
          this.activeJobFile.map((job, index) => {
            if (job.group === adopt.group) {
              let dm = adopt.arguments.filter((el) => {
                return !job.arguments.some((f) => {
                  return f.argument === el.argument && f.value === el.value;
                });
              });
              temp.push({
                arguments: dm,
                group: job.group
              })
            }
          })
        })
        this.jobSettings = temp
      }
      else {
        for (let i = 0; i < this.argGroups.length; i++) {
          this.jobSettings.push({
            group: this.argGroups[i].group,
            arguments: []
          })
        }

        this.isUpdate = false
        this.file_name = ''
        this.file_type = 'JOB'
      }

      this.tab = 0
      this.groupChanged()
    },

    close() {
      this.dialog = false
      this.waiting = false
    },
    async saveJobSettingFile() {
      let payload = {
        is_update: this.isUpdate,
        job_id: this.activeAuditJob.id,
        file_name: this.file_name,
        file_type: this.file_type,
        settings: this.jobSettings,
        adopt_all: 1
      }

      if (this.isUpdate) {
        payload['old_file_name'] = this.old_file_name
      }

      this.waiting = true
      let ret = await this.$store.dispatch('saveJobFile', payload)
      this.waiting = false

      if (ret['message']) {
        this.dialog = false
        this.$store.dispatch('loadAdoptedFiles', this.activeAuditJob.id)
        this.$store.dispatch('loadjobFiles', this.activeAuditJob.id)
      }
    },

    newItem() {
      this.editDialog = true
    },

    editItem(item) {
      let group = this.jobSettings[this.tab]
      this.editedIndex = group.arguments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editDialog = true
    },

    deleteItem(item) {
      let group = this.jobSettings[this.tab]
      const index = group.arguments.indexOf(item)
      group.arguments.splice(index, 1)
    },

    async mergeItem(item) {
      let group = this.jobSettings[this.tab]
      const index = group.arguments.indexOf(item)
      group.arguments.splice(index, 1)
      let payload = {
        is_update: this.isUpdate,
        job_id: this.activeAuditJob.id,
        file_name: this.file_name,
        file_type: this.file_type,
        settings: this.jobSettings
      }

      if (this.jobSettings.length == 0) {
        payload['adopt_all'] = 1
      }

      if (this.isUpdate) {
        payload['old_file_name'] = this.old_file_name
      }

      this.waiting = true
      let ret = await this.$store.dispatch('saveJobFile', payload)
      this.waiting = false
      if (ret['message']) {
        if (this.jobSettings.length == 0) {
          this.dialog = false
          this.$store.dispatch('loadAdoptedFiles', this.activeAuditJob.id)
          this.$store.dispatch('loadjobFiles', this.activeAuditJob.id)
        }
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: ret['message']
        })
      }
      // console.log(this.jobSettings)
      // let group = this.jobSettings[this.tab]
      // console.log(group)
      // console.log(item)
      // await this.$store.dispatch('mergeJobFile')
    },

    closeEdit() {
      this.editDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    updateItem() {
      let group = this.jobSettings[this.tab]
      if (this.editedIndex < 0) {
        group.arguments.push(this.editedItem)
        if (!this.editedItem.multi) {
          const index = this.availableArguments.indexOf(this.editedItem.argument)
          this.availableArguments.splice(index, 1)
        }
      }
      else {
        group.arguments[this.editedIndex].value = this.editedItem.value
      }
      this.closeEdit()
    },
  },

  watch: {
    dialog(val) {
      val || this.closeEdit()
    },
  }
}
</script>

<style>
.theme--dark.arguments-table.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none;
}
</style>