<template>
  <v-row dense class="reduce-row-gaps">
    <!-- subscribe manually dialog -->
    <v-dialog v-model="paymentSubscriptionModel" max-width="800px" persistent>
      <v-card>
        <v-card-title>Subscription : Manually - {{ activeUser ? activeUser.email : '' }}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-select :items="auditActivePackage" v-model="activePackage" label="Select Package" item-text="name"
                  v-on:change="changePackage()" return-object class="ml-2" :rules="[rules.required]" outlined />
              </v-col>
              <v-col cols="6">
                <v-select :items="PackList" v-model="selectedPlan" label="Package Type" item-text="name"
                  v-on:change="changePackageType()" item-value="id" class="ml-2" outlined />
              </v-col>
              <v-col cols="6" v-if="activePackage">
                <v-text-field v-model="packagePrice" label="Price" outlined :readonly="true"></v-text-field>
              </v-col>
              <v-col cols="12" md="12" v-if="activePackage">
                <v-textarea label="Description" rows="5" v-model="packageDesc" outlined :readonly="true"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="closePaymentSubscriptionModel()">Cancel</v-btn>
          <!-- <v-btn outlined color="error" @click="saveMeanallyPaymentData" :disabled="saving">
            Submit
            <v-progress-circular
                    v-if="saving"
                    :width="3"
                    color="green"
                    indeterminate
            ></v-progress-circular>
          </v-btn> -->
          <v-btn outlined :disabled="!canSave || subSaving" @click="saveMeanallyPaymentData()">
            CREATE
            <v-progress-circular v-if="subSaving" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="permissionButton" max-width="700px" persistent>
      <v-card>
        <v-card-title class="headline">Style Details of - {{ selectedPerName }}</v-card-title>
        <v-list class="transparent permission_job">
          <v-list-item v-for="item in selected_job" :key="item.day">
            <v-list-item-subtitle>{{ item.user.email }}</v-list-item-subtitle>
            <v-list-item>
              Page1:
              <v-list-item-subtitle class="text-right">
                start: {{ item.style_page_1 && item.style_page_1.length > 0 ? item.style_page_1[0].start : item.start }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-right">
                end: {{ item.style_page_1 && item.style_page_1.length > 0 ? item.style_page_1[0].end : item.end }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item>
              Page2:
              <v-list-item-subtitle class="text-right">
                start: {{ item.style_page_2 && item.style_page_2.length > 0 ? item.style_page_2[0].start : item.start }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-right">
                end: {{ item.style_page_2 && item.style_page_2.length > 0 ? item.style_page_2[0].end : item.end }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat="flat" @click="permissionButton = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog class="permission-dialog" max-width="86%" v-model="permissionDialog" persistent>
      <v-card>
        <v-card-title class="headline">Audit Permission - {{ activeUser ? activeUser.email : '' }}</v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="4" class="bootom_part">
                <v-checkbox label="Run Adjudicator" v-model="run_adjudicator" outlined></v-checkbox>
              </v-col>
              <v-col cols="4" class="bootom_part">
                <v-checkbox label="Run Audit" v-model="run_audit" outlined></v-checkbox>
              </v-col>
              <v-col cols="4" class="bootom_part">
                <v-checkbox label="Run targetmapper" v-model="run_targetmapper" outlined></v-checkbox>
              </v-col>
              <v-col cols="4" class="bootom_part">
                <v-checkbox label="Project permission" v-model="project_permission" outlined></v-checkbox>
              </v-col>
              <!-- <v-col cols="4" class="bootom_part">
                <v-checkbox label="Test Job permission" v-model="test_job" outlined></v-checkbox>
              </v-col> -->
              <v-col cols="4" class="bootom_part">
                <v-checkbox label="Full Auth" v-model="fullAuth" outlined></v-checkbox>
              </v-col>
              <v-col cols="4" class="bootom_part">
                <v-checkbox label="Verified" v-model="verified" outlined readonly></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                <v-btn @click="Enable_All_Job">Enable-All</v-btn>
              </v-col>
              <!-- <v-checkbox v-on:change="setAllAudits(selectall_audit)" label="All" class="all_check_btn" v-model="selectall_audit" outlined></v-checkbox> -->
              <v-col cols="2">
                <v-btn @click="Disable_All_job">Disable-All</v-btn>
              </v-col>
              <v-col cols="5"></v-col>
              <v-col cols="2">
                <v-btn @click="Authorized_All_job">Authorized-All-Audits</v-btn>
              </v-col>
            </v-row>
            <v-simple-table dark>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Audit Name
                    </th>
                    <th class="text-left">
                      Full Range
                    </th>
                    <th class="text-left">
                      Page 1
                    </th>
                    <th class="text-left">
                      Start
                    </th>
                    <th class="text-left">
                      End
                    </th>
                    <th class="text-left">
                      Page 2
                    </th>
                    <th class="text-left">
                      Start
                    </th>
                    <th class="text-left">
                      End
                    </th>
                    <th>
                      <v-col cols="1" class="plus_per_job" style=" position: relative;bottom: 15px;">
                        <v-btn @click="add" class="primary plusBtn">+</v-btn>
                      </v-col>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(selectedPermit, i) in selectedPermits" :key="i">
                    <td>
                      <v-text-field class="slected_job" v-if="selectedPermit && selectedPermit.status"
                        v-model="selectedPermit.name" readonly />
                      <v-select v-else :items="auditJobs" v-model="selectedPermit.id" label="Permited Audits"
                        item-text="name" v-on:change="changeRoute(selectedPermit.id, selectedPermit.name)"
                        item-value="id" />

                    </td>
                    <td><v-checkbox v-model="selectedPermit.fullRange" outlined></v-checkbox></td>
                    <td> <v-checkbox v-show="selectedPermit.fullRange == false" v-model="selectedPermit.page_1"
                        outlined></v-checkbox> </td>
                    <td>
                      <v-text-field label="Start Range" type="number" v-model="selectedPermit.start"
                        v-show="selectedPermit.fullRange == false"></v-text-field>
                    </td>
                    <td>
                      <v-text-field label="End Range" type="number" v-model="selectedPermit.end"
                        v-show="selectedPermit.fullRange == false"></v-text-field>
                    </td>
                    <td>
                      <v-checkbox v-show="selectedPermit.fullRange == false" v-model="selectedPermit.page_2"
                        outlined></v-checkbox>
                    </td>
                    <td>
                      <v-text-field label="Start Range" type="number" v-show="selectedPermit.fullRange == false"
                        v-model="selectedPermit.page2_start"></v-text-field>
                    </td>
                    <td>
                      <v-text-field label="End Range" type="number" v-show="selectedPermit.fullRange == false"
                        v-model="selectedPermit.page2_end"></v-text-field>
                    </td>
                    <td>
                      <v-col cols="1" class="reove_per_job">
                        <v-btn @click="remove(i)" class="error removeBtn">-</v-btn>
                      </v-col>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="permissionConfirmDialog">Close</v-btn>
          <v-btn color="blue darken-1" outlined @click="savePermissionData">
            {{ !permissionLoading ? 'Update' : '' }}
            <v-progress-circular indeterminate color="primary" v-if="permissionLoading">
            </v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="userDeleteDialog" max-width="800px" persistent>
      <v-card>
        <v-card-title>Are you sure {{ this.userlist === "Active" ? 'deactivating' : 'deleting permanently' }} {{ name }}'s
          account ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="userDeleteDialog = false">Cancel</v-btn>
          <v-btn outlined color="error" @click="deleteUserConfirm" :disabled="saving">
            {{ this.userlist === "Active" ? 'Deactivate' : 'Delete' }}
            <v-progress-circular v-if="saving" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- reactive user dialog -->
    <v-dialog v-model="reactiveUserDialog" max-width="800px" persistent>
      <v-card>
        <v-card-title>Are you sure reactivating {{ name }}'s account?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="reactiveUserDialog = false">Cancel</v-btn>
          <v-btn outlined color="success" @click="reactivateUserConfirm" :disabled="saving">
            Reactive
            <v-progress-circular v-if="saving" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="paymentDetailDialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>Payment Details</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6" class="flex-box">
                <lable for="">name</lable>
                <span>{{ activeItem && activeItem.payment && activeItem.payment.name }}</span>
              </v-col>
              <v-col cols="6" class="flex-box">
                <lable for="">Email</lable>
                <span>{{ activeItem && activeItem.payment && activeItem.payment.email }}</span>
              </v-col>
              <v-col cols="6" class="flex-box">
                <lable for="">Transaction Id</lable>
                <span>{{ activeItem && activeItem.payment && activeItem.payment.transaction_id }}</span>
              </v-col>
              <v-col cols="6" class="flex-box">
                <lable for="">Amount</lable>
                <span>${{ activeItem && activeItem.payment && activeItem.payment.price }}</span>
              </v-col>
              <v-col cols="6" class="flex-box">
                <lable for="">Payment Status</lable>
                <span>{{ activeItem && activeItem.payment && activeItem.payment.payment_status }}</span>
              </v-col>
              <v-col cols="6" class="flex-box">
                <lable for="">Currency</lable>
                <span>{{ activeItem && activeItem.payment && activeItem.payment.currency }}</span>
              </v-col>

              <v-col cols="6" class="flex-box">
                <lable for="">City</lable>
                <span>{{ activeItem && activeItem.payment && activeItem.payment.city }}</span>
              </v-col>
              <v-col cols="6" class="flex-box">
                <lable for="">State</lable>
                <span>{{ activeItem && activeItem.payment && activeItem.payment.state }}</span>
              </v-col>
              <v-col cols="6" class="flex-box">
                <lable for="">Country</lable>
                <span>{{ activeItem && activeItem.payment && activeItem.payment.country }}</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="closePaymentDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="subscriptionDialog" max-width="1200px" persistent>
      <v-card>
        <v-card-title>Subscription Details - {{ activeUser ? activeUser.email : '' }}</v-card-title>
        <v-card-text>
          <v-row dense class="reduce-row-gaps">
            <v-col cols="12">
              <v-card max-width="100%">
                <v-card-title class="mb-6">
                  <v-text-field v-model="subSearch" outlined append-icon="mdi-magnify" label="Search" single-line
                    hide-details clearable></v-text-field>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-container grid-list-xl fluid>
                  <v-layout row wrap>
                    <v-data-table :headers="subscribeHeaders" :items="subData" :search="subSearch" class="elevation-1"
                      :loading="allSubscribeLoading" loading-text="Subscription Loading ..." style="width: 100%"
                      :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                      <template v-slot:[`item.package_id`]="{ item }">
                        {{ item.package.name }}
                      </template>
                      <template v-slot:[`item.is_monthly`]="{ item }">
                        {{ item.is_monthly ? 'Monthly' : 'Yearly' }}
                      </template>
                      <template v-slot:[`item.startDate`]="{ item }">
                        {{ moment(item.startDate).format('MMMM Do YYYY') }}
                      </template>
                      <template v-slot:[`item.endDate`]="{ item }">
                        {{ moment(item.endDate).format('MMMM Do YYYY') }}
                      </template>
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip top color="primary">
                          <template v-slot:activator="{ on, ttprops }">
                            <v-btn icon @click.prevent="paymentDetails(item)" v-bind="ttprops" v-on="on">
                              <v-icon>payment</v-icon>
                            </v-btn>
                          </template>
                          <span>User Payment Details</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-layout>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="closeSubDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="comanConfirmDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title style="word-break: break-word;">Are you sure you want to close without Updating?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="comanConfirmDialog = false">Cancel</v-btn>
          <v-btn color="error" outlined @click="con_dialog" :disabled="overlay">
            Ok
            <v-progress-circular v-if="overlay" :width="3" color="error" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="permissionConfirm" max-width="500px" persistent>
      <v-card>
        <v-card-title style="word-break: break-word;">Are you sure you want to close without Updating?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="permissionConfirm = false">Cancel</v-btn>
          <v-btn color="error" outlined @click="con_dialog_permission" :disabled="overlay">
            Ok
            <v-progress-circular v-if="overlay" :width="3" color="error" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editUserDialog" persistent max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">Update User Data</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field label="Fist Name" v-model="first_name" outlined readonly></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Last Name" v-model="last_name" outlined readonly></v-text-field>
              </v-col>
              <v-col cols="6">

                <v-text-field :disabled="itemValue.role == 'User' || 'Admin' || 'Client' || 'Observer' || 'Sponsor'"
                  label="Email" v-model="email" outlined></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Phone Number" v-model="phone_number" outlined readonly></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select :items="roleItems" :disabled="itemValue.email == 'raylutz@citizensoversight.org'"
                  label='User Role' outlined v-model="role"></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="zipcode" label="Zip code" outlined></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select v-model="country" :items="countries" item-text="name" item-value="code" label="Country"
                  outlined></v-select>
              </v-col>
              <v-col cols="6">
                <v-select v-if="country == 'US'" v-model="US_state" :items="US_states" item-text="name" item-value="value"
                  label="State" outlined></v-select>
                <v-text-field v-else v-model="state" label="State" outlined></v-text-field>
              </v-col>
              <v-col cols="12" style="margin-bottom: 15px;">
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="editdialogclose">Close</v-btn>
          <v-btn color="blue darken-1" outlined @click="saveEditedUserData">
            {{ !getUserUpdateLoading ? 'Update' : '' }}
            <v-progress-circular indeterminate color="primary" v-if="getUserUpdateLoading"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col cols="12">
      <v-card max-width="100%">
        <v-card-title class="mb-6">

          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
            clearable></v-text-field>
          <v-col cols="3" class='p-0'>
            <v-select v-model="userlist" :items="userListOpt" item-text="name" @change="filterUserList" return-object
              label="" class="ml-2 " outlined></v-select>
          </v-col>

          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <!-- <v-btn @click="restartRds()" v-show="userrole=='Admin'" :disabled="btnDesable"  color="blue-grey darken-1"> Restart RDS </v-btn> -->
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap v-if="userlist == 'Active'">
            <v-data-table :headers="headers" :items="getUsersList.filter(item => item.id !== 34)" :search="search"
              class="elevation-1" :loading="getUsersLoading" loading-text="Users List Loading ..." style="width: 100%;">
              <template v-slot:[`item.run_adjudicator`]="{ item }">
                <v-checkbox :input-value="item.run_adjudicator == true ? true : false" readonly></v-checkbox>
              </template>

              <template v-slot:[`item.run_audit`]="{ item }">
                <v-checkbox :input-value="item.run_audit == true ? true : false" readonly></v-checkbox>
              </template>

              <template v-slot:[`item.run_targetmapper`]="{ item }">
                <v-checkbox :input-value="item.run_targetmapper == true ? true : false" readonly></v-checkbox>
              </template>

              <template v-slot:[`item.project_permission`]="{ item }">
                <v-checkbox :input-value="item.project_permission == true ? true : false" readonly></v-checkbox>
              </template>
              <template v-slot:[`item.test_job`]="{ item }">
                <v-checkbox :input-value="item.test_job == true ? true : false" readonly></v-checkbox>
              </template>

              <template v-slot:[`item.fullAuth`]="{ item }">
                <v-checkbox :input-value="item.fullAuth == true ? true : false" readonly></v-checkbox>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="PermissionSetData(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <span>User Edit Permissions</span>
                </v-tooltip>

                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="editUserSetData(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>User Edit Profile</span>
                </v-tooltip>

                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn v-if="item.role != 'Admin'" icon @click.prevent="subscriptionData(item)" v-bind="ttprops"
                      v-on="on">
                      <v-icon>subscriptions</v-icon>
                    </v-btn>
                  </template>
                  <span>User Subscriptions</span>
                </v-tooltip>

                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn v-if="item.role != 'Admin'" icon @click.prevent="MeanallyPayment(item)" v-bind="ttprops"
                      v-on="on">
                      <v-icon>mdi-wallet-membership</v-icon>
                    </v-btn>
                  </template>
                  <span>User Membership</span>
                </v-tooltip>

                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="deleteUser(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete User</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-layout>
          <v-layout row wrap v-else>
            <v-data-table :headers="headers" :items="getInactiveUserList.filter(item => item.id !== 34)" :search="search"
              class="elevation-1" :loading="getInactivateUsersLoading" loading-text="Users List Loading ..."
              style="width: 100%;">
              <template v-slot:[`item.run_adjudicator`]="{ item }">
                <v-checkbox :input-value="item.run_adjudicator == true ? true : false" readonly></v-checkbox>
              </template>

              <template v-slot:[`item.run_audit`]="{ item }">
                <v-checkbox :input-value="item.run_audit == true ? true : false" readonly></v-checkbox>
              </template>

              <template v-slot:[`item.run_targetmapper`]="{ item }">
                <v-checkbox :input-value="item.run_targetmapper == true ? true : false" readonly></v-checkbox>
              </template>

              <template v-slot:[`item.project_permission`]="{ item }">
                <v-checkbox :input-value="item.project_permission == true ? true : false" readonly></v-checkbox>
              </template>
              <template v-slot:[`item.test_job`]="{ item }">
                <v-checkbox :input-value="item.test_job == true ? true : false" readonly></v-checkbox>
              </template>

              <template v-slot:[`item.fullAuth`]="{ item }">
                <v-checkbox :input-value="item.fullAuth == true ? true : false" readonly></v-checkbox>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="reactiveUser(item)" v-bind="ttprops" v-on="on">
                      <v-icon> mdi-account-reactivate-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Reactive User</span>
                </v-tooltip>

                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="deleteUser(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete User</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { AbbrCountries } from './../../services/AbbrCountries.js'
import { AbbrStates } from './../../services/AbbrStates.js'
import moment from 'moment'
export default {
  name: 'UserList',

  components: {},

  computed: {
    ...mapGetters(['getUsersList', 'getInactiveUserList', 'getUsersLoading', 'getInactivateUsersLoading', 'getUserUpdateLoading', 'permissionLoading', 'auditJobs', 'auditJobLoading', 'activeUserLoading', 'activeUser', 'rdsRestart', 'allSubscribeLoading', 'allSubscribPlans', 'auditActivePackage']),

    
    canSave: function () {
      return this.activePackage != null && this.rules.required(this.activePackage)
    },
  },

  mounted() {
    this.$store.dispatch('loadUsers')
    this.$store.dispatch('loadAuditJobs')
    this.$store.dispatch('getAllSubscription')
    this.$store.dispatch('loadAuditActivePackages')

  },

  data() {
    return {
      show: false,
      itemValue: {},
      userlist: "Active",
      reactiveUserDialog: false,
      clear_all_audit: false,
      name: '',
      overlay: false,
      btnDesable: false,
      all_permission_ary: [],
      selected_job: [],
      selectedPerName: '',
      search: '',
      subSearch: '',
      sortBy: 'package_id',
      paymentDetailDialog: false,
      activeItem: null,
      activePackage: null,
      selectedUser: null,
      selectedPlan: 1,
      id: '',
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      role: '',
      run_adjudicator: false,
      run_audit: false,
      run_targetmapper: false,
      project_permission: false,
      test_job: false,
      fullAuth: false,
      verified: false,
      page_1: true,
      page_2: true,
      editUserDialog: false,
      userDeleteDialog: false,
      permissionButton: false,
      comanConfirmDialog: false,
      saving: false,
      countries: AbbrCountries,
      US_states: AbbrStates,
      subSaving: false,
      userrole: JSON.parse(localStorage.getItem('user')).role,
      country: 'US',
      rules: {
        required: value => !!value || 'Required.',
      },
      state: '',
      US_state: 'CA',
      county: '',
      zipcode: null,
      selectall_audit: false,
      sortDesc: true,
      paymentSubscriptionModel: false,
      selected_data: [],
      subData: [],
      subscriptionDialog: false,
      packagePrice: 0,
      packageDesc: "",
      PackList: [{ id: 1, name: 'Monthly' }, { id: 0, name: 'Yearly' }],
      roleItems: ['User', 'Admin', 'Client', 'Observer', 'Sponsor'],
      headers: [
        // { text: 'ID',align:'center', value: 'id', width: '10%' },
        { text: 'First Name', align: 'center', sortable: true, value: 'first_name', width: '10%' },
        { text: 'Last Name', align: 'start', sortable: true, value: 'last_name', width: '10%' },
        { text: 'Email', align: 'start', sortable: true, value: 'email', width: '15%' },
        // { text: 'Phone Number', align: 'start', sortable: false, value: 'phone_number', width: '15%' },
        { text: 'Run Adjudicator', align: 'start', value: 'run_adjudicator', width: '5%' },
        { text: 'Run Audit', align: 'start', value: 'run_audit', width: '5%' },
        { text: 'Run Targetmapper', align: 'start', value: 'run_targetmapper', width: '5%' },
        { text: 'Project Permission', align: 'start', value: 'project_permission', width: '5%' },
        { text: 'Test Job Permission', align: 'start', value: 'test_job', width: '5%' },
        { text: 'Full Auth', align: 'start', value: 'fullAuth', width: '5%' },
        { text: 'Actions', value: 'actions', width: '15%' },
      ],
      moment: moment,
      subscribeHeaders: [
        { text: 'Package Name', align: 'start', sortable: true, value: 'package_id', width: '10%' },
        { text: 'Package Type', align: 'start', sortable: true, value: 'is_monthly', width: '10%' },
        { text: 'Start Date', align: 'start', sortable: true, value: 'startDate', width: '10%' },
        { text: 'End Date', align: 'start', sortable: true, value: 'endDate', width: '10%' },
        { text: 'Status', align: 'start', sortable: false, value: 'status', width: '10%' },
        { text: 'Actions', value: 'actions', width: '10%' },
      ],
      permissionDialog: false,
      selectedPermits: [],
      permissionConfirm: false,
      userListOpt: ["Active", "Inactive"]

    }
  },
  methods: {
    filterUserList() {
      if (this.userlist == 'Inactive' && this.getInactiveUserList.length == 0) {
        this.$store.dispatch('loadInactivateUsers', this.userlist)
      }
      if (this.userlist == 'Active' && this.getUsersList.length == 0) {
        this.$store.dispatch('loadUsers')
      }
    },
    nextSort() {
      let index = this.headers.findIndex((h) => h.value === this.sortBy)
      index = (index + 1) % this.headers.length
      this.sortBy = this.headers[index].value
    },
    Enable_All_Job() {
      this.clear_all_audit = true
      this.selectedPermits.filter((item) => {
        item.fullRange = true
      })
    },
    Disable_All_job() {
      this.clear_all_audit = true
      this.selectedPermits.filter((item) => {
        item.end = 10000
        item.fullRange = false
        item.page2_end = 10000
        item.page2_start = 1
        item.page_1 = true
        item.page_2 = true
        item.start = 1
      })
    },
    async Authorized_All_job() {
      this.overlay = true
      const final_res = this.auditJobs.map((item) => {
        return {
          ...item,
          start: 1,
          end: 1000,
          page_1: false,
          page_2: false,
          page2_end: 1000,
          page2_start: 1,
          fullRange: true
        }
      })
      this.selectedPermits = final_res
      let idString = ''
      let electionstring = ''
      let rangeArray = []
      let style_rangeArray = []
      let str = '';
      this.selectedPermits.map((item, index) => {
        if (item.id) {
          if (item.election) {
          } else {
            var ele_id = null;
            this.auditJobs.map(x => {
              if (x.id == item.id) {
                ele_id = x.election_id
              }
            })
          }
          idString = idString + `${item.id}`
          if (this.selectedPermits.length !== index + 1) {
            idString = idString + ','
          }
          str = idString.replace(/,\s*$/, "");
          if (item.election && item.election.id) {
            electionstring = electionstring + `${item.election.id}`
          } else if (ele_id != null) {
            electionstring = electionstring + `${ele_id}`
          }
          if (this.selectedPermits.length !== index + 1) {
            electionstring = electionstring + ','
          }
          rangeArray.push({
            jobid: item.id,
            start: item.start,
            end: item.end,
            page_1: item.page_1,
            page_2: item.page_2,
            fullRange: item.fullRange
          })
          let page1_job = [];
          if (item.page_1) {
            page1_job.push({ start: item.start, end: item.end })
          }
          let page2_job = [];
          if (item.page_2) {
            page2_job.push({ start: item.page2_start, end: item.page2_end })
          }
          if (item.fullRange == true) {
            style_rangeArray.push({
              jobid: item.id,
              fullRange: item.fullRange,
              start: 0,
              end: 0,
              page_1: false,
              page_2: false,
              style_page_1: 0,
              style_page_2: 0
            })
          } else {
            style_rangeArray.push({
              jobid: item.id,
              fullRange: item.fullRange,
              start: item.start,
              end: item.end,
              page_1: item.page_1,
              page_2: item.page_2,
              style_page_1: page1_job,
              style_page_2: page2_job
            })
          }
        }
      })
      idString = str;
      let payload = {
        id: this.id,
        audit: idString,
        election_ids: electionstring,
        permissions: JSON.stringify(style_rangeArray),
        run_adjudicator: this.run_adjudicator,
        run_audit: this.run_audit,
        run_targetmapper: this.run_targetmapper,
        project_permission: this.project_permission,
        test_job: this.test_job,
        fullAuth: this.fullAuth,
      }
      await this.$store.dispatch('updateAuditPermission', payload)
      this.$store.dispatch('loadUsers')
      this.permissionDialog = false
      this.overlay = false
    },
    async PermissionSetData(item) {
      await this.$store.dispatch('getUserData', item.id);
      this.all_permission_ary = await this.$store.dispatch('getPermisssion')
      this.run_adjudicator = item.run_adjudicator ? item.run_adjudicator : false
      this.run_audit = item.run_audit ? item.run_audit : false
      this.run_targetmapper = item.run_targetmapper ? item.run_targetmapper : false
      this.project_permission = item.project_permission ? item.project_permission : false
      this.test_job = item.test_job ? item.test_job : false
      this.fullAuth = item.fullAuth ? item.fullAuth : false
      this.verified = item.verified ? item.verified : false

      this.id = item.id
      this.permissionDialog = true
      if (this.selectedPermits.length == this.auditJobs.length) {
        this.selectall_audit = true
      } else {
        this.selectall_audit = false
      }
    },
    setAllAudits(check_id) {
      this.clear_all_audit = true
      if (check_id == true) {
        this.selected_data = this.selectedPermits

        const final_res = this.auditJobs.map((item) => {
          return {
            ...item,
            start: 1,
            end: 1000,
            page_1: true,
            page_2: true,
            page2_end: 1000,
            page2_start: 1,
            fullRange: item.fullRange ? item.fullRange : false
          }
        })
        this.selectedPermits = final_res
      } else {
        this.selectedPermits = this.selected_data;
      }
    },
    restartRds() {
      this.btnDesable = true
      this.$store.dispatch('rdsRestart')
      setTimeout(() => {
        this.btnDesable = false
      }, 30000);
    },
    changeRoute(id) {
      let job = this.auditJobs.filter((item) => item.id == id)
      this.selectedPerName = job && job[0] ? job[0]['name'] : ''
    },
    changeJobRoute(id, name) {
      this.selectedPerName = name ? name : this.selectedPerName ? this.selectedPerName : 'JOBID_' + id
      this.permissionButton = true
      this.selected_job = [];
      this.all_permission_ary.map((item) => {
        let per = JSON.parse(item.permissions)
        if (per != null && per != []) {
          per.map((i) => {
            if (i.jobid == id) {
              i.user = item.user
              this.selected_job.push(i)
            }
          })
        }
      })
    },
    permissionConfirmDialog() {
      this.permissionConfirm = true;
    },
    editdialogclose() {
      this.comanConfirmDialog = true;
      this.selectall_audit = false
    },
    con_dialog() {
      this.comanConfirmDialog = false
      this.editUserDialog = false
    },
    con_dialog_permission() {
      this.permissionConfirm = false
      this.permissionDialog = false
    },
    add() {
      this.clear_all_audit = true
      let mapped_ary = [];
      this.selectedPermits.map((item) => {
        mapped_ary.push(item.id)
      })
      let myary = this.auditJobs.filter((item) => !mapped_ary.includes(item.id));



      this.selectedPermits.unshift({
        fullRange: false
      })
    },
    remove(index) {
      if (this.selectedPermits.length <= 1) {
        this.clear_all_audit = false
      }
      if (this.selectedPermits.length !== 0) {
        this.selectedPermits.splice(index, 1)
      }
    },
    clear_all() {
      this.selectedPermits = [];
      this.clear_all_audit = false;
      this.selectall_audit = false;
    },
    reactiveUser(user) {
      this.id = user.id
      this.name = user.first_name

      this.reactiveUserDialog = true
    },
    deleteUser(user) {
      this.id = user.id
      this.name = user.first_name
      this.userDeleteDialog = true
    },
    async deleteUserConfirm() {
      this.waiting = true
      if (this.userlist === "Active") {
        await this.$store.dispatch('deleteUser', this.id)
      }
      if (this.userlist === "Inactive") {
        await this.$store.dispatch('deleteUserPermanently', this.id)
      }
      this.$store.dispatch('loadUsers')
      this.$store.dispatch('loadInactivateUsers', 'Inactive')


      this.userDeleteDialog = false
      this.waiting = false
    },

    async reactivateUserConfirm() {
      this.waiting = true

      await this.$store.dispatch('reactivateUser', this.id, { name: this.name })
      this.$store.dispatch('loadUsers')
      this.$store.dispatch('loadInactivateUsers', 'Inactive')
      this.reactiveUserDialog = false

      console.log("this.reactiveUserDialog", this.reactiveUserDialog);
      this.waiting = false
    },
    async editUserSetData(item) {
      console.log("here", item)
      this.itemValue = item
      console.log(this.itemValue.email)
      await this.$store.dispatch('getUserData', item.id)
      this.id = item.id
      this.email = item.email
      this.first_name = item.first_name
      this.last_name = item.last_name
      this.phone_number = item.phone_number
      this.role = item.role
      this.country = item.country
      this.US_state = item.country == 'US' ? item.state : null
      this.state = item.state
      this.zipcode = item.zip_code

      this.editUserDialog = true
    },
    async savePermissionData() {
      this.overlay = true
      // const final_res = this.auditJobs.map((item) => {
      //       return {...item,
      //               start:1,
      //               end:1000,
      //               page_1:false,
      //               page_2:false,
      //               page2_end:1000,
      //               page2_start:1,
      //               fullRange:true
      //             }
      //   })
      // this.selectedPermits = final_res
      let idString = ''
      let electionstring = ''
      let rangeArray = []
      let style_rangeArray = []
      let str = '';
      this.selectedPermits?.map((item, index) => {
        if (item.id) {
          if (item.election) {
          } else {
            var ele_id = null;
            this.auditJobs.map(x => {
              if (x.id == item.id) {
                ele_id = x.election_id
              }
            })
          }
          idString = idString + `${item.id}`
          if (this.selectedPermits.length !== index + 1) {

            idString = idString + ','
          }
          str = idString.replace(/,\s*$/, "");
          if (item.election && item.election.id) {
            electionstring = electionstring + `${item.election.id}`
          } else if (ele_id != null) {
            electionstring = electionstring + `${ele_id}`
          }
          if (this.selectedPermits.length !== index + 1) {
            electionstring = electionstring + ','
          }
          rangeArray.push({
            jobid: item.id,
            start: item.start,
            end: item.end,
            page_1: item.page_1,
            page_2: item.page_2,
            fullRange: item.fullRange
          })
          let page1_job = [];
          if (item.page_1) {
            page1_job.push({ start: item.start, end: item.end })
          }
          let page2_job = [];
          if (item.page_2) {
            page2_job.push({ start: item.page2_start, end: item.page2_end })
          }
          if (item.fullRange == true) {
            style_rangeArray.push({
              jobid: item.id,
              fullRange: item.fullRange,
              start: 0,
              end: 0,
              page_1: false,
              page_2: false,
              style_page_1: 0,
              style_page_2: 0
            })
          } else {
            style_rangeArray.push({
              jobid: item.id,
              fullRange: item.fullRange,
              start: item.start,
              end: item.end,
              page_1: item.page_1,
              page_2: item.page_2,
              style_page_1: page1_job,
              style_page_2: page2_job
            })
          }

        }
      })
      idString = str;
      let payload = {
        id: this.id,
        audit: idString,
        election_ids: electionstring === "" ? null : electionstring,
        permissions: JSON.stringify(style_rangeArray),
        run_adjudicator: this.run_adjudicator,
        run_audit: this.run_audit,
        run_targetmapper: this.run_targetmapper,
        project_permission: this.project_permission,
        test_job: this.test_job,
        fullAuth: this.fullAuth,
      }
      await this.$store.dispatch('updateAuditPermission', payload)
      this.$store.dispatch('loadUsers')
      this.permissionDialog = false
      this.overlay = false
    },
    async subscriptionData(item) {
      await this.$store.dispatch('getUserData', item.id);
      if (this.allSubscribPlans.length > 0) {
        this.subData = this.allSubscribPlans.filter(itm => itm.users.id == item.id)
      }
      this.subscriptionDialog = true
    },
    closeSubDialog() {
      this.subData = []
      this.subscriptionDialog = false
    },
    paymentDetails(item) {
      this.activeItem = item
      this.paymentDetailDialog = true
    },
    closePaymentDialog() {
      this.activeItem = null
      this.paymentDetailDialog = false
    },
    async MeanallyPayment(item) {
      await this.$store.dispatch('getUserData', item.id);
      this.selectedUser = item
      this.paymentSubscriptionModel = true
    },
    changePackage() {
      if (this.selectedPlan == 1) {
        this.packagePrice = this.activePackage.monthlyPrice
      } else {
        this.packagePrice = this.activePackage.yearlyPrice
      }
      this.packageDesc = this.activePackage.description

    },
    changePackageType() {
      if (this.selectedPlan == 1) {
        this.packagePrice = this.activePackage.monthlyPrice
      } else {
        this.packagePrice = this.activePackage.yearlyPrice
      }
      this.packageDesc = this.activePackage.description
    },
    closePaymentSubscriptionModel() {
      this.paymentSubscriptionModel = false
      this.selectedUser = null
      this.activePackage = null
      this.packagePrice = 0
      this.packageDesc = ""
    },
    async saveMeanallyPaymentData() {
      this.subSaving = true;
      let payload = {
        user_id: this.selectedUser.id,
        package_id: this.activePackage.id,
        is_monthly: this.selectedPlan,
        price: this.packagePrice
      }
      await this.$store.dispatch('saveManualPackage', payload)
      this.subSaving = false;
      this.paymentSubscriptionModel = false
      await this.$store.dispatch('getAllSubscription')
      this.activePackage = null
      this.packagePrice = 0
      this.packageDesc = ""
    },
    async saveEditedUserData() {
      this.overlay = true
      // let idString = ''
      // let electionstring = ''
      // let rangeArray = []
      // let style_rangeArray = []
      // let str = '';
      // this.selectedPermits.map((item, index) => {
      //   if(item.id){
      //     if(item.election){
      //     }else{
      //       var ele_id = null;
      //       this.auditJobs.map(x => {
      //         if(x.id == item.id) {
      //           ele_id = x.election_id
      //         }
      //       })
      //     }
      //     idString = idString + `${item.id}`
      //     if(this.selectedPermits.length !== index+1) {

      //       idString = idString + ','
      //     }
      //     str = idString.replace(/,\s*$/, "");
      //     if(item.election && item.election.id){
      //       electionstring = electionstring + `${item.election.id}`
      //     }else if(ele_id != null){
      //       electionstring = electionstring + `${ele_id}`
      //     }
      //     if(this.selectedPermits.length !== index+1) {
      //       electionstring = electionstring + ','
      //     }
      //     rangeArray.push({
      //       jobid: item.id,
      //       start: item.start,
      //       end: item.end,
      //       page_1: item.page_1,
      //       page_2: item.page_2
      //     })
      //     let page1_job = [];
      //     if(item.page_1){
      //       page1_job.push({start:item.start,end:item.end})
      //     }
      //     let page2_job = [];
      //     if(item.page_2){
      //       page2_job.push({start:item.page2_start,end:item.page2_end})
      //     }
      //     style_rangeArray.push({
      //       jobid: item.id,
      //       start: item.start,
      //       end: item.end,
      //       page_1: item.page_1,
      //       page_2: item.page_2,
      //       style_page_1: page1_job,
      //       style_page_2: page2_job
      //     })
      //   }
      // })
      // idString = str;
      let payload = {
        id: this.id,
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        phone_number: this.phone_number,
        // run_adjudicator: this.run_adjudicator,
        // run_audit: this.run_audit,
        // run_targetmapper: this.run_targetmapper,
        // project_permission: this.project_permission,
        // test_job:this.test_job,
        // fullAuth: this.fullAuth,
        // audit: idString,
        // election_ids:electionstring,
        // permissions: JSON.stringify(style_rangeArray),
        role: this.role,
        country: this.country,
        state: this.country !== 'US' ? this.state : this.US_state,
        zip_code: this.zipcode
      }

      await this.$store.dispatch('updateUserData', payload)
      this.$store.dispatch('loadUsers')
      this.editUserDialog = false
      this.overlay = false
    },
    closeDialog() {
      this.editUserDialog = false
    },
    setEditData() { },
  },

  watch: {
    activeUser() {
      if (this.activeUser && this.activeUser.audit_ids) {
        let tempArray = [];
        let tempIds = JSON.parse(this.activeUser.audits);
        tempIds.map(audit => {
          this.auditJobs.map(x => {
            if (x.id == audit.jobid) {
              tempArray.push({
                id: x.id,
                election: x.election,
                name: x.name,
                description: x.description,
                progress: x.progress,
                received_donation: x.received_donation,
                request_donate: x.request_donate,
                active: x.active,
                status: x.status,
                created_at: x.created_at,
                updated_at: x.updated_at,
                page_1: audit.page_1,
                page_2: audit.page_2,
                start: audit.style_page_1 && audit.style_page_1.length > 0 ? audit.style_page_1[0]['start'] : audit.start ? audit.start : 0,
                end: audit.style_page_1 && audit.style_page_1.length > 0 ? audit.style_page_1[0]['end'] : audit.end ? audit.end : 0,
                page2_start: audit.style_page_2 && audit.style_page_2.length > 0 ? audit.style_page_2[0]['start'] : audit.start ? audit.start : 0,
                page2_end: audit.style_page_2 && audit.style_page_2.length > 0 ? audit.style_page_2[0]['end'] : audit.end ? audit.end : 0,
                fullRange: audit.fullRange ? audit.fullRange : false
              })
            }
          })
        })
        this.selectedPermits = tempArray;
        this.clear_all_audit = true
      } else {
        this.selectedPermits = [];
        this.clear_all_audit = false
      }
    }
  },
}
</script>

<style>
.plusBtn {
  position: absolute;
  top: 50%;
  align-content: end;
  font-size: 20px;
  padding: 0px !important;
  height: 30px !important;
  min-width: 30px !important;
}

.removeBtn {
  font-size: 20px;
  padding: 0px !important;
  height: 30px !important;
  min-width: 30px !important;
}

.aythorized_audit {
  font-size: 16px;
  margin-bottom: -20px;
}

.pages_checkbox .v-input--checkbox {
  margin: 0;
  padding: 0;
}

.pages_checkbox .v-input--checkbox .v-input__slot {
  margin: -10px 0 !important;
  padding: 0 !important;
}

.style_permission .v-input__slot {
  margin: 0px !important;
}

.style_permission .v-input {
  margin-top: 0;
}

.style_permission {
  margin-top: 10px;
}

.style_permission .reove_per_job {
  margin-top: -40px;
}

.plus_per_job .plusBtn {
  top: 55%;
}

.bootom_part .v-input__control .v-input__slot {
  margin: 0px !important;
}

.bootom_part .v-input--selection-controls {
  margin-top: 0px;
}

.v-list-item__title,
.v-list-item__subtitle {
  overflow: unset;
}

.permission_job .v-list-item {
  min-height: 35px;
}

.v-list.transparent.permission_job {
  max-height: 300px;
  overflow-y: scroll;
}

.permission_job::-webkit-scrollbar {
  width: 5px;
}

.permission_job::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.permission_job::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.all_check_btn {
  position: relative;
  top: -33%;
}

.permission-dialog {
  max-width: 80% !important;
  margin-left: 15%;
}

/* hide the "scrim", it's pointless */
.v-overlay--active .v-overlay__scrim {
  display: none;
}

/* style the overlay container as required */
.v-overlay--active {
  backdrop-filter: blur(2px);
  background: rgb(0 0 0 / 0.8);
}

/* if you have an auto dark theme
   for prefers-color-scheme: dark
   I find the 0.8 too dark
*/
@media (prefers-color-scheme: dark) {
  .v-overlay--active {
    background: rgb(0 0 0 / 0.4);
  }
}
</style>
