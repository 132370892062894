var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Cancel upload request to "+_vm._s(_vm.name)+" ?")]),_c('v-card-actions',{staticClass:"pa-8"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"outlined":"","color":"error","disabled":_vm.waiting},on:{"click":_vm.confirmDeleteDistrict}},[_vm._v(" Ok "),(_vm.waiting)?_c('v-progress-circular',{attrs:{"width":3,"color":"green","indeterminate":""}}):_vm._e()],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.uploadRequestDialog),callback:function ($$v) {_vm.uploadRequestDialog=$$v},expression:"uploadRequestDialog"}},[_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',[_c('div',[_vm._v("Upload Request For "+_vm._s(_vm.districtData.name))])]),_c('v-container',[_c('v-row',{staticClass:"reduce-row-gaps"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","outlined":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Email","outlined":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Valid Through","hint":"YYYY-MM-DD format","persistent-hint":"","append-icon":"mdi-calendar","outlined":""},on:{"blur":function($event){_vm.valid_through = _vm.parseDate(_vm.valid_through)}},model:{value:(_vm.valid_through),callback:function ($$v) {_vm.valid_through=$$v},expression:"valid_through"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.valid_through),callback:function ($$v) {_vm.valid_through=$$v},expression:"valid_through"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","outlined":"","disabled":_vm.waiting},on:{"click":function($event){_vm.uploadRequestDialog = false}}},[_vm._v("Close")]),(_vm.userValue.role == 'Observer' ? _vm.isHidden : !_vm.isHidden)?_c('v-btn',{attrs:{"color":"blue darken-1","outlined":"","disabled":_vm.waiting || !_vm.canSend},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.id > 0 ? "Update" : "Send")+" "),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.waiting),expression:"waiting"}],attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e()],1)],1)],1),_c('v-card',{attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"mb-6"},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var ttprops = ref.ttprops;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.refreshList()}}},'v-btn',ttprops,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh List")])]),(_vm.userValue.role == 'Observer' ? _vm.isHidden : !_vm.isHidden)?_c('v-btn',{staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":_vm.requestUpload}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-telegram")]),_vm._v(" Request Upload ")],1):_vm._e()],1),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.upload_requests,"search":_vm.search,"loading":_vm.uploadRequestLoading},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description && item.description.substring(0, 100))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.send_link)?_c('v-chip',{attrs:{"show":"email"}},[_vm._v("Email")]):_c('v-chip',{attrs:{"show":"link"}},[_vm._v("Link")])]}},{key:"item.expired",fn:function(ref){
var item = ref.item;
return [(item.expired)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v("Expired")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v("Valid")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.userValue.role == 'Observer' ? _vm.isHidden : !_vm.isHidden)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.editRequest(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.userValue.role == 'Observer' ? _vm.isHidden : !_vm.isHidden)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteRequest(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","outlined":"","disabled":_vm.waiting},on:{"click":function($event){_vm.uploadRequestDialog = false}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"blue darken-1","outlined":"","disabled":_vm.waiting || !_vm.canSend},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.id > 0 ? "Update" : "Send")+" "),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.waiting),expression:"waiting"}],attrs:{"indeterminate":"","color":"primary"}})],1)],1)],1),_c('v-card',{attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"mb-6"},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var ttprops = ref.ttprops;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.refreshList()}}},'v-btn',ttprops,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh List")])]),_c('v-btn',{staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":_vm.requestUpload}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-telegram")]),_vm._v(" Request Upload ")],1)],1),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.upload_requests,"search":_vm.search,"loading":_vm.uploadRequestLoading},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.description && item.description.substring(0, 100))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.send_link)?_c('v-chip',{attrs:{"show":"email"}},[_vm._v("Email")]):_c('v-chip',{attrs:{"show":"link"}},[_vm._v("Link")])]}},{key:"item.expired",fn:function(ref){
var item = ref.item;
return [(item.expired)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v("Expired")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v("Valid")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.editRequest(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.deleteRequest(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }