<template>
  <dash-layout>
    <template slot="dash-title">{{ tabs[activeTab].name }}</template>
    <template slot="dash-body">

      <v-tabs show-arrows v-model="tab" class="mb-1">
        <v-tab v-for="tab in tabs" :key="tab.component" :ripple="false" :ref="'btn' + tab.component">{{ tab.name }}</v-tab>
      </v-tabs>

      <transition name="component-fade" mode="out-in">
        <keep-alive>
          <component v-bind:is="tabs[activeTab].component"></component>
        </keep-alive>
      </transition>
    </template>
  </dash-layout>
</template>

<script>
  import {mapGetters} from 'vuex'

  import DashLayout from '../../containers/Layout'
  import AdminDashboard from './AdminDashboard'
  import AuditTypesEditor from './AuditTypesEditor'
  import ArgGroupEditor from './ArgGroupEditor'
  import ActionSpecsEditor from './ActionSpecsEditor'
  import AWSConfigEditor from './AWSConfigEditor'
  import AuditsList from './AuditsList'
  import UserList from './UserList'
  import JobpermissionList from './JobpermissionList'
  import MarkDownFiles from './MarkDownFiles'
  import DonationList from './DonationList'
  import UploadRequest from './UploadRequest'
  import GenralUpload from './GenralUpload'
  export default {
    name: 'ElectionLayout',
    data() {
      return {
        currentTabComponent: '',
        activeTab: 0,
        tabs: [
          // { name: 'Admin Dashboard', component: 'AdminDashboard'},
          // { name: 'Argument Specifications', component: 'ArgGroupEditor'},
          // { name: 'Action Specifications', component: 'ActionSpecsEditor'},
          { name: 'Users', component: 'UserList'},
          // { name: 'Funds', component: 'AuditsList'},
          // { name: 'Job Permission Request', component: 'JobpermissionList'},
          { name: 'Markdown Files', component: 'MarkDownFiles' },
          { name: 'Audit Types', component: 'AuditTypesEditor'},
          { name:'Fundraising',component:'DonationList'},
          { name:'Upload Requests',component:"UploadRequest"},
          { name:"General Upload",component:"GenralUpload"}
          // { name: 'AWS Configurations', component: 'AWSConfigEditor'},
        ]
      }
    },

    mounted() {
      
    },

    computed: {
      ...mapGetters(['user']),
      tab: {
        get () {
          return this.activeTab
        },
        set (value) {
          this.activeTab = value
        }
      }
    },

    components: {
      DashLayout,
      AdminDashboard,
      AuditTypesEditor,
      ArgGroupEditor,
      ActionSpecsEditor,
      AWSConfigEditor,
      AuditsList,
      UserList,
      JobpermissionList,
      MarkDownFiles,
      DonationList,
      UploadRequest,
      GenralUpload
    },

    methods: {
    },

    watch: {
      activeTab(tab){
      }
    }
  }
</script>
