<template>
  <v-card max-width="100%">
    <v-dialog v-model="jobFileCreateErrorDialog" max-width="520px" persistent>
      <v-card>
        <v-card-title
          >Job file already exists. Please edit existing file or try again after deleting the file.</v-card-title
        >
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="jobFileCreateErrorDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="jsonFileErrorDialog" max-width="550px" persistent>
      <v-card>
        <v-card-title
          >{{ jsonFileName }} already exists. Please edit existing file or try again after deleting the
          file.</v-card-title
        >
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="jsonFileErrorDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="jobSettingsDeleteDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>Are you sure deleting {{ file_name }} ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="jobSettingsDeleteDialog = false">Cancel</v-btn>
          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="error" outlined @click="deleteFileConfirm" :disabled="waiting">
            Delete
            <v-progress-circular v-if="waiting" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <job-file-upload-dialog ref="JobFileUploadDialog" />
    <JobFileCreateAuditDialog ref="JobFileCreateAuditDialog" />
    <JobSettingDialog ref="JobSettingDialog" />
    <GenerateJsonDialog ref="GenerateJsonDialog" />
    <DisplayJobsJSON ref="DisplayJobsJSON" />

    <v-card-title class="mb-6">
      <v-text-field
        v-model="search"
        outlined
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
      <v-spacer></v-spacer>

      <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" id="UploadFileBtn" dark color="blue-grey darken-1" @click.prevent="uploadNewFile">
        <v-icon left>mdi-upload</v-icon> Upload File
      </v-btn>
      <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" id="CreateAuditBtn" class="ml-1" dark color="blue-grey darken-1" @click.prevent="createNewAudit">
        <v-icon left>mdi-widgets</v-icon> Create Audit</v-btn
      >
      <!-- <v-btn class="ml-1" dark color="blue-grey darken-1" @click.prevent="generateJobFile">
        <v-icon left>mdi-widgets</v-icon> Generate File
      </v-btn> -->
       <template v-else-if="userValue.role == 'Observer'? isHidden : !isHidden">
      <v-btn
        id="CreateJobBtn"
        v-if="jobFileCheck"
        class="ml-1"
        dark
        color="blue-grey darken-1"
        @click.prevent="generateJobFile"
      >
        <v-icon left >mdi-plus</v-icon> Create Job File</v-btn
      >
        <v-btn v-else id="CreateJobBtn2" class="ml-1" dark color="blue-grey darken-1" @click.prevent="createNewJobFile">
          <v-icon left >mdi-plus</v-icon> Create a New Job File
        </v-btn>
      </template>
      
      <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" class="ml-1" dark color="blue-grey darken-1" @click.prevent="genrateJsonfile">
        <v-icon left>mdi-widgets</v-icon> Generate Json File
      </v-btn>
    </v-card-title>

    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-data-table
          :headers="headers"
          :items="files"
          :search="search"
          class="elevation-1"
          :loading="jobFilesLoading"
          loading-text="Loading Job Files ..."
          style="width: 100%"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn v-if="item.type == 'JOB'" icon @click.prevent="showSettingsDialog(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn v-else-if="item.type == 'JSON'" icon @click.prevent="showGenerate(item)">
              <v-icon>mdi-pencil</v-icon></v-btn
            >
            <v-btn v-else icon :disabled="true">
              <v-icon>mdi-none</v-icon>
            </v-btn>

            <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" icon @click.prevent="downloadFile(item)">
              <v-icon>mdi-download</v-icon>
            </v-btn>

            <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" icon @click.prevent="deleteFile(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-layout>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'

import DashLayout from '../../containers/Layout'
import JobFileUploadDialog from './JobFileUploadDialog'
import JobFileCreateAuditDialog from './JobFileCreateAuditDialog'
import JobSettingDialog from './JobSettingDialog'
import GenerateJsonDialog from './GenerateJsonDialog.vue'
import DisplayJobsJSON from './DisplayJobsJSON.vue'
export default {
  name: 'JonSettingContainer',
  components: {
    DashLayout,
    vSelect,
    JobFileUploadDialog,
    JobSettingDialog,
    GenerateJsonDialog,
    JobFileCreateAuditDialog,
    DisplayJobsJSON,
  },
  computed: {
    ...mapGetters(['jobFiles', 'jobFilesLoading', 'activeAuditJob', 'user', 'uploadedFiles']),
  },
  mounted() {
    this.$store.dispatch('loadjobFiles', this.activeAuditJob.id)
    this.userValue = this.user
  },
  data() {
    return {
      userValue: {},
      isHidden: false,
      overlay: false,
      search: '',
      file_name: '',
      waiting: false,
      jobFileCheck: false,
      jobSettingsDeleteDialog: false,
      jsonFileErrorDialog: false,
      jsonFileName: '',
      cvrFiles: [],
      sourceFile: [],
      styleFiles: [],
      adoptedFileList: [],
      headers: [
        { text: 'File Name', value: 'name', sortable: false, width: '20%' },
        { text: 'File Type', value: 'type', sortable: false, width: '10%' },
        { text: 'Size', value: 'size', sortable: false, width: '10%' },
        { text: 'Etag', value: 'etag', sortable: true, width: '20%' },
        { text: 'Last Modified', value: 'last_modified', sortable: false, width: '20%' },
        { text: 'Actions', value: 'actions', sortable: false, width: '20%' },
      ],
      files: [],
      jobFileCreateErrorDialog: false,
    }
  },

  methods: {
    deleteFile(file) {
      this.file_name = file.name
      this.jobSettingsDeleteDialog = true
    },
    async deleteFileConfirm() {
      this.waiting = true
      const payload = {
        job_id: this.activeAuditJob.id,
        file_name: this.file_name,
      }
      await this.$store.dispatch('deleteJobFile', payload)
      this.jobSettingsDeleteDialog = false
      this.waiting = false
    },

    async showSettingsDialog(item) {
      this.overlay = true

      this.$store.commit('setJobArgSpecs', [])
      const ret = await this.$store.dispatch('getJobArgSpecs')

      if (item.name) {
        const payload = {
          job_id: this.activeAuditJob.id,
          file_name: item.name,
        }
        let ret = await this.$store.dispatch('getJobFile', payload)
      } else {
        this.$store.commit('setActiveJobFile', {})
      }
      this.overlay = false
      this.$refs.JobSettingDialog.show(item)
    },
    async showGenerate(item) {
      this.overlay = true
      if (item.name) {
        const payload = {
          job_id: this.activeAuditJob.id,
          file_name: item.name,
        }
        let ret = await this.$store.dispatch('getJsonFile', payload)
      } else {
        this.$store.commit('setActiveJsonFile', {})
      }
      this.overlay = false
      this.$refs.GenerateJsonDialog.show(item)
    },
    closeDialog() {
      this.jobSettingsDeleteDialog = false
    },

    downloadFile(item) {
      window.location.href = item.url
    },

    uploadNewFile() {
      this.$refs.JobFileUploadDialog.show()
    },
    async createNewAudit() {
      for (let i = 0; i < this.jobFiles.length; i++) {
        if (this.jobFiles[i].file_type == 'JSON') {
          this.jsonFileName = this.jobFiles[i].file_name
          this.jsonFileErrorDialog = true
          return
        }
      }
      this.jsonFileName = ''
      this.overlay = true
      this.$store.commit('setJobArgSpecs', [])
      const ret = await this.$store.dispatch('getJobArgSpecs')
      this.overlay = false

      this.$refs.JobFileCreateAuditDialog.show()
    },
    generateJobFile() {
      this.showSettingsDialog({ jobFileCheck: this.jobFileCheck })
      this.showSettingsDialog({})
    },
    createNewJobFile() {
      for (let i = 0; i < this.jobFiles.length; i++) {
        if (this.jobFiles[i].file_type == 'JOB') {
          this.jobFileCreateErrorDialog = true
          return
        }
      }
      this.showSettingsDialog({ jobFileCheck: this.jobFileCheck })
    },

    async genrateJsonfile() {
      this.overlay = true
      this.cvrFiles = []
      this.sourceFile = []
      this.styleFiles = []

      await this.$store
        .dispatch('getAllLoadFiles', this.activeAuditJob.election_id)
        .then(() => this.adoptedFileList = this.uploadedFiles)
        .catch(() => {
          this.adoptedFileList = [];
          this.$store.commit('apiError', 'Adopted files not found!')
        })
      setTimeout(() => {
        this.overlay = false
        this.adoptedFileList.filter((data) => {
          if (data.file_type == 'CVR' && this.cvrFiles.indexOf(data.file_name) == -1) {
            return this.cvrFiles.push(data.file_name)
          }
          if (data.file_type == 'BIA' && this.sourceFile.indexOf(data.file_name) == -1) {
            return this.sourceFile.push(data.file_name)
          }
          if (data.file_type == 'BSM' && this.styleFiles.indexOf(data.file_name) == -1) {
            return this.styleFiles.push(data.file_name)
          }
        })

        const data = {
          archives_folder_s3path: '',
          cvr: this.cvrFiles,
          election_name: this.activeAuditJob.election.name,
          job_folder_s3path: this.activeAuditJob.address,
          job_name: this.activeAuditJob.name,
          source: this.sourceFile,
          style_masters: this.styleFiles,
        }
        this.$refs.DisplayJobsJSON.show({ fileName: this.activeAuditJob.name, data: data })
        // this.$refs.DisplayJobsJSON.close({
        //   data: { cvrFiles: this.cvrFiles, sourceFile: this.sourceFile, styleFiles: this.styleFiles },
        // })
      }, 2000)
    },
    async checkJobfile() {
      for (let i = 0; i < this.jobFiles.length; i++) {
        if (this.jobFiles[i].file_type == 'JOB') {
          this.jobFileCheck = true
          return
        }
      }
    },
  },

  watch: {
    jobFiles() {
      this.files = []
      for (let i = 0; i < this.jobFiles.length; i++) {
        if (this.jobFiles[i].file_name.includes('JOB_')) {
          this.jobFiles[i].file_type = 'JOB'
        }
        let item = {
          name: this.jobFiles[i].file_name,
          size: this.jobFiles[i].size,
          etag: this.jobFiles[i].etag,
          type: this.jobFiles[i].file_type,
          url: this.jobFiles[i].url,
          last_modified: this.jobFiles[i].last_modified,
        }

        if (this.jobFiles[i].size >= 1024 * 1024 * 1024) {
          const size = Math.round((this.jobFiles[i].size * 100) / (1024 * 1024 * 1024)) / 100
          item.size = size.toString() + ' GB'
        } else if (this.jobFiles[i].size >= 1024 * 1024) {
          const size = Math.round((this.jobFiles[i].size * 100) / (1024 * 1024)) / 100
          item.size = size.toString() + ' MB'
        } else if (this.jobFiles[i].size >= 1024) {
          const size = Math.round((this.jobFiles[i].size * 100) / 1024) / 100
          item.size = size.toString() + ' KB'
        } else {
          const size = Math.round(this.jobFiles[i].size * 100) / 100
          item.size = size.toString() + ' B'
        }
        this.files.push(item)
        // console.log("this.files>>>>>>>>>>>", this.files)
      }
    },
    jobFileLoading() {},
  },
}
</script>
