<template>
<dash-layout>
  <template slot="dash-title">Leaderboard</template>
  <template slot="dash-body">
    <v-row dense class="reduce-row-gaps">
      <v-col cols="12">
        <v-card max-width="100%">
          <v-card-title class="mb-6">
            
            <v-text-field
              v-model="search"
              outlined
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              clearable
            ></v-text-field>
            <v-spacer></v-spacer>
              <v-spacer></v-spacer>
          
          </v-card-title>
          <v-container grid-list-xl fluid>
            <v-layout row wrap>
              <v-data-table
                :headers="headers"
                :items="leader_board"
                :search="search"
                class="elevation-1"
                :loading="leaderloading"
                loading-text="Leader Board Loading ..."
                style="width: 100%;"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                
              </v-data-table>
            </v-layout>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </template>
</dash-layout>
</template>

<script>
import { mapGetters } from 'vuex'
 import DashLayout from '../../containers/Layout'

export default {
  name: 'LeaderBoard',

  components: {
    DashLayout
  },

  computed: {
    ...mapGetters(['leader_board','leaderloading']),
  },

  mounted() {
    this.$store.dispatch('getLeaderReport')
  },

  data() {
    return {
      search: '',
      sortBy: 'mapper_total',
      sortDesc: true,
      headers: [
        { text: 'First Name', align: 'start', sortable: true, value: 'first_name', width: '10%' },
        { text: 'Last Name', align: 'start', sortable: true, value: 'last_name', width: '10%' },
        { text: 'Email', align: 'start', sortable: true, value: 'email', width: '10%' },
        { text: 'Mapper Score', align: 'start', sortable: true, value: 'mapper_total', width: '10%' },
        { text: 'Adjudicator Score', align: 'start', sortable: false, value: 'adjudicator_total', width: '10%' }
      ],
      selectedPermits: [],
    }
  },
  methods: {
    toggleOrder () {
        this.sortDesc = !this.sortDesc
      },
      nextSort () {
        let index = this.headers.findIndex(h => h.value === this.sortBy)
        index = (index + 1) % this.headers.length
        this.sortBy = this.headers[index].value
      },
  },

  watch: {
    
  },
}
</script>

