import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService';
import { EventName, EventType } from '../../../services/LogEvent';

const URL = '/markdown-settings';
const MARK_URL = '/markdown-settings-edit'
const state = {
    markdownFiles: [],
    singleMarkdownFile:[],
    markdownFilesLoading: false,
    markdownFileLoading: false,
    markdownArgSpecsLoading: false,
  }

  function handleMarkdownfiles(context, response) {
    const markdown = response['data']
    let obj = {
      url: `${response.config.baseURL}${response.config.url}`,
    }
    context.state.markdownFilesLoading = false
    if (markdown['error']) {
      context.commit('apiError', markdown['error'])
       logApiService(obj, markdown['error'], EventName.MarkdownSetting_AdminBoard, EventType.markdownfiles)
    } else {
      context.commit('markDownFilesLoaded', markdown)
      logApiService(obj, 'Fetch MarkDown Files', EventName.MarkdownSetting_AdminBoard, EventType.markdownfiles)
    }
    return markdown
  }

  function handleloadMarkdownfiles(context,response){
    const markdown_files = response['data']
    let obj = {
      url: `${response.config.baseURL}${response.config.url}`,
    }
    if (markdown_files['error']){
      context.commit('apiError',markdown_files['error'])
      logApiService(obj, markdown_files['error'], EventName.MarkdownSetting_AdminBoard, EventType.loadMarkdownfiles)
    } else {
      context.commit('singleMarkdownfileLoad',markdown_files)
      logApiService(obj, 'Fetch Single Mark downfile', EventName.MarkdownSetting_AdminBoard, EventType.loadMarkdownfiles)
    }
    return markdown_files
  }

  function handlemarkdownSave(context, response, data) {
    const  markdown = response['data']
    let obj = {
      url: `${response.config.baseURL}${response.config.url}`,
      data: data
    }
    if (markdown['error']) {
      context.commit('apiError', markdown['error'])
       logApiService(obj, markdown['error'], EventName.MarkdownSetting_AdminBoard, EventType.markdownSave)
    } else {
      context.commit('markdownSuccess', markdown)
      logApiService(obj, 'Markdown updated', EventName.MarkdownSetting_AdminBoard, EventType.markdownSave)
    }
    return markdown
  }

  const getters = {
    markdownFiles: state => {
      return state.markdownFiles
    },
    markdownFilesLoading: state => {
      return state.markdownFilesLoading
    },
    markdownFileLoading: state => {
      return state.markdownFileLoading
    },
    markdownArgSpecsLoading: state => {
      return state.markdownArgSpecsLoading
    },
    singleMarkdownFile: state => {
      return state.singleMarkdownFile
    }
  }

  function get(context, url, handler) {
    return api().get(url)
    .then(response => handler(context, response))
    .catch(error => {
      handleApiError(error, '')
      handler(context, error['response'])
    })
  }
  
  function post(context, url, data, handler) {
    return api().post(url, data).then(response => handler(context, response, data))
  }
  
  function put(context, url, data, handler) {
    return api().put(url, data).then(response => handler(context, response, data))
  }
  
  function apiDelete(context, url, id, handler) {
    return api().delete(url + '/' + id).then(response => handler(context, id, response))
  }

  const actions = {
    loadmarkdownfiles(context){
      if (context.state.markdownFilesLoading) return
      context.state.markdownFilesLoading = true
      get(context, URL, handleMarkdownfiles)
    },
    loadmarkdownbyid(context,id){
      return get(context, URL + '/' + id, handleloadMarkdownfiles)
    },
    saveMarkdownRequest(context,payload)
    {
      let markdownData = {
        'markdown_content':payload['markdown_content'],
        'title':payload['title'],
        'id':payload['id'],
        'file_name':payload['file_name']
      }
      return put(context, MARK_URL + '/' + payload['id'], markdownData, handlemarkdownSave)
    }
  }

  const mutations = {
    markDownFilesLoaded(state, markdownFiles) {
      state.markdownFiles = markdownFiles
    },
    singleMarkdownfileLoad(state,singleMarkdownFile) {
      state.singleMarkdownFile = singleMarkdownFile
    },
    markdownSuccess(state,markdown){
      Vue.notify({
        group: 'messages',
        type: 'success',
        text: 'Markdown update success'
      })
    }
  }

  export default {
    state,
    getters,
    mutations,
    actions,
    name: 'markdown_setting'
  }