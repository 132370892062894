<template>
  <election-layout :id="id"></election-layout>
</template>

<script>
  import ElectionLayout from './ElectionLayout'

  export default {

    name: 'CreateElection',

    props: ['id'],

    components: {
      ElectionLayout,
    },

    mounted() {
    }

  }
</script>