var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.waiting}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"max-width":"90%","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.operation_name))])]),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-textarea',{attrs:{"id":"operation_log_screen","label":"Operation logs","value":_vm.operation_logs,"rows":"20"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeLogs()}}},[_vm._v("Close")])],1)],1)],1),_c('v-card',{attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"mb-6"},[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"blue-grey darken-1","disabled":_vm.selected.length <= 1},on:{"click":function($event){return _vm.resetSelectedOperations()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-refresh")]),_vm._v(" RESET ")],1),_c('v-btn',{staticClass:"ml-1",attrs:{"dark":"","color":"blue-grey darken-1","disabled":_vm.selected.length <= 1},on:{"click":function($event){return _vm.launchSelectedOperations()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-play")]),_vm._v(" Run ALL ")],1)],1),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.operations,"loading":_vm.jobOperationsLoading,"item-key":"operation","show-select":"","hide-default-footer":"","items-per-page":50},scopedSlots:_vm._u([{key:"item.running",fn:function(ref){
var item = ref.item;
return [(item.running)?_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.stopOperation(item)}}},[_c('v-icon',[_vm._v("mdi-stop")])],1):(item.status=='Not Ready')?_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.launchOperation(item)}}},[_c('v-icon',[_vm._v("mdi-play")])],1):_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.resetOperation(item)}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}},{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"color":"amber","height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true),model:{value:(item.percent),callback:function ($$v) {_vm.$set(item, "percent", $$v)},expression:"item.percent"}})]}},{key:"item.reports",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[(item.reports.length > 0)?_c('v-menu',{attrs:{"rounded":'b-lg',"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"outlined":""}},'v-btn',attrs,false),on),[_vm._v(" Download "),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"color":"grey darken-3"}},[_vm._l((item.reports),function(report,index){return _c('v-list-item',{key:index,attrs:{"link":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(report.text)},on:{"click":function($event){return _vm.openLink(report)}}})],1)}),_c('v-divider')],2)],1):_c('div',[_vm._v("Not Ready")])],1)]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":""}},[_c('v-icon',[_vm._v("mdi-weather-sunny")])],1)]}},{key:"item.logs",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","small":"","disabled":item.status == 'Not Ready'},on:{"click":function($event){return _vm.viewLogs(item)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }