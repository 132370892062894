<template>
  <div>
    <v-dialog v-model="deleteDialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>Cancel upload request to {{ name }} ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="deleteDialog = false">Cancel</v-btn>
          <v-btn outlined color="error" @click="confirmDelete" :disabled="waiting">
            Ok
            <v-progress-circular v-if="waiting" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="uploadRequestDialog" max-width="600px" persistent>
      <v-card class="pa-2">
        <v-card-title>
          <div>Upload Request For {{ activeElection.name }}</div>
        </v-card-title>

        <v-container>
          <v-row class="reduce-row-gaps">
            <v-col cols="12">
              <v-text-field v-model="name" label="Name" outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field v-model="email" label="Email" outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
                max-width="290px" min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model="valid_through" label="Valid Through" hint="YYYY-MM-DD format" persistent-hint
                    append-icon="mdi-calendar" v-bind="attrs" outlined :disabled="isExpiration"
                    @blur="valid_through = parseDate(valid_through)" v-on="on"></v-text-field>
                </template>
                <v-date-picker v-model="valid_through" no-title @input="menu1 = false"></v-date-picker>
              </v-menu>
              <!-- <v-dialog
                      ref="dateDialog"
                      v-model="dateModal"
                      :return-value.sync="valid_through"
                      persistent
                      width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                          v-model="valid_through"
                          label="Valid Through"
                          readonly
                          outlined
                          v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="valid_through" scrollable dark color="grey">
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" outlined @click="dateModal = false">Cancel</v-btn>
                  <v-btn text color="primary" outlined @click="$refs.dateDialog.save(valid_through)">OK</v-btn>
                </v-date-picker>
              </v-dialog> -->
            </v-col>
            <v-col cols="4">
              <v-checkbox v-model="send_email" label="Send Email" outlined></v-checkbox>
            </v-col>
            <v-col cols="4" style="display: flex;align-items: center;">
              <v-icon @click="copyUploadRequest" class="mdi-icn">mdi-link</v-icon> Link
            </v-col>
            <v-col cols="4">
              <v-checkbox v-model="isExpiration" label="No Expiration" outlined></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-textarea clearable clear-icon="cancel" label="Description" v-model="description" outlined
                :readonly="waiting" rows="7"></v-textarea>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="uploadRequestDialog=false" :disabled="waiting">Close</v-btn>
          <v-btn  color="blue darken-1" outlined @click="save"  :disabled="waiting || !canSend">
            {{id > 0 ? "Update" : "Send" }}
            <v-progress-circular
              indeterminate
              color="primary"
              v-show="waiting"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card max-width="100%">
      <v-card-title class="mb-6">
        <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
          clearable></v-text-field>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, ttprops }">
            <v-btn @click="refreshList()" dark color="blue-grey darken-1" class="ml-2" v-bind="ttprops" v-on="on">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh List</span>
        </v-tooltip>

        <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" @click="requestUpload" dark color="blue-grey darken-1" class="ml-2">
          <v-icon left>mdi-telegram</v-icon> Request Upload
        </v-btn>
      </v-card-title>


      <v-container grid-list-xl fluid>
        <v-layout row wrap>

          <v-data-table :headers="headers" :items="upload_requests" :search="search" class="elevation-1"
            :loading="uploadRequestLoading" style="width: 100%;">
            <template v-slot:item.description="{ item }">
              {{ item.description && item.description.substring(0, 100) }}
            </template>

            <template v-slot:item.type="{ item }">
              <v-chip v-if="item.send_link" show="email">Email</v-chip>
              <v-chip v-else show="link">Link</v-chip>
            </template>

            <template v-slot:item.expired="{ item }">
              <v-chip v-if="item.expired" class="ma-2" color="red" text-color="white">Expired</v-chip>
              <v-chip v-else class="ma-2" color="green" text-color="white">Valid</v-chip>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn icon @click.prevent="editRequest(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-btn icon @click.prevent="deleteRequest(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import DashLayout from '../../containers/Layout'

export default {
  name: 'RequestFiles',
  components: {
    DashLayout,
  },
  computed: {
    ...mapGetters(['uploadRequests', 'activeUploadRequest', 'activeElection', 'uploadRequestLoading', 'user']),
    canSend: function () {
      return this.email.length > 0 && this.name.length > 0
    }
  },
  mounted() {
    this.$store.dispatch('loadUploadRequests', this.activeElection.id)
  },
  data() {
    return {
      userValue: {},
      isHidden: false,
      search: '',
      upload_requests: [],
      headers: [
        { text: 'Name', value: 'name', width: '15%', },
        { text: 'Email', value: 'email', width: '15%', },
        { text: 'Description', value: 'description', width: '30%', },
        { text: 'Type', value: 'type', width: '15%', },
        { text: 'Valid Through', value: 'valid_through', width: '15%', },
        { text: 'Actions', value: 'actions', width: '15%', },
      ],
      valid_through: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().substr(0, 10),
      dateModal: false,
      deleteDialog: false,
      uploadRequestDialog: false,
      waiting: false,
      id: 0,
      name: '',
      email: '',
      expires: 24,
      description: '',
      menu1: false,
      send_email: false,
      isExpiration: false
    }
  },
  methods: {
    getStatusColor(status) {
      if (status === 'Uploaded' || status === 'Adopted') return 'light-green accent-3'
      else if (status === 'Aborted') return 'orange darken-1'
      else return 'blue darken-1'
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    deleteUploadSet(item) {
      this.selectedFile = item
      this.fileDeleteDialog = true
    },

    confirmDelete() {
      this.waiting = true
      this.$store.dispatch('DeleteUploadSet', this.selectedFile.id)
      this.waiting = false
      this.fileDeleteDialog = false
    },
    refreshList() {
      this.$store.dispatch('loadUploadRequests', this.activeElection.id)
      this.userValue = this.user
    },
    requestUpload() {
      this.id = 0
      this.name = ''
      this.email = ''
      this.valid_through = new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().substr(0, 10)
      this.description = ''
      this.uploadRequestDialog = true
      this.send_email = false
    },
    async save() {
      this.waiting = true
      let payload = {
        election_id: this.activeElection.id,
        name: this.name,
        email: this.email,
        // valid_through: this.valid_through,
        send_email: this.send_email ? this.send_email : false,
        description: this.description
      }
      if (!this.isExpiration) {
        payload['valid_through'] = this.valid_through
      }
      if (this.send_email == true) {
        payload['send_link'] = window.location.href;
      }

      if (this.id > 0) {
        payload.id = this.id
      }
      const ret = await this.$store.dispatch('saveUploadRequest', payload)
      this.waiting = false
      this.uploadRequestDialog = false
    },
    async copyUploadRequest() {
      let payload = {
        election_id: this.activeElection.id,
        name: this.name,
        email: this.email,
        valid_through: this.valid_through,
        send_email: this.send_email ? this.send_email : false,
        description: this.description
      }
      if (this.send_email == true) {
        payload['send_link'] = window.location.href;
      }

      if (this.id > 0) {
        payload.request_id = this.id
      }
      if (this.send_email == true) {
        if (this.email.length == 0) {
          Vue.notify({
            group: 'messages',
            type: 'error',
            text: 'Email Address is required'
          })
        } else {
          const ret = await this.$store.dispatch('copyUploadRequest', payload)
          await navigator.clipboard.writeText(ret.request_url);
          this.uploadRequestDialog = false
        }
      } else {
        const ret = await this.$store.dispatch('copyUploadRequest', payload)
        await navigator.clipboard.writeText(ret.request_url);
        this.uploadRequestDialog = false
      }

      this.refreshList()

    },
    editRequest(item) {
      this.id = item.id
      this.name = item.name
      this.email = item.email
      this.valid_through = item.valid_through
      this.description = item.description
      this.send_email = item.send_link
      this.uploadRequestDialog = true
      this.isExpiration = item.valid_through === null ? true : false
    },
    async deleteRequest(item) {
      this.id = item.id
      this.name = item.name
      this.email = item.email
      this.expires = 24
      this.description = item.description
      this.deleteDialog = true
    },
    async confirmDelete() {
      this.waiting = true
      const ret = await this.$store.dispatch('deleteUploadRequest', this.id)
      this.waiting = false
      this.deleteDialog = false
    }
  },
  watch: {
    uploadRequests(data) {
      this.upload_requests = []
      for (let i = 0; i < data.length; i++) {
        const item = {
          id: data[i].id,
          name: data[i].name,
          email: data[i].email,
          description: data[i].description,
          valid_through: data[i].valid_through,
          send_link: data[i].send_link
        }
        this.upload_requests.push(item)
      }
    }
  }
}
</script>
<style scoped>
.mdi-icn {
  font-size: 30px;
}
</style>
