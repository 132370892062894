<template>
    <div>
      <v-card class="mx-auto my-10" max-width="1200">
        <v-card-text>
          <h2>Your payment is successfully recived!</h2>
        </v-card-text>
      </v-card>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  
  export default {
    name: 'PaymentSuccess',
    props: ['id'],
    data() {
      return {
        price: null,
      }
    },
    computed: {
      ...mapGetters(['activeProject']),
    },
    async mounted() {
      const id = new URLSearchParams(window.location.search).get('session_id');
      await this.$store.dispatch('setPackagePayment', id)
      await this.$store.dispatch('getPackage', this.$route.params.id)
      setTimeout(() => {
         this.$router.push(`/subscription`) 
      }, 2000);
    },
    
  }
  </script>