
const state = {
  routeInfo: {},
}

const getters = {
  getRouteInfo: state => state.routeInfo,
}

const mutations = {
  setRouteInfo: (state, routeInfo) => { state.routeInfo = routeInfo },
}

const actions = {
  routeInfo: ({commit}, routeInfo) => { commit('setRouteInfo', routeInfo) },
}

export default {
  state, getters, mutations, actions
}
