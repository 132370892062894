<template>
  <div>
    <v-dialog v-model="fileDeleteDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>Delete {{ selectedFile.file_name }} ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <template v-if="userValue.role == 'Observer' ? isHidden : !isHidden">
            <v-btn @click="$refs.UploadFilesDialog.show()" dark color="blue-grey darken-1" class="ml-2">
              <v-icon left>mdi-upload</v-icon> Upload Files
            </v-btn>
          </template>
          <v-btn @click="refreshUploadFiles()" dark color="blue-grey darken-1" class="ml-2">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-actions>

          <v-container grid-list-xl fluid>
            <v-layout row wrap>

              <v-data-table :headers="headers" :items="files" :search="search" class="elevation-1"
                :loading="uploadFilesLoadingDistrict" style="width: 100%;">
                <template v-slot:[`item.status`]="{ item }">
                  <v-chip :color="getStatusColor(item.status)" dark style="color: black;">{{ item.status }}</v-chip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn @click="deleteFile(item)" icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-layout>
          </v-container>
      </v-card>
    </v-dialog>

    <UploadFilesDialog ref="UploadFilesDialog" />

    <v-card max-width="100%" class="mb-2">
      <v-card-title class="mb-6">
        Direct Uploads
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn @click="$refs.UploadFilesDialog.show()" dark color="blue-grey darken-1" class="ml-2">
          <v-icon left>mdi-upload</v-icon> Upload Files
        </v-btn>
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, ttprops }">
            <v-btn @click="refreshUploadFiles()" dark color="blue-grey darken-1" class="ml-2" v-bind="ttprops" v-on="on">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh Upload Files</span>
        </v-tooltip>
      </v-card-title>


      <v-container grid-list-xl fluid>
        <v-layout row wrap>

          <v-data-table :headers="headers" :items="files" :search="search" class="elevation-1"
            :loading="uploadFilesLoadingDistrict" style="width: 100%;">
            <template v-slot:[`item.status`]="{ item }">
              <v-chip :color="getStatusColor(item.status)" dark style="color: black;">{{ item.status }}</v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, ttprops }">
                  <v-btn icon @click="deleteFile(item)" v-bind="ttprops" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete File</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-layout>
      </v-container>
    </v-card>

    <v-card max-width="100%">
      <v-card-title class="mb-6">
        Requested Uploads
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, ttprops }">
            <v-btn @click="refreshRequestedFiles()" dark color="blue-grey darken-1" class="ml-2" v-bind="ttprops"
              v-on="on">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh Requested Files</span>
        </v-tooltip>
      </v-card-title>

      <v-container grid-list-xl fluid>
        <v-layout row wrap>

          <v-data-table :headers="headers" :items="requested_files" :search="search" class="elevation-1"
            :loading="requestedFilesLoadingDistrict" style="width: 100%;">
            <template v-slot:[`item.status`]="{ item }">
              <v-chip :color="getStatusColor(item.status)" dark style="color: black;">{{ item.status }}</v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, ttprops }">
                  <v-btn icon @click="deleteFile(item)" v-bind="ttprops" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete File</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>
  
<script>
import { mapGetters, mapActions } from 'vuex'
import DashLayout from '../../containers/Layout'
import UploadFilesDialog from './UploadFilesDialog'

export default {
  name: 'FileUploadDistrictContainer',
  components: {
    DashLayout,
    UploadFilesDialog,
  },
  computed: {
    ...mapGetters(['uploadFilesDistrict', 'requestedFilesDistrict', 'districtData', 'uploadFilesLoadingDistrict', 'requestedFilesLoadingDistrict', 'user'])
  },
  mounted() {
    this.$store.dispatch('loadUploadFilesDistrict', this.districtData.id)
    this.$store.dispatch('loadRequestedFilesDistrict', this.districtData.id)
  },
  data() {
    return {
      search: '',
      uploading: false,
      files: [],
      requested_files: [],
      headers: [
        { text: 'Name', value: 'file_name', },
        { text: 'Size', value: 'size', },
        { text: 'Started', value: 'start', },
        { text: 'Completed', value: 'end', },
        { text: 'Status', value: 'status', },
        { text: 'Uploaded By', value: 'uploaded_by', },
        { text: 'Actions', value: 'actions', },
      ],
      selectedFile: {},
      fileDeleteDialog: false,
      waiting: false,
    }
  },
  methods: {
    getStatusColor(status) {
      if (status === 'Success') return 'light-green accent-3'
      else if (status === 'Error' || status === 'Failed') return 'red darken-1'
      else return 'orange darken-1'
    },

    deleteFile(item) {
      this.selectedFile = item
      this.fileDeleteDialog = true
    },

    async confirmDelete() {
      this.$store.commit('setLoading', true)
      if (this.selectedFile.category == 'requested') {
        await this.$store.dispatch('DeleteRequestedFileDistrict', this.selectedFile.id)
      } else {
        await this.$store.dispatch('DeleteUploadFileDistrict', this.selectedFile.id)
      }
      this.$store.commit('setLoading', false)
      this.fileDeleteDialog = false
    },
    refreshUploadFiles() {
      this.files = []
      this.$store.dispatch('loadUploadFilesDistrict', this.districtData.id)
    },
    refreshRequestedFiles() {
      this.requested_files = []
      this.$store.dispatch('loadRequestedFilesDistrict', this.districtData.id)
    },
    async adoptFile() {
      this.$store.commit('setLoading', true)
      const ret = await this.$store.dispatch('loadAdoptableFilesDistrict', this.districtData.id)
      if (!ret['error']) {
        this.$refs.FileAdoptDialog.show()
      }

      this.$store.commit('setLoading', false)
    },
    editFile(item) {
      // console.log(item)
    },
  },
  watch: {
    uploadFilesDistrict(uploadFilesDistrict) {
      this.files = []
      for (let i = 0; i < this.uploadFilesDistrict.length; i++) {
        let file = {
          id: this.uploadFilesDistrict[i].id,
          status: this.uploadFilesDistrict[i].status,
          file_name: this.uploadFilesDistrict[i].file_name,
          uploaded_by: this.uploadFilesDistrict[i].uploader.first_name + ' ' + this.uploadFilesDistrict[i].uploader.last_name,
          category: 'uploaded',
        }

        file.start = this.uploadFilesDistrict[i].created_at.replace('T', ' ')
        if (this.uploadFilesDistrict[i].status != "Uploading") {
          file.end = this.uploadFilesDistrict[i].updated_at.replace('T', ' ')
        } else {
          file.end = "N/A"
        }


        if (this.uploadFilesDistrict[i].size >= 1024 * 1024 * 1024) {
          const size = Math.round(this.uploadFilesDistrict[i].size * 100 / (1024 * 1024 * 1024)) / 100
          file.size = size.toString() + 'GB'
        }
        else if (this.uploadFilesDistrict[i].size >= 1024 * 1024) {
          const size = Math.round(this.uploadFilesDistrict[i].size * 100 / (1024 * 1024)) / 100
          file.size = size.toString() + 'MB'
        }
        else if (this.uploadFilesDistrict[i].size >= 1024) {
          const size = Math.round(this.uploadFilesDistrict[i].size * 100 / (1024)) / 100
          file.size = size.toString() + 'KB'
        }
        else {
          const size = Math.round(this.uploadFilesDistrict[i].size * 100) / 100
          file.size = size.toString() + 'B'
        }

        this.files.push(file)
      }
    },

    requestedFilesDistrict(requestedFilesDistrict) {
      this.requested_files = []
      for (let i = 0; i < this.requestedFilesDistrict.length; i++) {
        let file = {
          id: this.requestedFilesDistrict[i].id,
          status: this.requestedFilesDistrict[i].status,
          file_name: this.requestedFilesDistrict[i].file_name,
          category: 'requested',
        }
        if (this.requestedFilesDistrict[i].request) {
          file.uploaded_by = this.requestedFilesDistrict[i].request.name
        }

        file.start = this.requestedFilesDistrict[i].created_at.replace('T', ' ')
        if (this.requestedFilesDistrict[i].status != "Uploading") {
          file.end = this.requestedFilesDistrict[i].updated_at.replace('T', ' ')
        } else {
          file.end = "N/A"
        }


        if (this.requestedFilesDistrict[i].size >= 1024 * 1024 * 1024) {
          const size = Math.round(this.requestedFilesDistrict[i].size * 100 / (1024 * 1024 * 1024)) / 100
          file.size = size.toString() + 'GB'
        }
        else if (this.requestedFilesDistrict[i].size >= 1024 * 1024) {
          const size = Math.round(this.requestedFilesDistrict[i].size * 100 / (1024 * 1024)) / 100
          file.size = size.toString() + 'MB'
        }
        else if (this.requestedFilesDistrict[i].size >= 1024) {
          const size = Math.round(this.requestedFilesDistrict[i].size * 100 / (1024)) / 100
          file.size = size.toString() + 'KB'
        }
        else {
          const size = Math.round(this.requestedFilesDistrict[i].size * 100) / 100
          file.size = size.toString() + 'B'
        }

        this.requested_files.push(file)
      }
    }
  }
}
</script>
  