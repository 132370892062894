<template>
  <v-row dense class="reduce-row-gaps">
    <v-col cols="12">
      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field
            v-model="search"
            outlined
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap>
            <v-data-table
              :headers="headers"
              :items="getPaymentList"
              :search="search"
              class="elevation-1"
              :loading="donationLoading"
              loading-text="Donation  List Loading ..."
              style="width: 100%;"
            >

              <template v-slot:[`item.price`]="{ item }">
                <span>$ {{item.price}}</span>
              </template> 
              
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { AbbrCountries } from './../../services/AbbrCountries.js'
import { AbbrStates } from './../../services/AbbrStates.js'

export default {
  name: 'DonationList',

  components: {},

  computed: {
    ...mapGetters(['getPaymentList','donationLoading','DonationLoaded']),
  },

  mounted() {
    this.$store.dispatch('loadallpayment')
  },

  data() {
    return {
      search: '',
      headers: [
        { text: 'Transaction Id', align: 'start', sortable: true, value: 'transaction_id', width: '10%' },
        { text: 'Project Name', align: 'start', sortable: true, value: 'project.name', width: '10%' },
        { text: 'Name', align: 'start', sortable: true, value: 'name', width: '10%' },
        { text: 'Email', align: 'start', sortable: true, value: 'email', width: '10%' },
        { text: 'Price', align: 'start', sortable: false, value: 'price', width: '10%' },
        { text: 'Address', align: 'start', value: 'address', width: '10%' },
        { text: 'Country',align: 'start', value: 'country', width: '10%' },
        { text: 'State',align: 'start', value: 'state', width: '10%' },
        { text: 'City',align: 'start', value: 'city', width: '10%' },
        { text: 'Zip Code', value: 'zip_code', width: '10%' },
      ],
      selectedPermits: [],
    }
  },
  methods: {
   
  },

  watch: {
    
  },
}
</script>

