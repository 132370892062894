
import Vue from 'vue'
import api from 'Api'
import { handleApiError } from '../../../services/apiService'
const state = {
  major_release: null,
  minor_release: null,
  logo_string: null,  
}

const getters = {
  major_release: state => {
    return state.major_release
  },
  minor_release: state => {
    return state.minor_release
  },
  logo_string: state => {
    return state.logo_string
  },
}

const actions = {
  getComputeInfo(context) {
    return api(false).get('/compute/version')
    .then(response => { 
      context.commit("setComputeInfo", response.data)
    })
    .catch(error => {
      return error.response
    });
  },
}

const mutations = {
  setComputeInfo(state, configData) {
    state.major_release = configData.MAJOR_RELEASE
    state.minor_release = configData.MINOR_RELEASE
    state.logo_string = configData.LOGO
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  name: 'computeInfoStore'
}
