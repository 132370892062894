<template>
<div>
    <v-overlay v-if="waiting">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

  <v-card max-width="100%">
    <v-dialog v-model="jobSettingsDeleteDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>Are you sure deleting {{file_name}} ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline"  outlined @click="jobSettingsDeleteDialog=false">Cancel</v-btn>
          <v-btn color="error" outlined @click="deleteFileConfirm" :disabled="waiting">
            Delete
            <v-progress-circular
                    v-if="waiting"
                    :width="3"
                    color="green"
                    indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="auditattechdialog" max-width="700px" persistent>    
      <v-card>
        <v-card-title>
            <span class="headline">Audit Attech</span>
          </v-card-title>
        <v-card-text class="picker-popup">
        <v-row>
          <v-col cols="12">
              <v-file-input
                v-model="files"
                ref="files"
                placeholder="Upload your documents"
                label="File input"
                prepend-icon="mdi-paperclip"
                accept="image/*,application/pdf,application/msword,
                application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                @change="uploadFiles" >
                <template v-slot:selection="{ text }">
                <v-chip
                    small
                    label
                    color="primary"
                >
                    {{ text }}
                </v-chip>
                </template>
            </v-file-input>
          </v-col>

          </v-row>
            <v-col cols="12">
               <vue-simplemde :highlight="true" :rules="[rules.required]" v-model="md_text" />
            </v-col>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" outlined @click="close" v-if="!fileUploading" :disabled="uploadSetSaving">CLOSE</v-btn>
          <v-btn color="blue darken-1" outlined @click="hide" v-else>HIDE</v-btn>
          <v-btn color="blue darken-1" outlined @click="upload" v-if="!fileUploading" :disabled="!file || uploadSetSaving">UPLOAD</v-btn>
          <v-btn color="blue darken-1" outlined @click="cancel" v-else>CANCEL</v-btn> -->
          <v-btn color="blue darken-1" outlined @click="attechsave" :disabled="AttechDisabled || waiting">Save
            <v-progress-circular
              v-if="waiting"
              :width="3"
              color="green"
              indeterminate
            ></v-progress-circular>
            </v-btn> 
           <v-btn color="blue darken-1" outlined @click="cancel">CANCEL</v-btn> 
            
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title class="mb-6">
      <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details clearable></v-text-field>
      <v-spacer></v-spacer>

      <v-btn dark color="blue-grey darken-1" @click.prevent="uploadNewFile">
        <v-icon left>mdi-upload</v-icon> Attech New File
      </v-btn>

    </v-card-title>


    <v-container grid-list-xl fluid>
      <v-layout row wrap>

        <v-data-table
                :headers="headers"
                :items="attechFiles"
                :search="search"
                class="elevation-1"
                :loading="attechFileLoading"
                loading-text="Loading attech Files ..."
                style="width: 100%;"
        >
          <template v-slot:item.actions="{ item }">
            <!-- <v-btn v-if="item.name.split('_')[0]=='JOB'" icon @click.prevent="showSettingsDialog(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn v-else icon :disabled="true">
              <v-icon>mdi-none</v-icon>
            </v-btn> -->
            <v-btn  icon @click.prevent="showSettingsDialog(item)">
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
            <v-btn v-if="item.attech_url != null" icon @click.prevent="downloadFile(item)">
              <v-icon>mdi-download</v-icon>
            </v-btn>

            <v-btn icon @click.prevent="deleteFile(item)">
              <v-icon>mdi-delete</v-icon>
            </v-btn>            
          </template>
        </v-data-table>
      </v-layout>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import DashLayout from '../../containers/Layout'
  import VueSimplemde from 'vue-simplemde'
import hljs from 'highlight.js';
 window.hljs = hljs;

  export default {
    name: 'JonSettingContainer',
    components: {
      DashLayout,
      VueSimplemde
    },
    computed: {
      AttechDisabled: function() {
        let invalidName = !isNaN(this.md_text) || this.md_text == ''
        return invalidName
      },
      ...mapGetters(['jobFiles', 'jobFilesLoading', 'activeAuditJob', 'user','attechFileLoading','attechFiles'])
      
    },
    mounted() {      
      this.$store.dispatch('loadAuditattechfiles', this.activeAuditJob.id)
    },
    data () {
      return {
        overlay: false,
        search: '',
        file_name: '',

        waiting: false,
        jobSettingsDeleteDialog: false,
        md_text:'',
        attech_id:0,
        headers: [
          { text: 'Sl.No', value: 'id', sortable: false, width: '20%'},
          {text:'Username',value:'users.email',sortable: false, width: '20%'},
          { text: 'File Name', value: 'audit_file_name', sortable: false, width: '20%'},
        // { text: 'description', value: 'description', sortable: false, width: '10%'},
        //   { text: 'Size', value: 'size', sortable: false, width: '10%'},
        //   { text: 'Last Modified', value: 'last_modified', sortable: false, width: '20%'},
          { text: 'Actions', value: 'actions', sortable: false, width: '20%'},
        ],
        rules: {
          required: value => !!value || 'Required.',
        },
        files: [],
        jobFileCreateErrorDialog: false,
        auditattechdialog:false,
      }
    },


    methods: {
        uploadNewFile(){
            this.auditattechdialog = true;
        },
        async uploadFiles(event){
          this.overlay = true;
          let formData = new FormData()
           formData.append("files", this.files)
           formData.append('job_id', this.activeAuditJob.id);
           const res = await this.$store.dispatch('uploadattechfile', formData)
           if(res.url){
             this.md_text = '<' + res.url + '>';
           }
           this.attech_id = res.id;
            
            this.overlay = false;  
            
        },
        async attechsave(){
          this.waiting = true;
          const payload = {
            'job_id': this.activeAuditJob.id,
            'description':this.md_text
          }
          if(this.attech_id != ''){
            payload['id'] = this.attech_id
          }
          const res = await this.$store.dispatch('Auditattechfile', payload)
          this.$store.dispatch('loadAuditattechfiles', this.activeAuditJob.id)
          this.md_text = ''
          this.files = []
          this.auditattechdialog = false; 
          this.waiting = false;
        },
        cancel(){
          this.auditattechdialog = false; 
        },
        close(){
         this.auditattechdialog = true; 
      },
        deleteFile(file) {
        this.attech_id = file.id
        this.jobSettingsDeleteDialog = true
      },
      async deleteFileConfirm() {
        this.waiting = true
        const payload = {
          id: this.attech_id
        }
        await this.$store.dispatch('deleteAuditattech', payload)
        this.jobSettingsDeleteDialog = false
        this.waiting = false
      },

      async showSettingsDialog(item) {
        this.overlay = true       

        this.$store.commit('setJobArgSpecs', [])
        const ret = await this.$store.dispatch('getJobArgSpecs')

        if(item.name){
          const payload = {
            job_id: this.activeAuditJob.id,
            file_name: item.name
          }
          let ret = await this.$store.dispatch('getJobFile', payload)   
                 
        }
        else {
          this.$store.commit('setActiveJobFile', {})
        }

        this.overlay = false
        this.$refs.JobSettingDialog.show(item)
      },

      closeDialog() {
        this.jobSettingsDeleteDialog = false
      },

    //   deleteFile(file) {
    //     this.file_name = file.name
    //     this.jobSettingsDeleteDialog = true
    //   },
    //   async deleteFileConfirm() {
    //     this.waiting = true
    //     const payload = {
    //       job_id: this.activeAuditJob.id,
    //       file_name: this.file_name
    //     }
    //     await this.$store.dispatch('deleteJobFile', payload)
    //     this.jobSettingsDeleteDialog = false
    //     this.waiting = false
    //   },

    //   async showSettingsDialog(item) {
    //     this.overlay = true       

    //     this.$store.commit('setJobArgSpecs', [])
    //     const ret = await this.$store.dispatch('getJobArgSpecs')

    //     if(item.name){
    //       const payload = {
    //         job_id: this.activeAuditJob.id,
    //         file_name: item.name
    //       }
    //       let ret = await this.$store.dispatch('getJobFile', payload)   
                 
    //     }
    //     else {
    //       this.$store.commit('setActiveJobFile', {})
    //     }

    //     this.overlay = false
    //     this.$refs.JobSettingDialog.show(item)
    //   },

    //   closeDialog() {
    //     this.jobSettingsDeleteDialog = false
    //   },

    //   downloadFile(item) {
    //     window.location.href = item.url
    //   },

    //   uploadNewFile() {
    //     this.$refs.JobFileUploadDialog.show()
    //   },

    //   generateJobFile() {
        
    //   },
    //   createNewJobFile() {
    //     for(let i = 0; i < this.jobFiles.length; i ++){
    //       if(this.jobFiles[i].file_type == 'JOB') {
    //         this.jobFileCreateErrorDialog = true
    //         return
    //       }
    //     }        
    //     this.showSettingsDialog({})
    //   }
    },

    watch: {
    //   jobFiles() {
    //     this.files = []
    //     for(let i = 0; i < this.jobFiles.length; i ++){
    //       let item = {
    //         name: this.jobFiles[i].file_name,
    //         size: this.jobFiles[i].size,
    //         type: this.jobFiles[i].file_type,
    //         url: this.jobFiles[i].url,
    //         last_modified: this.jobFiles[i].last_modified,
    //       }

    //       if(this.jobFiles[i].size >= 1024 * 1024 * 1024) {
    //         const size = Math.round(this.jobFiles[i].size * 100/(1024 * 1024 * 1024))/100
    //         item.size = size.toString() + ' GB'
    //       }
    //       else if (this.jobFiles[i].size >= 1024 * 1024 ) {
    //         const size = Math.round(this.jobFiles[i].size * 100/(1024 * 1024))/100
    //         item.size = size.toString() + ' MB'
    //       }
    //       else if (this.jobFiles[i].size >= 1024 ) {
    //         const size = Math.round(this.jobFiles[i].size * 100/(1024))/100
    //         item.size = size.toString() + ' KB'
    //       }
    //       else {
    //         const size = Math.round(this.jobFiles[i].size * 100)/100
    //         item.size = size.toString() + ' B'
    //       }

    //       this.files.push(item)
    //     }
    //   },
    //   jobFileLoading() {
    //   }
    }
  }
</script>
