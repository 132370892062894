<template>
<div class="window-container" :class="{ 'window-mobile': isDevice }">
 <v-card max-width="100%">
	<v-dialog v-model="comanConfirmDialog" max-width="500px" persistent>
		<v-card>
		<v-card-title style="word-break: break-word;">Update Room</v-card-title>
		<v-card-text>
            <v-container>
              <v-row>
                  <v-col cols="12">
                    <v-text-field                   
                    v-model="updateRoomUsername" 
                    label="Room Name" 
                    outlined
                  ></v-text-field>
                  </v-col>
              </v-row>                                                   
            </v-container>
          </v-card-text>
		<v-card-actions class="pa-8">
			<v-spacer></v-spacer>
			<v-btn class="btn-outline" outlined @click="closedeleteDialog">Cancel</v-btn>
			<v-btn color="error" outlined @click="deleteRoom(rm_id)" :disabled="overlay">
			Update
			<v-progress-circular v-if="overlay" :width="3" color="error" indeterminate></v-progress-circular>
			</v-btn>
		</v-card-actions>
		</v-card>
	</v-dialog>
	<v-dialog v-model="deleteuserConfirmDialog" max-width="500px" persistent>
		<v-card>
		<v-card-title style="word-break: break-word;">Are you sure you want to delete room user?</v-card-title>
		<v-card-actions class="pa-8">
			<v-spacer></v-spacer>
			<v-btn class="btn-outline" outlined @click="closeuserdeletedialog">Cancel</v-btn>
			<v-btn color="error" outlined @click="deleteRoomUser" :disabled="overlay">
			Ok
			<v-progress-circular v-if="overlay" :width="3" color="error" indeterminate></v-progress-circular>
			</v-btn>
		</v-card-actions>
		</v-card>
	</v-dialog>
   <v-dialog v-model="roomDialog" max-width="50%" persistent>
      <v-card>
       
         <form v-if="addNewRoom" @submit.prevent="createRoom">
            <v-card-title>Add Room</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                  <v-col cols="12">
                    <v-text-field                   
                    v-model="addRoomUsername" 
                    label="Room Name" 
                    outlined
                  ></v-text-field>
                  </v-col>
              </v-row>                                                   
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-8">
            <v-spacer></v-spacer>
            <v-btn class="btn-outline"  outlined @click="roomDialog=false">Cancel</v-btn>
            <v-btn color="error" type="submit" outlined :disabled="disableForm || !addRoomUsername">
              Save
              <v-progress-circular
                      v-if="waiting"
                      :width="3"
                      color="green"
                      indeterminate
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </form>
        <form v-if="inviteRoomId" @submit.prevent="addRoomUser">
          <v-card-title>Invite Users</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-combobox
                    :items="roomuserselect"
                    v-model="invitedUsername"
                    label="Select User"
                    item-text="email"
                    item-value="id"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        :key="JSON.stringify(data.item.id)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                      >
                        <v-avatar
                          class="accent white--text"
                          left
                          v-text="data.item.email.slice(0,1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item.email}}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
            <v-card-actions class="pa-8">
              <v-spacer></v-spacer>
              <v-btn class="btn-outline"  outlined @click="cancelclick">Cancel</v-btn>
              <v-btn color="error" type="submit" outlined :disabled="disableForm || !invitedUsername || waiting">
                Add User
                <v-progress-circular
                        v-if="waiting"
                        :width="3"
                        color="green"
                        indeterminate
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
		    </form>
			<form v-if="removeRoomId" @submit.prevent="deleteuserdialog">
				<v-card-title>Remove Users</v-card-title>
				<v-card-text>
					<v-container>
					<v-row>
						<v-col cols="12">
						<v-combobox
							:items="removeUserId"
							v-model="removeinvitedUsername"
							label="Select User"
							item-text="email"
							item-value="id"
							multiple
						>
							<template v-slot:selection="data">
							<v-chip
								:key="JSON.stringify(data.item.id)"
								v-bind="data.attrs"
								:input-value="data.selected"
								:disabled="data.disabled"
							>
								<v-avatar
								class="accent white--text"
								left
								v-text="data.item.email.slice(0,1).toUpperCase()"
								></v-avatar>
								{{ data.item.email}}
							</v-chip>
							</template>
						</v-combobox>
						</v-col>
					</v-row>
					</v-container>
				</v-card-text>
					<v-card-actions class="pa-8">
					<v-spacer></v-spacer>
					<v-btn class="btn-outline"  outlined @click="removeUserclick">Cancel</v-btn>
					<v-btn color="error" type="submit" outlined :disabled="disableForm || !removeinvitedUsername || waiting">
						Remove User
						<v-progress-circular
								v-if="waiting"
								:width="3"
								color="green"
								indeterminate
						></v-progress-circular>
					</v-btn>
					</v-card-actions>
		    </form>
      </v-card>
    </v-dialog>
	<div class="export-btn">
	 <v-btn class="btn-outline" v-show="userrole == 'Admin'"  outlined @click="export_csv" :disabled="messages.length == 0">Export Messages</v-btn>
	</div>
  <chat-window
        :height="screenHeight"
		:theme="theme"
		:styles="styles"
        :current-user-id="currentUserId"
        :room-id="roomId"
        :rooms="loadedRooms"
        :loading-rooms="loadingRooms"
        :rooms-loaded="roomsLoaded"
        :room-actions="roomActions"
        :menu-actions="menuActions"
        :room-message="roomMessage"
        :messages="messages"
        :messages-loaded="messagesLoaded"
        @fetch-more-rooms="fetchMoreRooms"
        @fetch-messages="fetchMessages"
        @send-message="sendMessage"
		@edit-message="editMessage"
		@delete-message="deleteMessage"
        @add-room="addRoom"
        @open-file="openFile"
		@open-user-tag="openUserTag"
        @room-action-handler="menuActionHandler"
		@menu-action-handler="menuActionHandler"
        @send-message-reaction="sendMessageReaction"
		@typing-message="typingMessage"
        @toggle-rooms-list="$emit('show-demo-options', $event.opened)"
    />
 </v-card>
</div>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import vSelect from 'vue-select'
  import { parseTimestamp, formatTimestamp } from '../../utils/dates'
  import ChatWindow from 'vue-advanced-chat'
  import 'vue-advanced-chat/dist/vue-advanced-chat.css'
  import SocketIO from 'socket.io-client';

  import DashLayout from '../../containers/Layout'



  export default {
    name: 'ChatContainer',
    components: {
      ChatWindow
    },
    props: {
      currentUserId: { type: Number, required: true },
      theme: { type: String, required: true },
      isDevice: { type: Boolean, required: true }
	  },
   
    emits: ['show-demo-options'],
    computed: {
      ...mapGetters(['roomLoaded','roomloading','activeAuditJob','rooms_data','user','room_save','setAuditRoom','auditActiveJobLoading','get_messages']),
      loadedRooms() {
		if(this.rooms.length > 0){
			//document.getElementsByClassName("vac-add-icon").style.display = "none";
			var add_icon = document.querySelector(".vac-add-icon");
			add_icon?add_icon.classList.add("room-hidden-element"):'';
		}
        return this.rooms.slice(0, this.roomsLoadedCount)
      },
      screenHeight() {
        return this.isDevice ? window.innerHeight + 'px' : 'calc(100vh - 80px)'
      }
    },
    data () {
      return {
		 	overlay: false,
      		roomsPerPage: 15,
			rooms: [],
			RoomPerList: [],
			roomuserselect: [],
			roomId: '',
			startRooms: null,
			endRooms: null,
			roomsLoaded: false,
			loadingRooms: true,
			allUsers: [],
			loadingLastMessageByRoom: 0,
			roomsLoadedCount: false,
			selectedRoom: null,
			messagesPerPage: 20,
			messages: [],
			messagesLoaded: false,
			roomMessage: '',
			lastLoadedMessage: null,
			previousLastLoadedMessage: null,
			roomsListeners: [],
			listeners: [],
			typingMessageCache: '',
			disableForm: false,
			addNewRoom: null,
			addRoomUsername: '',
			inviteRoomId: null,
			invitedUsername: [],
			removeinvitedUsername: [],
			removeRoomId: null,
			removeUserId: [],
			removeUsers: [],
      		selectedPermits:[],
			comanConfirmDialog:false,
			deleteuserConfirmDialog:false,
		    file_obj:'',
			updateRoomUsername:null,
      		userrole:JSON.parse(localStorage.getItem('user')).role,
		    socket: SocketIO(`${process.env.VUE_APP_SOCKET_IO}`),
			//socket:SocketIO('https://server.auditengine.org/send-message'),
			roomActions: [
				{ name: 'inviteUser', title: 'Invite User' },
				{ name: 'removeUser', title: 'Remove User' },
				{ name: 'updateRoom', title: 'Update Room' }
			],
			menuActions: [
				{ name: 'inviteUser', title: 'Invite User' },
				{ name: 'removeUser', title: 'Remove User' },
				{ name: 'updateRoom', title: 'Update Room' }
			],
			styles: { container: { borderRadius: '4px' } },
			
      waiting:false,
      roomDialog:false,
      user_data:[],
      all_users:[],
	  rm_id:null,
	  mess_id:null,
       
       
      }
    },
 	
	async mounted() {  
        //this.currentUserId = JSON.parse(localStorage.getItem('user')).user_id;  
        await this.$store.dispatch('loadUserroom');
		await this.$store.dispatch('getjobroom',this.activeAuditJob.id)
        await  this.fetchRooms()
        await this.all_userdata()
		// await firebaseService.updateUserOnlineStatus(this.currentUserId)
		this.socket.on('MESSAGE', (socket) => {
      		// this.messages = JSON.parse(socket);
			this.mess_id = socket.id;
			let room = this.rooms.filter(item => item.id == socket.rooms.id);     
			const formattedMessage = this.formatMessage(room[0], socket)
			const messageIndex = this.messages.findIndex(m => m.id === socket.id)

			if (messageIndex === -1) {
				this.messages = this.messages.concat([formattedMessage])
			} else {
				this.messages[messageIndex] = formattedMessage
				this.messages = [...this.messages]
			}

    	})
		// await this.add_class()
    },
    methods: {
	
	export_csv(){
			/**user
			datetime
			text */
		let final_rs = [];
		if(this.messages && this.messages.length > 0){
			for (let i = 0; i < this.messages.length; i++) {
				let res = {}
				if(this.messages[i].deleted == null){
					res.user = this.messages[i].users.email;
					res.datetime = this.messages[i].mess_time;
					res.text =  this.messages[i].content;
					if(this.messages[i].files != null && this.messages[i].files.length > 0){
						let fil = []
						for(let j=0;j<this.messages[i].files.length;j++){
							let re = {}
							re.path = this.messages[i].files[j].url
							re.size = this.messages[i].files[j].size
							re.extension = this.messages[i].files[j].extension
							fil.push(re);
						}
						res.attechment =  fil;
					}else{
						res.attechment = [];
					}
					final_rs.push(res)
				}
			}
			
			let res_arr = {};
			res_arr['messages'] = final_rs
			const payload ={
				'export_data':JSON.stringify(res_arr),
				'job_id':this.activeAuditJob.id
			}
			this.$store.dispatch('reportexport',payload);
			if(final_rs.length > 0){
				let csvContent = "data:text/json;charset=utf-8,";
				csvContent += encodeURIComponent(JSON.stringify(res_arr))
				//reportexport
				// const data = encodeURI(csvContent);
				const link = document.createElement("a");
				link.setAttribute("href", csvContent);
				link.setAttribute("download", this.activeAuditJob.name+".json");
				link.click();
			}
		}
		
	},
	current_timestamp() {
      const today = new Date();
      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
      const dateTime = `${date} ${time}`;
      // this.timestamp = dateTime;
      return dateTime;
    },
    async all_userdata(){
      const roomUserIds = []
		this.room_save.forEach(room => {
			if(room.audits && room.audits.id == this.activeAuditJob.id){
				let rm_usr = JSON.parse(room.users)
				rm_usr.forEach(userId => {
					const foundUser = this.allUsers.find(user => user?.id === userId)
					if (!foundUser && roomUserIds.indexOf(userId) === -1) {
						roomUserIds.push(userId)
					}
				})
			}
		})
		
		// this.incrementDbCounter('Fetch Room Users', roomUserIds.length)
		const rawUsers = []
		roomUserIds.forEach(userId => {
			if(this.user && this.user.id == userId){
				rawUsers.push(this.user)
			}else{
				const foundUser = this.rooms_data.filter(item => item.id == userId);
				rawUsers.push(foundUser[0])
			}

		})
		this.allUsers = [...this.allUsers, ...rawUsers]
    },
    cancelclick(){
        this.roomDialog=false 
        this.inviteRoomId = null
    },
	removeUserclick(){
        this.roomDialog=false
        this.removeRoomId = null
    },
	resetRooms() {
		this.loadingRooms = true
		this.loadingLastMessageByRoom = 0
		this.roomsLoadedCount = 0
		this.rooms = []
		this.roomsLoaded = true
		this.startRooms = null
		this.endRooms = null
		this.roomsListeners.forEach(listener => listener())
		this.roomsListeners = []
		this.resetMessages()
	},
    resetMessages() {
			this.messages = []
			this.messagesLoaded = false
			this.lastLoadedMessage = null
			this.previousLastLoadedMessage = null
			this.listeners.forEach(listener => listener())
			this.listeners = []
		},
    resetForms() {
			this.disableForm = false
			this.addNewRoom = null
			this.addRoomUsername = ''
			this.inviteRoomId = null
			this.invitedUsername = []
			this.removeRoomId = null
			this.removeinvitedUsername = []
		},
    addRoom() {
      this.roomDialog = true;
      this.addNewRoom = true;
    },
    async createRoom() {
		this.waiting = true;
		this.disableForm = true
		let u_id = this.currentUserId
		
		const payload = {
			name:this.addRoomUsername,
			job_id:this.activeAuditJob.id,
			lastUpdated: new Date(),
			users: JSON.stringify([u_id])
		}
		await this.$store.dispatch('addroom',payload);
		this.addNewRoom = false
		this.addRoomUsername = ''
		this.waiting = false;
		this.roomDialog = false;
		this.fetchRooms();
	},
    async fetchMessages({ room, options = {} }) {
		await this.$store.dispatch('loadroommessage',room.id);
		
		if (options.reset) {
			this.resetMessages()
			this.roomId = room.roomId
		}
        this.selectedRoom = room.roomId
		
		if (this.selectedRoom !== room.roomId) return

		if (options.reset) this.messages = []
        // this.get_messages.forEach(message =>{
		// 	// const formattedMessage = this.formatMessage(room, message)
		// 	// this.messages.unshift(formattedMessage)
			
		// })
		
		setTimeout(() => {
			this.listenMessages(this.get_messages && this.get_messages, room)
			document.getElementById("vac-circle").style.display = "none";
		}, 3000);
		
		if(this.messages.length > 0){
			document.getElementById("infinite-loader-messages").style.display = "none";
		}else{
			document.getElementById("vac-circle").style.display = "none";
		}
		
    },
    listenMessages(messages, room) {
			messages.forEach(message => {
				const formattedMessage = this.formatMessage(room, message)
				const messageIndex = this.messages.findIndex(m => m.id === message.id)

				if (messageIndex === -1) {
					this.messages = this.messages.concat([formattedMessage])
				} else {
					this.messages[messageIndex] = formattedMessage
					this.messages = [...this.messages]
				}
				
				this.markMessagesSeen(room, message)
			})
		},

		markMessagesSeen(room, message) {
			if (
				message.users.id !== this.currentUserId &&
				(!message.seen || !message.seen[this.currentUserId])
			) {

				// const payload = {
				// 	'message_id':message.id,
				// 	'room_id':room.roomId,
				// 	'user_id':this.currentUserId,
				// 	'c_time':this.current_timestamp()
				// }
				// this.socket.emit('messageMarkSeen', message);
				this.$store.dispatch('markmessageseen',{
					'message_id':message.id,
					'room_id':room.roomId,
					'user_id':this.currentUserId,
					'c_time':this.current_timestamp()
				});

			}
		},

		formatMessage(room, message) {
			const timestamp = new Date(message.timestamp)
			
			if(message.files){
				let mess_files = typeof message.files == 'string' ?JSON.parse(message.files):message.files
				message.files = mess_files;
			}
			const formattedMessage = {
				...message,
				...{
					senderId: message.users.id,
					_id: message.id,
					seconds: timestamp.getTime(),
					timestamp: parseTimestamp(timestamp, 'HH:mm'),
					date: parseTimestamp(timestamp, 'DD MMMM YYYY'),
					username: room.users.find(
						user => message.users.id === user.id
					)?.first_name,
					// avatar: senderUser ? senderUser.avatar : null,
					distributed: true,
					reactions:message.reactions && typeof message.reactions == 'string'?JSON.parse(message.reactions):'',
					mess_time:message.timestamp
				}
			}
			if (message.replyMessage) {
				let replay_message = JSON.parse(message.replyMessage)
				formattedMessage.replyMessage = {
					...replay_message,
					...{
						senderId: replay_message.sender_id
					}
				}
			}
			

			return formattedMessage
		},
    async sendMessage({ content, roomId, files, replyMessage }) {

			const message = {
				sender_id: this.currentUserId,
				content,
				timestamp: this.current_timestamp(),
				roomid:roomId
			}

			if (files) {
				this.file_obj = files;
				message.files = this.formattedFiles(files)
			}

			if (replyMessage) {
				message.replyMessage = {
					_id: replyMessage._id,
					content: replyMessage.content,
					sender_id: replyMessage.senderId
				}

				if (replyMessage.files) {
					message.replyMessage.files = replyMessage.files
				}
			}
			//  console.log('message',message);
			await this.socket.emit('event', message);
			
			const payload = {
				'lastUpdated': this.current_timestamp(),
				'id':this.roomId
			}
	
			await this.$store.dispatch('updateroom',payload);

			if (files && this.mess_id) {
				for (let index = 0; index < files.length; index++) {
					await this.uploadFile({ file: files[index], messageId: this.mess_id, roomId })
				}
			}
		},

		async editMessage({ messageId, newContent, roomId, files }) {
			const newMessage = { edited: new Date() }
			newMessage.content = newContent

			if (files) {
				this.file_obj = files;
				newMessage.files = this.formattedFiles(files)
			} else {
				newMessage.files = ''
			}
			newMessage.messageId = messageId
		    await this.socket.emit('event', newMessage);

			const payload = {
				'lastUpdated': this.current_timestamp(),
				'id':this.roomId
			}
	
			await this.$store.dispatch('updateroom',payload);

			if (files) {
				await this.uploadFile({ file: files, messageId, roomId })
			}
		},

		async deleteMessage({ message, roomId }) {
			const deleteMessage = { deleted: new Date() }
			deleteMessage.messageId = message.id
			this.socket.emit('event', deleteMessage);

			const { files } = message
			if (files) {
				//deletemessagefile
					const payload = {
						'message_id':message.id,
					}
					this.$store.dispatch('deletemessagefile',payload);
				
			}
		},
		async uploadFile({ file, messageId, roomId }) {
			let type = file.extension || file.type
				if (type === 'svg' || type === 'pdf') {
					type = file.type
				}
				let formData = new FormData()
				formData.append("message_id", messageId)
				for(var i=0;i<this.file_obj.length;i++){
					//console.log('f',this.file_obj[i])
					if(this.file_obj[i].blob){
						formData.append("files[]",this.file_obj[i].blob,this.file_obj[i].name);
					}
				}
				formData.append("type", type)
				formData.append("job_id", this.activeAuditJob.id)

			 const message = await this.$store.dispatch('uploadmessagefile',formData);
			 const payload = {messageId :message.id}
			 await this.socket.emit('getmessage', payload);
			
		},

		formattedFiles(files) {
			
			const formattedFiles = []

			files.forEach(file => {
					const messageFile = {
						name: file.name,
						size: file.size,
						type: file.type,
						extension: file.extension || file.type,
						url: file.url || file.localUrl
					}

					if (file.audio) {
						messageFile.audio = true
						messageFile.duration = file.duration
					}

					formattedFiles.push(messageFile)
			})
			return formattedFiles
		},
		
		openFile({ file }) {
			window.open(file.file.url, '_blank')
		},

		async openUserTag({ user }) {
			console.log(user)
		},

	async sendMessageReaction({ reaction, remove, messageId, roomId }) {
		
		const payload = {
			'room_id':roomId,
			'messageId':messageId,
			'user_id':this.currentUserId,
			'reaction':reaction.unicode,
			'remove':remove ? 'remove' : 'add'
		}
		await this.socket.emit('messageReaction', payload);
		
	},

	typingMessage({ message, roomId }) {
		// if (roomId) {
		// 	if (message?.length > 1) {
		// 		this.typingMessageCache = message
		// 		return
		// 	}

		// 	if (message?.length === 1 && this.typingMessageCache) {
		// 		this.typingMessageCache = message
		// 		return
		// 	}

		// 	this.typingMessageCache = message

		// 	firestoreService.updateRoomTypingUsers(
		// 		roomId,
		// 		this.currentUserId,
		// 		message ? 'add' : 'remove'
		// 	)
		// }
	},
    fetchRooms(){
        this.resetRooms()
        this.fetchMoreRooms()
    },
    async fetchMoreRooms() {
			const rooms = this.room_save
			const roomUserIds = []
			if(this.room_save.length > 0){
				this.room_save.forEach(room => {
					if(room.audits && room.audits.id == this.activeAuditJob.id){
						let rm_usr = JSON.parse(room.users)
						rm_usr.forEach(userId => {
							const foundUser = this.allUsers.find(user => user?.id === userId)
							if (!foundUser && roomUserIds.indexOf(userId) === -1) {
								roomUserIds.push(userId)
							}
						})
					}
				})
			}
			
			
			// this.incrementDbCounter('Fetch Room Users', roomUserIds.length)
			const rawUsers = []
			roomUserIds.forEach(userId => {
				if(this.user && this.user.id == userId){
					rawUsers.push(this.user)
				}else{
					const foundUser = this.rooms_data.filter(item => item.id == userId);
					rawUsers.push(foundUser[0])
				}
	
			})
			this.allUsers = [...this.allUsers, ...rawUsers]
			
			const roomList = {}
			rooms.forEach(room => {
				if(room.id){
					roomList[room.id] = { ...room, users: [] }
					const room_users = JSON.parse(room.users);
					room_users.forEach(userId => {
						
						const foundUser = this.allUsers.find(user => user?.id === userId)
						
						if (foundUser) roomList[room.id].users.push(foundUser)
					})
				}
			})
			const formattedRooms = []
			Object.keys(roomList).forEach(key => {
				const room = roomList[key]
					room._id = room.id.toString();
					if(room.audits.id ==  this.activeAuditJob.id){
						const roomContacts = room.users.filter(
							user => user.id !== this.currentUserId
						)
						this.RoomPerList = roomList
						// room.roomName =
						// 	roomContacts.map(user => user.first_name +' '+user.last_name).join(', ') || 'Myself'
							room.roomName =room.name
						const roomAvatar =
							roomContacts.length === 1 && roomContacts[0].avatar
							? roomContacts[0].avatar
							: require('../../../public/A.png')
							
						let seconds = new Date(room.lastUpdated).getTime()
						formattedRooms.push({
							...room,
							roomId: key,
							avatar: roomAvatar,
							index: seconds,
							lastMessage: {
							content: 'Room created',
							timestamp: formatTimestamp(
								new Date(seconds * 1000),
								new Date(room.lastUpdated)
							)
							}
						})
					}
			})
			this.rooms = this.rooms.concat(formattedRooms)
			formattedRooms.forEach(room => this.listenLastMessage(room))
			if (!this.rooms.length) {
				this.loadingRooms = false
				this.roomsLoadedCount = 0
			}
			// console.log('formeted',formattedRooms);
			this.loadingRooms = false
			this.roomsLoadedCount = 0
			// this.listenUsersOnlineStatus(formattedRooms)
			 if(this.userrole == 'Admin'){  
				 setTimeout(() => {
					 var class_data = document.querySelector(".vac-room-options");
					 var add_icon = document.querySelector(".vac-add-icon");
					 var option_container = document.querySelectorAll(".vac-room-options-container"); 
					 if(option_container != null){
						option_container.forEach(item=> {
							item.classList.remove("room-hidden-element");
						})
					 }
					 class_data?class_data.classList.remove("room-hidden-element"):'';
					 class_data?add_icon.classList.remove("room-hidden-element"):'';
					
				 }, 1000);
			}else{
				 setTimeout(() => {
					 var class_data = document.querySelector(".vac-room-options");
					 
					 var add_icon = document.querySelector(".vac-add-icon");
					 var option_container = document.querySelectorAll(".vac-room-options-container"); 
					 if(option_container != null){
						option_container.forEach(item=> {
						  item.classList.add("room-hidden-element");
					 }	)
					 }
					 class_data?class_data.classList.add("room-hidden-element"):'';
					 add_icon?add_icon.classList.add("room-hidden-element"):'';
					
				 }, 1000);
			}
			
			
			// setTimeout(() => console.log('TOTAL', this.dbRequestCount), 2000)
		},
    	async listenLastMessage(room) {
			await this.$store.dispatch('loadroommessage',room.id);
			
			this.get_messages.forEach(message =>{
				const lastMessage = this.formatLastMessage(message, room)
				const roomIndex = this.rooms.findIndex(
							r => room.roomId === r.roomId
						)
						this.rooms[roomIndex].lastMessage = lastMessage
						this.rooms = [...this.rooms]
			})
			if (this.loadingLastMessageByRoom < this.rooms.length) {
				this.loadingLastMessageByRoom++

				if (this.loadingLastMessageByRoom === this.rooms.length) {
					this.loadingRooms = false
					this.roomsLoadedCount = this.rooms.length
				}
			}
			
		},
    	formatLastMessage(message, room) {
			if (!message.timestamp) return
			let content = message.content
			if (message.files?.length) {
				const file = message.files[0]
				content = `${file.name}.${file.extension || file.type}`
			}
			let seconds = new Date(message.timestamp).getTime()
			const username =
				message.users.id !== this.currentUserId
					? room.users.find(user => message.users.id === user.id)?.first_name
					: ''
			return {
				...message,
				...{
					content,
					timestamp: formatTimestamp(
						new Date(seconds * 1000),
						new Date(message.timestamp)
					),
					username: username,
					distributed: true,
					seen: message.users.id === this.currentUserId ? message.seen : null,
					mess_time:message.timestamp,
					new:
						message.users.id !== this.currentUserId &&
						(!message.seen || !message.seen[this.currentUserId])
				}
			}
		},
    listenUsersOnlineStatus(rooms) {
			// rooms.forEach(room => {
			// 	room.users.forEach(user => {
			// 		const listener = firebaseService.firebaseListener(
			// 			firebaseService.userStatusRef(user._id),
			// 			snapshot => {
			// 				if (!snapshot || !snapshot.val()) return

			// 				const lastChanged = formatTimestamp(
			// 					new Date(snapshot.val().lastChanged),
			// 					new Date(snapshot.val().lastChanged)
			// 				)

			// 				user.status = { ...snapshot.val(), lastChanged }

			// 				const roomIndex = this.rooms.findIndex(
			// 					r => room.roomId === r.roomId
			// 				)

			// 				this.rooms[roomIndex] = room
			// 				this.rooms = [...this.rooms]
			// 			}
			// 		)
			// 		this.roomsListeners.push(listener)
			// 	})
			// })
		},
    menuActionHandler({ action, roomId }) {
		switch (action.name) {
			case 'inviteUser':
				return this.inviteUser(roomId)
			case 'removeUser':
				return this.removeUser(roomId)
			case 'updateRoom':
				return this.deletedialog(roomId)
		}
	},
    async inviteUser(roomId) {
		var room_user =  JSON.parse(this.room_save[0].users);
		if(room_user.length > 0){
			this.roomuserselect = this.rooms_data.filter(person => !(room_user.includes(person.id)))
		}else{
			this.roomuserselect = this.rooms_data
		}
		
		this.resetForms()
		this.inviteRoomId = roomId
		this.roomDialog = true      
	},
	async removedRoomUser(){
		this.waiting = true;
      	this.disableForm = true;
		var room_user_rm = []
		this.removeinvitedUsername.map((key)=>{
			room_user_rm.push(key.id)
		})
		var un_id = [];
		this.all_users.map((item)=>{
			if(room_user_rm.includes(item.id)){
				un_id.push(item.id)
			}
		})
	},
    async addRoomUser() {
      this.waiting = true;
      this.disableForm = true;
		if(this.invitedUsername.length > 0)
		{
			const uids = [];
			const user_ids = JSON.parse(this.room_save[0].users);
			let id = 0;
			for(var i=0;i<this.invitedUsername.length;i++){
				uids.push(this.invitedUsername[i].id)  
			}
			var marge_res = user_ids.concat(uids)
			var final_res = marge_res.filter((item, pos) => marge_res.indexOf(item) === pos)
			const payload = {
				'users':JSON.stringify(final_res),
				'id':this.inviteRoomId
			}
			await this.$store.dispatch('updateroomuser',payload);
		}
		 Vue.notify({
			group: 'messages',
			type: 'success',
			text: 'Room user add successfully',
		 })
		this.inviteRoomId = null
		this.waiting = false
		this.invitedUsername = []
		this.roomDialog = false
		await this.all_userdata()
		this.fetchRooms()
	},
    removeUser(roomId) {
		this.roomDialog = true    
		var room_user_delete = [];
		this.RoomPerList[roomId]['users'].map((key)=>{
			room_user_delete.push(key.id)
		})
		if(room_user_delete.length > 0){
			this.removeUserId = this.rooms_data.filter(person => room_user_delete.includes(person.id))
		}
		
		this.resetForms()
		this.removeRoomId = roomId
		
		this.removeUsers = this.rooms.find(room => room.roomId === roomId).users
	},
	deleteuserdialog(){
		this.deleteuserConfirmDialog = true	
	},
	closeuserdeletedialog(){
		this.deleteuserConfirmDialog = false
	},
    async deleteRoomUser() {
		this.waiting = true;
      	this.disableForm = true;
		var room_user_rm = []
		this.removeinvitedUsername.map((key)=>{
			room_user_rm.push(key.id)
		})
		let user_arr = JSON.parse(this.room_save[0].users);
		user_arr = user_arr.filter(item => !room_user_rm.includes(item))
	   	const payload = {
			'users':JSON.stringify(user_arr),
			'id':this.removeRoomId
		}
		await this.$store.dispatch('updateroomuser',payload);
		Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Delete room users successfully',
        })
		this.waiting = false;
		this.deleteuserConfirmDialog = false
		this.removeRoomId = null
		this.removeUserId = []
		this.roomDialog = false;
		await this.all_userdata()
		this.fetchRooms()
	},
	closedeleteDialog(){
		this.rm_id = null
		this.comanConfirmDialog = false
		
	},
	deletedialog(roomId){
		this.comanConfirmDialog = true
		this.rm_id = roomId
		this.updateRoomUsername = this.rooms[0].name;
	},
	async deleteRoom(roomId) {
		this.overlay = true;
		const payload = {
			'lastUpdated': this.current_timestamp(),
			'id':this.roomId
		}
		if(this.updateRoomUsername != null){
			payload['name'] = this.updateRoomUsername
		}
	
		await this.$store.dispatch('updateroom',payload);
		Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Update Room Successfully',
        })
		//await this.$store.dispatch('deleteRoom',roomId);
		this.fetchRooms()
		this.comanConfirmDialog = false
		this.overlay = false;
	}
    },
    watch: {  
    },
    
  }
</script>

<style>
.room-hidden-element{
	display: none !important;
}
.export-btn{
    display: flex;
    justify-content: end;
    padding: 5px;
}
.vac-icon-textarea-left .vac-svg-button{
	display: none;
}
</style>