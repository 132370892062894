<template>
 <div v-if="!adminPermission">
    <router-view :name="user.role"></router-view>
  </div>
  <div v-else>
    <dash-layout>
      <v-container class="session-container fill-height" fluid>
      <v-row   justify="center" align="center">
        <v-col cols="12" sm="8">
          <v-col cols="12" class="d-flex justify-center">
            <h3>You don’t have permission. Please contact Admin.</h3>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
    </dash-layout>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  name: 'Elections',
  data(){
    return {
      adminPermission: false,
    }
  },
  computed: {
      ...mapGetters(['user','getRouteInfo']),
    },

  mounted() {
    if(this.getRouteInfo.to?.name == "ElectionList" && this.user?.subscriptionPlan == 0 && this.user.role.toLowerCase() == "user"){
      // this.$router.push("/pricing");
    //  this.$router.push("/home");
    this.adminPermission = true; 

    }
  },
  
};

</script>