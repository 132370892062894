export const EventName = {
    Auth : 'Auth',
    District : 'District',
    District_Contact : 'District Contact',
    Elections : 'Elections',
    Audit_jobs : 'Audit Jobs',
    Audit_package : 'Audit package',
    MarkdownSetting_AdminBoard: 'Markdown Setting AdminBoard',
    Action_specs : 'Action Specs',
    Admin_panel : 'Admin Panel',
    Arg_specs : 'Arg Specs',
    File_upload : 'File Upload',
    File_upload_district : 'File Upload District',
    Home : 'Home',
    Project : 'Project',
    Root_election : 'Root Election',
    Leader_board : 'Leader Board',
    User_manage : 'User Manage',
    Upload_requests : 'Upload Requests',
    Upload : 'Upload',
    Job_setting : 'Job Setting'
    }
  
export const EventType = {
    Login : 'Login',   // Auth
    SignUp : 'SignUp',
    Resent_Password : 'Resent Password',
    setNewPassword: 'Set New Password',
    updateProfile: 'Update Profile',
    updatePassword: 'Update Password',
    closeAccount: 'Close Account',
    getUserProfile: 'Get User Profile',
    getUserLogin: 'Get User Login',
    emailVerified: 'Email Verified',
    resendVerifyEmail: 'Resend Verify Email',
    LogOutUser: 'Log Out User',
    districtSaved: 'District Saved',   // District
    districtDelete: 'District Delete',
    newDistrictCreate: 'New District Create',
    getDisctric: 'Get Disctric',
    districtLoad: 'District Load',
    districtUpdated: 'District Updated',
    contactLoad: 'Contact Load', // District_Contact
    getDisContact: 'Get District Contact',
    disContactDelete: 'District Contact Delete',
    disContactSave: 'District Contact Save',
    electionsLoad: 'Elections Load',  // Elections
    electionSave: 'Election Saved',
    electionDelete: 'Election Delete',
    getElection: 'Get Election',
    getJurisdiction: 'Get Jurisdiction',
    adopTableFilesLoad: 'Adopt Table Files Load',
    uploadRequest: 'Upload Request',
    electionUserSave: 'Election User saved',
    electionUsersLoad: 'Election Users Load',
    electionUserDelete: 'Election User Delete',
    auditJobsLoad: 'Audit Jobs Load',  // Audit jobs
    userLoad: 'User Load',
    roomMessageLoad: 'Room Message Load',
    auditJobsPermission: 'Audit Jobs Permission',
    auditJobsPermissionRequest: 'Audit Jobs Permission Request',
    permissionSave: 'Permission Save',
    auditJobSave: 'Audit Job Save',
    reportExport: 'Report Export',
    updateRoomSave: 'Update Room Save',
    roomSave: 'Room Save',
    jobsSettingJSONSave: 'Jobs Setting Save',
    uploadFileSave: 'Upload File Save',
    deleteFile: 'Delete File',
    markeSeen: 'Marke Seen',
    auditJobDelete: 'Audit Job Delete',   
    roomDelete: 'Room Delete',
    getAuditJob: 'Get Audit Job',
    getAuditRoom: 'Get Audit Room',
    leaderBoard: 'Leader Board',
    getPermission: 'Get Permission',
    auditJobsSetting: 'Audit Jobs Setting',
    paymentSessionCreate: 'Payment Session',
    projectPaymentSessionCreate: 'Project Payment Session',
    auditJobupdate: 'Audit Job Update',
    auditPackageSave: 'Audit Package Save',  // Audit package
    auditPackageCreated: 'Audit Package Created',
    auditPackagesLoad: 'Audit Packages Load',
    auditActivePackagesLoad: 'Audit Active Packages Load',
    paymentSessionCreate: 'Payment Session Create',
    getPackage: 'Get Package',
    paymentSuccess: 'Payment Success',
    subscribePlan: 'Subscribe Plan',
    allSubscribePlan:'All Subscribe Plan',
    manualPackageSave: 'Manual Package Save',
    activeAuditPackagesLoad: 'Active Audit Packages Load',
    markdownfiles: 'Markdown files', // Markdown Setting AdminBoard
    loadMarkdownfiles: 'Load Markdown files',
    markdownSave: 'Markdown Save',
    auditTypesLoad: 'Audit Types Loaded', // Action_specs
    deleteTools: 'Delete Tools', 
    auditTypeLoad: 'Audit Type Loaded',
    addTools: 'Add Tools',
    actionTools: 'Action Tools',
    // toolsStatus: 'Tools Status',
    argGroupsLoad: 'Arg Groups Load', //Admin_panel
    argGroupsLoadArgSpecs: 'Arg Groups Load ArgSpecs', // Arg_specs
    argGroupUpdated: 'Arg Group Updated',
    argGroupCreated: 'Arg Group Created',
    argGroupDelete: 'Arg Group Delete',
    getArgGroup: 'Get Arg Group',
    argumentsLoad: 'Arguments Load',
    argumentUpdated: 'Argument Updated',
    argumentCreated: 'Argument Created',
    argumentDelete: 'Argument Delete',
    getArgument: 'Get Argument',
    auditTypesLoad: 'Audit Types Load',
    auditTypeUpdated: 'Audit Type Updated',
    auditTypeCreated: 'Audit Type Created',
    auditTypeDelete: 'Audit Type Delete',
    getauditType: 'Get Audit Type',
    argStagesLoad: 'Arg Stages Load',
    argSpecsLoad: 'Arg Specs Load',
    // requestedFilesLoad: 'Requested Files Load',  // File Upload
    // uploadFileDelete: 'Upload File Delete',
    // uploadFilesLoad: 'Upload Files Load',
    // requestedFileDelete: 'Requested File Delete',
    uploadFilesLoad: 'Upload Files Load', // File_upload_district
    requestedFilesLoad: 'Requested Files Load',
    uploadFileDelete: 'Upload File Delete',
    requestedFileDelete: 'Requested File Delete',
    markdowncontent: 'Mark Down Content', // Home
    checkUpdateMarkdown: 'Check Markdown',
    projectsLoad: 'Projects Load', // Project
    projectSave: 'Project Save',
    manualDonationSave: 'Manual Donation Save',
    getProject: 'Get Project',
    getFundring: 'Get Funding',
    getDonors: 'Get Donors',
    getManuallyDonors: 'Get Manually Donors',
    electionsLoad: 'Elections Load',  // Root_election
    getElection: 'Get Election',
    electionsLoadRoot: 'Elections Root',
    signInError: 'sign-in-error',  // Leader_board
    donationLoaded: 'Donation Loaded', // User Manage
    userUpdate: 'User Update',
    permissionUpdate: 'Permission Update',
    userDelete: 'User Delete',
    userReactivate: 'User Reactivate',
    userdata: 'User Data',
    permissiondata: 'Permission Data',
    usersLoaded: 'Users Loaded',
    rdsRestartLoaded: 'Rds Restart Loaded', 
    allUploadRequestsLoad: 'All Upload Requests' ,//Upload_requests
    uploadRequestsLoad: 'Upload Requests Load',
    genralUploadLoad: 'Genral Upload Load',
    copyRequestSave: 'Copy Request Save',
    uploadRequestDelete: 'Upload Request Delete',
    getUploadRequest: 'Get Upload Request',    
    computeInfo: 'Compute Info',
    uploadSetsLoad : 'Upload Sets Load', //Upload
    jobFilesLoad : 'Job Files Load' ,
    getJobArgSpecs : 'Get Job ArgSpecs'                                   //Job_setting
} 