import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/upload-files'

const state = {
  uploadFiles: [],
  requestedFiles: [],
  uploadFilesLoading: false,
  requestedFilesLoading: false,
  activeUploadFile: {},
}

const getters = {
  uploadFiles: state => {
    return state.uploadFiles
  },
  requestedFiles: state => {
    return state.requestedFiles
  },
  uploadFilesLoading: state => {
    return state.uploadFilesLoading
  },
  requestedFilesLoading: state => {
    return state.requestedFilesLoading
  },
  activeUploadFile: state => {
    return state.activeUploadFile
  },
}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api().post(url, data).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
  .catch(function (error) {return error.response})
}

function put(context, url, data, handler) {
  return api().put(url, data).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response)).catch(error=>handleApiError(error, ''))
}

function handleUploadFilesLoad(context, response) {
  const data = response['data']
  context.state.uploadFilesLoading = false
  if (data['error']) {
    context.commit('apiError', data['error'])
    // logApiService(data, data['error'], EventName.File_upload, EventType.uploadFilesLoad)
  } else {
    // logApiService(data, 'Fetch Upload Files', EventName.File_upload, EventType.requestedFilesLoad)
    context.commit('uploadFilesLoaded', data)
  }
  return data
}

function handleRequestedFilesLoad(context, response) {
  const data = response['data']
  context.state.requestedFilesLoading = false
  if (data['error']) {
    context.commit('apiError', data['error'])
    // logApiService(data, data['error'], EventName.File_upload, EventType.requestedFilesLoad)
  } else {
    // logApiService(data, 'Fetch Requested Files', EventName.File_upload, EventType.requestedFilesLoad)
    context.commit('requestedFilesLoaded', data)
  }
  return data
}

function handleUploadFileDelete(context, id, response) {
  const uploadFile = response['data']
  if (uploadFile['error']) {
    context.commit('apiError', uploadFile['error'])
    // logApiService(uploadFile, uploadFile['error'], EventName.File_upload, EventType.uploadFileDelete)
  } else {
    // logApiService(uploadFile, 'Upload File Deleted', EventName.File_upload, EventType.uploadFileDelete)
    context.commit('uploadFileDeleted', id)
  }
}

function handleRequestedFileDelete(context, id, response) {
  const data = response['data']
  if (data['error']) {
    context.commit('apiError', data['error'])
    // logApiService(data, data['error'], EventName.File_upload, EventType.requestedFileDelete)
  } else {
    // logApiService(data, 'File Deleted', EventName.File_upload, EventType.requestedFileDelete) 
    context.commit('requestedFileDeleted', id)
  }
}

const actions = {
  loadUploadFiles(context, election_id) {
    if(context.state.uploadFilesLoading)return
    context.state.uploadFilesLoading = true
    get(context, URL + '/' + election_id, handleUploadFilesLoad)
  },

  loadRequestedFiles(context, election_id) {
    if(context.state.requestedFilesLoading)return
    context.state.requestedFilesLoading = true
    get(context, '/requested-files/' + election_id, handleRequestedFilesLoad)
  },

  DeleteUploadFile(context, id) {
    return apiDelete(context, URL, id, handleUploadFileDelete)
  },

  DeleteRequestedFile(context, id) {
    return apiDelete(context, '/requested-files', id, handleRequestedFileDelete)
  },

  
  createUpload(context, payload){
    return api(false).post( URL, payload).then(function (response) {      
      const responseData = response['data']
      if (responseData['error']) {
        context.commit('apiError', responseData['error'])
      }
      return responseData
    })
    .catch(error => {
      handleApiError(error, '')
      const responseData = error.response.data
      context.commit('apiError', responseData.error)
      return responseData
    });
  },
  updateUpload(context, payload){
    return api(false).put( URL + '/' + payload.id, payload).then(function (response) {      
      const responseData = response['data']
      if (responseData['error']) {
        context.commit('apiError', responseData['error'])
      }
      return responseData
    })
    .catch(error => {
      handleApiError(error, '')
      const responseData = error.response.data
      context.commit('apiError', responseData.error)
      return responseData
    });
  },
  deleteUpload(context, id){
    return api(false).delete( URL + '/' + id).then(function (response) {      
      const responseData = response['data']
      if (responseData['error']) {
        context.commit('apiError', responseData['error'])
      }
      return responseData
    })
    .catch(error => {
      handleApiError(error, '')
      const responseData = error.response.data
      context.commit('apiError', responseData.error)
      return responseData
    });
  },
}


const mutations = {
  uploadFilesLoaded(state, uploadFiles) {
    state.uploadFiles = []
    for (let i = 0; i < uploadFiles.length; i++) {
      state.uploadFiles.push(uploadFiles[i])
    }
  },

  requestedFilesLoaded(state, requestedFiles) {
    state.requestedFiles = []
    for (let i = 0; i < requestedFiles.length; i++) {
      state.requestedFiles.push(requestedFiles[i])
    }
  },

  uploadFileSuccess(state, uploadFile) {
    for (let i = 0; i < state.uploadFiles.length; i++) {
      if (state.uploadFiles[i].id == uploadFile.id) {
        state.uploadFiles.splice(i, 1, uploadFile)        
        return
      }
    }

    state.uploadFiles.push(uploadFile)
  },

  uploadFileDeleted(state, id) {
    for (let i = 0; i < state.uploadFiles.length; i++) {
      if (state.uploadFiles[i].id == id) {
        state.uploadFiles.splice(i, 1)        
      }
    }
  },

  requestedFileDeleted(state, id) {
    for (let i = 0; i < state.requestedFiles.length; i++) {
      if (state.requestedFiles[i].id == id) {
        state.requestedFiles.splice(i, 1)       
      }
    }
  },
}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'UploadFileStore'
}
