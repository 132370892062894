var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.fileDeleteDialog),callback:function ($$v) {_vm.fileDeleteDialog=$$v},expression:"fileDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete "+_vm._s(_vm.selectedFile.file_name)+" ?")]),_c('v-card-actions',{staticClass:"pa-8"},[_c('v-spacer'),_c('v-spacer'),(_vm.userValue.role == 'Observer' ? _vm.isHidden : !_vm.isHidden)?[_c('v-btn',{staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.$refs.UploadFilesDialog.show()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Upload Files ")],1)]:_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.refreshUploadFiles()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],2),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.files,"search":_vm.search,"loading":_vm.uploadFilesLoadingDistrict},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"color":"black"},attrs:{"color":_vm.getStatusColor(item.status),"dark":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteFile(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)})],1)],1)],1)],1),_c('UploadFilesDialog',{ref:"UploadFilesDialog"}),_c('v-card',{staticClass:"mb-2",attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"mb-6"},[_vm._v(" Direct Uploads "),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.$refs.UploadFilesDialog.show()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Upload Files ")],1),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var ttprops = ref.ttprops;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.refreshUploadFiles()}}},'v-btn',ttprops,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Upload Files")])])],1),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.files,"search":_vm.search,"loading":_vm.uploadFilesLoadingDistrict},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"color":"black"},attrs:{"color":_vm.getStatusColor(item.status),"dark":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var ttprops = ref.ttprops;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteFile(item)}}},'v-btn',ttprops,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete File")])])]}}],null,true)})],1)],1)],1),_c('v-card',{attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"mb-6"},[_vm._v(" Requested Uploads "),_c('v-spacer'),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var ttprops = ref.ttprops;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.refreshRequestedFiles()}}},'v-btn',ttprops,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Requested Files")])])],1),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.requested_files,"search":_vm.search,"loading":_vm.requestedFilesLoadingDistrict},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticStyle:{"color":"black"},attrs:{"color":_vm.getStatusColor(item.status),"dark":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var ttprops = ref.ttprops;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteFile(item)}}},'v-btn',ttprops,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete File")])])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }