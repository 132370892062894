import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/audit-types'

const state = {
  audit_types: [],
  active_audit_type: {},
  audit_typesLoading: false
}


const getters = {
  audit_types: state => {
    return state.audit_types
  },
  active_audit_type: state => {
    return state.active_audit_type
  },
  audit_typesLoading: state => {
    return state.audit_typesLoading
  },

}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api().post(url, data).then(response => handler(context, response, data)).catch(error=>handleApiError(error, ''))
}

function put(context, url, data, handler) {
  return api().put(url, data).then(response => handler(context, response, data)).catch(error=>handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response)).catch(error=>handleApiError(error, ''))
}

function handle_audit_typesLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const audit_types = response['data']
  context.state.audit_typeLoading = false
  if (audit_types['error']) {
    context.commit('apiError', audit_types['error'])
    logApiService(obj, audit_types['error'], EventName.Arg_specs, EventType.auditTypesLoad)
  } else {
    logApiService(obj, 'Fetch Audit Types', EventName.Arg_specs, EventType.auditTypesLoad)
    context.commit('audit_typesLoaded', audit_types)
  }
  return audit_types
}

function handle_audit_typeSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const  audit_type = response['data']
  if (audit_type['error']) {
    context.commit('apiError', audit_type['error'])
    logApiService(obj, audit_type['error'], EventName.Arg_specs, EventType.auditTypeUpdated)
  } else {
    logApiService(obj, 'Audit type update', EventName.Arg_specs, EventType.auditTypeUpdated)  
    context.commit('audit_typeSuccess', audit_type)
  }
  return audit_type
}

function handle_audit_typeDelete(context, id, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: id
  }
  const audit_type = response['data']
  if (audit_type['error']) {
    context.commit('apiError', audit_type['error'])
    logApiService(obj, audit_type['error'], EventName.Arg_specs, EventType.auditTypeDelete)
  } else {
    context.commit('audit_typeDeleted', id)
    logApiService(obj, 'Audit type removed', EventName.Arg_specs, EventType.auditTypeDelete)
  }
}

function handleGet_audit_type(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const audit_type = response['data']
  if (audit_type['error']) {
    context.commit('apiError', audit_type['error'])
    logApiService(obj, audit_type['error'], EventName.Arg_specs, EventType.getauditType)
  } else {
    context.commit('setActive_audit_type', audit_type)
    logApiService(obj, 'Fetch audit type', EventName.Arg_specs, EventType.getauditType)
  }
  return audit_type
}

const actions = {
  load_audit_types(context, id) {
    if (context.state.audit_typeLoading) return
    context.state.audit_typeLoading = true
    get(context, URL, handle_audit_typesLoad)
  },

  save_audit_type(context, payload) {
    return post(context, URL, payload, handle_audit_typeSave)
  },

  update_audit_type(context, payload) {
    return put(context, URL + '/' + payload.id, payload, handle_audit_typeSave)
  },

  delete_audit_type(context, id) {
    return apiDelete(context, URL, id, handle_audit_typeDelete)
  },

  get_audit_type(context, id) {
    return get(context, URL + '/' + id, handleGet_audit_type)
  },
}


const mutations = {
  audit_typesLoaded(state, audit_types) {
    state.audit_types = []
    for (let i = 0; i < audit_types.length; i++) {
      state.audit_types.push(audit_types[i])
    }
  },

  audit_typeSuccess(state, audit_type) {
    state.active_audit_type = audit_type
    for (let i = 0; i < state.audit_types.length; i++) {
      if (state.audit_types[i].id == audit_type.id) {
        state.audit_types.splice(i, 1, audit_type)      
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Audit type updated'
        })
        return
      }
    }
    logApiService(audit_type, 'Audit type created', EventName.Arg_specs, EventType.auditType)  
    state.audit_types.push(audit_type)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Audit type created'
    })
  },

  audit_typeDeleted(state, id) {
    for (let i = 0; i < state.audit_types.length; i++) {
      if (state.audit_types[i].id == id) {
        state.audit_types.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Audit type removed'
        })
      }
    }
  },

  clearLoaded_audit_types(state){
    state.audit_typeLoading = false
    state.audit_types = []
  },

  setActive_audit_type(state, audit_type) {
    state.active_audit_type = audit_type
  },

}


export default {
  state,
  getters,
  mutations,
  actions,
  name: '_audit_typeStore'
}
