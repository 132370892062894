<template>
  <v-row align="center" justify="center">
    <v-dialog v-model="editDialog" max-width="50%" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field 
                  v-if="editedIndex > -1" 
                  v-model="editedItem.argument" 
                  label="Name" 
                  readonly
                ></v-text-field>
                <v-select
                  v-else
                  :items="availableArguments"
                  label="Name"
                  menu-props="auto"
                  v-model="editedItem.argument"
                  :rules="[rules.required]"
                  @change="argumentChanged"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-if="editedItem.choices.length > 0"
                  :items="editedItem.choices"
                  label="Value"
                  v-model="editedItem.value"
                  :rules="[rules.required]"
                ></v-select>
                <v-text-field 
                  v-else 
                  v-model="editedItem.value" 
                  label="Value" 
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col v-if="editedItem.create_job_file_api" cols="12" md="6">
                 <v-text-field 
                    v-model="editedItem.create_job_file_api" 
                    label="Create Job File Api" 
                    readonly
                ></v-text-field>
              </v-col>
              <v-col v-if="editedItem.internal" cols="12" md="6">
                 <v-text-field 
                    v-model="editedItem.internal" 
                    label="Internal" 
                    readonly
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
              <v-textarea
              label="Help"
              v-model="editedItem.help"
              readonly
              ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="closeEdit">Cancel</v-btn>
          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="blue darken-1" outlined 
            @click="updateItem" :disabled="editedItem.value.length == 0"
          >{{ formSubmitText }}</v-btn>                      
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="70%" persistent>
      <v-card max-width="100%">

        <v-card-title>          
        </v-card-title>

        <v-container grid-list-xl fluid>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field 
                v-model="file_name" 
                label="File Name"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                :items="file_types"
                label="File Type"
                v-model="file_type"
                item-text="description"
                item-value="value"
                readonly
              ></v-select>
            </v-col>
            <!-- <v-col v-if="jobCheck" cols="12" md="6">
              <v-text-field v-model="job_tag" label="Job Tag"></v-text-field>
            </v-col> -->
            <v-col cols="12" md="6">
              <!-- <v-text-field v-model="file_name" label="Operation Name" :rules="[rules.required]"></v-text-field> -->
              <v-select
                v-if="jobSettings.length > 0"
                :items="jobSettings"
                label="Group Name"
                v-model="groupName"
                item-text="group"
                item-value="group"
                @change="groupChanged()"
              ></v-select>
            </v-col>
          </v-row>
            <v-card flat>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="jobSettings[tab].arguments"
                  :loading="jobFileLoading || jobArgSpecsLoading"
                  :search="search"
                  sort-by="calories"
                  class="arguments-table"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 50]
                  }"
                  :items-per-page="5"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title></v-toolbar-title>                      
                      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details clearable></v-text-field>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>   
                        <v-btn
                        v-if="userValue.role == 'Observer'? isHidden : !isHidden"
                          color="primary"
                          dark
                          class="mb-2"
                          @click="newItem"
                        >Add Argument</v-btn>
                    </v-toolbar>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card-text>
              </v-card>
          <!-- <v-tabs
            v-model="tab"
            @change="groupChanged"
            dark
          >
            <v-tab
              v-for="(group, index) in argGroups"
              :key="index"
            >
              {{ group.group }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="(jobSetting, index) in jobSettings"
              :key="index"
            >
              <v-card flat>
              <v-card-text>
                <v-data-table
                  :headers="headers"
                  :items="jobSetting.arguments"
                  :loading="jobFileLoading || jobArgSpecsLoading"
                  :search="search"
                  sort-by="calories"
                  class="arguments-table"
                  :footer-props="{
                    'items-per-page-options': [5, 10, 20, 50]
                  }"
                  :items-per-page="5"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title></v-toolbar-title>                      
                      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details clearable></v-text-field>
                      <v-divider
                        class="mx-4"
                        inset
                        vertical
                      ></v-divider>
                      <v-spacer></v-spacer>   
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          @click="newItem"
                        >Add Argument</v-btn>
                    </v-toolbar>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>           -->
        </v-container>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="close"
        >
          Close
        </v-btn>
        <v-btn
        v-if="userValue.role == 'Observer'? isHidden : !isHidden"
          outlined
          @click="saveJobSettingFile"
          :disabled="file_name.length == 0 || jobSettings.length == 0 || waiting"
        >
          Save
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="waiting"
        ></v-progress-circular>
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row> 
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'JobSettingDialog',
    components: {
    },

    data: () => ({
      userValue: {},
      isHidden: false,
      dialog: false,
      file_name:  '',
      old_file_name: '',
      file_type: 'JOB',
      isUpdate: false,
      waiting: false,
      job_tag:'',
      search: '',
      file_types: [
          {value: 'JOB', description: 'Job Configuration'},
          {value: 'EIF', description: 'Election Information'},
          {value: 'BOF', description: 'Ballot Options'},
          {value: 'STC', description: 'Manual Styles to Contests'},
      ],
      types: [],      
      editDialog: false,
      headers: [
        { text: 'Argument', value: 'argument', width: '40%',},
        { text: 'Value', value: 'value', sortable: false, width: '40%',},        
        { text: 'Actions', value: 'actions', sortable: false, width: '15%',},
      ],
      rules: {
          required: value => !!value || 'Required.',
        },     

      jobSettings: [],
      availableArguments: [],
      editGroup: -1,
      editedIndex: -1,
      jobCheck:false,
      editedItem: {
        argument: '',
        choices: [],
        format: '',
        help: '',
        multi: '',
        required: '',
        type: '',
        value: '',        
      },
      defaultItem: {
        argument: '',
        choices: [],
        format: '',
        help: '',
        multi: '',
        required: '',
        type: '',
        value: '',
      },
      removeItem:[],
      // tab groups
      tab: 0,
      groupName:"",
      argGroups: [],
    }),

    computed: {
      ...mapGetters(['activeJobFile', 'jobFileLoading', 'jobArgSpecs', 'jobArgSpecsLoading', 'activeAuditJob', 'user']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Argument' : 'Edit Argument'
      },
      formSubmitText() {
        return this.editedIndex === -1 ? 'Add' : 'Save'
      }
    },

    mounted() {
      this.userValue = this.user
    },

    methods: {
      groupChanged() {
        this.tab = this.jobSettings.findIndex(data => data.group === this.groupName)
        this.availableArguments = []
        const specGroup = this.argGroups[this.tab]
        const group = this.jobSettings[this.tab]
        for(let i = 0; i < specGroup.arguments.length; i++){

          let exist = false
          for(let j =0 ; j < group.arguments.length; j ++) {
            if(specGroup.arguments[i].argument == group.arguments[j].argument)
            {
              if(!specGroup.arguments[j].multi)exist = true
            }
          }
          if(!exist)this.availableArguments.push(specGroup.arguments[i].argument)
        }
        
      },
      argumentChanged() {
        const selectedArgGroup = this.argGroups[this.tab]        
        for(let i = 0; i < selectedArgGroup.arguments.length; i ++){     
          if(this.editedItem.argument == selectedArgGroup.arguments[i].argument){          
            this.editedItem.choices = selectedArgGroup.arguments[i].choices            
            this.editedItem.format = selectedArgGroup.arguments[i].format            
            this.editedItem.help = selectedArgGroup.arguments[i].help
            this.editedItem.multi = selectedArgGroup.arguments[i].multi
            this.editedItem.required = selectedArgGroup.arguments[i].required
            this.editedItem.type = selectedArgGroup.arguments[i].type
            this.editedItem.value = selectedArgGroup.arguments[i].value 
            if(selectedArgGroup.arguments[i].create_job_file_api){
               this.editedItem.create_job_file_api = selectedArgGroup.arguments[i].create_job_file_api
            }  
             if(selectedArgGroup.arguments[i].internal){
               this.editedItem.internal = selectedArgGroup.arguments[i].internal
            }          
          }
        }
      },
      show(item){
        this.argGroups = this.jobArgSpecs      
        this.jobSettings = []

        this.dialog = true  
        if(item.name){
          this.isUpdate = true
          this.file_name = item.name
          this.old_file_name = item.name
          this.file_type = item.type
          this.jobSettings = this.activeJobFile
        }
        else {          
          for(let i = 0; i < this.argGroups.length; i++){
            this.jobSettings.push({
              group: this.argGroups[i].group,
              arguments: []
            })
          }

          this.isUpdate = false
          this.file_name = ''
          this.file_type = 'JOB'         
        }
        if(item.jobFileCheck){
          this.jobCheck = true
        }
        // let gpName = this.jobSettings.findIndex(data => data.group === "the following are also determined by frontend through direct user entry")
        // let gData = this.jobSettings[gpName]
        // if(gData && gData.arguments.length > 0){
        //   gData.arguments.forEach((item) => {
        //     if(item.argument == "job_tag"){
        //       this.job_tag = item.value
        //     }
        //   })
        // }
        this.tab = 0
        this.groupName = this.jobSettings[0].group;
        this.groupChanged()
      },

      close() {
        this.dialog=false
        this.waiting = false
      },
      async saveJobSettingFile(){
          let payload = {
            is_update: this.isUpdate,
            job_id: this.activeAuditJob.id,
            file_name: this.file_name,
            settings: this.jobSettings
          }
        
          if(this.file_type != undefined)
          {
            payload['file_type'] = this.file_type
          }else{
            payload['file_type'] = this.file_name.split("_")[0]
          }

          if(this.isUpdate){
            payload['old_file_name'] = this.old_file_name
          }

          if(this.removeItem.length > 0){
            payload['deleted_item'] = this.removeItem
          }
          if(this.job_tag){
            let gpName = this.jobSettings.findIndex(data => data.group === "the following are also determined by frontend through direct user entry")
            const specGroup = this.argGroups[gpName]
            const group = this.jobSettings[gpName]
            let j_tag = null;
            if(specGroup && specGroup.arguments.length > 0){
              specGroup.arguments.forEach(element => {
                if(element.argument == "job_tag"){
                  element.value = this.job_tag
                  j_tag = element
                }
              });
            }
           
            if(j_tag != null){
              let tag = false
              if(group && group.arguments.length > 0){
                group.arguments.forEach((item) =>{
                  if(item.argument == "job_tag"){
                      tag = true
                      item.value = this.job_tag
                      this.jobSettings[gpName]?.arguments.push(item)
                  }
                })
              }
              if(!tag && j_tag != null){
                this.jobSettings[gpName]?.arguments.push(j_tag)
              }
            }
          }

          if(this.jobCheck){
            payload.jobfile = true
          }
          this.waiting = true
          let ret = await this.$store.dispatch('saveJobFile', payload)
          this.waiting = false

          if(ret['message']){
            this.dialog = false
            this.$store.dispatch('loadjobFiles', this.activeAuditJob.id)
          }          
      },

      newItem() {
        this.editDialog = true
      },

      editItem (item) {
        let group = this.jobSettings[this.tab]
        this.editedIndex = group.arguments.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.editDialog = true
      },

      deleteItem (item) { 
        this.removeItem.push(item)     
        let group = this.jobSettings[this.tab]
        const index = group.arguments.indexOf(item)
        group.arguments.splice(index, 1)
      },

      closeEdit () {
        this.editDialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      updateItem () {
        let group = this.jobSettings[this.tab]
        if(this. editedIndex < 0){
          group.arguments.push(this.editedItem)
          if(!this.editedItem.multi){
            const index = this.availableArguments.indexOf(this.editedItem.argument)
            this.availableArguments.splice(index, 1)
          }          
        }
        else {
          group.arguments[this.editedIndex].value = this.editedItem.value
        }        
        this.closeEdit()
      },    
    },

    watch: {
      dialog (val) {
        val || this.closeEdit()
      },    
    }
  }
</script>

<style>
.theme--dark.arguments-table.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none;
}
</style>