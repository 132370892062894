import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import compute_info from './modules/compute_info'

import route from './modules/route'
import common from './modules/common'
import component from './modules/copmonent'
import upload from './modules/upload'
import upload_district from './modules/upload_district'
import dropzone from './modules/dropzone'

import project from './modules/projects'
import district from './modules/district'
import districtContact from './modules/district-contact'
import election from './modules/election'
import electionUser from './modules/election-user'
import rootElection from './modules/root-election'
import audit_jobs from './modules/audit_jobs'
import job_settings from './modules/job_settings'
import job_operations from './modules/job_operations'

import markdown_setting from './modules/markdown-setting'

import action_specs from './modules/action-specs'

import upload_requests from './modules/upload_requests'

import arg_specs from './modules/arg-specs'
import audit_types from './modules/arg-specs/audit_types'
import arg_groups from './modules/arg-specs/arg_groups'
import audit_arguments from './modules/arg-specs/audit_arguments'

import requested_upload from './modules/requested_upload'
import upload_files from './modules/file_upload'
import upload_district_files from './modules/file_upload_district'

import users_manage from "./modules/users-manage"

import audit_package from "./modules/audit-package"
import homeContent from "./modules/home"

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    auth,
    compute_info,
    homeContent,
    route,
    common,
    component,
    upload,
    upload_district,
    
    dropzone,
    project,
    district,
    districtContact,
    election,
    electionUser,
    rootElection,
    audit_jobs,
    job_settings,
    markdown_setting,
    job_operations,
    action_specs,
    upload_requests,

    arg_specs,
    audit_types,
    arg_groups,
    audit_arguments,
    requested_upload,
    upload_files,
    upload_district_files,
    users_manage,
    audit_package
  }
})
