<template>
  <dash-layout>
    <template slot="dash-title">Tools</template>
    <template slot="dash-body">
        <Tools />
    </template>
  </dash-layout>
</template>
<script>
 import {mapGetters} from 'vuex'
 import DashLayout from '../../containers/Layout'
 import Tools from './Tools.vue'

 export default {
    name: 'ToolLayout',
    data() {
      return {
        
        
      }
    },
     components: {
      DashLayout,
      Tools
     }
 }
</script>
