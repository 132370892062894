<template>
  <v-row dense class="reduce-row-gaps">

    <v-dialog v-model="argGroupDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Argument Group</span>
        </v-card-title>

          <v-container grid-list-xl fluid>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                label="Group Name"
                v-model="arg_group.group_name"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                label="Help"
                v-model="arg_group.help"
                rows="5"
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="addArgumentGroup" :disabled="arg_group.group_name.length == 0">{{ arg_group.id ? "Update" : "Add" }}</v-btn>
          <v-btn color="blue darken-1" outlined @click="argGroupDialog=false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col cols="12">
        <v-card>            
          <v-tabs v-model="tab" show-arrows>
            <v-tab v-for="item in AuditTypes" :key="item.id" @change="changeAuditType">
              {{ item.name }}
            </v-tab>
                  
            <v-tab-item v-for="item in AuditTypes" :key="item.id">
              <v-divider></v-divider>
              <group-editor :auditType="item"></group-editor>
            </v-tab-item>
          </v-tabs>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import GroupEditor from './GroupEditor'

  export default {
    name: 'ArgSpecsEditor',

    components: {
      GroupEditor,
    },

    computed: {
        ...mapGetters(['argSpecs']),
    },

    async mounted() {
      await this.$store.dispatch('loadArgSpecs')
    },

    data() {
      return {
        overlay: false,
        tab: 0,
        argGroupDialog: false,
        AuditTypes: [],
        arg_group: {
          group_name: '',
          help: ''
        }
      }
    },
    methods: {   
      editAtgumentGroup(group) {
        if(group.id){
          this.arg_group.id = group.id
          this.arg_group.group_name = group.group_name
          this.arg_group.help = group.help
        }
        else {
          this.arg_group.id = null
          this.arg_group.group_name = ''
          this.arg_group.help = ''
        }
        this.argGroupDialog = true
      },
      addArgumentGroup() {
        console.log(this.arg_group)
        this.argGroupDialog = false
      },
      changeAuditType() {
        console.log(this.tab)
      }
    },

    watch: {
      argSpecs(data) {
        this.AuditTypes = []
         for(let i = 0; i < data.length; i++){
          const item = {
            id: data[i].id,
            name: data[i].name,
            help: data[i].help,
          }
          this.AuditTypes.push(item)
        }
      }
    }
  }
</script>