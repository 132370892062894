import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/packages'
const ACTIVEURL="/active-packages"
const PAYMENT_URL="/payment-session"
const MANUAL_PAYMENT_URL="/manually-payment"

const state = {
  auditPackages: [],
  packageLoading: false,
  activePackage:{},
  auditActivePackage:[],
  subscribPlans:[],
  subscribeLoading:false,
  allSubscribPlans:[],
  allSubscribeLoading:false,
  auditPackageLoading:false,
  createPaymentSubSessionLoading:false,
  manualPackage : []
}

const getters = {
  auditPackages: (state) => {
    return state.auditPackages
  },
  packageLoading: (state) => {
    return state.packageLoading
  },
  activePackage:(state) => {
    return state.activePackage
  },
  auditActivePackage:(state) =>{
    return state.auditActivePackage
  },
  auditPackageLoading:(state) =>{
    return state.auditPackageLoading
  },
  createPaymentSubSessionLoading:(state) =>{
    return state.createPaymentSubSessionLoading
  },
  subscribPlans:(state) => {
    return state.subscribPlans
  },
  subscribeLoading:(state) => {
    return state.subscribeLoading
  },
  allSubscribeLoading:(state) => {
    return state.allSubscribeLoading;
  },
  allSubscribPlans:(state) => {
    return state.allSubscribPlans;
  }
}

function get(context, url, handler) {
  return api()
    .get(url)
    .then((response) => handler(context, response))
    .catch(error=>handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api()
    .post(url, data)
    .then((response) => handler(context, response, data))
    .catch(error=>handleApiError(error, ''))
}

function put(context, url, data, handler) {
  return api()
    .put(url, data)
    .then((response) => handler(context, response, data))
    .catch(error=>handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api()
    .delete(url + '/' + id)
    .then((response) => handler(context, id, response))
    .catch(error=>handleApiError(error, ''))
}

function createPaymentSession(context, url, data, handler) {
  return api()
    .post(url, data)
    .then((response) => handler(context, response, data))
    .catch(error=>handleApiError(error, ''))
}

function handleAuditPackagesLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const auditPackages = response['data']
  context.state.packageLoading = false
  if (auditPackages['error']) {
    context.commit('apiError', auditPackages['error'])
    logApiService(obj, auditPackages['error'], EventName.Audit_package, EventType.auditPackagesLoad)
  } else {
    logApiService(obj, `Fetch Audit Package`, EventName.Audit_package, EventType.auditPackagesLoad)
    context.commit('auditPackageLoaded', auditPackages)
  }
  return auditPackages
}

function handleAuditPackageSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const auditPackage = response['data']
  if (auditPackage['error']) {
    context.commit('apiError', auditPackage['error'])
    logApiService(obj, auditPackage['error'], EventName.Audit_package, EventType.auditPackageSave)
  } else {
    logApiService(obj, `Audit Package Save`, EventName.Audit_package, EventType.auditPackageSave)
    context.commit('auditPackageSuccess', auditPackage)
  }
  return auditPackage
}

function handleAuditActivePackagesLoad(context, response){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const auditPackage = response['data']
  if (auditPackage['error']) {
    context.commit('apiError', auditPackage['error'])
    logApiService(obj, auditPackage['error'], EventName.Audit_package, EventType.auditActivePackagesLoad)
  } else {
    logApiService(obj, `Fetch Audit Package`, EventName.Audit_package, EventType.activeAuditPackagesLoad)
    context.commit('auditActivePackageLoaded', auditPackage)
  }
  return auditPackage
}

function handlePaymentSessionCreate(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  context.state.createPaymentSubSessionLoading = false
  const payment_res = response['data']
  if (payment_res['error']) {
    context.commit('apiError', payment_res['error'])
    logApiService(obj, payment_res['error'], EventName.Audit_package, EventType.paymentSessionCreate)
  }
  logApiService(obj, 'Payment Session Created', EventName.Audit_package, EventType.paymentSessionCreate)
  return payment_res
}

function handleGetPackage(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const pack = response['data']
  if (pack['error']) {
    context.commit('apiError', pack['error'])
    logApiService(obj, pack['error'], EventName.Audit_package, EventType.getPackage)
  } else {
    logApiService(obj, 'Fetch Package list', EventName.Audit_package, EventType.getPackage)
    context.commit('setActiveProject', pack)
  }
  return pack
}

function handlePaymentSuccess(context,response){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const payment_res = response['data']
  if (payment_res['error']) {
    context.commit('apiError', payment_res['error'])
    logApiService(obj, payment_res['error'], EventName.Audit_package, EventType.paymentSuccess)
  }
  logApiService(obj, 'Fetch Payment Success', EventName.Audit_package, EventType.paymentSuccess)
  return payment_res
}

function handleSubscribePlan(context,response, data){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  context.state.subscribeLoading = false
  const auditPlan = response['data']
  if (auditPlan['error']) {
    context.commit('apiError', auditPlan['error'])
    logApiService(obj, auditPlan['error'], EventName.Audit_package, EventType.subscribePlan)
  } else {
    logApiService(obj, 'Fetch Subscribe Product', EventName.Audit_package, EventType.subscribePlan)
    context.commit('subscribeProductLoaded', auditPlan)
  }
  return auditPlan
}

function handleAllSubscribePlan(context,response){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  context.state.allSubscribeLoading = false
  const auditPlan = response['data']
  if (auditPlan['error']) {
    context.commit('apiError', auditPlan['error'])
    logApiService(obj, auditPlan['error'], EventName.Audit_package, EventType.allSubscribePlan)
  } else {
    logApiService(obj, 'Fetch All Product Subscribe Plan', EventName.Audit_package, EventType.allSubscribePlan)
    context.commit('subscribeAllProductLoaded', auditPlan)
  }
  return auditPlan
}
// for manual-package-save
function handleManualPackageSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const manualPackage = response['data']
  if (manualPackage['error'] || response['message']) {
    logApiService(obj, 'kdmkdlfdfl', EventName.Audit_package, EventType.manualPackageSave)
    context.commit('apiError', manualPackage['error'])
  } else {
    logApiService(obj, 'Manual Package Created', EventName.Audit_package, EventType.manualPackageSave)
    context.commit('manualPackageSuccess', manualPackage)
  }
  return manualPackage
}
const actions = {
  loadAuditPackages(context) {
    if (context.state.auditJobLoading) return
    context.state.packageLoading = true
    get(context, URL, handleAuditPackagesLoad)
  },
  loadAuditActivePackages(context){
    if (context.state.auditPackageLoading) return
    context.state.auditPackageLoading = true
    get(context, ACTIVEURL, handleAuditActivePackagesLoad)
  },
  getPackage(context,id){
    get(context, URL +'/'+id, handleGetPackage)
  },
  setPackagePayment(context, id) {
    if (context.state.packageLoading) return
    context.state.packageLoading = true
    return get(context, '/subscription-payment-success/' + id, handlePaymentSuccess)
  },
  saveAuditPackage(context, payload) {
    let auditPackageData = {
      name: payload['name'],
      description: payload['description'],
      monthlyPrice: payload['monthlyPrice'],
      yearlyPrice:payload['yearlyPrice'],
      status:payload['status']
    }
    if (payload['id']) {
      auditPackageData['id'] = payload['id']
      return put(context, URL + '/' + payload['id'], auditPackageData, handleAuditPackageSave)
    } else {
      return post(context, URL, auditPackageData, handleAuditPackageSave)
    }
  },
  getSubscriptionByUser(context,payload){
    if (context.state.subscribeLoading) return
    context.state.subscribeLoading = true
    
    return post(context,'/user-subscription', payload, handleSubscribePlan)
  },
  getAllSubscription(context){
    if (context.state.allSubscribeLoading) return
    context.state.allSubscribeLoading = true
    
    return get(context,'/user-subscription', handleAllSubscribePlan)
  },
  genratePackagePaymentSession(context, data) {
    if (context.state.createPaymentSubSessionLoading) return
    context.state.createPaymentSubSessionLoading = true
    return createPaymentSession(context, PAYMENT_URL, data, handlePaymentSessionCreate)
  },
  // for manual-package-save
  saveManualPackage(context, payload) {
    let ManualPackageData = {
      user_id: payload['user_id'],
      package_id: payload['package_id'],
      is_monthly: payload['is_monthly'],
      price:payload['price'],
    }
    return post(context, MANUAL_PAYMENT_URL, ManualPackageData, handleManualPackageSave)
  },
}

const mutations = {
    auditPackageLoaded(state, auditPackages) {
      state.auditPackages = []
      for (let i = 0; i < auditPackages.length; i++) {
        state.auditPackages.push(auditPackages[i])
      }
    },
    auditActivePackageLoaded(state,auditPackages){
      state.auditPackages = []
      for (let i = 0; i < auditPackages.length; i++) {
        state.auditActivePackage.push(auditPackages[i])
      }
    },
    subscribeProductLoaded(state,plan){
      state.subscribPlans = []
      for (let i = 0; i < plan.length; i++) {
        state.subscribPlans.push(plan[i])
      }
    },
    subscribeAllProductLoaded(state,plan){
      state.allSubscribPlans = []
      for (let i = 0; i < plan.length; i++) {
        state.allSubscribPlans.push(plan[i])
      }
    },
    auditPackageSuccess(state, auditPackage) {
      state.activePackage = auditPackage
      for (let i = 0; i < state.auditPackages.length; i++) {
        if (state.auditPackages[i].id == auditPackage.id) {
          state.auditPackages.splice(i, 1, auditPackage)
          Vue.notify({
            group: 'messages',
            type: 'success',
            text: 'Audit Package Updated',
          })
          return
        }
      }
  
      state.auditPackages.push(auditPackage)
      Vue.notify({
        group: 'messages',
        type: 'success',
        text: 'Audit Package Created',
      })
    },
    setActiveProject(state, packageLst) {
      state.activePackage = packageLst
    },
    //for manual pageage save success
    manualPackageSuccess(state, manualPackage) {
      
      Vue.notify({
        group: 'messages',
        type: 'success',
        text: 'Manual Package Created',
      })
    },
}
export default {
  state,
  getters,
  mutations,
  actions,
  name: 'packageStore'
}


