<template>
  <div>
    <v-row class="justify-end pr-4">
      <v-spacer></v-spacer>
      <v-dialog v-model="dialog" max-width="800px" persistent> 
        <v-card>
          <v-card-title>{{isEdit ? 'Edit' :  'Add'}} Contact Information</v-card-title>
          <v-divider></v-divider>
          <v-form ref="form" class="mx-2">
            <v-card-text class="picker-popup">
              <v-row >
                <v-col cols="12">
                  <v-text-field v-model="contactName"  outlined :rules="[rules.required]">
                    <template v-slot:label>
                      Contact Name<span  class="red--text"> *</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="title"  outlined :rules="[rules.required]">
                    <template v-slot:label>
                       Title<span  class="red--text"> *</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="email"  outlined :rules="[rules.required]">
                    <template v-slot:label>
                      Email<span  class="red--text"> *</span>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="address" label="Address" outlined >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="city" label="City" outlined ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="state_zipcode"
                    label="State Postal Code"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="phone_number"
                    label="Phone Number"
                    outlined
                  ></v-text-field>
                </v-col>
              
                <v-col cols="6">
                  <v-text-field
                    v-model="website"
                    label="Website"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions v-show="!editNotAllowed" class="pa-8">
            <v-spacer></v-spacer>
            <v-btn outlined @click="hide">
              Cancel
            </v-btn>
            <v-btn outlined @click="saveDistrict" v-if="userValue.role == 'Observer'? isHidden : !isHidden">
              {{ isEdit ? 'Update' : 'Create' }}
              <v-progress-circular v-if="saving" :width="3" color="green" indeterminate></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>   
  
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ContactOverview',
  components: {},

  computed: {
    ...mapGetters(['districtContactbyID',, 'user']),
    editNotAllowed: function () {
      return false
    },
   
  },

  async mounted() {
    this.districtId = this.$route.params.id
    this.userValue = this.user
  },
  data() {
    return {
      isEdit:false,
      dialog:false,
      districtId:0,
      isHidden: false,
      id: 0,
      contactId:0,
      date: new Date().toISOString().substr(0, 10),
      saving: false,
      contactName: '',
      address: '',
      city: '',
      state_zipcode: '',
      phone_number: '',
      email: '',
      title: '',
      website: '',
      rules: {
        required: (value) => !!value || 'Required.',
      },
    }
  },
  methods: {
    async show(item){
      this.dialog = true;
      if(item.id){
        this.isEdit =true
      this.contactId = item.id
      this.$store.commit('setLoading', true)
      const data={
        districtId: this.districtId || this.$route.params.id,
        id: item.id
      }
      await this.$store.dispatch('getDistrictContact',data)
      this.$store.commit('setLoading', false)
      this.editDistrict(this.districtContactbyID)
      }
     
    },
    hide(){
      this.dialog = false;
      this.$refs.form.reset();
      this.isEdit =false
    },
    editDistrict(district) {
      this.id = district.id
      this.date = district.date
      this.contactName= district.name
      this.title = district.title
      this.address= district.address
      this.city= district.city
      this.state_zipcode= district.state_zipcode
      this.phone_number= district.phone_number
      this.email= district.email
      this.website= district.website
    },
    
    async saveDistrict() {
      if(this.$refs.form.validate()){
        this.saving = true
        const payload = {
          districtId: this.districtId || this.$route.params.id,
          name: this.contactName,
          title: this.title,
          email: this.email,
          address: this.address,
          city: this.city,
          state_zipcode :  this.state_zipcode,
          phone_number: this.phone_number,
          website: this.website,
          date: this.date,
        }
        if (this.contactId > 0) {
          payload['id'] = this.contactId
        }
        const response = await this.$store.dispatch('saveDistrictContext', payload)
        if (response.id > 0) {
          this.dialog = false
          this.saving = false
          this.$refs.form.reset();
          this.isEdit =false
        }
      }
      
    },
  },

  watch: {
  },
}
</script>

<style>
.g-layout {
  padding: 0px 10px !important;
}
</style>

