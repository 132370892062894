const state = {
    dataLoading: false,
  }
  
  const getters = {
    dataLoading: state => state.dataLoading,
  }
  
  const mutations = {
    setLoading(state, value){
      state.dataLoading = value
    },
  }
  
  const actions = {
  }
  
  export default {
    state,
    getters,
    mutations,
    actions,
    name: 'CommonStore'
  }