<template>
  <v-row dense class="reduce-row-gaps">
    <v-dialog v-model="rdsRestartDialog" max-width="800px" persistent>
      <v-card>
        <v-card-title>Are you sure restarting RDS?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="rdsRestartDialog = false">Cancel</v-btn>
          <v-btn outlined color="error" @click="restartRds()" :disabled="saving">
            Yes
            <v-progress-circular v-if="saving" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-btn @click="ConfirmDialog()" v-show="userrole == 'Admin'" class="float-right" :disabled="btnDesable" color="blue-grey darken-1">
        Restart RDS
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Tools',
  computed: {
    ...mapGetters(['rdsRestart']),
  },
  data() {
    return {
      rdsRestartDialog: false,
      saving: false,
      userrole: JSON.parse(localStorage.getItem('user')).role,
      btnDesable: false,
    }
  },
  methods: {
    ConfirmDialog() {
        this.rdsRestartDialog = true
    },
    restartRds() {
      this.saving = true
      this.btnDesable = true
      this.$store.dispatch('rdsRestart')
      this.saving = false
       this.rdsRestartDialog = false
      setTimeout(() => {
        this.btnDesable = false
      }, 30000)
    },
  },
}
</script>