<template>
  <v-row dense class="reduce-row-gaps">
    <!-- <v-dialog v-model="editUserDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{donationTitle}}</span>
        </v-card-title>

         <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                To donate by check, please make checks payable to Citizens Oversight, and note the project you want to earmark.
              </v-col>
              <v-col cols="12">
                Please mail to: <br>
                Citizens Oversight <br>
                771 Jamacha Rd. #148 <br>
                El Cajon, CA 92019 <br>
              </v-col>
              <v-col cols="12">
                Please note: we appreciate getting your donation in check form because we do not have to pay credit card processing fees!
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="closeDonation()">Close</v-btn>
          <v-btn color="blue darken-1" outlined>
            Complete Donation
             <v-progress-circular indeterminate color="primary" v-if="getUserUpdateLoading"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
    <!--
    <v-col cols="6">
      <v-card max-width="100%">
        <v-card-title>
          Donation
        </v-card-title>
        <v-card-text>
          <v-container class="emailTextBox">
            <v-row>
              <v-col cols="4" class="fixHeight">
                <v-text-field
                  v-model="amount"
                  label="Amount"
                  type="number"
                  outlined
                  >
                </v-text-field>
              </v-col>
              <v-col cols="8" class="fixHeight">
                <v-select
                  v-model="donateBy"
                  :items="donateByOptions"
                  item-text="text"
                  item-value="id"
                  label="Donate By"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-btn color="blue darken-1" outlined @click="donateToProject()">
                  Donate
                  <v-progress-circular indeterminate color="primary" v-if="getUserUpdateLoading"></v-progress-circular>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col> -->
    <!-- 
    <v-col cols="6">
      <v-card max-width="100%">
        <v-card-title>
          Email Subscribe
        </v-card-title>
        <v-card-text>
          <v-container class="emailTextBox">
            <v-row>
              <v-col cols="12" class="fixHeight">
                <v-text-field 
                  class="textField"
                  label="Email Subscribe" 
                  v-model="email" 
                  outlined></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn color="blue darken-1" outlined>
                  Subscribe
                  <v-progress-circular indeterminate color="primary" v-if="getUserUpdateLoading"></v-progress-circular>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col> -->

    <v-col cols="12">
      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
            clearable></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap>

            <v-data-table :headers="headers" :items="elections" :search="search" class="elevation-1"
              :loading="electionLoading" loading-text="Election Data Loading ..." style="width: 100%;">
              <template v-slot:[`item.county`]="{ item }">
                {{ item.county }}, {{ item.state }}, {{ item.country }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="openElection(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Open Election</span>
                </v-tooltip>

                <!--<v-btn v-if="item.role == 'Auditor'" icon @click.prevent="deleteElection(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>-->
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { AbbrCountries } from './../../services/AbbrCountries.js'
import { AbbrStates } from './../../services/AbbrStates.js'

export default {
  name: 'ProjectElectionList',
  props: ['projectId'],

  components: {},

  computed: {
    ...mapGetters(['activeProject', 'electionLoading', 'electionUserLoading', 'user', 'getUserUpdateLoading']),
  },

  async mounted() {
    // await this.$store.dispatch('getproject', this.projectId)
    // await this.$store.dispatch('loadElections', this.user.id)
    // this.getStripePublishableKey()

    this.elections = this.activeProject && this.activeProject.elections

    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);

    if (params.get("session_id")) {
      // this.$store.dispatch('setSuccess', params.get("session_id"))
      setTimeout(() => {
        if (localStorage.getItem('user')) {
          setTimeout(() => {
            // this.$router.push(`/home/project/${this.$route.params.id}`)  
            this.$router.push(`/projects/${this.activeProject.project.name}/${this.$route.params.id}`)
          }, 2000)
        }
      }, 2000)
    }
  },

  data() {
    return {
      donationTitle: '',
      donateBy: '',
      donateByOptions: [
        { id: 0, text: 'Donate Online' },
        { id: 1, text: 'Donate by cheque' }
      ],
      amount: 0,
      email: '',
      id: 0,
      name: '',
      e_type: 'Primary',
      country: 'US',
      state: '',
      US_state: 'CA',
      county: '',
      date: new Date().toISOString().substr(0, 10),
      description: '',
      dateModal: false,
      electionSaveDialog: false,
      electionDeleteDialog: false,
      saving: false,
      editUserDialog: false,
      search: '',
      elections: [],
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Type', value: 'e_type' },
        { text: 'Privacy', value: 'privacy', mustSort: true },
        { text: 'Location', value: 'county' },
        { text: 'Date', value: 'date' },
        { text: 'Role', value: 'role' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },
  methods: {
    async donateToProject() {
      if (this.amount) {
        if (this.donateBy === 0) {
          this.donationTitle = "Donate by Online"
          this.editUserDialog = false
          fetch(`${process.env.VUE_APP_ROOT_API}/project-donation`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              project_id: this.projectId,
              price: this.amount,
              host: window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2],
              // audit_id: this.selecteAuditId,
            }),
          })
            .then(result => result.json())
            .then(data => {
              // Redirect to Stripe Checkout
              return this.stripe.redirectToCheckout({ sessionId: data.sessionId })
            })
            .then(res => {
              this.$route.push(`/projects/success/${this.projectId}?session_id=${data.sessionId}`)
              // this.$route.push('/home/project/' + this.projectId+ '')
            })

        } else if (this.donateBy === 1) {
          this.donationTitle = "Donate by cheque"
          this.editUserDialog = true
        } else {
          Vue.notify({
            group: 'messages',
            type: 'error',
            duration: 5000,
            text: 'Please Select Donation By'
          })
        }
      } else {
        Vue.notify({
          group: 'messages',
          type: 'error',
          duration: 5000,
          text: 'Please Enter amount'
        })
      }
    },

    // async editUserSetData(item) {
    //   // await this.$store.dispatch('getUserData', this.id)
    //   await this.$store.dispatch('getUserData', item.id)
    //   this.id = item.id
    //   this.email = item.email
    //   this.first_name = item.first_name
    //   this.last_name = item.last_name
    //   this.phone_number = item.phone_number
    //   this.role = item.role
    //   this.run_adjudicator = item.run_adjudicator
    //   this.run_audit = item.run_audit
    //   this.run_targetmapper = item.run_targetmapper
    //   this.verified = item.verified
    //   this.country = item.country
    //   this.US_state = item.country == 'US' ? item.state : null
    //   this.state = item.state
    //   this.zipcode = item.zip_code

    //   this.editUserDialog = true
    // },
    // async saveEditedUserData() {
    //   this.overlay = true
    //   let idString = '';
    //   this.selectedPermits.map((item, index) => {
    //     idString = idString + `${item.id}`
    //     if(this.selectedPermits.length !== index+1) {
    //       idString = idString + ','
    //     }
    //   })
    //   let payload = {
    //     id: this.id,
    //     email: this.email,
    //     first_name: this.first_name,
    //     last_name: this.last_name,
    //     phone_number: this.phone_number,
    //     run_adjudicator: this.run_adjudicator,
    //     run_audit: this.run_audit,
    //     run_targetmapper: this.run_targetmapper,
    //     audit: idString,
    //     role: this.role,
    //     country: this.country,
    //     state: this.country !== 'US' ? this.state : this.US_state,
    //     zip_code: this.zipcode,
    //   }
    //   await this.$store.dispatch('updateUserData', payload)
    //   this.$store.dispatch('loadUsers')
    //   this.editUserDialog = false
    //   this.overlay = false
    // },
    // closeDialog() {
    //   this.editUserDialog = false
    // },
    // setEditData() {},
    // getStripePublishableKey() {
    //   /*this.stripe = Stripe(
    //     'pk_test_51J34l9SCOUQvwVycdiLMK6rfJXaJ1WuBsyYbldyLsc7F676lRLWDrJxEgvzGxgJVo7zQwDhiKNjryB74NNGZ96R700trGKLVBL'
    //   )*/
    //   // this.stripe = Stripe('pk_test_51HVDbDKjPzH68xcJOjnWnf2o7Pn4qcc1qZHZhkZe8hVRHXCKZqtsa6jwb42q8qwmt7i57XKObnzdJbpIJFMBHDol00vjKXmSZy');
    //   this.stripe = Stripe('pk_live_8SN1v2fgMje93cz4CQCokc6m');
    //   // this.stripe = Stripe('pk_test_7SB2QMpnvPKhyqVlDQNYgaLG');
    // },
    closeDonation() {
      this.editUserDialog = false
      this.donationTitle = ""
      this.donateBy = ''
      this.amount = 0
    },
    async openElection(item) {
      this.$router.push(`/elections/${item.id}`)
    }
  },

  watch: {
  },
}
</script>

<style>
.emailTextBox {
  padding: 0 !important;
}

.v-text-field input.textField {
  padding: 0 !important;
}

.fixHeight {
  padding: 0 !important;
}

.col-12.checkBox {
  padding: 0px !important;
}
</style>
