<template>
    <v-dialog v-model="dialogVisible" max-width="700">
      <v-card>
        <v-card-title class="headline">
          Technical Details
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="errorText"
            label="Error Details"
            rows="3"
            class="mail-error-textbox"
            readonly
          ></v-textarea>
        </v-card-text>
        <v-card style="text-align: center; margin-bottom: 10px; box-shadow: none;">
          <v-btn text @click="copyToClipboard" style="border: 1px solid;">Copy to Clipboard</v-btn>
        </v-card>
        <v-card style="text-align: center; padding-bottom: 20px;">
          <span class="mr-2">To send this information, please email it to:</span>
          <a class="" href="mailto:support@citizensoversight.org">support@citizensoversight.org</a>
        </v-card>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      dialogVisible: Boolean,
      errorText: String,
    },
    methods: {
      copyToClipboard() {
        // Implement the copy to clipboard logic here
        const textArea = document.createElement('textarea');
        textArea.value = this.errorText;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        // this.snackbarVisible = true; 
      },
    },
  };
  </script>
  
  <style>
  .mail-error-textbox textarea{
    margin-top: 20px; border: 1px solid;
  }
  .mail-error-textbox label{
    right: auto !important;
  }
  </style>