<template>
			<chat-container
				v-if="showChat"
				:current-user-id="currentUserId"
				:theme="theme"
				:is-device="isDevice"
				@show-demo-options="showDemoOptions = $event"
			/>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ChatContainer from './Discussion.vue'

export default {
      name: 'Discusioncontainer',
	components: {
		ChatContainer
	},

	data() {
		return {
			theme: 'dark',
			showChat: true,
			currentUserId: JSON.parse(localStorage.getItem('user')).user_id,
			isDevice: false,
			showDemoOptions: true,
			updatingData: false
		}
	},

	computed: {
		...mapGetters(['user'])
		
	},

	watch: {
		currentUserId() {
			this.showChat = false
			setTimeout(() => (this.showChat = true), 150)
		}
	},

	async mounted() {
		this.isDevice = window.innerWidth < 500
		window.addEventListener('resize', ev => {
			if (ev.isTrusted) this.isDevice = window.innerWidth < 500
		})
		
	},

	methods: {
		
		
		
	}
}
</script>