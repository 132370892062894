var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"reduce-row-gaps",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"mb-6"},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-spacer')],1),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.elections,"search":_vm.search,"loading":_vm.electionLoading,"loading-text":"Election Data Loading ..."},scopedSlots:_vm._u([{key:"item.county",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.county)+", "+_vm._s(item.state)+", "+_vm._s(item.country)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var ttprops = ref.ttprops;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.openElection(item)}}},'v-btn',ttprops,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Open Election")])])]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }