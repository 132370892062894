
<template>
  <v-row dense class="reduce-row-gaps">
    <v-dialog v-model="auditUserDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Update Audit Data</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field label="Name" v-model="name" outlined readonly></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Status" v-model="status" outlined readonly></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Request Donate" v-model="request_donate" outlined></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field label="Total Received Donation" v-model="received_donation" outlined
                  readonly></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field type="number" label="Add Received Donation" v-model="add_recieved_donate"
                  outlined></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-textarea label="Description" v-model="description" outlined readonly></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="closeDialog">Close</v-btn>
          <v-btn color="blue darken-1" outlined @click="saveEditedUserData">
            {{ !updateAuditDonationLoading ? 'Update' : '' }}
            <v-progress-circular indeterminate color="primary" v-if="updateAuditDonationLoading"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col cols="12">
      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
            clearable></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap>
            <v-data-table :headers="headers" :items="auditJobs" :search="search" class="elevation-1"
              :loading="auditJobLoading" loading-text="Users List Loading ..." style="width: 100%;">
              <template v-slot:[`item.progress`]="{ item }">
                <div>
                  <v-progress-linear color="amber" height="25" :value="!isNaN(item.request_donate) && !isNaN(item.received_donation)
                    ? Number(item.request_donate) === 0
                      ? 0 : (Number(item.received_donation) / Number(item.request_donate)) * 100
                    : 0
                    ">
                    <template v-slot="{ value }">
                      <strong>{{ value > 100 ? "> 100.00" : value.toFixed(2) }} %</strong>
                    </template>
                  </v-progress-linear>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="editAuditSetData(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Audit Data</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
// import { AbbrCountries } from '../../services/AbbrCountries.js'
// import { AbbrStates } from '../../services/AbbrStates.js'

export default {
  name: 'AdminAuditsList',
  components: {},

  computed: {
    ...mapGetters([
      'getUsersList',
      'getUsersLoading',
      'getUserUpdateLoading',
      'auditJobs',
      'auditJobLoading',
      'updateAuditDonationLoading',
    ]),
  },

  mounted() {
    this.$store.dispatch('loadUsers')
    this.$store.dispatch('loadAuditJobs')
  },

  data() {
    return {
      search: '',
      id: '',
      name: '',
      status: '',
      description: '',
      phone_number: '',
      role: '',
      election_id: '',
      add_recieved_donate: null,
      request_donate: false,
      received_donation: false,
      headers: [
        { text: 'ID', value: 'id', width: '10%' },
        { text: 'Name', align: 'start', sortable: false, value: 'name', width: '15%' },
        { text: 'Status', align: 'start', sortable: false, value: 'status', width: '10%' },
        // { text: 'Description', align: 'start', sortable: false, value: 'description', width: '25%' },
        // { text: 'Phone Number', align: 'start', sortable: false, value: 'phone_number', width: '15%' },
        { text: 'Progress', value: 'progress', width: '20%' },
        { text: 'Requested Donate', value: 'request_donate', width: '10%' },
        { text: 'Received Donation', value: 'received_donation', width: '10%' },
        { text: 'Active', value: 'active', width: '10%' },
        // { text: 'Run Targetmapper', value: 'run_targetmapper', width: '10%' },
        { text: 'Actions', value: 'actions', width: '10%' },
      ],
      selectedPermits: [],
      auditUserDialog: false,
    }
  },
  methods: {
    editAuditSetData(item) {
      this.id = item.id
      this.name = item.name
      this.status = item.status
      this.description = item.description
      this.request_donate = item.request_donate
      this.received_donation = item.received_donation
      this.election_id = item.election_id
        ; (this.auditUserDialog = true), (this.election_id = item.election_id)
    },
    async saveEditedUserData() {
      if (Number(this.request_donate) + Number(this.received_donation) < Number(this.add_recieved_donate)) {
        this.$notify({
          group: 'messages',
          type: 'error',
          text: 'Donation Amount can not be greater than Requested amount',
        })
        // alert('Donation Amount can not be greater than Requested amount');
      } else {
        let payload = {
          id: this.id,
          name: this.name,
          status: this.status,
          description: this.description,
          request_donate: this.request_donate,
          received_donation: this.add_recieved_donate,
          election_id: this.election_id,
        }
        await this.$store.dispatch('AuditDonationUpdate', payload)
        this.$store.dispatch('loadAuditJobs')
        this.auditUserDialog = false
      }
    },
    closeDialog() {
      this.id = ''
      this.name = ''
      this.status = ''
      this.description = ''
      this.request_donate = ''
      this.received_donation = ''
      this.election_id = ''
      this.add_recieved_donate = null
      this.auditUserDialog = false
    },
    setEditData() { },
  },

  watch: {},
}
</script>
