<template>    
    <v-row align="center" justify="center">     
      <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Audit Create Files</span>
          </v-card-title>

          <v-card-text>
          <v-container>
            <v-row>
              <v-radio-group
                v-model="radios"
                row
                class="radioBtn"
              >
              
                <v-radio
                  v-for="link in vendorArr"
                  :key="link"
                  :label="link"
                  color="primary"
                  :value="link"
                ></v-radio>
                <!-- <v-radio
                  label="Dominion"
                  color="primary"
                  value="1"
                ></v-radio>
                <v-radio
                  label="Hart"
                  color="primary"
                  value="2"  
                  disabled                
                ></v-radio> -->
              </v-radio-group>
            </v-row>
          </v-container>
        </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined @click="close" :disabled="uploading">Close</v-btn>
            <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="blue darken-1" outlined @click="save" :disabled="radios === null">
              Create
              <v-progress-circular
                      indeterminate
                      color="primary"
                      v-if="uploading"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <JobFileVendorAuditQuesDialog ref="JobFileVendorAuditQuesDialog"/>
    </v-row>    
</template>

<script>
  import Vue from 'vue'
  import { mapGetters, mapState } from 'vuex';
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'
  import JobFileVendorAuditQuesDialog from './JobFileVendorAuditQuesDialog.vue'
  export default {
    name: 'JobFileCreateAuditDialog',
    components: {
      vueDropzone: vue2Dropzone,
      JobFileVendorAuditQuesDialog
    },
    computed: {
      ...mapGetters(['vonderJsonFile','jobArgSpecs']),
     
    },
    mounted () {
      this.access_token = JSON.parse(localStorage.getItem('user')).access_token 
      this.userValue = this.user
      // this.dropzoneOptions.headers['Authorization'] = 'Bearer ' + this.access_token    
    },
    data() {
      return {
        userValue: {},
        isHidden: false,
        dialog: false,
        file_type: 'JOB',
        access_token:null,
        vendorArr:[],
        description: '',
        file_types: [
            {value: 'JOB', description: 'Job Configuration'},
            {value: 'EIF', description: 'Election Information'},
            {value: 'BOF', description: 'Ballot Options'},
            {value: 'STC', description: 'Manual Styles to Contests'},
        ],
        uploading: false,
        radios: null,
      }
    },
    methods: {
      async show(){
        this.dialog = true
        if(this.jobArgSpecs.length > 0){
          this.jobArgSpecs.forEach((item) => {
            // console.log(item.group)
            if(item.group == "bia_specs"){
              item.arguments && item.arguments.forEach((itm) => {
                if(itm.argument.toLowerCase() == "vendor"){
                  if(itm.choices.length > 0){
                    this.vendorArr = itm.choices
                  }
                }
              })
            }
          })
        }
        if(this.vendorArr.length == 0){
          this.vendorArr = ["ES&S","Dominion"]
        }
       
      },
      close(){
        this.radios = null 
        this.dialog = false
      },
      async save(){
        if(!this.radios) {
        Vue.notify({
        group: 'messages',
        type: 'error',
        duration: 5000,
        text: "Please Choose any one option."
        })
        } 
        else {
          // const vendor = `${(this.radios == 0 && 'ESS') || (this.radios == 1 && 'Dominion') || (this.radios == 2 && 'Hart')  }`
          let payload = {
            vendor: this.radios
          }        
          if(this.isUpdate){
            payload['isUpdate'] = this.isUpdate
          }
          this.uploading=true
          let ret = await this.$store.dispatch('generateTemplate', payload)
        
          this.uploading=false
          this.dialog = false
          this.radios = null
          if(this.vonderJsonFile.length > 0){
             this.$refs.JobFileVendorAuditQuesDialog.show()
          }
        }
      }, 
    },
    watch: {
    }
  }
</script>


<style>
.vue-dropzone-multiple {
    display: block !important;
  }
</style>