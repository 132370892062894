<template>
  <dash-layout>
  
    <template slot="dash-title" v-if="id != 'new'">
       <!-- <v-btn  outlined @click="$router.push(`/audit-jobs`).catch(err => {})">Go To Audits</v-btn > -->
      <span v-if="activeElection.name">
        {{ activeElection.name }}
        <v-chip
          small
          color="green"
          text-color="white"
          style="margin-bottom: 16px; margin-left: -8px;"
        >
          {{ activeElection.role }}
        </v-chip>
      </span>        
      <span v-else>Loading...</span>
    </template>
    <template slot="dash-title" v-else>New Election</template>
    <template slot="dash-body">
        
      <v-tabs show-arrows v-model="tab" class="mb-4">
        <div class="back-btn" style="line-height: 50px;">
        <v-btn icon @click.prevent="backProject()" class="mb-1">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn><span>Back</span>
          </div>
        <v-tab :ripple="false" :ref="'btnElectionProperties'">Election Properties</v-tab>
        <v-tab 
          :ripple="false" 
          :ref="'btnElectionFiles'"
          :disabled="id == 'new'"
          v-show="activeElection.role == 'Auditor' || activeElection.role == 'Uploader'"
        >Election Files</v-tab>
        <v-tab 
          :ripple="false" 
          :ref="'btnUploadedFiles'"
          :disabled="id == 'new'"
          v-show="activeElection.role == 'Auditor' || activeElection.role == 'Uploader'"
        >File Upload</v-tab>
        <v-tab 
          :ripple="false" 
          :ref="'btnRequestFiles'"
          :disabled="id == 'new'"
          v-show="activeElection.role == 'Auditor' || activeElection.role == 'Uploader'"
        >Upload Requests</v-tab>
        <v-tab
          :ripple="false" 
          :ref="'btnUsers'"
          :disabled="id == 'new'"
          v-show="activeElection.role == 'Auditor'"
        >Users</v-tab>
         <v-tab :ripple="false" :ref="'gotoaudit'" @click="$router.push({ name: 'AuditJobsList', params: { electionId: id } })">Audits</v-tab>
      </v-tabs>

      <transition name="component-fade" mode="out-in">
        <keep-alive>
          <component v-bind:is="tabs[activeTab].component" :selectedDistrict="selectedDistrict" :electionId="id"></component>        </keep-alive>
      </transition>

    </template>
  </dash-layout>

</template>

<script>
  import {mapGetters} from 'vuex'
  import DashLayout from '../../containers/Layout'
  import Overview from './Overview'
  import Upload from './UploadSets'
  import FileUpload from './FileUpload'
  import Request from './RequestFiles'
  import ElectionUsers from './ElectionUsers'

  export default {
    name: 'ElectionLayout',
    props: ['id'],

    data() {
      return {
        currentTabComponent: '',
        activeTab: 0,
        tabs: [
          { name: 'Election Properties', component: 'Overview'},
          { name: 'Election Files', component: 'Upload'},
          { name: 'File Upload', component: 'FileUpload'},
          { name: 'Upload Requests', component: 'Request'},
          { name: 'Users', component: 'ElectionUsers'},
          ],
        selectedDistrict: ''
      }
    },

    mounted() {
      console.log(this.activeElection.name)
      this.selectedDistrict = this.$route.params.selectedDistrict
    },


    computed: {
      ...mapGetters(['elections', 'activeElection', 'electionLoading', 'user']),
      tab: {
        get () {
          return this.activeTab
        },
        set (value) {
          this.activeTab = value
        }
      }
    },

    components: {
      DashLayout,
      Overview,
      Upload,
      FileUpload,
      Request,
      ElectionUsers,
    },

    methods: {
      backProject() {     
        if(this.activeElection.district){
          this.$router.push({ name: 'ElectionList', params: { districtId: this.activeElection.district.id } }).catch((err) => {})
        }else{
          this.$router.push('/elections')
        }
      }
    },

    watch: {
      activeElection(){
      }
    }
  }
</script>
