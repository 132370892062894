<template>
  <v-row align="center" justify="center">
    <v-overlay :value="jobsSettingLoading" style="z-index: 1999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialog" max-width="70%" persistent>
      <v-card max-width="100%">
        <v-card-title> </v-card-title>

        <v-container grid-list-xl fluid>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field v-model="file_name" label="File Name" readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="file_type" label="File Path" readonly></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <label for="">Json Data</label>
              <v-jsoneditor
                v-model="jsonData"
                :options="options"
                :plus="false"
                :height="`300px`"
                :expandedOnStart="true"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="close"> Close </v-btn>
          <v-btn outlined @click="saveJobSettingFile">
            Save
            <v-progress-circular indeterminate color="primary" v-if="waiting"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VJsoneditor from 'v-jsoneditor/src/index'
export default {
  name: 'DisplayJobsJSON',
  components: {
    VJsoneditor
  },

  data: () => ({
    overlay: false,
    dialog: false,
    file_name: '',
    file_type: 'JSON',
    isUpdate: false,
    waiting: false,
    fileId: 0,
    jsonData: {},
    options: {
      mode: 'code',
    },
    rules: {
      required: (value) => !!value || 'Required.',
    },
  }),

  computed: {
    ...mapGetters(['auditJobsSetting', 'user', 'dataLoading', 'jobsSettingLoading']),
  },

  mounted() {},

  methods: {
    async show(item) {
      // await this.$store.dispatch('createAndFetchJSON', item.fileName)
      this.dialog = true
      setTimeout(() => {
        this.file_name = item.fileName
        this.jsonData = item.data
      }, 1000)
    },
    close() {
      this.dialog = false
      this.waiting = false
    },
    async saveJobSettingFile() {
      let payload = {
        file_name: this.file_name,
        json_data: JSON.stringify(this.jsonData),
        file_type: this.file_type,
      }
      if (this.isUpdate) {
        payload['isUpdate'] = this.isUpdate
      }
      this.waiting = true
      await this.$store.dispatch('saveJobsSettingJSON', payload)
      this.json_data = {}
      this.file_name = ''
      this.file_type = ''
      this.waiting = false
      this.dialog = false
    },
  },

  watch: {},
}
</script>

<style>
.theme--dark.arguments-table.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none;
}
</style>
