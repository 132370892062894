import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const STATEURL = '/state-county'
const URL = '/elections'
const USER_URL = '/election-users/election'

const state = {
  elections: [],
  jurisdictionsList: {},
  activeElection: {},
  electionLoading: false,
  // USjurisdictions : {}
}


const getters = {
  elections: state => {
    return state.elections
  },
  jurisdictionsList: state => {
    return state.jurisdictionsList
  },
  activeElection: state => {
    return state.activeElection
  },
  electionLoading: state => {
    return state.electionLoading
  },
  // USjurisdictions: state => {
  //   return state.USjurisdictions
  // },

}

function get(context, url, handler) {
  console.log('sfcd', url)
  return api().get(url)
  .then(response => handler(context, response))
  .catch((error) => handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api().post(url, data)
  .then(response => handler(context, response, data))
  .catch((error) => {
    handler(context, error.response, data);
    handleApiError(error, '');
  });
}

function put(context, url, data, handler) {
  return api().put(url, data)
  .then(response => handler(context, response, data))
  .catch((error) => handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id)
  .then(response => handler(context, id, response))
  .catch((error) => handleApiError(error, ''))
}
// function getDiscrictList(context,response){
//   const districts = response['data']
//   context.state.electionLoading = false
//   if (districts['error']) {
//     context.commit('apiError', districts['error'])
//   } else {
//     context.commit('districtsLoaded', districts)
//   }
//   return districts
// }
function handleElectionsLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const elections = response['data']
  context.state.electionLoading = false
  if (elections['error']) {
    logApiService(obj, elections['error'], EventName.Elections, EventType.electionsLoad)
    context.commit('apiError', elections['error'])
  } else {
    logApiService(obj, `Fetch Elections`, EventName.Elections, EventType.electionsLoad)
    context.commit('electionsLoaded', elections)
  }
  return elections
}

function handleElectionSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const  election = response['data']
  if (election['error']) {
    logApiService(obj, election['error'], EventName.Elections, EventType.electionSave)
    context.commit('apiError', election['error'])
  } else {
    logApiService(obj, `Election Updated`, EventName.Elections, EventType.electionSave)
    context.commit('electionSuccess', election)
  }
  return election
}

function handleElectionDelete(context, id, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: id
  }
  const election = response['data']
  if (election['error']) {
    logApiService(obj, election['error'], EventName.Elections, EventType.electionDelete)
    context.commit('apiError', election['error'])
  } else {
    logApiService(obj, `Election deleted`, EventName.Elections, EventType.electionDelete)
    context.commit('electionDeleted', id)
  }
}

function handleGetElection(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const election = response['data']
  if (election['error']) {
    logApiService(obj, election['error'], EventName.Elections, EventType.getElection)
    context.commit('apiError', election['error'])
  } else {
    logApiService(obj, `Fetch election`, EventName.Elections, EventType.getElection)
    context.commit('setActiveElection', election)
  }
  return election
}

function handleGetJurisdiction(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const jurisdictions = response['data']
  if (jurisdictions['error']) {
    context.commit('apiError', jurisdictions['error'])
    logApiService(obj, jurisdictions['error'], EventName.Elections, EventType.getJurisdiction)
  } else {
    logApiService(obj, 'Fetch Jurisdiction', EventName.Elections, EventType.getJurisdiction)
    context.commit('setJurisdictions', jurisdictions)
  }
  return jurisdictions
}

const actions = {
  loadElections(context, id) {
    if (context.state.electionLoading) return

    context.state.electionLoading = true
    get(context, URL, handleElectionsLoad)
  },

  // loadDistrictList(context, id) {
  //   get(context, STATEURL, getDiscrictList)
  // },
  saveElection(context, payload) {
    let electionData = {
      'name': payload['name'],
      'e_type': payload['e_type'],
      'privacy': payload['privacy'],
      'country': payload['country'],
      'state': payload['state'],
      'county': payload['county'],
      'date': payload['date'],
      'description': payload['description'],
      'address': payload['address'],
      'project_ids':payload['project_ids'],
      'districtId':payload['districtId']
    }
    if (payload['id']) {
      electionData['id'] = payload['id']
      return put(context, URL + '/' + payload['id'], electionData, handleElectionSave)
    } else {
      return post(context, URL, electionData, handleElectionSave)
    }
  },

  deleteElection(context, id) {
    return apiDelete(context, URL, id, handleElectionDelete)
  },

  getElection(context, id) {
    return get(context, URL + '/' + id, handleGetElection)
  },
  getJurisdiction(context, state) {
    return get(context, '/public/us/jurisdictions', handleGetJurisdiction)
  },
}


const mutations = {
  electionsLoaded(state, elections) {
    state.elections = []
    for (let i = 0; i < elections.length; i++) {
      state.elections.push(elections[i])
    }
  },
  // districtsLoaded(state, districts) {
  //   state.USjurisdictions = {}
  //   districts.filter((itm)=>{
  //     if(Object.keys(state.USjurisdictions).includes(itm.state)){  
  //          state.USjurisdictions[itm.state].push(itm.Jurisdiction)
  //     }else{
  //       state.USjurisdictions[itm.state] = [itm.Jurisdiction]
  //     }
      
  //   })
  //   for (let i = 0; i < districts.length; i++) {
  //     state.USjurisdictions.push(districts[i])
  //   }
  // },
  electionSuccess(state, election) {
    state.activeElection = election
    for (let i = 0; i < state.elections.length; i++) {
      if (state.elections[i].id == election.id) {
        state.elections.splice(i, 1, election)        
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Election Updated'
        })
        return
      }
    }

    state.elections.push(election)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Election Created'
    })
  },

  electionDeleted(state, id) {
    for (let i = 0; i < state.elections.length; i++) {
      if (state.elections[i].id == id) {
        state.elections.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Election deleted'
        })
      }
    }
  },

  clearLoadedElections(state){
    state.electionLoading = false
    state.elections = []
  },

  setActiveElection(state, election) {
    state.activeElection = election
  },

  setJurisdictions(state, jurisdictions) {
    state.jurisdictionsList = {}
    jurisdictions.filter((itm)=>{
      if(Object.keys(state.jurisdictionsList).includes(itm.state)){  
           state.jurisdictionsList[itm.state].push(itm.Jurisdiction.replace(" County",""))

          // logApiService(jurisdictions, 'jurisdictionsList', EventName.Elections, EventType.getJurisdiction)
      }else{
        state.jurisdictionsList[itm.state] = [itm.Jurisdiction.replace(" County","")]
        //  logApiService(jurisdictions, 'jurisdictionsList', EventName.Elections, EventType.getJurisdiction)
      }
      
    })
  },
}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'ElectionStore'
}
