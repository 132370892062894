<template>
  <v-row class="justify-end pr-4">
    <v-spacer></v-spacer>
    <v-dialog v-model="dialog" max-width="500px" persistent>    
      <v-card class="reduce-row-gaps">
        <v-card-text class="picker-popup">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-if="!!id"
              v-model="job_name"
              label="Job Name"
              validate-on-blur
              outlined
              :readonly="waiting"
              disabled
            ></v-text-field>
            <!-- <v-combobox
              v-else
              :items="jobpermission"
              v-model="selectedPermits"
              label="Select Job"
              multiple
            >
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item.id)"
                  v-bind="data.name"
                  :disabled="data.disabled"
                >
                  <v-avatar
                    class="accent white--text"
                    left
                    v-text="data.item.slice(0,1).toUpperCase()"
                  ></v-avatar>
                  {{ data.item }}
                </v-chip>
              </template>
            </v-combobox> -->
            <v-combobox
              v-else
              :items="jobpermission"
              v-model="selectedPermits"
              label="Select Jobs"
              multiple
              item-text="name"
              item-value="id"
            >
              <template v-slot:selection="data">
                <v-chip
                  :key="JSON.stringify(data.item.id)"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :disabled="data.disabled"
                >
                  <v-avatar
                    class="accent white--text"
                    left
                    v-text="data.item.name.slice(0, 1).toUpperCase()"
                  ></v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="close">Close</v-btn>
          <v-btn color="blue darken-1" outlined @click="requestjob" >
            {{ !JobrequestLoading ? 'Request' : '' }}
            <v-progress-circular indeterminate color="primary" v-if="JobrequestLoading"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>



<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'

  export default {
    name: 'JobPermissionDialog',
    components: {
    },
    computed: {
     ...mapGetters(['auditJobs', 'activeAuditJob', 'auditJobLoading', 'user','allauditJobs','jobpermission','JobrequestLoading']),
    },
    mounted() {
      // this.$store.dispatch('loadjobpermission') 
    },
    data() {
      return {
        dialog: false,
        waiting: false,
        id: null,
        all_audit:null,
        status: null,
        job_name: '',
        selectedPermits: [],
      }
    },
    methods: {
      close() {
        this.dialog = false
        this.id = null
      },
      show(item) {
        if(item.id) {
          this.id = item.id
          this.job_name = item.name
        }
        else {
          if(this.activeAuditJob.length > 0) {
            this.job_name = this.activeAuditJob[0]
          } else {
            this.job_name = ''
          }
        }
        this.dialog = true
      },
      async requestjob(){
        this.waiting = true
        let jobstring = '';
        let fileString = '';
        this.selectedPermits.map((item, index) => {
          fileString = jobstring + item.id
            jobstring = fileString + ','
        })
        if(jobstring != "")
        {
          let payload = {
            job_ids: jobstring.replace(/,\s*$/, ""),
          }
          const response = await this.$store.dispatch('savePermissionRequest', payload)
          if(response['data'])
          {
            this.$store.commit('apiError', response.data.error)
          }else{
            this.waiting = false
            this.dialog = false
            this.selectedPermits = []
          }
        }else{
          Vue.notify({
            group: 'messages',
            type: 'error',
            text: 'Please select one job'
          })
        }
       
      },
    },
    watch: {

    }
  }
</script>
