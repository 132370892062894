<template>
  <v-row class="justify-end pr-4">
    <v-spacer></v-spacer>
    <v-dialog v-model="dialog" max-width="500px" persistent>    
      <v-card>
        <v-card-text class="picker-popup">
        <v-row>
          <v-col cols="12">
            <v-select
            v-model="file_type"
            :items="file_types"
             item-text="description"
             item-value="value"
            label="File Type"   
            :disabled="fileUploading"
            outlined           
          ></v-select>
          </v-col>

          <v-col cols="12">
            <v-textarea
                    clearable
                    clear-icon="cancel"
                    label="Description"
                    v-model="description"
                    outlined
            ></v-textarea>
          </v-col>
          
          <v-col cols="12" v-cloak @drop.prevent="addDropFile" @dragover.prevent>
            <template>
              <v-file-input
                v-model="file"
                color="deep-purple accent-4"
                label="File input"
                placeholder="Select a file to upload"
                prepend-icon="mdi-paperclip"
                accept="application/x-zip-compressed"
                outlined                
                :show-size="1024"
                :disabled="uploadSetSaving"
                :loading="fileUploading"
              >
                <template  v-slot:selection="{ text}">
                  <v-chip
                    color="deep-purple accent-4"
                    dark
                    small
                  >
                    {{ text }}
                  </v-chip>
                </template>
              </v-file-input>
              <v-progress-linear
                :value="uploadProgress"
                height="25"
              >
                <strong>{{ Math.floor(uploadProgress) }}%</strong>
              </v-progress-linear>
            </template>
          </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="close" v-if="!fileUploading" :disabled="uploadSetSaving">CLOSE</v-btn>
          <v-btn color="blue darken-1" outlined @click="hide" v-else>HIDE</v-btn>
          <template v-if="userValue.role == 'Observer'? isHidden : !isHidden"> 
          <v-btn color="blue darken-1" outlined @click="upload" v-if="!fileUploading" :disabled="!file || uploadSetSaving">UPLOAD</v-btn>
          <v-btn color="blue darken-1" outlined @click="cancel" v-else>CANCEL</v-btn></template>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  export default {
    name: 'FileUploadDialog',
    components: {
      vueDropzone: vue2Dropzone
    },
    computed: {
      ...mapGetters(['activeUploadSet', 'activeElection','urls', 'user'])
    },
    mounted() {
      this.userValue = this.user
    },
    data() {
      return {
        userValue: {},
        isHidden: false,
        file: null,
        description: '',
        file_types: [
          {value: 'BIA', description: 'Ballot Image Archive'},
          {value: 'CVR', description: 'Cast Vote Records'},
          // {value: 'EIF', description: 'Election Information'},
          // {value: 'BOF', description: 'Ballot Options'},
          // {value: 'STC', description: 'Manual Styles to Contests'},
          // {value: 'ANN', description: 'Annotations'},
          // {value: 'ADJ', description: 'Adjudications'},
          // {value: 'JOB', description: 'Job Configuration'},
          // {value: 'SUM', description: 'Summary Results'},
        ],
        file_type: 'BIA',
        uploadOptions: {
          PART_SIZE: 4 * 1024 * 1024 * 1024,
          completed: false,
          file: null,
          sendBackData: null,
          signedUrls: [],
          uploadXHR: [],
          byterate: [],
          lastUploadedSize: [],
          lastUploadedTime: [],
          loaded: [],
          total: []
        },

        dialog: false,
        fileUploading: false,
        uploadSetSaving: false,
        uploadProgress: 0,

        byteRate: 0,

        uploadParts: [],
      }
    },
    methods: {
      observerValidation(){
        userValue.role == 'Observer'? isHidden : !isHidden
      },
      close() {
        this.file = null
        this.fileUploading = false
        this.uploadSetSaving = false
        this.hide()
      },
      hide() {
        this.dialog = false
      },
      show() {
        this.dialog = true
      },
      async cancel() {
        this.fileUploading = false    
        for (var i=0; i<this.uploadOptions.uploadXHR.length; ++i) {
            this.uploadOptions.uploadXHR[i].abort();
        }

        const self = this
        const payload = {
          id: this.activeUploadSet.id,
          status: 'Aborted'
        }
        await this.$store.dispatch('UpdateUploadSet', payload)
        .then( response => {self.$store.commit('apiError', response.file_name + " canceled!")})
        .catch( error => {self.$store.commit('apiError', "Upload canceled")})        
         
         this.uploadSetSaving = false
      },

      async upload(){   
        this.uploadSetSaving = true   
        this.uploadProgress = 0  
        this.uploadOptions.file = this.file
        this.uploadParts = []
        let start = 0
        let parts =0
        let end, blob
        let partNum = 0
        let blobs = this.uploadOptions.blobs = []
        while(start < this.uploadOptions.file.size) {
        end = Math.min(start + this.uploadOptions.PART_SIZE, this.uploadOptions.file.size);
        let filePart = this.uploadOptions.file.slice(start, end);
            // this is to prevent push blob with 0Kb
            if (filePart.size > 0)
                blobs.push(filePart);
            start = this.uploadOptions.PART_SIZE * ++partNum;
        }

        const payload = {
          election_id: this.activeElection.id,
          file_name: this.uploadOptions.file.name,
          file_type: this.file_type,
          chunk_count: blobs.length,
          chunk_size: this.uploadOptions.PART_SIZE,
          file_size: this.uploadOptions.file.size,
          content_type:  this.uploadOptions.file.type,
          status: 'Uploading'
        }

        let self = this
        await this.$store.dispatch('CreateUploadSet', payload).then(response => {
            self.uploadOptions.signedUrls = response.urls
            self.sendAll()
        }).catch( error => {
          self.$store.commit('apiError', error.response.data.error)
          this.fileUploading = false
          this.uploadSetSaving = false 
        })        
      },
      sendAll() {
        this.fileUploading = true
        let blobs = this.uploadOptions.blobs;
        let length = blobs.length;
        if (length==1)
            this.sendToS3(this.uploadOptions.signedUrls[0], blobs[0], 0);
        else for (let i = 0; i < length; i++) {
            this.sendToS3(this.uploadOptions.signedUrls[i], blobs[i], i);
        }
      },
      sendToS3(url, blob, index) {
            let self = this
            let options = this.uploadOptions
            let size = blob.size;
            let request = options.uploadXHR[index] = new XMLHttpRequest();
            request.onreadystatechange = function() {
                if (request.readyState === 4) { // 4 is DONE
                    // self.uploadXHR[index] = null;
                    if (request.status !== 200) {
                        self.updateProgress();
                        self.onS3UploadError(request);
                        return;
                    }
                    else {                      
                      const searchString = new URL(url).search.substring(1)
                      const partNumber = decodeURIComponent(searchString.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent("partNumber").replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
                      const uploadPart = {                        
                        ETag: request.getResponseHeader('ETag').replace(/"/g, ''),
                        PartNumber: parseInt(partNumber),
                      }                      
                      self.uploadParts.push(uploadPart)
                      if(self.uploadOptions.blobs.length == self.uploadParts.length){
                        self.onUploadCompleted()
                      }
                    }
                    self.updateProgress();
                }
            };

            request.upload.onprogress = function(e) {
                if (e.lengthComputable) {
                    options.total[index] = size;
                    options.loaded[index] = e.loaded;
                    if (options.lastUploadedTime[index])
                    {
                        let time_diff=(new Date().getTime() - options.lastUploadedTime[index])/1000;
                        if (time_diff > 0.005) // 5 miliseconds has passed
                        {
                            let byterate=(options.loaded[index] - options.lastUploadedSize[index])/time_diff;
                            options.byterate[index] = byterate; 
                            options.lastUploadedTime[index]=new Date().getTime();
                            options.lastUploadedSize[index]=options.loaded[index];
                        }
                    }
                    else 
                    {
                        options.byterate[index] = 0; 
                        options.lastUploadedTime[index]=new Date().getTime();
                        options.lastUploadedSize[index]=options.loaded[index];
                    }
                    // Only send update to user once, regardless of how many
                    // parallel XHRs we have (unless the first one is over).
                    if (index==0 || options.total[0]==options.loaded[0])
                        self.updateProgress();
                }
            };
            request.open('PUT', url, true);
            request.send(blob);
      },
      updateProgress(){
          let self = this.uploadOptions
          let total=0;
          let loaded=0;
          let byterate=0.0;
          let complete=1;
          for (let i=0; i<self.total.length; ++i) {
              loaded += +self.loaded[i] || 0;
              total += self.total[i];
              if (self.loaded[i]!=self.total[i])
              {
                  // Only count byterate for active transfers
                  byterate += +self.byterate[i] || 0;
                  complete=0;
              }
          }
          if (complete && this.fileUploading)            
          total=self.file.size;
          this.onProgressChanged(loaded, total, byterate);
      },
      async onUploadCompleted() {
        if(this.fileUploading){
          this.fileUploading = false
          const self = this
          const payload = {
            id: this.activeUploadSet.id,
            status: 'Uploaded',
            upload_parts: {uploadParts: self.uploadParts}
          }
          await this.$store.dispatch('UpdateUploadSet', payload)
          .then( response => {
              Vue.notify({
                group: 'messages',
                type: 'success',
                text: response.file_name + ' uploaded!'
              })
              this.close()
            }
          )
          .catch( error => {self.$store.commit('apiError', error.response.data.error)})

          this.uploadSetSaving = false
        }
      },
      async onS3UploadError(xhr) {
        if(this.fileUploading){
          this.fileUploading = false
          for (var i=0; i<this.uploadOptions.uploadXHR.length; ++i) {
            this.uploadOptions.uploadXHR[i].abort();
        }

        const self = this
        const payload = {
          id: this.activeUploadSet.id,
          status: 'Failed',
        }
        await this.$store.dispatch('UpdateUploadSet', payload)
        .then( response => {self.$store.commit('apiError', response.file_name + " failed!")})
        .catch( error => {self.$store.commit('apiError', "Upload failed")})        
        this.uploadSetSaving = false 
        }        
      },
      onProgressChanged(uploadedSize, totalSize, byterate) {
        this.uploadProgress = uploadedSize * 100 / totalSize
        this.byteRate = byterate
      },
    },
    watch: {
      file(){
        this.uploadProgress = 0
      }
    }
  }
</script>
