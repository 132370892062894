<template>
  <v-card>            
    <v-tabs>
      <v-tab v-for="item in groups" :key="item.id" show-arrows>
        {{ item.name }}
      </v-tab>
            
      <v-tab-item v-for="item in groups" :key="item.id">
        <v-divider></v-divider>
        <v-card flat>
          <v-card-title>                  
            <v-spacer></v-spacer>
            <v-btn @click="editAtgumentGroup({})" color="blue-grey darken-1" class="ml-2">Add Group</v-btn>
            <v-btn @click="removeGroup({})" color="blue-grey darken-1" class="ml-2"> Remove Group </v-btn>
          </v-card-title>                
          <v-card-text>
            <p>
              Sed aliquam ultrices mauris. Donec posuere vulputate arcu. Morbi ac felis. Etiam feugiat lorem non metus. Sed a libero.
            </p>

            <p>
              Nam ipsum risus, rutrum vitae, vestibulum eu, molestie vel, lacus. Aenean tellus metus, bibendum sed, posuere ac, mattis non, nunc. Aliquam lobortis. Aliquam lobortis. Suspendisse non nisl sit amet velit hendrerit rutrum.
            </p>

            <p class="mb-0">
              Phasellus dolor. Fusce neque. Fusce fermentum odio nec arcu. Pellentesque libero tortor, tincidunt et, tincidunt eget, semper nec, quam. Phasellus blandit leo ut odio.
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'GroupEditor',
    props: {
        auditType: Object,
    },

    components: {

    },

    computed: {

    },

    mounted() {
    },

    data() {
      return {
        overlay: false,
        groups: [],
      }
    },
    methods: {   
    },

    watch: {
    }
  }
</script>
