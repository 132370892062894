import Vue from 'vue'
import api from 'Api'
import { handleApiError } from '../../../services/apiService'

const URL = '/direct-upload'
const URL_DISTRICT = '/direct-district-upload'

const GENRAL_UPLOAD = '/general-upload'
const state = {
  uploadRequestId: null,
    uploadEmail: null,
  }
  
  const getters = {
    uploadRequestId: state => {
      return state.uploadRequestId
    },
    uploadEmail: state => {
      return state.uploadEmail
    },
  }
  
  const actions = {
    verifyUploadToken(context, payload){
      return api(false).post( URL + '/verify', payload).then(function (response) {      
        const responseData = response['data']
        if (responseData['error']) {
          context.commit('apiError', responseData['error'])
        } else {
          Vue.notify({
            group: 'messages',
            type: 'success',
            duration: 5000,
            text: 'Signature confirmed!'
          })
        }
        context.commit('tokenVerified', responseData)
        return responseData
      })
      .catch(error => {
        handleApiError(error, '')
        const responseData = error.response.data
        context.commit('apiError', responseData.error)
        return responseData
      });
    },
    verifyUploadTokenDistrict(context, payload){
      return api(false).post( URL_DISTRICT + '/verify', payload).then(function (response) {      
        const responseData = response['data']
        if (responseData['error']) {
          context.commit('apiError', responseData['error'])
        } else {
          Vue.notify({
            group: 'messages',
            type: 'success',
            duration: 5000,
            text: 'Signature confirmed!'
          })
        }
        context.commit('tokenVerified', responseData)
        return responseData
      })
      .catch(error => {
        handleApiError(error, '')
        const responseData = error.response.data
        context.commit('apiError', responseData.error)
        return responseData
      });
    },
    createDirectUpload(context, payload){
      return api(false).post( URL, payload).then(function (response) {      
        const responseData = response['data']
        if (responseData['error']) {
          context.commit('apiError', responseData['error'])
        }
        return responseData
      })
      .catch(error => {
        handleApiError(error, '')
        const responseData = error.response.data
        context.commit('apiError', responseData.error)
        return responseData
      });
    },
    createDirectUploadDistrict(context, payload){
      return api(false).post( URL_DISTRICT, payload).then(function (response) {      
        const responseData = response['data']
        if (responseData['error']) {
          context.commit('apiError', responseData['error'])
        }
        return responseData
      })
      .catch(error => {
        handleApiError(error, '')
        const responseData = error.response.data
        context.commit('apiError', responseData.error)
        return responseData
      });
    },
    generalUpload(context,payload){
      return api(false).post(GENRAL_UPLOAD,payload).then(function (response){
        const responseData = response['data']
        if(responseData['error']){
          context.commit('apiError',responseData['error'])
        }
        return responseData
      })
    },
    updateGenralUpload(context, payload){
      return api(false).put( GENRAL_UPLOAD + '/' + payload.id, payload).then(function (response) {      
        const responseData = response['data']
        if (responseData['error']) {
          context.commit('apiError', responseData['error'])
        }
        return responseData
      })
      .catch(error => {
        handleApiError(error, '')
        const responseData = error.response.data
        context.commit('apiError', responseData.error)
        return responseData
      });
    },
    updateDirectUpload(context, payload){
      return api(false).put( URL + '/' + payload.id, payload).then(function (response) {      
        const responseData = response['data']
        if (responseData['error']) {
          context.commit('apiError', responseData['error'])
        }
        return responseData
      })
      .catch(error => {
        handleApiError(error, '')
        const responseData = error.response.data
        context.commit('apiError', responseData.error)
        return responseData
      });
    },
    updateDirectUploadDistrict(context, payload){
      return api(false).put( URL_DISTRICT + '/' + payload.id, payload).then(function (response) {      
        const responseData = response['data']
        if (responseData['error']) {
          context.commit('apiError', responseData['error'])
        }
        return responseData
      })
      .catch(error => {
        handleApiError(error, '')
        const responseData = error.response.data
        context.commit('apiError', responseData.error)
        return responseData
      });
    },
    deleteGenrelUpload(context, id){
      return api(false).delete(GENRAL_UPLOAD + '/' + id).then(function (response) {      
        const responseData = response['data']
        if (responseData['error']) {
          context.commit('apiError', responseData['error'])
        }
        return responseData
      })
      .catch(error => {
        handleApiError(error, '')
        const responseData = error.response.data
        context.commit('apiError', responseData.error)
        return responseData
      });
    },
    deleteDirectUpload(context, id){
      return api(false).delete( URL + '/' + id).then(function (response) {      
        const responseData = response['data']
        if (responseData['error']) {
          context.commit('apiError', responseData['error'])
        }
        return responseData
      })
      .catch(error => {
        handleApiError(error, '')
        const responseData = error.response.data
        context.commit('apiError', responseData.error)
        return responseData
      });
    },
  }

  const mutations = {
    tokenVerified(state, data){
      state.uploadRequestId = data.request_id
      state.uploadEmail = data.email
    }
  }
  
  export default {
    state,
    getters,
    mutations,
    actions,
    name: 'RequestedUploadStore'
  }
