import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService';
import { EventName, EventType } from '../../../services/LogEvent';

const URL = 'users';
const UpdateURL = 'update-permission'
const UpdateAuditPermissionURL = 'save-audit-permission'
const USER_DETAIL_URL = 'user-details'
const DONATION_URL = 'donation-list'
const state = {
  users: [],
  inactiveUsers: [],
  donations:[],
  usersLoading: false,
  inactivateUsersLoading: false,
  donationLoading:false,
  activeUser: {},
  getauditPermission: {},
  activeUserLoading: false,
  userUpdateLoading: false,
  permissionLoading:false,
  restartLoading:false
}


const getters = {
  getUsersList: state => {
    return state.users
  },
  getInactiveUserList : state => {
    return state.inactiveUsers
  },
  getPaymentList: state => {
    return state.donations
  },
  donationLoading: state => {
    return state.donationLoading
  },
  getUsersLoading: state => {
    return state.usersLoading
  },
  getInactivateUsersLoading: state => {
    return state.inactivateUsersLoading
  },
  getUserUpdateLoading: state => {
    return state.userUpdateLoading
  },
  activeUserLoading: state => {
    return state.activeUserLoading
  },
  activeUser: state => {
    return state.activeUser
  },
  getauditPermission: state => {
    return state.getauditPermission
  },
  permissionLoading: state => {
    return state.permissionLoading
  },
  restartLoading: state => {
    return state.restartLoading
  }
}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response))
  .catch(error => {
    handleApiError(error, '')
    handler(context, error['response'])
  })
}

function getUserDetail(context, url, id, handler) {
  return api().get(`${url}/${id}`).then(response => handler(context, response))
  .catch(error => {
    handleApiError(error, '')
    handler(context, error['response'])
  })
}

function getPermissionDetail(context, url, handler) {
  return api().get(`${url}`).then(response => handler(context, response))
  .catch(error => {
    handleApiError(error, '')
    handler(context, error['response'])
  })
}

function updateUser(context, url, id, data, handler) {
  console.log('',url, id, data)
  return api().put(`${url}/${id}`, data).then(response =>  {//context.commit('getUserUpdateLoading',false); 
    handler(context, response, data)}).catch((error)=>{handler(context, error, data);})
  .catch(error => handler(context, error['response']))
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response)).catch(error=>handleApiError(error, ''))
}

function handleUsersLoaded(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
  context.state.usersLoading = false
  if (response.status != 200) {
    context.commit('apiError', data['error'])
    logApiService(obj, data['error'], EventName.User_manage, EventType.usersLoaded)
    Vue.notify({
      group: 'messages',
      type: 'error',
      text: 'error while fetch users'
    })
  } else {
    logApiService(obj, `Fetch Users Data`, EventName.User_manage, EventType.usersLoaded)
    context.commit('usersLoaded', data)
  }
  return data
}

function handleInactiveUsersLoaded(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
  context.state.inactivateUsersLoading = false
  if (response.status != 200) {
    logApiService(obj, 'error while fetch users', EventName.User_manage, EventType.usersLoaded)
    context.commit('apiError', data['error'])
    Vue.notify({
      group: 'messages',
      type: 'error',
      text: 'error while fetch users'
    })
  } else {
    logApiService(obj, 'Fetch User Data', EventName.User_manage, EventType.usersLoaded)
    context.commit('inActiveUsersLoaded', data)
  }
  return data
}
function handleRdsRestartLoaded(context,response){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
  context,state.restartLoading = false
  if(response.status != 200){
    context.commit('apiError', data['error'])
    logApiService(obj, data['error'], EventName.User_manage, EventType.rdsRestartLoaded)
    Vue.notify({
      group: 'messages',
      type: 'error',
      text: 'error while fetch users'
    })
  }else{
    logApiService(obj, 'Fetch Restarting RDS', EventName.User_manage, EventType.rdsRestartLoaded)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Restarting RDS'
    })
  }
  return data
}

function handleDonationLoaded(context, response){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
  context.state.donationLoading = false
  if (response.status != 200) {
    context.commit('apiError', data['error'])
    logApiService(obj, 'error while fetch payment', EventName.User_manage, EventType.donationLoaded)
    Vue.notify({
      group: 'messages',
      type: 'error',
      text: 'error while fetch payment'
    })
  } else {
    logApiService(obj, 'Fetch Donation', EventName.User_manage, EventType.donationLoaded)
    context.commit('DonationLoaded', data)
  }
  return data
}

function handleUserUpdate(context, response, info) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: info
  }
  const data = response['data']
  context.state.userUpdateLoading = false
  if (response.status != 200) {
    logApiService(obj, data['error'], EventName.User_manage, EventType.userUpdate)
    handleApiError(response, '');
  } else {
    logApiService(obj, 'User Updated', EventName.User_manage, EventType.userUpdate)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'User Updated Successfully'
    })
  }
  return data
}

function handlePermissionUpdate(context,response, info){
  console.log('ffff', response, response['message'], info)
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: info
  }
  const data = response['data']
  context.state.permissionLoading = false
  if(response.status != 200 || response['message']){
    logApiService(obj, response['message'], EventName.User_manage, EventType.permissionUpdate)
    context.commit('apiError',data['error'])
    context.commit('apiError', data['message'])
  }else{
    logApiService(obj, 'Permission Updated', EventName.User_manage, EventType.permissionUpdate)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Permission Updated Successfully'
    })
  }
}


function handleUserDelete(context, id, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: id
  }
  const data = response['data']
  context.state.userUpdateLoading = false
  if (response.status != 200) {
    context.commit('apiError', data['error'])
    context.commit('apiError', data['message'])
    logApiService(obj,  data['message'], EventName.User_manage, EventType.userDelete)
  } else {
    logApiService(obj,  'User Deleted', EventName.User_manage, EventType.userDelete)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: data.message
    })
  }
}
function handleUserReactivate(context, response, info){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: info
  }
  const data = response['data']
  context.state.userUpdateLoading = false

  if (response.status != 200) {
    context.commit('apiError', data['error'])
    context.commit('apiError', data['message'])
    logApiService(obj, data['message'], EventName.User_manage, EventType.userReactivate)
  } else {
    logApiService(obj, 'User Activated', EventName.User_manage, EventType.userReactivate)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'User Activated Successfully'
    })
  }
}

function handleUserdata(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
  context.state.activeUserLoading = false
  if (response.status != 200) {
    context.commit('apiError', data['error'])
    logApiService(obj, data['error'], EventName.User_manage, EventType.userdata)
    Vue.notify({
      group: 'messages',
      type: 'error',
      text: 'error while fetch users'
    })
  } else {
    logApiService(obj, 'Fetch Active User Data', EventName.User_manage, EventType.userdata)
    context.commit('activeUserDataLoaded', data)
  }
  return data
}

function handlePermissiondata(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
  context.state.activeUserLoading = false
  logApiService(obj, 'Fetch Active User Data', EventName.User_manage, EventType.permissiondata)
  return data
}

const actions = {
  loadUsers(context) {
    if (context.state.usersLoading) return;

    context.state.usersLoading = true
    return get(context, URL, handleUsersLoaded)
  },
  loadInactivateUsers(context,status) {
    if (context.state.inactivateUsersLoading) return;

    context.state.inactivateUsersLoading = true
    return get(context, URL+ '-'+status, handleInactiveUsersLoaded)
  },
  rdsRestart(context){
    if(context.state.restartLoading) return;
    context.state.restartLoading = true
    return get(context, 'restart-rds', handleRdsRestartLoaded)
  },
  loadallpayment(context){
    if(context.state.donationLoading) return;

    context.state.donationLoading = true
    return get(context, DONATION_URL, handleDonationLoaded)
  },
  updateUserData(context, data) {
    if (context.state.userUpdateLoading) return;

    context.state.userUpdateLoading = true
    return updateUser(context, UpdateURL, data.id, data, handleUserUpdate)
  },
  updateAuditPermission(context,data){
    if(context.state.permissionLoading) return;

    context.state.permissionLoading = true
    return updateUser(context,UpdateAuditPermissionURL,data.id,data,handlePermissionUpdate)
  },
  getUserData(context, id) {
    if (context.state.activeUserLoading) return;

    context.state.activeUserLoading = true
    return getUserDetail(context, USER_DETAIL_URL, id, handleUserdata)
  },
  getPermisssion(context) {
    if (context.state.activeUserLoading) return;

    context.state.activeUserLoading = true
    return getPermissionDetail(context, 'get-permission', handlePermissiondata)
  },
  deleteUser(context, id) {
    return apiDelete(context, URL, id, handleUserDelete)
  },
  reactivateUser(context, id,data){
    return updateUser(context, URL+ '-activate' , id, data, handleUserReactivate)
  },
  deleteUserPermanently(context, id) {
    return apiDelete(context,  URL+ '-Inactive', id, handleUserDelete)
  },
}


const mutations = {
    usersLoaded(state, data) {
        state.users = []
        for (let i = 0; i < data.length; i++) {
            state.users.push(data[i])
        }
    },
    inActiveUsersLoaded(state, data) {
      state.inactiveUsers = []
      for (let i = 0; i < data.length; i++) {
          state.inactiveUsers.push(data[i])
      }
  },
    DonationLoaded(state, data){
      state.donations = []
      for (let i = 0; i < data.length; i++) {
        state.donations.push(data[i])
      } 
    },
    auditTypeLoaded(state, data) {
        state.activeAuditType = data
    },

    activeUserDataLoaded(state, data) {
      state.activeUser = []
      let tempdata = { ...data.response, audit_ids: data.audit_res.audit_ids, audits: data.audit_res.permissions }
      state.activeUser = tempdata
      // for (let i = 0; i < data.length; i++) {
      //     state.users.push(data[i])
      // }
  },
}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'UserManage'
}
