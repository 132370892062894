import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/arguments'

const state = {
  argGroups: [],
  activeArgGroup: {},
  argGroupsLoading: false
}


const getters = {
  argGroups: state => {
    return state.argGroups
  },
  activeArgGroup: state => {
    return state.activeArgGroup
  },
  argGroupsLoading: state => {
    return state.argGroupsLoading
  },

}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api().post(url, data).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function put(context, url, data, handler) {
  return api().put(url, data).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response)).catch(error=>handleApiError(error, ''))
}

function handleArgGroupsLoad(context, response) {
  const argGroups = response['data']
  context.state.argGroupLoading = false
  if (argGroups['error']) {
    context.commit('apiError', argGroups['error'])
    logApiService(argGroups, argGroups['error'], EventName.Arg_specs, EventType.argGroupsLoadArgSpecs)
  } else {
    logApiService(argGroups, 'Fetch Arg Groups', EventName.Arg_specs, EventType.argGroupsLoadArgSpecs)
    context.commit('argGroupsLoaded', argGroups)
  }
  return argGroups
}

function handleArgGroupSave(context, response) {
  const  argGroup = response['data']
  if (argGroup['error']) {
    context.commit('apiError', argGroup['error'])
    logApiService(argGroup, argGroup['error'], EventName.Arg_specs, EventType.argGroupSave)
  } else {
    logApiService(argGroup, 'ArgGroup Updated', EventName.Arg_specs, EventType.argGroupUpdated)
    context.commit('argGroupSuccess', argGroup)
  }
  return argGroup
}

function handleArgGroupDelete(context, id, response) {
  const argGroup = response['data']
  if (argGroup['error']) {
    context.commit('apiError', argGroup['error'])
    logApiService(argGroup, argGroup['error'], EventName.Arg_specs, EventType.argGroupDelete)
  } else {
    logApiService(argGroup, 'ArgGroup deleted', EventName.Arg_specs, EventType.argGroupDelete)
    context.commit('argGroupDeleted', id)
  }
}

function handleGetArgGroup(context, response) {
  const argGroup = response['data']
  if (argGroup['error']) {
    context.commit('apiError', argGroup['error'])
    logApiService(argGroup, argGroup['error'], EventName.Arg_specs, EventType.getArgGroup)
  } else {
    context.commit('setActiveArgGroup', argGroup)
    logApiService(argGroup, 'Fetch Arg Group', EventName.Arg_specs, EventType.getArgGroup)
  }
  return argGroup
}

const actions = {
  loadArgGroups(context, id) {
    if (context.state.argGroupLoading) return
    context.state.argGroupLoading = true
    get(context, URL + '/' + id, handleArgGroupsLoad)
  },

  saveArgGroup(context, payload) {
    if (payload.id) {
      return put(context, URL + '/' + payload.id, payload, handleArgGroupSave)
    } else {
      return post(context, URL, payload, handleArgGroupSave)
    }
  },

  deleteArgGroup(context, id) {
    return apiDelete(context, URL, id, handleArgGroupDelete)
  },

  getArgGroup(context, id) {
    return get(context, URL + '/' + id, handleGetArgGroup)
  },
}


const mutations = {
  argGroupsLoaded(state, argGroups) {
    state.argGroups = []
    for (let i = 0; i < argGroups.length; i++) {
      state.argGroups.push(argGroups[i])
    }
  },

  argGroupSuccess(state, argGroup) {
    state.activeArgGroup = argGroup
    for (let i = 0; i < state.argGroups.length; i++) {
      if (state.argGroups[i].id == argGroup.id) {
        state.argGroups.splice(i, 1, argGroup)        
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'ArgGroup Updated'
        })
        return
      }
    }

    logApiService(argGroup, 'ArgGroup Created', EventName.Arg_specs, EventType.argGroupCreated)

    state.argGroups.push(argGroup)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'ArgGroup Created'
    })
  },

  argGroupDeleted(state, id) {
    for (let i = 0; i < state.argGroups.length; i++) {
      if (state.argGroups[i].id == id) {
        state.argGroups.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'ArgGroup deleted'
        })
      }
    }
  },

  clearLoadedArgGroups(state){
    state.argGroupLoading = false
    state.argGroups = []
  },

  setActiveArgGroup(state, argGroup) {
    state.activeArgGroup = argGroup
  },

}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'ArgGroupStore'
}
