<template>
  <v-app>
    <v-overlay :value="dataLoading" style="z-index: 999;">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <Header />
    <v-content class="pt-0">
      <transition :name="transitionName" mode="out-in">
        <router-view v-if="user" :name="user.role"></router-view>
        <router-view v-else></router-view>
      </transition>
      <notifications group="messages" position="top right" animation-type="velocity" />
    </v-content>
    <Footer />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Footer from './components/Footer'
import Header from './components/Header'
import './master.css'

export default {
  name: 'App',
  components: {
    Footer,
    Header
  },
  computed: {
      ...mapGetters(['user', 'dataLoading']),
    },

   data () {
    return {
      showVersion: true,
      version: '0.0.1',
      transitionName: 'fade'
    }
  },
  watch: {
      '$route.path':  {
       handler: function(path) {

       }
      },
    '$route' (to, from) {
      if ( to.path.startsWith("/session/") || to.path.startsWith("/upload/") || to.path.startsWith("/verify/")) {
        this.transitionName =  ''
        // if(this.user != null){
        //   this.$store.commit('logoutUser')
        // }
      } else  {
        this.transitionName = 'fade'

      }
    }
  },
};

</script>

<style>
  .v-application {font-family: Montserrat,sans-serif !important;}

 .transparent-dropzone  {
    background: none !important;
    color: #FFFFFF;    
  }

  .v-text-field--outlined .v-label {
    top: unset !important;
  }
  .vue-dropzone {
  display: flex;
  justify-content: center;
}
.vue-dropzone .dz-details {
  text-align: center!important;
}
.vue-dropzone .dz-preview a.dz-remove {
  margin-left: auto!important;
  left: 33%;
  transform: translateX(-23%);
}
.vue-dropzone .dz-preview .dz-error-message {
  top: 35%;
}
.dz-message {
  min-height: 175px;
  padding-top: 70px;
}

.reduce-row-gaps .v-text-field__details {
  display: none !important;
}

/* width */
::-webkit-scrollbar {
 
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1E1E1E; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #f1f1f1; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

</style>
