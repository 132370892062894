<template>
  <v-row align="center" justify="center" v-if="AuditList">
    <v-dialog v-model="dialog" persistent max-width="900px">
      <v-card>
        <v-card-title>
          <span class="headline">Audit Settings</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div>
                  Please enter information known at this time. See help for more information about each setting.
                </div>
                <span v-if="this.error" class="text-danger">Please fill required fields.</span>
              </v-col>
              <v-col cols="12">
                <v-card height="500px" class="overflow-y-auto">
                 
                    <v-row v-for="(item, i) in AuditList" :key="i">
                      
                        <v-col cols="4" v-if="item.value" class="help-container">
                          <v-icon color="teal"> mdi-check </v-icon>
                          <span>{{ item.argument }}<span v-if="item.required == 'TRUE'" class="text-danger">*</span></span>
                          
                        </v-col>
                        <v-col cols="4"  v-else class="help-container">
                          <v-icon color="error"> mdi-alert-circle </v-icon>
                          <span>   {{ item.argument }}<span v-if="item.required == 'TRUE'" class="text-danger">*</span></span>
                       
                        </v-col>
                        <v-col cols="5">
                          <v-select
                            v-if="item.type === 'bool' && item.choices.length > 0"
                            :items="item.choices"
                            v-model="item.value"
                          ></v-select>
                          <div v-else-if="item.type === 'int'">
                            <v-select
                              v-if="item.type === 'int' && item.choices.length > 0"
                              :items="item.choices"
                              v-model="item.value"
                            ></v-select>
                            <v-text-field
                              v-else
                              label=""
                              outlined
                              type="number"
                              :name="item.argument"
                              v-model="item.value"
                            ></v-text-field>
                          </div>
                          <v-text-field
                            v-else-if="item.type === 'list'"
                            outlined
                            disabled
                            label=""
                            type="text"
                            :name="item.argument"
                            v-model="item.value"
                          ></v-text-field>
                          <div v-else>
                            <v-select
                              v-if="item.type === 'str' && item.choices.length > 0"
                              :items="item.choices"
                              v-model="item.value"
                            ></v-select>
                            <v-text-field
                              v-else
                              outlined
                              label=""
                              type="text"
                              :name="item.argument"
                              v-model="item.value"
                              :rules="item.required == 'TRUE' && [rules.required]"
                            ></v-text-field>
                          </div>
                        </v-col>
                        <v-col cols="1" class="help-container ">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="grey" v-bind="attrs" v-on="on"> mdi-help-circle-outline </v-icon>
                                </template>
                                <span class="tooltip-container">{{ item.help }}</span>
                              </v-tooltip>
                        </v-col>
                        <v-col cols="1" class="help-container cursor-class"  @click.prevent="clearInput(item)">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon color="grey" v-bind="attrs" v-on="on"> mdi-close-circle-outline </v-icon>
                                </template>
                                <span class="tooltip-container">Clear</span>
                              </v-tooltip> 
                        </v-col>
                      </v-row>
           
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="close" :disabled="waiting">Close</v-btn>
          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="blue darken-1" outlined @click="save" :disabled="this.error">
            Create
            <v-progress-circular indeterminate color="primary" v-if="waiting"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'JobFileVendorAuditQuesDialog',
  computed: {
    ...mapGetters(['vonderJsonFile', 'activeAuditJob', 'jobArgSpecs']),
  },
  mounted() {
    this.access_token = JSON.parse(localStorage.getItem('user')).access_token
    this.userValue = this.user
    // this.dropzoneOptions.headers['Authorization'] = 'Bearer ' + this.access_token
  },
  data() {
    return {
      userValue: {},
      isHidden: false,
      dialog: false,
      panel: [0, 1],
      selectBoolValue: ['TRUE', 'FALSE'],
      jobObject: {},
      isUpdate: false,
      waiting: false,
      HelpDataTypeArray: [],
      uploadFileTypeList: {},
      overlay: false,
      error: false,
      AuditList: [],
      rules: {
        required: (value) => {
          this.error = false
          if (!value) {
            this.error = true
          } else {
            this.error = false
          }
          return !!value || 'Required.'
        },
      },
    }
  },
  methods: {
    async show() {
      this.overlay = true
      const ret = await this.$store.dispatch('getJobArgSpecs')
      if (ret) {
        ret.forEach((element) => {
          element.arguments.forEach((ele) => this.HelpDataTypeArray.push(ele))
        })
      }
      if (this.HelpDataTypeArray) {
        this.vonderJsonFile.forEach((ele, i) =>
          this.HelpDataTypeArray.forEach((element) => {
            if (ele.argument == element.argument) {
              ele['help'] = element.help
              ele['type'] = element.type
              ele['choices'] = element.choices
              ele['required'] = element.required
            }
          })
        )
      }
      const results = this.vonderJsonFile.reduce((acc, curr) => {
        const objInAcc = acc.find((o) => o.argument === curr.argument)
        if (objInAcc) {
          let temp = objInAcc.value
          objInAcc.value = []
          objInAcc.value.push(temp)
          objInAcc.value.push(curr.value)
          temp = ''
        } else acc.push(curr)
        return acc
      }, [])

      this.AuditList = results
      this.overlay = false
      this.dialog = true
    },
    close() {
      this.dialog = false
    },
    clearInput(item){
      this.AuditList.forEach((ele) => {
        if(ele.argument == item.argument){
          ele.value = ""
        }
      })
    },
    convertArrayToObject() {
      const initialValue = {}
      return this.AuditList.reduce((obj, item) => {
        return {
          ...obj,
          // [item.argument]: { value: item.value, help: item.help },
          [item.argument]: item.value,
        }
      }, initialValue)
    },
    validateOnSave() {
      this.AuditList.forEach((ele, i) => {
        if (ele.required == 'TRUE' && ele.value == '') {
          this.error = true
        }
      })
    },
    async save() {
      this.validateOnSave()
      if (!this.error) {
        // this.jobObject = { ...this.convertArrayToObject(), ...JSON.parse(this.uploadFileTypeList) }
        this.jobObject = this.convertArrayToObject()

        let payload = {
          job_id: this.activeAuditJob.id,
          file_name: this.activeAuditJob.name + '-setting.json',
          json_data: JSON.stringify(this.jobObject),
          file_type: 'JSON',
        }
        if (this.isUpdate) {
          payload['isUpdate'] = this.isUpdate
        }
        this.waiting = true
        let ret = await this.$store.dispatch('generateJsonFile', payload)
        this.json_data = {}
        this.waiting = false

        if (ret['message']) {
          this.dialog = false
          this.$store.dispatch('loadjobFiles', this.activeAuditJob.id)
        }
      }
      this.error = false
    },
  },
  watch: {},
}
</script>

<style>
.vue-dropzone-multiple {
  display: block !important;
}
.text-danger {
  color: #ff0000;
}
.help-container {
  align-items: center;
    display: flex;
}
.v-tooltip__content{
 max-width: 500px;
}
.cursor-class{
  cursor: pointer;
}
</style>
