const state = {
  uploadLoader: false,
  uploadDialog: false,
  drawer: true,
}

const getters = {
  getUploadLoader: state => state.uploadLoader,
  getUploadDialog: state => state.uploadDialog,
  getDrawer: state => state.drawer,
}

const mutations = {
  setUploadLoader: (state, value) => {
    state.uploadLoader = value
  },

  setUploadDialog: (state, value) => {
    state.uploadDialog = value
  },

  setDrawer: (state, value) => {
    state.drawer = value
  }
}

const actions = {
}

export default {
  state, getters, mutations, actions
}