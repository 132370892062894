import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/job-settings'
const JSONURL = "/job-settings/generate-file"
const ADOPTED_URL = '/job-settings/adopted-files'
const ATTECH_FILES = '/upload-attech-files'
const AUDIT_ATTECH = '/attech-files'
const DELETE_URL = '/audit-attech'
const ADDATTECH_URL = '/add-auditattech'
const GET_TEMPLATE = "/vendor-template"

const state = {
  jobFiles: [],
  jobArgSpecs: [],
  adoptedFiles: [],
  attechFiles: [],
  activeJobFile: {},
  activeJsonFile:{},
  activeAdoptedFile: {},
  jobFilesLoading: false,
  attechFileLoading: false,
  jobFileLoading: false,
  jsonfileLoading:false,
  adoptedFileLoading: false,
  jobArgSpecsLoading: false,
  actionLoading: false,
  actionFiles:[],
  vonderJsonFile:[]
}

const getters = {
  jobFiles: state => {
    return state.jobFiles
  },
  jobArgSpecs: state => {
    return state.jobArgSpecs
  },
  activeJobFile: state => {
    return state.activeJobFile
  },
  activeJsonFile: state =>{
    return state.activeJsonFile
  },
  jobFilesLoading: state => {
    return state.jobFilesLoading
  },
  attechFileLoading: state => {
    return state.attechFileLoading
  },
  jobFileLoading: state => {
    return state.jobFileLoading
  },
  jsonfileLoading: state => {
    return state.jsonfileLoading
  },
  jobArgSpecsLoading: state => {
    return state.jobArgSpecsLoading
  },
  adoptedFileLoading: state => {
    return state.adoptedFileLoading
  },
  adoptedFiles: state => {
    return state.adoptedFiles
  },
  attechFiles: state => {
    return state.attechFiles
  },
  activeAdoptedFile: state => {
    return state.activeAdoptedFile
  },
  actionLoading: state => {
    return state.actionLoading
  },
  actionFiles:state => {
    return state.actionFiles
  },
  vonderJsonFile:state => {
    return state.vonderJsonFile
  }
}

function get(context, url, handler) {
  return api()
    .get(url)
    .then(response => handler(context, response))
    .catch(error => {
      handleApiError(error, '')
      handler(context, error['response'])
    })
}

function post(context, url, data, handler) {
  return api()
    .post(url, data)
    .then(response => handler(context, response))
    .catch(err => {
      handleApiError(err, '')
      handler(context,err['response'])
    })
}

function put(context, url, data, handler) {
  return api()
    .put(url, data)
    .then(response => handler(context, response))
    .catch(error=>handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api()
    .delete(url + '/' + id)
    .then(response => handler(context, id, response))
    .catch(error=>handleApiError(error, ''))
}



function handlejobFilesLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`
  }
  const jobFiles = response['data']
  context.state.jobFilesLoading = false
  if (jobFiles['error']) {
    logApiService(obj, jobFiles['error'], EventName.Job_setting, EventType.jobFilesLoad)
    context.commit('apiError', jobFiles['error'])
  } else {
    logApiService(obj, 'Fetch Job Files', EventName.Job_setting, EventType.jobFilesLoad)
    logApiService(obj, user['error'], EventName.Elections, EventType.electionUserSave)
    context.commit('jobFilesLoaded', jobFiles)
  }
  return jobFiles
}

function handleAdobtedFiles(context, response) {
  const adoptedFiles = response['data']
  context.state.adoptedFileLoading = false
  if (adoptedFiles['error']) {
    context.commit('apiError', adoptedFiles['error'])
  } else {
    context.commit('adoptedFilesLoaded', adoptedFiles)
  }
  return adoptedFiles
}

function handleAttechFiles(context, response) {
  const adoptedFiles = response['data']
  context.state.attechFileLoading = false
  if (adoptedFiles['error']) {
    context.commit('apiError', adoptedFiles['error'])
  } else {
    context.commit('attechFilesLoaded', adoptedFiles)
  }
  return adoptedFiles
}

function handleJobFileSave(context, response) {
  const jobFile = response['data']
  if (jobFile['error']) {
    context.commit('apiError', jobFile['error'])
  } else {
    context.commit('jobFileSuccess', jobFile)
  }
  return jobFile
}


function handleJsonFileSave(context,response){
  const JsonFile = response['data']
  if(JsonFile['error']){
    context.commit('apiError', jobFile['error'])
  }else{
    context.commit('jsonFileSuccess', JsonFile)
  }
  return JsonFile
}
// to get json file according to vonder
function handleVendorJsonQ_A(context,response){
  
  const vonderJsonFile = response['data']
  if(vonderJsonFile['error']){
    context.commit('apiError', vonderJsonFile['error'])
  }else{
    context.commit('vendorSuccess', vonderJsonFile)
  }
  return vonderJsonFile
}


function handleJobattechFileSave(context, response) {
  const jobFile = response['data']
  if (jobFile['error']) {
    context.commit('apiError', jobFile['error'])
  } else {
    context.commit('auditattechSuccess', jobFile)
  }
  return jobFile
}

function handleAddJobattechFileSave(context, response){
  const jobFile = response['data']
  if (jobFile['error']) {
    context.commit('apiError', jobFile['error'])
  } else {
    context.commit('auditaddattechSuccess', jobFile)
  }
  return jobFile
}

function handleJobFileDelete(context, id, response) {
  const jobFile = response['data']
  if (jobFile['error']) {
    context.commit('apiError', jobFile['error'])
  } else {
    context.commit('jobFileDeleted', id)
  }
}
function handleAttechDelete(context, id, response) {
  const jobFile = response['data']
  if (jobFile['error']) {
    context.commit('apiError', jobFile['error'])
  } else {
    context.commit('attechFileDeleted', id)
  }
}

function handleGetJobFile(context, response) {
  const jobFile = response['data']
  context.state.jobFileLoading = false
  if (jobFile['error']) {
    context.commit('apiError', jobFile['error'])
  } else {
    context.commit('setActiveJobFile', jobFile)
  }
  return jobFile
}


function handleGetJsonFile(context,response){
  const jFile = response['data']
  context.state.jsonfileLoading = false;
  if (jFile['error']) {
    context.commit('apiError', jFile['error'])
  } else {
    context.commit('setActiveJsonFile', jFile)
  }
  return jFile
}

function handleGetActionFile(context, response){
  const jobFile = response['data']
  context.state.actionLoading = false
  if (jobFile['error']) {
    context.commit('apiError', jobFile['error'])
  } else {
    context.commit('setActiveActionFile', jobFile)
  }
  return jobFile
}

function handleAdoptedFiles(context, response) {
  const adopted_files = response['data']
  context.state.adoptedFileLoading = false
  if (adopted_files['error']) {
    context.commit('apiError', adopted_files['error'])
  } else {
    context.commit('setActiveAdoptedFiles', adopted_files)
  }
  return adopted_files
}

function handleGetJobArgSpecs(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`
  }
  const jobArgSpecs = response['data']
  context.state.jobArgSpecsLoading = false

  if (jobArgSpecs['error']) {
    logApiService(obj, jobArgSpecs['error'], EventName.Job_setting, EventType.getJobArgSpecs)
    context.commit('apiError', jobArgSpecs['error'])
  } else {
    if (jobArgSpecs['data']) {
      logApiService(obj, jobArgSpecs['data'], EventName.Job_setting, EventType.getJobArgSpecs)
      context.commit('apiError', jobArgSpecs['error'])
    } else {
      logApiService(obj, 'Fetch Get Job ArgSpecs', EventName.Job_setting, EventType.getJobArgSpecs)
      context.commit('setJobArgSpecs', jobArgSpecs)
    }
  }

  return jobArgSpecs
}

const actions = {
  loadjobFiles(context, job_id) {
    if (context.state.jobFilesLoading) {
      return
    }

    context.state.loadjobFiles = []
    context.state.jobFilesLoading = true
    get(context, URL + '/' + job_id, handlejobFilesLoad)
  },

  loadAdoptedFiles(context, job_id) {
    if (context.state.adoptedFileLoading) {
      return
    }
    context.state.adoptedFiles = []
    context.state.adoptedFileLoading = true
    get(context, ADOPTED_URL + '/' + job_id, handleAdobtedFiles)
  },
  loadAuditattechfiles(context, job_id) {
    if (context.state.attechFileLoading) {
      return
    }
    context.state.attechFileLoading = []
    context.state.attechFileLoading = true
    get(context, AUDIT_ATTECH + '/' + job_id, handleAttechFiles)
  },
  uploadattechfile(context, payload) {
    return post(context, ATTECH_FILES, payload, handleJobattechFileSave)
  },
  Auditattechfile(context, payload){
    return post(context, ADDATTECH_URL, payload, handleAddJobattechFileSave)
  },
  generateJsonFile(context,payload){
    if (payload['isUpdate']) {
      return post(context,JSONURL,payload,handleJsonFileSave)
    }else{
      return post(context,JSONURL,payload,handleJsonFileSave)
    }
  },
  generateTemplate(context,payload){
      return post(context,GET_TEMPLATE,payload,handleVendorJsonQ_A)
    
  },
  saveJobFile(context, payload) {
    let jobFileData = {
      job_id: payload['job_id'],
      file_name: payload['file_name'],
      file_type: payload['file_type'],
      settings: JSON.stringify(payload['settings']),
    }
    if(payload['jobfile']){
      jobFileData['jobfile'] = true
    }else{
      jobFileData['jobfile'] = false
    }
    if (payload['is_update']) {
      jobFileData['old_file_name'] = payload['old_file_name']
      if (payload['adopt_all']) {
        jobFileData['adopt_all'] = payload['adopt_all']
      }
      if(payload['deleted_item']){
        jobFileData['deleted_item'] = JSON.stringify(payload['deleted_item'])
      }
      return put(context, URL + '/' + payload.job_id, jobFileData, handleJobFileSave)
    } else {
      return post(context, URL + '/' + payload.job_id, jobFileData, handleJobFileSave)
    }
  },

  deleteJobFile(context, payload) {
    return apiDelete(context, URL + '/' + payload.job_id, payload.file_name, handleJobFileDelete)
  },
  deleteAuditattech(context, payload) {
    return apiDelete(context, DELETE_URL ,payload.id, handleAttechDelete)
  },

  mergeJobFile(context, payload) {
    return alert('Hello')
  },

  getJobFile(context, payload) {
    if (context.state.jobFileLoading) return
    context.state.jobFileLoading = true
    return get(context, URL + '/' + payload.job_id + '/' + payload.file_name, handleGetJobFile)
  },
  getJsonFile(context,payload){
    if (context.state.jobFileLoading) return
    context.state.jsonfileLoading = true
    return get(context, URL + '/get-file/' + payload.job_id + '/' + payload.file_name, handleGetJsonFile)
  },
  getActionFile(context,payload){
    if(context.state.actionLoading) return
    context.state.actionLoading = true
    return get(context, URL + '/stages/' + payload.job_id ,handleGetActionFile)
  },
  getAdoptedJobFile(context, payload) {
    if (context.state.adoptedFileLoading) return
    context.state.adoptedFileLoading = true
    return get(context, ADOPTED_URL + '/' + payload.job_id + '/' + payload.file_name, handleAdoptedFiles)
  },
  getJobArgSpecs(context) {
    if (context.state.jobArgSpecsLoading) return
    context.state.jobArgSpecsLoading = true
    return get(context, URL + '/arg-specs', handleGetJobArgSpecs)
  },
}

const mutations = {
  jobFilesLoaded(state, jobFiles) {
    state.jobFiles = []
    for (let i = 0; i < jobFiles.length; i++) {
      state.jobFiles.push(jobFiles[i])
    }
  },

  adoptedFilesLoaded(state, adoptedFiles) {
    state.adoptedFiles = []
    for (let i = 0; i < adoptedFiles.length; i++) {
      state.adoptedFiles.push(adoptedFiles[i])
    }
  },
  attechFilesLoaded(state, attechFiles) {
    state.attechFiles = []
    for (let i = 0; i < attechFiles.length; i++) {
      state.attechFiles.push(attechFiles[i])
    }
  },

  jobFileSuccess(state, jobFile) {
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'JobFile Saved',
    })
  },
  jsonFileSuccess(state, jobFile) {
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'JsonFile Saved',
    })
  },
  vendorSuccess(state, vonderJsonFile) {
    state.vonderJsonFile = []
    for (let i = 0; i < vonderJsonFile.length; i++) {
      state.vonderJsonFile.push(vonderJsonFile[i])
    }
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Vendor Saved',
    })
  },
  auditattechSuccess(state, jobFile) {
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Upload attech file',
    })
  },
  auditaddattechSuccess(state, jobFile){
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Audit attech add success',
    })
  },
  attechFileDeleted(state, id) {
    for (let i = 0; i < state.attechFiles.length; i++) {
      if (state.attechFiles[i].id == id) {
        state.attechFiles.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Job Attechment deleted',
        })
      }
    }
  },
  jobFileDeleted(state, file_name) {
    for (let i = 0; i < state.jobFiles.length; i++) {
      if (state.jobFiles[i].file_name == file_name) {
        state.jobFiles.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'JobFile deleted',
        })
      }
    }
  },

  setJobArgSpecs(state, jobArgSpecs) {
    state.jobArgSpecs = jobArgSpecs
  },

  clearLoadedjobFiles(state) {
    state.jobFilesLoading = false
    state.jobFiles = []
  },

  setActiveJobFile(state, jobFile) {
    state.activeJobFile = jobFile
  },
  setActiveJsonFile(state,jFile){
    state.activeJsonFile = jFile
  },
  setActiveActionFile(state, jobFile){
    state.actionFiles = jobFile
  },
  setActiveAdoptedFiles(state, adoptedFile) {
    state.activeAdoptedFile = adoptedFile
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  name: 'JobFileStore',
}
