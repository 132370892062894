<template>
  <div>
    <router-view :name="user.role"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  name: 'Home',

  computed: {
      ...mapGetters(['user','getRouteInfo']),
    },

  mounted() {
    if(this.getRouteInfo.to?.name == "Home" && this.user?.subscriptionPlan == 0 && this.user.role.toLowerCase() == "user"){
      // this.$router.push("/pricing");
      this.$router.push("/home");

    }
  },
  
};

</script>
