import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService';
import { EventName, EventType } from '../../../services/LogEvent';

const URL = '/markdown-home';
const state = {
    markdowncontent: {},
    homeContentLoading: false,
    isUpdatemarkdown:false
  }

  function handleMarkdowncontent(context, response) {
    let obj = {
      url: `${response.config.baseURL}${response.config.url}`,
    }
    const markdown = response['data']
    context.state.homeContentLoading = false
    if (markdown['error']) {
      context.commit('apiError', markdown['error'])
      logApiService(obj, markdown['error'], EventName.Home, EventType.markdowncontent)
    } else {
      context.commit('markdowncontentLoaded', markdown)
      logApiService(obj, 'Fetch Markdown content', EventName.Home, EventType.markdowncontent)
    }
    return markdown
  }
  function handleCheckUpdateMarkdown(context, response) {
    let obj = {
      url: `${response.config.baseURL}${response.config.url}`,
    }
    const markdown = response['data']
    if(markdown){
      if (markdown['error']) {
        context.commit('apiError', markdown['error'])
        logApiService(obj, markdown['error'], EventName.Home, EventType.checkUpdateMarkdown)
      } else {
        context.commit('isUpdatemarkdowncontent', markdown)
        logApiService(obj, 'Update Markdown Content', EventName.Home, EventType.checkUpdateMarkdown)
      }
    }
    
    return markdown
  }



  const getters = {
    markdowncontent: state => {
      return state.markdowncontent
    },
    homeContentLoading: state => {
      return state.homeContentLoading
    },
    isUpdatemarkdown: state => {
      return state.isUpdatemarkdown
    },
   
  }
  function get(context, url, handler) {
    return api().get(url).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
  }
  

  const actions = {
    loadmarkdowncontent(context){
      if (context.state.homeContentLoading) return
      context.state.homeContentLoading = true
      get(context, URL, handleMarkdowncontent)
    },
    checkmarkdowncontent(context){
      get(context, URL+'-check', handleCheckUpdateMarkdown)
    },
  }

  const mutations = {
    markdowncontentLoaded(state, markdowncontentaa) {
        state.markdowncontent = markdowncontentaa
    },
    isUpdatemarkdowncontent(state, markdown){
      state.isUpdatemarkdown = markdown
    }
  }

  export default {
    state,
    getters,
    mutations,
    actions,
    name: 'HomeStore'
  }