<template>
  <v-row dense>
    <v-col md="12">
      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details clearable></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="blue-grey darken-1" small @click="editElectionUser({})"> <v-icon left>mdi-plus</v-icon>Invite </v-btn>
        </v-card-title>

        <v-container grid-list-xl fluid>

          <v-data-table
            :headers="headers"
            :items="users"
            :search="search"
            class="elevation-1"
            :loading="electionUserLoading"
            loading-text="Users Loading ..."
            style="width: 100%;"
          >
            <template v-slot:item.name="{ item }">
            <span>{{ item.first_name + ' ' + item.last_name }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
                <div v-if="user.email != item.email">
                  <v-btn icon @click.prevent="editElectionUser(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" icon @click.prevent="deleteElectionUser(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>         
              </template>
          </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>

    <election-user-dialog ref="ElectionUserDialog"></election-user-dialog>

    <v-dialog v-model="electionUserDeleteDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>Are you sure removing {{name}} ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="electionUserDeleteDialog=false">Cancel</v-btn>
          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" outlined @click="deleteElectionConfirm" :disabled="saving">
            Remove
            <v-progress-circular
                    v-if="waiting"
                    :width="3"
                    color="green"
                    indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import {mapGetters} from 'vuex'
  import ElectionUserDialog from './ElectionUserDialog'
  export default {
    name: 'ElectionUsers',

    components: {
      ElectionUserDialog
    },

    computed: {
      ...mapGetters(['elections', 'activeElection', 'electionLoading', 'user', 'electionUsers', 'electionUserLoading']),

    },

    data() {
      return {
        userValue: {},
        isHidden: false,
        electionUserDeleteDialog: false,
        waiting: false,
        id: 0,
        name: '',
        saving: false,
        users: [],
        search: '',
        headers: [
        { text: 'Name', value: 'name', }, 
        { text: 'Email', value: 'email', },
        { text: 'Role', value: 'role' },
        { text: 'Status', value: 'status' },
        { text: 'Member Since', value: 'created_at' },
        { text: 'Actions', value: 'actions' },
    ],
      }
    },

    mounted () {
      this.$store.dispatch('getElectionUsers', this.activeElection.id)
      this.userValue = this.user
    },


    methods: {
      deleteElectionUser(item) {
        this.id = item.id
        this.name = item.name
        this.electionUserDeleteDialog = true
      },

      async deleteElectionConfirm() {
        this.waiting = true
        await this.$store.dispatch('deleteElectionUser', this.id)
        this.electionUserDeleteDialog = false
        this.waiting = false
      },

      editElectionUser(item) {
        this.$store.commit('setActiveElectionUser', item)
        this.$refs.ElectionUserDialog.show(item)
      }
    },
    watch: {
      electionUsers(data){
        this.users = []
        for(let i = 0; i < data.length; i++) {
          const item = {
            id: data[i].id,
            first_name: data[i].user.first_name,
            last_name: data[i].user.last_name,
            email: data[i].user.email,
            role: data[i].role,
            status: data[i].status,
            created_at: data[i].created_at.replace('T', ' ')
          }
          this.users.push(item)
        }
      }
    }

  }
</script>