<template>
  <v-row dense class="reduce-row-gaps">
    <v-dialog v-model="DeleteDialog" max-width="800px" persistent>
      <v-card>
        <v-card-title>Are you sure deleting file ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="DeleteDialog = false">Cancel</v-btn>
          <v-btn outlined color="error" @click="deleteGenrelConfirm" :disabled="saving">
            Delete
            <v-progress-circular v-if="saving" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
            clearable></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn @click="copyurl()" color="blue-grey darken-1"> Copy Link </v-btn>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap>
            <v-data-table :headers="headers" :items="genralUpload" :search="search" class="elevation-1"
              :loading="genralUploadLoading" loading-text="General Upload List Loading ..." style="width: 100%">
              <template v-slot:[`item.actions`]="{ item }">
                <!-- <v-btn icon @click.prevent="PermissionSetData(item)"> <v-icon>mdi-cog</v-icon> </v-btn> -->
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="deleteGenralUpload(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Genral Upload</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  name: 'GenralUpload',

  components: {},

  computed: {
    ...mapGetters(['user', 'genralUpload', 'genralUploadLoaded', 'genralUploadLoading']),
  },

  mounted() {
    this.$store.dispatch('loadAllGenralUpload')
  },

  data() {
    return {
      search: '',
      DeleteDialog: false,
      saving: false,
      deleteId: 0,
      headers: [
        { text: 'File Name', align: 'start', sortable: true, value: 'file_name', width: '10%' },
        { text: 'File Type', align: 'start', sortable: true, value: 'file_type', width: '10%' },
        { text: 'State', align: 'start', sortable: true, value: 'state', width: '10%' },
        { text: 'County', align: 'start', sortable: true, value: 'county', width: '10%' },
        { text: 'Date', align: 'start', sortable: false, value: 'upload_date', width: '10%' },
        { text: 'Status', align: 'start', sortable: false, value: 'status', width: '10%' },
        { text: 'Actions', value: 'actions', width: '10%' },
      ],
    }
  },
  methods: {
    async copyurl() {
      await navigator.clipboard.writeText(location.protocol + '//' + location.host + '/general_upload/')
      Vue.notify({
        group: 'messages',
        type: 'success',
        text: 'copied!',
      })
    },
    async deleteGenralUpload(item) {
      this.deleteId = item.id;
      this.DeleteDialog = true
    },
    async deleteGenrelConfirm() {
      if (this.deleteId > 0) {
        this.saving = true;
        await this.$store.dispatch('deleteGenrelUpload', this.deleteId)
        this.$store.dispatch('loadAllGenralUpload')
        this.DeleteDialog = false;
        this.saving = false;
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Delete file successfully',
        })
      }
    },
  },

  watch: {},
}
</script>

