import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/upload-requests'
const URL_DISCTRICT = '/upload-district-requests'
const COPY_URL = '/upload-requests-copy'
const COPY_URL_DISCTRICT = '/upload-district-requests-copy'
const GENRAL_UPLOAD = '/general-upload'

const state = {
  uploadRequests: [],
  activeUploadRequest: {},
  uploadRequestLoading: false,
  alluploadRequest: [],
  alluploadRequestLoading: false,
  genralUpload:[],
  genralUploadLoading:false
}


const getters = {
  uploadRequests: state => {
    return state.uploadRequests
  },
  activeUploadRequest: state => {
    return state.activeUploadRequest
  },
  uploadRequestLoading: state => {
    return state.uploadRequestLoading
  },
  alluploadRequest: state => {
    return state.alluploadRequest
  },
  alluploadRequestLoading: state => {
    return state.alluploadRequestLoading
  },
  genralUpload:state => {
    return state.genralUpload
  },
  genralUploadLoading:state => {
    return state.genralUploadLoading
  }
}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api().post(url, data).then(response => handler(context, response, data)).catch(error=>handleApiError(error, ''))
}

function put(context, url, data, handler) {
  return api().put(url, data).then(response => handler(context, response, data)).catch(error=>handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response)).catch(error=>handleApiError(error, ''))
}

function handleUploadRequestsLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const uploadRequests = response['data']
  context.state.uploadRequestLoading = false
  if (uploadRequests['error']) {
    context.commit('apiError', uploadRequests['error'])
    logApiService(obj, uploadRequests['error'], EventName.Upload_requests, EventType.uploadRequestsLoad)
  } else {
    logApiService(obj, 'Fetch Upload Requests', EventName.Upload_requests, EventType.uploadRequestsLoad)
    context.commit('uploadRequestsLoaded', uploadRequests)
  }
  return uploadRequests
}

function handleAllUploadRequestsLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const uploadRequests = response['data'];
  context.state.alluploadRequestLoading = false
  if (uploadRequests['error']) {
    context.commit('apiError', uploadRequests['error'])
    logApiService(obj, uploadRequests['error'], EventName.Upload_requests, EventType.allUploadRequestsLoad)
  } else {
    logApiService(obj, 'Fetch All  Upload Requests', EventName.Upload_requests, EventType.allUploadRequestsLoad)
    context.commit('uploadAllRequestsLoaded', uploadRequests)
  }
  return uploadRequests
}

function handleGenralUploadLoad(context, response){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const uploadRequests = response['data'];
  context.state.genralUploadLoading = false
  if (uploadRequests['error']) {
    context.commit('apiError', uploadRequests['error'])
    logApiService(obj, uploadRequests['error'], EventName.Upload_requests, EventType.genralUploadLoad)
  } else {
    logApiService(obj, 'Fetch Genral Upload', EventName.Upload_requests, EventType.genralUploadLoad)
    context.commit('genralUploadLoaded', uploadRequests)
  }
  return uploadRequests
}

function handleUploadRequestSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const uploadRequest = response['data']
  if (uploadRequest['error']) {
    logApiService(obj, uploadRequest['error'], EventName.Upload_requests, EventType.uploadRequest)
    context.commit('apiError', uploadRequest['error'])
  } else {
    logApiService(obj, 'Upload request updated', EventName.Upload_requests, EventType.uploadRequest)
    context.commit('uploadRequestSuccess', uploadRequest)
  }
  return uploadRequest
}

function handleCopyRequestSave(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const uploadRequest = response['data']
  if (uploadRequest['error']) {
    context.commit('apiError', uploadRequest['error'])
    logApiService(obj, uploadRequest['error'], EventName.Upload_requests, EventType.copyRequestSave)
  } else {
    logApiService(obj, 'Request Copy save', EventName.Upload_requests, EventType.copyRequestSave)
    context.commit('CopyRequestSuccess', uploadRequest)
  }
  return uploadRequest
}

function handleUploadRequestDelete(context, id, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: id
  }
  const uploadRequest = response['data']
  if (uploadRequest['error']) {
    context.commit('apiError', uploadRequest['error'])
    logApiService(obj, uploadRequest['error'], EventName.Upload_requests, EventType.uploadRequestDelete)
  } else {
    logApiService(obj, 'Upload Request Deleted', EventName.Upload_requests, EventType.uploadRequestDelete)
    context.commit('uploadRequestDeleted', id)
  }
}

function handleGetUploadRequest(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`
  }
  const uploadRequest = response['data']
  if (uploadRequest['error']) {
    context.commit('apiError', uploadRequest['error'])
    logApiService(obj, uploadRequest['error'], EventName.Upload_requests, EventType.getUploadRequest)
  } else {
    context.commit('setActiveUploadRequest', uploadRequest)
    logApiService(obj, 'Fetch Active Upload Request', EventName.Upload_requests, EventType.getUploadRequest) 
  }
  return uploadRequest
}

const actions = {
  loadUploadRequests(context, election_id) {
    if (context.state.uploadRequestLoading) return
    context.state.uploadRequestLoading = true
    get(context, URL + '/' + election_id, handleUploadRequestsLoad)
  },

  loadUploadRequestsDistrict(context, district_id) {
    if (context.state.uploadRequestLoading) return
    context.state.uploadRequestLoading = true
    get(context, URL_DISCTRICT + '/' + district_id, handleUploadRequestsLoad)
  },

  loadAllUploadRequest(context) {
    if (context.state.alluploadRequestLoading) return
    context.state.alluploadRequestLoading = true
    get(context, URL + '/all', handleAllUploadRequestsLoad)
  },

  loadAllGenralUpload(context){
    if (context.state.genralUploadLoading) return
    context.state.genralUploadLoading = true
    get(context, GENRAL_UPLOAD, handleGenralUploadLoad)
  },

  saveUploadRequest(context, payload) {
    if (payload['id']) {
      return put(context, URL + '/' + payload['id'], payload, handleUploadRequestSave)
    } else {
      return post(context, URL, payload, handleUploadRequestSave)
    }
  },

  saveUploadRequestDistric(context, payload) {
    if (payload['id']) {
      return put(context, URL_DISCTRICT + '/' + payload['id'], payload, handleUploadRequestSave)
    } else {
      return post(context, URL_DISCTRICT, payload, handleUploadRequestSave)
    }
  },

  copyUploadRequest(context, payload) {
    return post(context, COPY_URL, payload, handleCopyRequestSave)
  },

  copyUploadRequestDistrict(context, payload) {
    return post(context, COPY_URL_DISCTRICT, payload, handleCopyRequestSave)
  },

  deleteUploadRequest(context, id) {
    return apiDelete(context, URL, id, handleUploadRequestDelete)
  },


  deleteUploadRequestDistrict(context, id) {
    return apiDelete(context, URL_DISCTRICT, id, handleUploadRequestDelete)
  },

  getUploadRequest(context, id) {
    return get(context, URL + '/' + id, handleGetUploadRequest)
  },
}


const mutations = {
  uploadRequestsLoaded(state, uploadRequests) {
    state.uploadRequests = []
    for (let i = 0; i < uploadRequests.length; i++) {
      state.uploadRequests.push(uploadRequests[i])
    }
  },

  uploadAllRequestsLoaded(state,uploadRequests){
    state.alluploadRequest = []
    for (let i = 0; i < uploadRequests.length; i++) {
      state.alluploadRequest.push(uploadRequests[i])
    }
  },
  genralUploadLoaded(state,genralupload){
    state.genralUpload = []
    for (let i = 0; i < genralupload.length; i++) {
      state.genralUpload.push(genralupload[i])
    }
  },

  uploadRequestSuccess(state, uploadRequest) {
    state.activeUploadRequest = uploadRequest
    for (let i = 0; i < state.uploadRequests.length; i++) {
      if (state.uploadRequests[i].id == uploadRequest.id) {
        state.uploadRequests.splice(i, 1, uploadRequest)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Upload request updated'
        })
        return
      }
    }

    state.uploadRequests.push(uploadRequest)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Upload request sent'
    })
  },

  CopyRequestSuccess(state, uploadRequest) {
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Upload request copy successfully'
    })
  },

  uploadRequestDeleted(state, id) {
    for (let i = 0; i < state.uploadRequests.length; i++) {
      if (state.uploadRequests[i].id == id) {
        state.uploadRequests.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Upload request deleted'
        })
      }
    }
  },

  clearLoadedUploadRequests(state) {
    state.uploadRequestLoading = false
    state.uploadRequests = []
  },

  setActiveUploadRequest(state, uploadRequest) {
    state.activeUploadRequest = uploadRequest
  },

}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'UploadRequestStore'
}
