<template>
  <div class="text-center">
    Your donation is successfully completed!
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AuditsDetail',
  data() {
    return {
      price: null,
    }
  },
  mounted() {
    const id = new URLSearchParams(window.location.search).get('session_id');
    this.$store.dispatch('setSuccess', id)
    setTimeout(() => {
      this.$router.push('/public')
    }, 2000);
  },
}
</script>