var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dash-layout',[(_vm.$route.params.id)?_c('template',{slot:"dash-title"},[_vm._v("Edit Job Settings")]):_c('template',{slot:"dash-title"},[_vm._v("Create Job Settings")]),_c('template',{slot:"dash-body"},[_c('v-card',{attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"mb-6"},[_c('v-spacer'),_c('v-spacer'),(_vm.editAbleRow === null)?_c('v-btn',{attrs:{"color":"blue-grey darken-1"},on:{"click":_vm.addRow}},[_vm._v(" Add ")]):_vm._e()],1),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.itemsWithSno,"loading":"false"},scopedSlots:_vm._u([{key:"item.argument",fn:function(ref){
var item = ref.item;
return [(_vm.editAbleRow != null && _vm.editAbleRow === item.index)?_c('span',[_c('v-text-field',{attrs:{"label":"Argument"},model:{value:(_vm.setting.argument),callback:function ($$v) {_vm.$set(_vm.setting, "argument", $$v)},expression:"setting.argument"}})],1):_c('span',[_vm._v(_vm._s(item.argument))])]}},{key:"item.data_type",fn:function(ref){
var item = ref.item;
return [(_vm.editAbleRow != null && _vm.editAbleRow === item.index)?_c('span',[_c('v-select',{attrs:{"items":_vm.types,"label":"Data Type"},model:{value:(_vm.setting.data_type),callback:function ($$v) {_vm.$set(_vm.setting, "data_type", $$v)},expression:"setting.data_type"}})],1):_c('span',[_vm._v(" "+_vm._s(item.data_type)+" ")])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [(_vm.editAbleRow != null && _vm.editAbleRow === item.index)?_c('span',[_c('v-text-field',{attrs:{"label":"Value"},model:{value:(_vm.setting.value),callback:function ($$v) {_vm.$set(_vm.setting, "value", $$v)},expression:"setting.value"}})],1):_c('span',[_vm._v(_vm._s(item.value))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.editAbleRow === null)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.makeEditAble(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.editAbleRow === null)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteSetting(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),(_vm.editAbleRow != null && _vm.editAbleRow === item.index)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.abortAction(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(_vm.editAbleRow != null && _vm.editAbleRow === item.index)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.saveSettings(item)}}},[_c('v-icon',[_vm._v("mdi-check")])],1):_vm._e()]}}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""}},[_vm._v(" Return ")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }