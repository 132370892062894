<template>
  <dash-layout>
    <template slot="dash-title">Package</template>
    <template slot="dash-body">
      <!-- upload  -->
      <v-dialog v-model="Packagedialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Package Details</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field v-model="packageValue.name" label="Title" :rules="[rules.required]"
                    outlined></v-text-field>
                </v-col>
                <v-col cols="6">
                  <!-- <v-label>Monthly Price:</v-label> -->
                  <v-text-field @keypress="isNumber($event)" v-on:keyup="handleChange()" min="0"
                    v-model="packageValue.monthlyPrice" label="Monthly Price" type="number" :rules="[rules.required]"
                    outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <!-- <v-label>Yearly Price:</v-label> -->
                  <v-text-field @keypress="isNumber($event)" v-on:keyup="handleChange()" min="0"
                    v-model="packageValue.yearlyPrice" label="Yearly Price" :rules="[rules.required]" type="number"
                    outlined>
                  </v-text-field>
                </v-col>
                <v-col cols="12" v-if="isEdit">
                  <v-select :items="statusItems" label="Status" v-model="packageValue.status"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea label="Description" v-model="packageValue.description" rows="5" outlined></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined @click="closeDialog">Close</v-btn>
            <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="blue darken-1" :disabled="!canSave || saving" @click="save()" outlined>
              Add
              <v-progress-circular indeterminate color="primary" v-if="saving"></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="auditPackageDeleteDialog" max-width="600px" persistent>
      <v-card>
        <v-card-title>Are you sure deleting {{packageValue.name}} ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline"  outlined @click="closeDeleteDialog">Cancel</v-btn>
          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="error" outlined @click="save" :disabled="saving">
            Delete
            <v-progress-circular
                    v-if="saving"
                    :width="3"
                    color="green"
                    indeterminate
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-row dense class="reduce-row-gaps">
        <v-col cols="12">
          <v-card max-width="100%">
            <v-card-title class="mb-6">
              <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
                clearable></v-text-field>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" class="ml-1" dark color="blue-grey darken-1" @click.prevent="newPackage">
                  <v-icon left>mdi-plus</v-icon> Add Package</v-btn>
            </v-card-title>
            <v-container grid-list-xl fluid>
              <v-layout row wrap>
                <v-data-table :headers="headers" :items="auditPackages" :search="search" class="elevation-1"
                  :loading="packageLoading" loading-text="Audit Packages Loading ..." style="width: 100%"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, ttprops }">
                        <v-btn v-if="item.users.role == 'Admin'" icon @click.prevent="editAuditPackage(item)" v-bind="ttprops" v-on="on">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit Audit Package</span>
                    </v-tooltip>


                    <!-- <v-btn v-if="item.users.role == 'Admin'" icon @click.prevent="deleteAuditPackage(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn> -->
                  </template>
                </v-data-table>
              </v-layout>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </dash-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import DashLayout from '../../containers/Layout'

const validators = {
  alphabet: /^[A-Za-z\s]+$/,
  email: /\S+@\S+\.\S+/,
  number: /^[\d]+$/,
  phone: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
}

export default {
  name: 'Package',

  components: {
    DashLayout,
  },

  computed: {
    ...mapGetters(['auditPackages', 'packageLoading', 'user']),
    canSave: function(){
        return this.packageValue.name!='' && this.rules.required(this.packageValue.name) &&
          this.packageValue.monthlyPrice != null && this.packageValue.yearlyPrice != null &&
          this.rules.onlyNumber(this.packageValue.monthlyPrice) &&
          this.rules.onlyNumber(this.packageValue.yearlyPrice)
      },
  },

  mounted() {
    this.$store.dispatch('loadAuditPackages')
    this.userValue = this.user
  },

  data() {
    return {
      userValue: {},
      isHidden: false,
      search: '',
      sortBy: 'name',
      sortDesc: true,
      saving: false,
      Packagedialog: false,
      isEdit: false,
      auditPackageDeleteDialog: false,
      statusItems: ['Active', 'Deactive', 'Deleted'],
      packageValue: {
        name: '',
        monthlyPrice: null,
        yearlyPrice: null,
        description: '',
        status: 'Active',
      },
      rules: {
        required: value => !!value || 'Required.',
        integer: value => Number.isInteger(Number(value)) || 'Invalid value.',
        onlyAlphabet: value => validators.alphabet.test(value) || 'Invalid value.',
        emptyAlphabet: value => { if (value && value.length > 0) { return validators.alphabet.test(value) || "Invalid value"; } else { return true } },
        onlyNumber: value => validators.number.test(value) || 'Invalid value.',

      },
      packageId: 0,
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name', width: '10%' },
        { text: 'Description', align: 'start', sortable: true, value: 'description', width: '10%' },
        { text: 'Monthly Price', align: 'start', sortable: true, value: 'monthlyPrice', width: '10%' },
        { text: 'Yearly Price', align: 'start', sortable: true, value: 'yearlyPrice', width: '10%' },
        { text: 'Status', align: 'start', sortable: false, value: 'status', width: '10%' },
        { text: 'Actions', value: 'actions', width: '10%' },
      ],
      selectedPermits: [],
    }
  },
  methods: {
    toggleOrder() {
      this.sortDesc = !this.sortDesc
    },
    nextSort() {
      let index = this.headers.findIndex((h) => h.value === this.sortBy)
      index = (index + 1) % this.headers.length
      this.sortBy = this.headers[index].value
    },
    editAuditPackage(item) {
      this.isEdit = true
      this.packageValue = {
        name: item.name,
        monthlyPrice: item.monthlyPrice,
        yearlyPrice: item.yearlyPrice,
        description: item.description,
        status: item.status,
      },
        this.packageId = item.id,
        this.Packagedialog = true
    },
    deleteAuditPackage(item) {
      this.isEdit = true
      this.packageValue = {
        name: item.name,
        monthlyPrice: item.monthlyPrice,
        yearlyPrice: item.yearlyPrice,
        description: item.description,
        status: 'Deleted',
      },
        this.packageId = item.id,
        this.auditPackageDeleteDialog = true
    },
    handleChange(evt) {
      evt = (evt) ? evt : window.event
      var code = (evt.which) ? evt.which : evt.keyCode
      if (code == 189 || this.monthlyPrice < 0 || this.yearlyPrice < 0) {
        if (this.packageValue.monthlyPrice < 0) {
          this.packageValue.monthlyPrice = 0
        }
        if (this.packageValue.yearlyPrice < 0) {
          this.packageValue.yearlyPrice = 0
        }
        return false
      }
      return true
    },
    resetData() {
      this.packageValue = {
        name: '',
        monthlyPrice: null,
        yearlyPrice: null,
        description: '',
        status: 'Active',
      }
      this.isEdit = false
      this.saving = false
      this.packageId = 0
    },
    newPackage() {
      this.resetData();
      this.Packagedialog = true
    },
    isNumber($evt) {
      if ($evt.keyCode != 45) {
        return true
      } else {
        $evt.preventDefault();
      }
    },
    closeDeleteDialog() {
      this.auditPackageDeleteDialog = false
      this.resetData()
    },
    closeDialog() {
      this.Packagedialog = false
      this.resetData()
    },
    async save() {
      this.saving = true
      let auditPackage = {
        name: this.packageValue.name,
        monthlyPrice: this.packageValue.monthlyPrice,
        yearlyPrice: this.packageValue.yearlyPrice,
        description: this.packageValue.description,
        status: this.packageValue.status
      }
      if (this.packageId > 0) auditPackage['id'] = this.packageId

      const response = await this.$store.dispatch('saveAuditPackage', auditPackage)
      this.saving = false
      this.Packagedialog = false
      if (this.packageId == 0) {
        this.$store.dispatch('loadAuditPackages')
      }
      this.resetData()

    }
  },

  watch: {},
}
</script>
