/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log('Service worker has been registered.',registration)
    },
    cached (registration) {
      console.log('Content has been cached for offline use.')
      if (registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.', registration)
      if (registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    },

    activate (event) {
      console.log('activated event ',event)
     }
  })
}


navigator.serviceWorker.addEventListener('message', (event) => {
  const { type } = event.data
  if (type === 'CACHE_CLEAR') {
    // Clear the cache
    console.log('CACHE_CLEAR', CACHE_CLEAR)

    caches.keys().then((cacheNames) => {
      cacheNames.forEach((cacheName) => {
        console.log('caches', cacheName)

        caches.delete(cacheName)
      })
    })
  }
})