var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[(_vm.selectedFile.file)?_c('v-card-title',[_vm._v("Are you sure removing "+_vm._s(_vm.selectedFile.file.name)+" from list ?")]):_vm._e(),_c('v-card-actions',{staticClass:"pa-8"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.deleteDialog=false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":_vm.removeFile}},[_vm._v("Remove")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"60%","persistent":""},model:{value:(_vm.fileUploadDialog),callback:function ($$v) {_vm.fileUploadDialog=$$v},expression:"fileUploadDialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"mb-2",attrs:{"color":"blue","dark":""}},[_vm._v(" Upload Files for "+_vm._s(_vm.districtData.name)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":""},on:{"click":function($event){return _vm.$refs.files.click()}}},[_c('input',{ref:"files",staticStyle:{"display":"none"},attrs:{"id":"fileSelector","type":"file","multiple":""},on:{"change":_vm.filesSelected}}),_c('span',{staticClass:"mr-2"},[_vm._v("Select Files")])])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.files,"loading":_vm.uploadedFilesLoading},scopedSlots:_vm._u([{key:"item.progress",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"value":item.progress,"height":"25"}},[_c('strong',[_vm._v(_vm._s(Math.ceil(item.progress))+"%")])])]}},{key:"item.byterate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(8 * Math.round(10 * item.byterate/1024/1024)/10)+"Mbps ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status.success)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v("Success")]):(item.status.failed)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v("Failed")]):(item.status.canceled)?_c('v-chip',{staticClass:"ma-2",attrs:{"text-color":"white"}},[_vm._v("Canceled")]):(item.status.uploading)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","text-color":"white"}},[_vm._v("Uploading")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"primary","text-color":"white"}},[_vm._v("Ready")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.status.uploading)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.cancelFileUpload(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):(!item.status.success)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.uploadFile(item)}}},[_c('v-icon',[_vm._v("mdi-upload")])],1):_vm._e(),(!item.status.uploading)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.removeConfirm(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.files.length == 0},on:{"click":_vm.uploadFiles}},[_vm._v("Upload All")]),_c('v-btn',{attrs:{"disabled":_vm.files.length == 0},on:{"click":_vm.clearFiles}},[_vm._v("Remove All")]),_c('v-btn',{on:{"click":function($event){_vm.fileUploadDialog=false}}},[_vm._v("Hide")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }