<template>
  <div>
      <v-card class="mx-auto my-10" max-width="1200">
        <v-card-text>
          <h2>Donation has been canceled.</h2>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Canceled',
  data() {
    return {
      price: null,
    }
  },
  computed: {
    ...mapGetters(['activeProject']),
  },
  async mounted() {
    await this.$store.dispatch('getproject', this.$route.params.id)
    setTimeout(() => {
        this.$router.push(`/projects/${this.activeProject.project.name}`)
    }, 2000);
  },
}
</script>