<template>
  <dash-layout>
    <template slot="dash-title">Audit Jobs</template>

    <template slot="dash-body">
      <v-dialog v-model="auditJobDeleteDialog" max-width="500px" persistent>
        <v-card>
          <v-card-title id="card-title">Are you sure deleting {{ name }} ?</v-card-title>
          <v-card-actions class="pa-8">
            <v-spacer></v-spacer>
            <v-btn class="btn-outline" outlined @click="auditJobDeleteDialog = false">Cancel</v-btn>
            <v-btn v-if="userValue.role == 'Observer'? isHiddenUser : !isHiddenUser" color="error" outlined @click="deleteAuditJobConfirm" :disabled="waiting">
              Delete
              <v-progress-circular v-if="waiting" :width="3" color="green" indeterminate></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <JobPermissionDialog ref="JobPermissionDialog" />
      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
            clearable></v-text-field>
          <v-select v-model="election" :items="elections_data" item-text="name" @change="election_change" return-object
            label="Election" class="ml-2" outlined></v-select>
          <v-spacer></v-spacer>
          <v-btn
          v-if="userValue.role == 'Observer'? isHiddenUser : !isHiddenUser"
            id="newAuditbtn"
            @click="$router.push({ name: 'AuditJobsView', params: { id: parm, electionId: el_id } })"
            color="blue-grey darken-1">
            New Audit Job
          </v-btn>
          <v-btn id="goElectionbtn" @click="HandleGotoElection" class="ml-2" color="blue-grey darken-1">
            Go To Election
          </v-btn>
          <!-- <v-btn
            @click="GenerateJSONinParams()"
            v-if="this.user_role !== 'User'"
            color="blue-grey darken-1"
            class="ml-2"
            >Generate JSON</v-btn
          > -->

          <!--<v-btn @click="RequestPermission()" v-if="this.user_role == 'User'" color="blue-grey darken-1" class="ml-2">Request Job Permission</v-btn>-->
        </v-card-title>
        <v-tabs show-arrows v-model="tab" class="mb-1">
          <v-tab class="v-tab"> JOB List </v-tab>
          <v-tab v-show="user.test_job"> Test JOB List </v-tab>
        </v-tabs>
        <v-container grid-list-xl fluid v-if="activeTab == 0">
          <v-layout row wrap>
            <v-data-table id="datatableAudit" :headers="headers"
              :items="audit_list.filter((item) => item.is_test == 'False')" :search="search" class="elevation-1"
              :loading="auditJobLoading" loading-text="Audit Jobs Loading ..." style="width: 100%">
              
              <!-- Slot for the election name to make it clickable -->
              <template v-slot:[`item.name`]="{ item }">
                <v-btn text @click="editAuditJob(item)" v-bind="ttprops" v-on="on">
                  {{ item.name }}
                </v-btn>
              </template>
              
              <template v-slot:[`item.election.name`]="{ item }">
                <v-span
                  style="border-bottom: 1px solid; cursor: pointer;"
                  @click="$router.push(`/elections/` + item.election_id).catch((err) => {})"
                  >{{ item.election.name }}</v-span
                >
              </template>
              <template v-slot:[`item.location`]="{ item }">
                {{ item.county }}, {{ item.state }}, {{ item.country }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn v-if="item.election.privacy == 'Open' || item.role != 'Observer'" icon
                      @click.prevent="editAuditJob(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-location-enter</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Audit Job</span>
                </v-tooltip>

                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn v-if="item.role == 'Auditor'" icon @click.prevent="deleteAuditJob(item)" v-bind="ttprops"
                      v-on="on">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Audit Job</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
        <v-container grid-list-xl fluid v-if="activeTab == 1">
          <v-layout row wrap>
            <v-data-table :headers="headers" :items="audit_list.filter((item) => item.is_test == 'True')" :search="search"
              class="elevation-1" :loading="auditJobLoading" loading-text="Audit Jobs Loading ..." style="width: 100%">
              <template v-slot:[`item.election.name`]="{ item }">
                <v-span id="electionNameLink" style="border-bottom: 1px solid"
                  @click="$router.push(`/elections/` + item.election_id).catch((err) => { })">{{ item.election.name
                  }}</v-span>
              </template>
              <template v-slot:[`item.location`]="{ item }">
                {{ item.county }}, {{ item.state }}, {{ item.country }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn v-if="item.election.privacy == 'Open' || item.role != 'Observer'" icon
                      @click.prevent="editAuditJob(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-location-enter</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Audit Job</span>
                </v-tooltip>

                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn v-if="item.role == 'Auditor'" icon @click.prevent="deleteAuditJob(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Audit Job</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
      <DisplayJobsJSON ref="DisplayJobsJSON" />

    </template>
  </dash-layout>
</template>

<script>
import DashLayout from '../../containers/Layout'

import { mapGetters, mapActions } from 'vuex'
import JobPermissionDialog from './JobPermissionDialog'
import DisplayJobsJSON from './DisplayJobsJSON'
export default {
  name: 'AuditJobsList',
  components: {
    DashLayout,
    JobPermissionDialog,
    DisplayJobsJSON,
  },
  computed: {
    ...mapGetters(['auditJobs', 'activeAuditJob', 'auditJobLoading', 'user', 'elections']),
    tab: {
      get() {
        return this.activeTab
      },
      set(value) {
        this.activeTab = value
        if (this.activeTab == 0) {
          this.isHidden = true
        } else {
          this.isHidden = false
        }
      },
    },
  },

  async mounted() {
    await this.$store.dispatch('loadAuditJobs')
    await this.$store.dispatch('loadElections', this.user.id)
    this.userValue = this.user
    this.user_role = JSON.parse(localStorage.getItem('user')).role

    await setTimeout(() => {
      this.elections.unshift({ name: 'All Election', value: 0 })
      this.elections_data = this.elections
    }, 1000)

    if (this.$route.params.electionId != undefined) {
      this.el_id = this.$route.params.electionId
      await setTimeout(() => {
        const elc = this.elections.filter((item) => {
          if (item.id == this.$route.params.electionId) {
            return item
          }
        })
        this.election = elc[0]
        this.audit_list = this.auditJobs.filter((item) => item.election_id == this.$route.params.electionId)
      }, 2000)
    } else if (this.$route.params.districtName != undefined) {
      await setTimeout(() => {
        this.audit_list = this.auditJobs.filter(
          (item) => item.election.county.toUpperCase() == this.$route.params.districtName.toUpperCase()
        )
      }, 2000)
    } else {
      await setTimeout(() => {
        this.audit_list = this.auditJobs
      }, 1000)
    }

    if (this.activeTab == 0) {
      this.isHidden = true
    } else {
      this.isHidden = false
    }
  },

  data() {
    return {
      userValue: {},
      isHiddenUser: false,
      id: 0,
      name: '',
      auditJobDeleteDialog: false,
      waiting: false,
      search: '',
      user_role: '',
      election: null,
      elections_data: [],
      activeTab: 0,
      currentUserId: JSON.parse(localStorage.getItem('user'))?.user_id,
      audit_list: [],
      el_id: 0,
      parm: 'new',
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Election', value: 'election.name' },
        { text: 'Status', value: 'status' },
        { text: 'Role', value: 'role' },
        { text: 'Actions', value: 'actions' },
      ],
      tabs: [
        { id: 0, name: 'JOB List' },
        { id: 1, name: 'Test JOB List' },
      ],
    }
  },
  methods: {
    election_change() {
      if (this.election.value == 0) {
        this.audit_list = this.auditJobs
      } else {
        this.audit_list = this.auditJobs.filter((item) => item.election_id == this.election.id)
      }
    },
    deleteAuditJob(auditJob) {
      this.id = auditJob.id
      this.name = auditJob.name
      this.auditJobDeleteDialog = true
    },
    async deleteAuditJobConfirm() {
      this.waiting = true
      await this.$store.dispatch('deleteAuditJob', this.id)
      this.auditJobDeleteDialog = false
      this.waiting = false
    },
    closeDialog() {
      this.auditJobDeleteDialog = false
    },
    editAuditJob(item) {
      //this.$store.dispatch('RefreshStatus', item.id)
      this.$router.push(`/audit-jobs/${item.id}`)
    },
    async RequestPermission() {
      this.$store.commit('setLoading', true)
      const ret = await this.$store.dispatch('loadjobpermission')
      this.$refs.JobPermissionDialog.show({})
      this.$store.commit('setLoading', false)
    },
    GenerateJSONinParams() {
      this.$refs.DisplayJobsJSON.show({ fileName: 'Audit_jobs_setting.json' })
    },
    HandleGotoElection() {
      console.log('this.election', this.election)
      if (this.$route.params.electionId) {
        this.$router.push({ name: 'ElectionView', params: { id: this.$route.params.electionId } }).catch((err) => { })
      } else {
        this.$router.push({ name: 'ElectionList', params: { districtId: this.election?.districtId } }).catch((err) => { })
      }
    },
  },

  watch: {},
}
</script>
