<template>
  <v-row dense class="reduce-row-gaps">
    <v-col cols="12">
      <v-card>
        <v-card-title>Action Specifications</v-card-title>
        <v-card-text>
          <v-row>
          </v-row>
        </v-card-text>

        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import {mapGetters} from 'vuex'

  export default {
    name: 'ActionSpecsEditor',

    components: {

    },

    computed: {

    },

    mounted() {
    },

    data() {
      return {
        overlay: false,
      }
    },
    methods: {   
    },

    watch: {
    }
  }
</script>