<template>
  <v-row dense class="reduce-row-gaps">
    <v-col md="6">
      <v-card>
        <v-card-text class="picker-popup">
          <v-row>
            <!-- <v-col cols="12">
              <v-select
                  v-model="election"
                  :items="elections"
                  item-text="name"
                  return-object
                  :disabled="jobId > 0"
                  label="Project"
                  outlined
              ></v-select>
            </v-col> -->
            <v-col cols="12">
              <!-- <v-select
                v-model="election"
                :items="elections"
                item-text="name"
                return-object
                label="Select election"
                @change="chnageelection"
                outlined
              ></v-select> -->
              <v-autocomplete
                id="electionselect"
                v-model="election"
                :items="elections"
                item-text="name"
                return-object
                 :disabled="jobId > 0"
                label="Select election"
                @change="chnageelection"
                outlined
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-text-field v-model="name" label="Audit Job Name" outlined :readonly="role != 'Auditor'"></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                clearable
                clear-icon="cancel"
                label="Audit Description"
                v-model="description"
                :readonly="role != 'Auditor'"
                outlined
              ></v-textarea>  
            </v-col>
            <v-col cols="12" v-if="jobId == 'new' && user.test_job">
              <v-checkbox class="check_box" label="Test Job" v-model="test_job" outlined></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-7">
          <v-spacer></v-spacer>
          <!--<v-btn class="btn-outline" outlined @click="$router.go(-1)">Cancel</v-btn>-->
          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" id="saveAuditJobBtn" outlined @click="saveAuditJob" :disabled="!canSave">
            {{ jobId > 0 ? 'Update' : 'Create' }}
            <v-progress-circular v-if="saving" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col md="6">
      <v-card>
        <v-card-title class="grey--text"
          >{{ election.name }}
          <v-btn
          id="editElection-btn"
            v-show="election.name"
            icon
            class="float-right"
            @click="$router.push(`/elections/` + election.id).catch((err) => {})"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="picker-popup">
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="election.e_type"
                :items="['Primary', 'General', 'Special']"
                label="Election Type"
                outlined
                :disabled="true"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="election.privacy"
                :items="['Public', 'Open', 'Closed']"
                label="Privacy"
                outlined
                :disabled="true"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="election.date" label="Date" outlined :disabled="true"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="election.country"
                :items="[election.country]"
                item-text="name"
                item-value="code"
                label="Country"
                outlined
                :disabled="true"
              ></v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="election.state" label="State" outlined :disabled="true"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="election.county" label="County" outlined :disabled="true"></v-text-field>
            </v-col>
            <!-- <v-col cols="12">
              <v-textarea
                      clearable
                      clear-icon="cancel"
                      label="Description"
                      rows="6"
                      v-model="election.description"
                      outlined
                      :disabled="true"
              ></v-textarea>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AuditJobOverview',
  props: ['jobId'],

  components: {},

  computed: {
    ...mapGetters(['elections', 'activeAuditJob', 'user']),

    canSave: function () {
      return !(this.name.length == 0 || this.saving)
    },
  },
  async mounted() {
    this.$store.commit('setLoading', true)
    this.$store.commit('setActiveAuditJob', {})
    this.userValue = this.user
    if(this.$route.params?.electionId != undefined){
       await setTimeout(() => {
        const elc = this.elections.filter((item) => {
          if (item.id == this.$route.params.electionId) {
            return item
          }
        })
        this.election = elc[0];
        this.role  = this.election.role;
      }, 1000)
    }
    await this.$store.dispatch('loadElections', this.user.id)
    if (this.jobId > 0) {
      await this.$store.dispatch('getAuditJob', this.jobId)
    }
    this.$store.commit('setLoading', false)
  },

  data() {
    return {
      userValue: {},
      isHidden: false,
      search: '',
      id: 0,
      name: '',
      election: {},
      role: '',
      description: '',
      test_job: false,
      saving: false,
      currentUserId: JSON.parse(localStorage.getItem('user'))?.user_id,
    }
  },
  methods: {
    current_timestamp() {
      const today = new Date()
      const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date} ${time}`
      // this.timestamp = dateTime;
      return dateTime
    },
    chnageelection() {
      console.log("change" ,this.election)
      this.role = this.election.role
      this.name = this.election.name
    },
    async saveAuditJob() {
      this.saving = true
      let auditJob = {
        name: this.name,
        election_id: this.election.id,
        description: this.description,
        users: JSON.stringify([this.currentUserId]),
        lastUpdated: this.current_timestamp(),
        is_test:this.test_job
      }

      if (this.jobId > 0) auditJob['id'] = this.jobId

      const response = await this.$store.dispatch('saveAuditJob', auditJob)
      this.saving = false
      if (response.id > 0) {
        this.$router.push('/audit-jobs/' + response.id).catch(() => {})
      }
    },
  },

  watch: {
    activeAuditJob() {
      if (this.activeAuditJob.id > 0) {
        this.name = this.activeAuditJob.name
        this.election = this.activeAuditJob.election
        this.description = this.activeAuditJob.description
        this.role = this.activeAuditJob.role
      } else {
        // if(this.elections.length > 0){
        //   this.election = this.elections[1]
        //   this.role = this.election.role
        // }
      }
    },
    elections() {
      if (this.activeAuditJob.hasOwnProperty('id')) {
        this.election = this.elections.filter((item) => item.id == this.activeAuditJob.election.id)[0]
      }
    },
    // if (this.activeAuditJob.id > 0) {
    //     this.name = this.activeAuditJob.name
    //     this.election = this.activeAuditJob.election
    //     this.description = this.activeAuditJob.description
    //     this.role = this.activeAuditJob.role
    //   }
    //   else {
    //     if(this.elections.length > 0){
    //       this.election = this.elections[1]
    //       console.log( this.election)
    //       this.role = this.election.role
    //       console.log(this.role);
    //     }
    //   }
    // },
    // elections() {
    //   if(this.activeAuditJob.hasOwnProperty('id')){
    //     this.election = this.elections[0]
    //   }
    // }
  },
}
</script>

<style scoped>
.col .col-12{
  padding: 6px;
}
.check_box{
  margin: 0;
  padding: 0;
}
</style>