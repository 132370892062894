<template>
  <div>
    
    <v-toolbar flat dark class="dash-app-bar" height="100px">

      <v-app-bar-nav-icon @click="drawerToggle()"></v-app-bar-nav-icon>

      <v-toolbar-title class="dash-headline">
        <slot name="dash-title"></slot>        
      </v-toolbar-title>

      

      <slot name="bar-search"></slot>

      <v-spacer></v-spacer>

      <slot name="bar-content"></slot>

      <v-spacer></v-spacer>
      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on"
        >Version: 0.1.6</span>
      </template>
      <pre>{{ logo_string }}</pre>
    </v-tooltip>

    </v-toolbar>

    <slot name="bar-divider"></slot>


    <v-container grid-list-xl fluid px-5 pt-2>     
      <slot name="dash-body"></slot>
    </v-container>
  </div>

</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'AppMainLayout',

    computed: {
      ...mapGetters(['user', 'getRouteInfo', 'getDrawer', 'major_release', 'minor_release', 'logo_string']),
      computeVersion: function() {
        version_string = ''
        if(this.major_release && this.minor_release){
          version_string = "Version: " + this.version_number + '.' + this.major_release + '.' + this.minor_release
        }
        return version_string
      },
    },

    mounted() {
      this.$store.dispatch('getComputeInfo')
    },

    data () {
      return {
        version_number: 0,
      }
    },

    methods: {
      drawerToggle () {
        this.$store.commit('setDrawer', !this.getDrawer)
      }
    },

    watch: {
    }
  }
</script>

<style>
  .dash-app-bar { background: none !important; }
  .dash-headline { font-size: 2.2rem !important; font-weight: bold;}
</style>