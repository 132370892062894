<template>
  <dash-layout>
  
    <template slot="dash-title" v-if="id != 'new'">
       <!-- <v-btn  outlined @click="$router.push(`/audit-jobs`).catch(err => {})">Go To Audits</v-btn > -->
      <span v-if="districtData.name">
        {{ districtData.name }}
        <v-chip
          small
          color="green"
          text-color="white"
          style="margin-bottom: 16px; margin-left: -8px;"
        >
          {{ districtData.role }}
        </v-chip>
      </span>        
      <span v-else>Loading...</span>
    </template>
    <template slot="dash-title" v-else>New District</template>
    <template slot="dash-body">
      <v-tabs show-arrows v-model="tab" class="mb-4">
        <div class="back-btn" style="line-height: 50px;">
        <v-btn icon @click.prevent="backProject()" class="mb-1">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn><span>Back</span>
          </div>
        <v-tab :ripple="false" :ref="'btnElectionProperties'">District Properties</v-tab>
        <v-tab :ripple="false" :ref="'btnElectionProperties'" v-if="id !== 'new'">Contacts</v-tab>
        <v-tab 
          :ripple="false" 
          :ref="'btnUploadedFiles'"
          :disabled="id == 'new'"
          v-show="districtData.role == 'Auditor' || districtData.role == 'Uploader'"
        >File Upload</v-tab>
        <v-tab 
          :ripple="false" 
          :ref="'btnRequestFiles'"
          :disabled="id == 'new'"
        >Upload Requests</v-tab>
        <!-- <v-tab
          :ripple="false" 
          :ref="'btnUsers'"
          :disabled="id == 'new'"
        >Users</v-tab> -->
         <!-- <v-tab :ripple="false" :ref="'gotoaudit'">Election</v-tab> -->
         <v-tab :ripple="false" :ref="'gotoaudit'" @click="$router.push({ name: 'ElectionList', params: { districtId: districtid } })">Election</v-tab>
      </v-tabs>
      <transition name="component-fade" mode="out-in">
        <keep-alive>
          <component v-bind:is="tabs[activeTab].component" :districtId="id"></component>
        </keep-alive>
      </transition>

    </template>
  </dash-layout>

</template>

<script>
  import {mapGetters} from 'vuex'
  import DashLayout from '../../containers/Layout'
  import Overview from './Overview'
  import Request from './UploadRequestFile.vue'
  import FileUploadDistrict from './FileUploadDistrict'
  import  ContentList from './ContactList'
  export default {
    name: 'DisctrictLayout',
    props: ['id'],
    districtid  : "",

    data() {
      return {
        currentTabComponent: '',
        activeTab: 0,
        tabs: [
          { name: 'District Properties', component: 'Overview'},
          { name: 'Contacts', component: 'ContentList'},
          { name: 'File Upload', component: 'FileUploadDistrict'},
          { name: 'Upload Requests', component: 'Request'},
        ]
      }
    },

    mounted() {
      this.districtid =  this.$route.params.id;
      if(this.$route.params.activetab === "Contacts" ){
        this.activeTab = 1
      }
    },


    computed: {
      ...mapGetters(['districts', 'districtData', 'districtLoading', 'user']),
      tab: {
        get () {
          return this.activeTab
        },
        set (value) {
          this.activeTab = value
        }
      }
    },

    components: {
      ContentList,
      DashLayout,
      Overview,
      FileUploadDistrict,
      Request,
    },

    methods: {
      backProject() {
        this.$router.push({ name: 'DistrictsList', params: { districtname: this.districtData.name } }).catch((err) => {})
      }
    },

    watch: {
      districtData(){
      }
    }
  }
</script>
