<template>
  <disctrict-layout :id="id" :activetab="activetab"></disctrict-layout>
</template>

<script>
import { mapGetters } from 'vuex';
  import DisctrictLayout from './DisctrictLayout'

  export default {

    name: 'CreateDistrict',

    props: ['id' , 'activetab'],

    components: {
      DisctrictLayout,
    },
    computed: {
    ...mapGetters(['user']),
  },
    mounted() {
    if (
      this.user.role.toLowerCase() == 'user'
    ) {
      this.$router.push("/districts");
    }
  },

  }
</script>