<template>
  <div>
    <v-row dense>
      <v-col md="8">
        <v-card class="reduce-row-gaps">
          <v-divider></v-divider>
          <v-card-text class="picker-popup">
            <v-row>
              <v-col cols="12" class="g-layout">
                <v-text-field
                  v-if="this.id > 0"
                  v-model="name"
                  label="Election Name"
                  outlined
                  :readonly="editNotAllowed"
                ></v-text-field>
              </v-col>

              <v-col cols="4" class="g-layout">
                <v-select
                  v-model="e_type"
                  :items="['General', 'Primary', 'Presidential Preference Primary', 'Special', 'Municipal']"
                  label="Election Type"
                  outlined
                  :readonly="editNotAllowed || electionId > 0"
                ></v-select>
              </v-col>
              <v-col cols="4" class="g-layout">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      label="Election Date"
                      hint="MM-DD-YYYY format"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      outlined
                      readonly
                      v-if="editNotAllowed || electionId > 0"
                      @blur="date = parseDate(date)"
                    ></v-text-field>
                    <v-text-field
                      v-model="date"
                      label="Election Date"
                      hint="MM-DD-YYYY format"
                      persistent-hint
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      outlined
                      @blur="date = parseDate(date)"
                      v-else
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="4" class="g-layout">
                <v-select
                  v-model="privacy"
                  :items="['Public', 'Open', 'Closed']"
                  label="Privacy"
                  outlined
                  :readonly="editNotAllowed || electionId > 0"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  v-model="district"
                  label="Select District"
                  :items="districts"
                  item-text="name"
                  return-object
                  @change="chnageelection"
                  outlined
                  :readonly="editNotAllowed || electionId > 0"
                  :rules="[rules.required]"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" class="g-layout">
                <v-select
                  v-model="country"
                  :items="countries"
                  item-text="name"
                  item-value="code"
                  label="Election Country"
                  outlined
                  :readonly="editNotAllowed || electionId > 0"
                ></v-select>
              </v-col>
              <v-col cols="6" class="g-layout">
                <v-select
                  v-if="country == 'US'"
                  v-model="US_state"
                  :items="US_states"
                  item-text="name"
                  item-value="value"
                  label="Election State"
                  outlined
                  readonly
                ></v-select>
                <v-text-field
                  v-else
                  v-model="state"
                  label="Election State"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="g-layout">
                <v-select
                  v-if="country == 'US'"
                  v-model="county"
                  :items="jurisdictions"
                  label="Election District"
                  outlined
                  readonly
                ></v-select>
                <v-text-field
                  v-else
                  v-model="county"
                  label="Election District"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="g-layout">
                <v-select
                  v-model="project"
                  :items="projects"
                  item-text="name"
                  return-object
                  label="Select Project"
                  class="ml-2"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12" class="g-layout">
                <v-textarea
                  clearable
                  clear-icon="cancel"
                  label="Description"
                  v-model="description"
                  height="30"
                  outlined
                  :readonly="editNotAllowed"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions v-show="!editNotAllowed" class="pa-8">
            <v-spacer></v-spacer>
            <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" outlined @click="checkElection" :disabled="!canSave">
              {{ electionId > 0 ? 'Update' : 'Create' }}
              <v-progress-circular v-if="saving" :width="3" color="green" indeterminate></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDialog" width="800">
      <v-card>
        <v-card-title class="headline warning"> Please confirm election properties. </v-card-title>

        <v-card-text>
          <v-list subheader two-line flat>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Election Date: {{ this.date }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Election Path: {{ this.electionPath }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="userValue.role == 'Observer'? isHidden : !isHidden"
            color="primary"
            outlined
            @click="saveElection"
          >
            Confirm
          </v-btn>
          <v-btn
            color="primary"
            outlined
            @click="confirmDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
  import {mapGetters} from 'vuex'
  import { AbbrCountries } from './../../services/AbbrCountries.js'
  import { AbbrStates } from './../../services/AbbrStates.js'
  // import { USjurisdictions } from './jurisdictions.js'

  export default {
    name: 'ElectionOverview',
    props: ['electionId', 'selectedDistrict'],    
    components: {

    },

    computed: {
      ...mapGetters(['elections', 'activeElection', 'electionLoading', 'user','projects','districts','jurisdictionsList']),
      canSave: function() {
        if(this.country === 'US') {
          return !(this.county.length == 0 || this.saving || !this.district)
        }
        else {
          return !(this.name.length == 0 || this.state.length == 0 || this.county.length == 0 || this.saving ||  !this.district)
        }
      },
      editNotAllowed: function() {
        return this.electionId > 0 && this.activeElection.role != 'Auditor'
      }
    },

    async mounted() {
      console.log(this.id, 'id')
      this.userValue = this.user

      await this.$store.dispatch('getJurisdiction')   
      await this.$store.dispatch('loadProjects')
      await this.$store.dispatch('loadDistricts', this.user.id)
      if (this.electionId > 0) {
        this.$store.commit('setLoading', true)
        this.$store.commit('setActiveElection', {})
        let ret = await this.$store.dispatch('getElection', this.electionId)       
        this.$store.commit('setLoading', false)
      }
      else {
        this.country = 'US'
        this.jurisdictions = this.jurisdictionsList['CA']
      }
    },

    data() {
      return {
        userValue: {},
        isHidden: false,
        district:this.selectedDistrict,
        search: '',
        confirmDialog: false,
        address: '',
        electionPath: '',
        id: 0,
        name: '',
        e_type: 'General',
        privacy: 'Public',
        country: 'US',
        state: '',
        US_state: '',
        county: '',
        project:'',
        date: new Date().toISOString().substr(0, 10),
        description: '',
        role: 'Oberver',
        dateModal: false,
        saving: false,
        countries: AbbrCountries,
        US_states: AbbrStates,
        jurisdictions: [],
        menu1: false,
        rules: {
          required: value => !!value || 'Required.',
        }
      }
    },
    methods: {
      changeCounty() {
      this.district =  this.districts.filter((item) => item.district_name == this.county)[0]
    },
      chnageelection() {
        this.US_state = this.district.state_name
        this.county = this.district.district_name
        this.jurisdictions = this.jurisdictionsList[this.district.state_name]

    },
      editElection(election) {
        this.id = election.id
        this.name = election.name
        this.e_type = election.e_type
        this.privacy = election.privacy
        this.country = election.country
        this.district = election.district
        this.state = election.state
        this.US_state = election.state
        this.jurisdictions = this.jurisdictionsList[election.state]
        this.county = election.county
        this.date = election.date
        this.description = election.description
        this.role = election.role
        this.project =  this.projects.filter(item => item.id == election.project_ids)[0];
      },
      changeUSState(state) {
        this.jurisdictions = this.jurisdictionsList[state]
      },
      checkElection() {
        
        if(this.electionId > 0) {
          this.saveElection()
        } else {
          this.confirmDialog = true

          if(this.country == 'US') {
            this.electionPath = this.country + '/' + this.US_state + '/' + this.country + '_' + this.US_state + '_' + this.county.replaceAll(" ",'') + '_' + this.e_type + '_'  + this.date.replace(/-/g, "")
          }
          else {
            this.electionPath = this.country + '/' + this.state + '/' + this.country + '_' + this.state + '_' + this.county.replaceAll(" ",'') + '_' + this.e_type + '_'  + this.date.replace(/-/g, "")
          }          
        }
      },
      async saveElection() {
        this.confirmDialog = false
        this.saving = true
        let dateString = this.date.split('-');
        let election = {
          e_type: this.e_type,
          privacy: this.privacy,
          country: this.country,
          county: this.county,
          date: this.date,
          description: this.description,
          project_ids:this.project?.id,
          districtId: this.district.id
        }

        if(this.country == 'US') {
          election.state = this.US_state
          let dateString = this.date.split('-');
          let auditAddress = 's3://us-east-1-audit-engine-jobs/' + this.country + '/' + this.US_state + '/' + this.country + '_' + this.US_state + '_' + this.county.replaceAll(" ",'') + '_' + dateString[0]+dateString[1]+dateString[2] + '/map/'
          election.address = auditAddress
        }
        else {
          election.state = this.state
          let dateString = this.date.split('-');
          let auditAddress = 's3://us-east-1-audit-engine-jobs/' + this.country + '/' + this.state + '/' + this.country + '_' + this.state + '_' + this.county.replaceAll(" ",'') + '_' + dateString[0]+dateString[1]+dateString[2] + '/map/'
          election.address = auditAddress
        }
        
        if(this.id > 0){
          election['name'] = this.name
          election['id'] = this.id
        }else{
          election['name'] = this.country + '_' + election.state + '_' + this.county.replaceAll(" ",'') + '_' + dateString[0]+dateString[1]+dateString[2]
        }
        const response = await this.$store.dispatch('saveElection', election)
        this.saving = false
        
        if(response.id > 0) {
          this.$router.push('/elections/' + response.id)
        }        
      },
      parseDate (date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    },

    watch: {
      activeElection (val) {
        if (Object.keys(val).length > 0) {
          if(val.districtId){
             let district = this.districts.filter(item => item.districtId == election.districtId)
             val.district = district
          }
          this.editElection(val)
        }
      },
      selectedDistrict(newValue) {
        this.district = newValue;
      }
    }
  }
</script>

<style>
.g-layout{
  padding: 0px 10px !important;
}
</style>
