<template>
  <div>
      <v-card class="mx-auto my-10" max-width="1200">
        <v-card-text>
          <h2>Payment has been canceled.</h2>
        </v-card-text>
      </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Canceled',
  data() {
    return {
      price: null,
    }
  },
  mounted() {
    // const id = new URLSearchParams(window.location.search).get('session_id');
    // this.$store.dispatch('setSuccess', id)
    setTimeout(() => {
      if(localStorage.getItem('user')) {
        this.$router.push('/audit-donation')
      } else {
        this.$router.push('/public')
      }
    }, 2000);
  },
}
</script>