var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dash-layout',[_c('template',{slot:"dash-title"},[_vm._v("Districts")]),_c('template',{slot:"dash-body"},[_c('v-card',{attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"mb-6"},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-spacer'),(_vm.userValue.role == 'Observer'? _vm.isHidden : !_vm.isHidden)?_c('v-btn',{attrs:{"color":"blue-grey darken-1","disabled":this.user.role === 'User'},on:{"click":function($event){_vm.$router.push("/districts/new").catch(function (err) {})}}},[_vm._v(" New District ")]):_vm._e()],1),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.dis_data,"search":_vm.search,"loading":_vm.districtLoading,"loading-text":"Districts Data Loading ..."},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""},on:{"click":function($event){return _vm.openDistrict(item)}}},'v-btn',_vm.ttprops,false),_vm.on),[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.district_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.address && (item.address + ','))+" "+_vm._s(item.district_name)+", "+_vm._s(item.state_name)+", "+_vm._s(item.country_name)+" ")]}},{key:"item.district_contacts",fn:function(ref){
var item = ref.item;
return [_c('v-span',{staticStyle:{"border-bottom":"1px solid","cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({name:"DistrictView" , params: {id: item.id ,activetab: 'Contacts'}})}}},[_vm._v(" Contacts ")])]}},{key:"item.district_elections",fn:function(ref){
var item = ref.item;
return [_c('v-span',{staticStyle:{"border-bottom":"1px solid","cursor":"pointer"},on:{"click":function($event){_vm.$router.push({ name: 'ElectionList', params: { districtId: item.id } }).catch(function (err) {})}}},[_vm._v("Elections")])]}},{key:"item.district_audits",fn:function(ref){
var item = ref.item;
return [_c('v-span',{staticStyle:{"border-bottom":"1px solid","cursor":"pointer"},on:{"click":function($event){_vm.$router.push({ name: 'AuditJobsList', params: { districtName: item.district_name } }).catch(function (err) {})}}},[_vm._v(" Audits")])]}},(this.user.role !== 'User')?{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var ttprops = ref.ttprops;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.openDistrict(item)}}},'v-btn',ttprops,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit District")])])]}}:{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_vm._v(" - ")]}}],null,true)})],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }