
import Vue from 'vue'
import api from 'Api'
import axios from 'axios';

import jwtDecode from 'jwt-decode'
import router from '../../../router'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent';
// import { clearCacheOnLogin } from '../../../registerServiceWorker'
const state = {
  user: null,
  loading: false,
  passwordReseting: false,
  userUpdateLoading: false,
}

const getters = {
  user: state => {
    return state.user
  },
  loading: state => {
    return state.loading
  },
  passwordReseting: state => {
    return state.passwordReseting
  },
  getUserUpdateLoading: state => {
    return state.userUpdateLoading
  },
}

function setUserLocalStorage(userData) {
  let user = userData
  let storage_data = JSON.parse(localStorage.getItem('user'))
  user['access_token'] = storage_data['access_token']
  user['refresh_token'] = storage_data['refresh_token']
  user['expires_in'] = storage_data['expires_in']
  localStorage.setItem('user', JSON.stringify(user))
} 

const actions = {
  signUpUserApi(context, payload) {
    const hashPassword = btoa(payload.password);
    const user = {
      'email': payload.username,
      'password': hashPassword,
      'role': payload.role,
      'first_name': payload.first_name,
      'last_name': payload.last_name,
      'phone_number': payload.phone_number,
      'signup_date': new Date()
    }
    let obj = {
      url: `${process.env.VUE_APP_ROOT_API}/auth/sign-up`,
      data : user
    }
    return api(false).post('/auth/sign-up', user)
      .then(response => {
        logApiService(obj, response?.data?.message, EventName.Auth, EventType.SignUp)
        return response
      })
      .catch(error => {
        logApiService(obj, error, EventName.Auth, EventType.SignUp)
        handleApiError(error, user?.email);
        return error.response
      });
  },

  signInUserApi(context, payload) {
    let obj = {
      url: `${process.env.VUE_APP_ROOT_API}/auth/sign-in`,
      data : payload
    }
    const user = payload
    state.loading = true
    return api(false).post('/auth/sign-in', {
      ...payload,
    }).then(function (response) {
      logApiService(obj, 'User login', EventName.Auth, EventType.Login)
      const userData = response['data']
      if (userData['error']) {
        state.loading = false 
        state.loginDisabled = false
        cache.delete()
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
              console.log('Service Worker registered: ', registration);
            })
            .catch(error => {
              console.log('Service Worker registration failed: ', error);
            });
        }
        window.localStorage.reload()
        context.commit('apiError', userData['error'])
      } else {
        userData['redirect'] = user.redirect
        userData['email'] = user.email
        context.dispatch('getUserLogin', userData)
      }
      state.loading = false
      return userData
    })
      .catch(error => {
        handleApiError(error, payload?.email);

        const responseData = error.response
        console.log('error', responseData, error, payload?.email)
        logApiService(obj, responseData.data.error,EventName.Auth, EventType.Login)
        context.commit('apiError', responseData.data.error)
        state.loading = false
        return responseData
      });
  },
  async signOutUserFromApi(context, user) {
    alert('hi')
    context.commit('logoutUser', user)
  },
  resetPassword(context, payload) {
    let obj = {
      url: `${process.env.VUE_APP_ROOT_API}/auth/password-reset`,
      data : payload
    }
    state.passwordReseting = true
    api(false).post('auth/password-reset', {
      'email': payload.email,
      'host': payload.host
    }).then(function (response) {
      state.passwordReseting = false
      const userData = response['data']
      if (userData['error']) {
        logApiService(obj, userData['error'], EventName.Auth, EventType.Resent_Password)
        context.commit('apiError', userData['error'])
      } else {
        logApiService(obj, 'Reset Password', EventName.Auth, EventType.Resent_Password)
        context.commit('resetPasswordSuccess', userData)
      }
    })
      .catch(error => {
        handleApiError(error, payload?.email);
        const responseData = error.response
        logApiService(obj, responseData.data.error, EventName.Auth, EventType.Resent_Password)
        context.commit('apiError', responseData.data.error)
        state.passwordReseting = false
        return responseData
      });
  },
  setNewPassword(context, payload) {
    let obj = {
      url: `${process.env.VUE_APP_ROOT_API}/auth/new-password`,
      data : payload
    }
    state.passwordReseting = true
    api(false).post('auth/new-password', {
      'new_password': btoa(payload.new_password),
      'confirm_password': btoa(payload.confirm_password),
      'forgot_key': payload.forgot_key,
      'user_id': payload.user_id
    }).then(function (response) {
      state.passwordReseting = false
      const userData = response['data']
      if (userData['error']) {
        logApiService(obj, userData['error'], EventName.Auth, EventType.setNewPassword)
        context.commit('apiError', userData['error'])
      } else {
        logApiService(obj, 'New Set Password', EventName.Auth, EventType.setNewPassword)
        context.commit('serNewPasswordSuccess', userData)
      }
    })
      .catch(error => {
        handleApiError(error, '');
        const responseData = error.response
        logApiService(obj, responseData.data.error, EventName.Auth, EventType.setNewPassword)
        context.commit('apiError', responseData.data.error)
        state.passwordReseting = false
        return responseData
      });
  },
  updateProfile(context, profile) {
    if (context.state.userUpdateLoading) return;
    context.state.userUpdateLoading = true
    const profileData = {
      "first_name": profile.first_name,
      "last_name": profile.last_name,
      "email": profile.email,
      "role": profile.role,
      "phone_number": profile.phone_number
    }
    let obj = {
      url: `${process.env.VUE_APP_ROOT_API}/profile`,
      data : profileData
    }
    return api(false).put('/profile', profileData).then(function (response) {
      const userData = response['data']
      context.state.userUpdateLoading = false
      if (userData['error']) {
        logApiService(obj, userData['error'], EventName.Auth, EventType.updateProfile)
        context.commit('apiError', userData['error'])
      } else {
        logApiService(obj, 'Profile Updated', EventName.Auth, EventType.updateProfile)
        context.commit('setUserInStore', userData);
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Profile Updated'
        });
        // context.commit('profileSuccess', userData)
      }
      return response['data']
    }).cache(error => handleApiError(error, ''))
  },
  updatePassword(context, payload) {
    const passwordData = {
      "email": payload.email,
      "current_password": payload.current_password,
      "new_password": payload.new_password,
      "confirm_password": payload.confirm_password,
    }
    let obj = {
      url: `${process.env.VUE_APP_ROOT_API}/profile/password-reset`,
      data : passwordData
    }
    return api(false).post('/profile/password-reset', passwordData).then(function (response) {
      const responseData = response['data']
      if (responseData['error']) {
        logApiService(obj, responseData['error'], EventName.Auth, EventType.updatePassword)
        context.commit('apiError', responseData['error'])
      } else {
        logApiService(obj, 'Password updated!', EventName.Auth, EventType.updatePassword)
        Vue.notify({
          group: 'messages',
          type: 'success',
          duration: 5000,
          text: 'Password updated successfully!'
        })
      }
      return responseData
    })
      .catch(error => {
        handleApiError(error, payload.email);
        const responseData = error.response['error']``
        logApiService(obj, responseData['error'], EventName.Auth, EventType.updatePassword)
        context.commit('apiError', responseData['message'])
        return responseData
      });
  },
  closeAccount(context, payload) {
    const closePayload = {
      "email": state.user.email,
      "active": false
    }
    let obj = {
      url: `${process.env.VUE_APP_ROOT_API}/profile/close-account`,
      data : profileData
    }
    return api(false).post('/profile/close-account', closePayload).then(function (response) {
      const responseData = response['data']
      if (responseData['error']) {
        logApiService(obj, responseData['error'], EventName.Auth, EventType.closeAccount)
        context.commit('apiError', responseData['error'])
        return false;
      } else {
        logApiService(obj, 'Account closed', EventName.Auth, EventType.closeAccount)
        Vue.notify({
          group: 'messages',
          type: 'success',
          duration: 5000,
          text: 'Account closed successfully!'
        })
        return true;
      }
    })
      .catch(error => {
        handleApiError(error, state.user.email);
        const responseData = error.response['error']
        logApiService(obj, responseData['error'], EventName.Auth, EventType.closeAccount)
        context.commit('apiError', responseData['message'])
        return false;
      });
  },
  getUserProfile(context) {
    let obj = {
      url: `${process.env.VUE_APP_ROOT_API}/profile`,
    }
    api(false).get('/profile').then(function (response) {
      const userData = response['data']
       logApiService(obj, 'Fetch User Profile', EventName.Auth, EventType.getUserProfile)
      context.commit('setUserInStore', userData)
    }).catch(error => {
      handleApiError(error, '')
      logApiService(obj, error.response['error'].msg ? error.response['error'].msg : error.response['error'], EventName.Auth, EventType.getUserProfile)
      context.commit('apiError', error.response['error'].msg ? error.response['error'].msg : error.response['error'])
      localStorage.clear();
      router.push('/session/login');
    });
  },
  getUserLogin(context, user) {
  let obj = {
    url: `${process.env.VUE_APP_ROOT_API}/profile`,
    data: user
  }
    const redirect = user.redirect
    delete user.redirect
    delete user.password
    localStorage.setItem('user', JSON.stringify(user))

    api(false).get('/profile').then(function (response) {

      const userData = response['data']
      const token = user['access_token']
      const decoded = jwtDecode(token);
      const expires_at = decoded.exp * 1000;
      localStorage.setItem('expires_at', expires_at)
      var hours = 12; // to clear the localStorage after 1 hour(if someone want to clear after 8hrs simply change hours=8)
      var now = Date.now();
      var date = new Date();
      var expires = (12 * 60 * 60);
      var setupTime = localStorage.getItem('setupTime');
      // if (setupTime == null) {
      //     localStorage.setItem('setupTime', now + expires*1000)
      // } else {
      //     if(setupTime < now) {
      //         localStorage.removeItem('user')
      //         localStorage.removeItem('expires_at')
      //         localStorage.removeItem('setupTime')
      //         localStorage.clear()
      //         router.push('/');
      //         // localStorage.setItem('setupTime', now);
      //     }
      // }
      // window.location = redirect
      // setUserInStore
      logApiService(obj, 'Get User Login', EventName.Auth, EventType.getUserLogin)
      context.commit('setUserInStore', userData);
      // const profileDataupdate = {
      //   "id": userData.id,
      //   "email": userData.email,
      //   "signin_date": new Date()
      // }
      // api(false).put('/profiledate', profileDataupdate).then(function (response) {   
      //   console.log("put-logindate-response",response) 
      // })
      router.push('/home');
      // context.commit('userDataLoaded', userData)
    }).catch(error => {
      handleApiError(error, user?.email)
      logApiService(obj, error.response['error'].msg ? error.response['error'].msg : error.response['error'], EventName.Auth, EventType.getUserLogin)
      context.commit('apiError', error.response['error'].msg ? error.response['error'].msg : error.response['error'])
      localStorage.clear();
      router.push('/session/login');
    });

  },
  getTokenValidity(context, token) {
    const payload = {
      'token': token
    }
    let obj = {
      url: `${process.env.VUE_APP_ROOT_API}/email/verify`,
      data: token
    }
    return api(false).post('/auth/email/verify', payload).then(function (response) {
      const responseData = response['data']
      if (responseData['error']) {
        logApiService(obj, responseData['error'], EventName.Auth, EventType.emailVerified)
        context.commit('apiError', responseData['error'])
      } else {
        logApiService(obj, 'Email verified', EventName.Auth, EventType.emailVerified)
        Vue.notify({
          group: 'messages',
          type: 'success',
          duration: 5000,
          text: 'Email verified'
        })
      }
      return responseData
    })
      .catch(error => {
        const responseData = error.response.data
        logApiService(obj, responseData.error || responseData.message, EventName.Auth, EventType.emailVerified)
        context.commit('apiError', responseData.error || responseData.message)
        return responseData
      });
  },
  resendVerifyEmail(context, payload) {
    let obj = {
      url: `${process.env.VUE_APP_ROOT_API}/auth/email/resend`,
      data: payload
    }
    return api(false).post('/auth/email/resend', payload).then(function (response) {
      const responseData = response['data']
      if (responseData['error']) {
        logApiService(obj, responseData['error'], EventName.Auth, EventType.resendVerifyEmail)
        context.commit('apiError', responseData['error'])
      } else {
        logApiService(obj, 'Verification email sent again!', EventName.Auth, EventType.resendVerifyEmail)
        Vue.notify({
          group: 'messages',
          type: 'success',
          duration: 5000,
          text: 'Verification email sent again!'
        })
      }
      return responseData
    })
      .catch(error => {
        const responseData = error.response.data
        logApiService(obj, responseData.error, EventName.Auth, EventType.resendVerifyEmail)
        context.commit('apiError', responseData.error)
        return responseData
      });
  }
}

const mutations = {
  loginUserSuccess(state, user) {
  },
  setUserInStore(state, user) {
    state.user = user;
    state.loading = false;
  },
  apiError(state, error) {
    Vue.notify({
      group: 'messages',
      type: 'error',
      duration: 5000,
      text: error
    })
  },
  logoutUser(user) {
    console.log(user.user)
    logApiService(user.user, 'User Logged Out', EventName.Auth, EventType.LogOutUser)
    localStorage.removeItem('user')
    localStorage.removeItem('expires_at')
    localStorage.clear()
    state.user = null
  },
  resetPasswordSuccess(state) {
    if (!state.user) {
      router.push('/session/login')
    }
    setTimeout(function () {
      Vue.notify({
        group: 'messages',
        type: 'success',
        text: 'You should receive an email with password reset instructions.'
      })
    }, 1500)
  },
  serNewPasswordSuccess(state) {
    if (!state.user) {
      router.push('/session/login')
    }
    setTimeout(function () {
      Vue.notify({
        group: 'messages',
        type: 'success',
        text: 'Your Password Change Successfully Now You Can Login Here.'
      })
    }, 1500)
  },
  userDataLoaded(state, user) {
    setUserLocalStorage(user)
    setTimeout(function () {
      Vue.notify({
        group: 'messages',
        type: 'success',
        text: 'User Logged In'
      })
    }, 1500)
  },
  profileSuccess(state, user) {
    setUserLocalStorage(user);
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Profile Updated'
    });
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  name: 'AuthAuth'
}
