<template>
  <v-row dense class="reduce-row-gaps">
    <v-dialog v-model="JobDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Assign Job Permission</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="jobPermitted" v-for="(permissionState, i) in permissionStates" :key="i">
                <v-row>
                  <v-col cols="11" class="jobPermitted">
                    <v-text-field label="Job" type="text" v-model="permissionState.job_name" outlined :readonly="waiting"
                      disabled></v-text-field>
                  </v-col>
                  <v-col cols="1" class="jobPermitted">
                    <v-checkbox v-model="permissionState.permission"
                      @click="permittedJob(permissionState.job_id, permissionState.permission)">-</v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="closeDialog">Close</v-btn>
          <v-btn color="blue darken-1" @click="jobpermissionupdate()" outlined>
            {{ !JobrequestLoading ? 'Update' : '' }}
            <v-progress-circular indeterminate color="primary" v-if="JobrequestLoading"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
            clearable></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap>
            <v-data-table :headers="headers" :items="job_perm" :search="search" class="elevation-1"
              :loading="JobrequestLoading" loading-text="Job Permission Request ..." style="width: 100%;">

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="editJobPermission(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Job Permission</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
// import { AbbrCountries } from '../../services/AbbrCountries.js'
// import { AbbrStates } from '../../services/AbbrStates.js'

export default {
  name: 'AdminJobpermissionList',
  components: {},

  computed: {
    ...mapGetters([
      'getUsersList',
      'getUsersLoading',
      'getUserUpdateLoading',
      'auditJobs',
      'auditJobLoading',
      'updateAuditDonationLoading',
      'job_perm',
      'jobpermission',
      'JobrequestLoading'
    ]),
  },

  mounted() {
    //this.$store.dispatch('loadUsers')
    //this.$store.dispatch('loadAuditJobs')
    this.$store.dispatch('loadrequestedjobpermission')
  },

  data() {
    return {
      search: '',
      id: '',
      first_name: '',
      last_name: '',
      flag: '',
      email: '',
      user_id: '',
      job_name: '',
      job_data: null,
      headers: [
        { text: 'ID', value: 'id', width: '10%' },
        { text: 'First Name', align: 'start', sortable: false, value: 'first_name', width: '10%' },
        { text: 'Last Name', align: 'start', sortable: false, value: 'last_name', width: '10%' },
        { text: 'Email', align: 'start', sortable: false, value: 'email', width: '20%' },
        { text: 'Status', align: 'start', sortable: false, value: 'flag', width: '10%' },
        { text: 'Request Jobs', value: 'audit_jobsname', width: '30%' },
        { text: 'Actions', value: 'actions', width: '10%' },
      ],
      selectedPermits: [],
      JobDialog: false,
      job_permission: '',
      waiting: false,
      permissionStates: null
    }
  },
  methods: {
    editJobPermission(item) {
      this.id = item.id
      const data = this.job_perm.filter((innerItem) => {
        return innerItem.id === item.id && innerItem.audit_ids
      })
      this.permissionStates = data[0].audit_ids
      this.JobDialog = true;
    },
    async jobpermissionupdate() {
      let job_list = [];
      $.each(this.permissionStates, function (key, value) {
        if (value.permission == true) {
          job_list.push({ 'accept_permission': value.job_id, 'permission': value.permission })

        }
      })
      if (job_list.length != 0) {
        let payload = {
          'id': this.id,
          'job_permission': JSON.stringify(job_list)
        }
        await this.$store.dispatch('savePermissionRequest', payload)
        this.$store.dispatch('loadrequestedjobpermission')
        this.JobDialog = false
      } else {
        // JobrequestLoading = false
        Vue.notify({
          group: 'messages',
          type: 'error',
          text: 'At least one checkbox checked'
        })
      }
    },
    permittedJob(JobId, permission) {
      for (let i = 0; i < this.permissionStates.length; i++) {
        if (this.permissionStates[i].job_id == JobId) {
          this.permissionStates[i].permission = permission
        }
      }
    },
    closeDialog() {

      this.JobDialog = false;
    }
    /* editAuditSetData(item) {
       this.id = item.id
       this.name = item.name
       this.status = item.status
       this.description = item.description
       this.request_donate = item.request_donate
       this.received_donation = item.received_donation
       this.election_id = item.election_id
       ;(this.auditUserDialog = true), (this.election_id = item.election_id)
     },
     async saveEditedUserData() {
       if (Number(this.request_donate) + Number(this.received_donation) < Number(this.add_recieved_donate)) {
         this.$notify({
           group: 'messages',
           type: 'error',
           text: 'Donation Amount can not be greater than Requested amount',
         })
         // alert('Donation Amount can not be greater than Requested amount');
       } else {
         let payload = {
           id: this.id,
           name: this.name,
           status: this.status,
           description: this.description,
           request_donate: this.request_donate,
           received_donation: this.add_recieved_donate,
           election_id: this.election_id,
         }
         await this.$store.dispatch('AuditDonationUpdate', payload)
         this.$store.dispatch('loadAuditJobs')
         this.auditUserDialog = false
       }
     },
     closeDialog() {
       this.id = ''
       this.name = ''
       this.status = ''
       this.description = ''
       this.request_donate = ''
       this.received_donation = ''
       this.election_id = ''
       this.add_recieved_donate = null
       this.auditUserDialog = false
     },
     setEditData() {},*/
  },

  watch: {},
}
</script>

<style>
.jobPermitted {
  padding: 0 !important;
}
</style>