import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/audit-jobs'
const OPEN_URL = '/get-audit'
const GET_SINGLE_AUDIT_URL = '/get-singleaudit'
const PAYMENT_URL = '/donation-payment'
const PAYMENT_SUCCESS = '/donation-success'
const JOB_PERMISSION_URL = '/job-permission'
const GET_JOB_PERMISSSION_URL = '/getjob-permission'
const PROJECT_PAYMENT_URL = '/project-donation'
const USER_LIST = '/users-list'
const ADD_ROOM_URL = '/add-room'
const GET_MESSAGE = '/get-messages'
const UPDATE_ROOM_USER = '/update-room-user'
const DELETE_ROOM = '/delete-room'
const UPDATE_ROOM = '/update-room'
const UPLOAD_FILE = '/upload-message-files'
const DELETE_MESSAGE_FILE = '/delete-message-files'
const MAREK_SEEN = '/mark-seen-message'
const REPORT_EXPORT = '/report-export'
const LEADER_BOARD = "/leader-board"

const state = {
  auditJobs: [],
  allauditJobs: [],
  jobpermission: [],
  rooms_data: [],
  room_save: [],
  auditJobLoading: false,
  activeAuditJob: {},
  auditActiveJobLoading: false,
  auditroomloading: false,
  createPaymentSessionLoading: false,
  JobrequestLoading: false,
  roomloading: false,
  get_messages: [],
  job_perm: [],
  job_permission: [],
  leader_board:[],
  leaderloading: false,
  auditJobsSetting: {},
  jobsSettingLoading:false
}

const getters = {
  auditJobs: (state) => {
    return state.auditJobs
  },
  allauditJobs: (state) => {
    return state.allauditJobs
  },
  jobpermission: (state) => {
    return state.jobpermission
  },
  job_permission: (state) => {
    return state.job_permission
  },
  job_perm: (state) => {
    return state.job_perm
  },
  activeAuditJob: (state) => {
    return state.activeAuditJob
  },
  auditJobLoading: (state) => {
    return state.auditJobLoading
  },
  auditActiveJobLoading: (state) => {
    return state.auditActiveJobLoading
  },
  createPaymentSessionLoading: (state) => {
    return state.createPaymentSessionLoading
  },
  JobrequestLoading: (state) => {
    return state.JobrequestLoading
  },
  roomloading: (state) => {
    return state.roomloading
  },
  rooms_data: (state) => {
    return state.rooms_data
  },
  room_save: () => {
    return state.room_save
  },
  auditroomloading: () => {
    return state.auditroomloading
  },
  get_messages: () => {
    return state.get_messages
  },
  leader_board: () => {
    return state.leader_board
  },
  leaderloading: () => {
    return state.leaderloading
  },
  auditJobsSetting: () => {
    return state.auditJobsSetting
  },
  jobsSettingLoading: () => {
    return state.jobsSettingLoading
  }
}

function get(context, url, handler) {
  return api()
    .get(url)
    .then((response) => handler(context, response))
    .catch((error) => handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api()
    .post(url, data)
    .then((response) => handler(context, response, data))
    .catch((error) => {
      handler(context, error.response);
      handleApiError(error, '');
    });
}

function put(context, url, data, handler) {
  return api()
    .put(url, data)
    .then((response) => handler(context, response, data))
    .catch((error) => handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api()
    .delete(url + '/' + id)
    .then((response) => handler(context, id, response))
    .catch((error) => handleApiError(error, ''))
}

function createPaymentSession(context, url, data, handler) {
  return api()
    .post(url, data)
    .then((response) => handler(context, response, data))
    .catch((error) => {
      Vue.notify({
        group: 'messages',
        type: 'error',
        duration: 2000,
        text: error?.response?.error ? error?.response?.error : error?.response?.data,
      })
      handleApiError(error, '')
  })
}

function handleAuditJobsLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const auditJobs = response['data']
  context.state.auditJobLoading = false
  if (auditJobs['error']) {
    context.commit('apiError', auditJobs['error'])
    logApiService(obj, auditJobs['error'], EventName.Audit_jobs, EventType.auditJobsLoad)
  } else {
    logApiService(obj, `Fetch Audit Jobs`, EventName.Audit_jobs, EventType.auditJobsLoad)
    context.commit('auditJobsLoaded', auditJobs)
    /*context.commit('allauditjobLoded',auditJobs)*/
  }
  return auditJobs
}

function handleAuditJobsLog(context, response){
  const log = response['data']
  return log;
}

function handleUserLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const users = response['data']
  context.state.roomloading = false
  if (users['error']) {
    context.commit('apiError', users['error'])
    logApiService(obj, users['error'], EventName.Audit_jobs, EventType.userLoad)
  } else {
    logApiService(obj, 'Fetch Room', EventName.Audit_jobs, EventType.userLoad)
    context.commit('roomLoaded', users)
  }
  return users
}

function handleRoommessageLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const message = response['data']
  // context.state.roomloading = false
  if (message['error']) {
    context.commit('apiError', message['error'])
    logApiService(obj, message['error'], EventName.Audit_jobs, EventType.roomMessageLoad)
  } else {
    logApiService(obj, 'Fetch Room Message', EventName.Audit_jobs, EventType.roomMessageLoad)
    context.commit('roomMessages', message)
  }
  return message
}

function handleAuditJobsPermission(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const auditJobper = response['data']
  context.state.auditJobLoading = false
  if (auditJobper['error']) {
    context.commit('apiError', auditJobs['error'])
    logApiService(obj, auditJobs['error'], EventName.Audit_jobs, EventType.auditJobsPermission)
  } else {
    logApiService(obj, 'Fetch Audit Jobs Permission ', EventName.Audit_jobs, EventType.auditJobsPermission)
    context.commit('jobpermissionLoaded', auditJobper)
  }
  return auditJobper
}
function handleAuditJobsPermissionRequest(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const auditJobper = response['data']
  context.state.JobrequestLoading = false
  if (auditJobper['error']) {
    context.commit('apiError', auditJobs['error'])
    logApiService(obj, auditJobs['error'], EventName.Audit_jobs, EventType.auditJobsPermissionRequest)
  } else {
    logApiService(obj, 'Fetch Job Permission', EventName.Audit_jobs, EventType.auditJobsPermissionRequest)
    context.commit('job_permLoaded', auditJobper)
  }
  return auditJobper
}

function handelpermissionSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const job_permission_data = response['data']
  context.state.JobrequestLoading = false
  if (job_permission_data['error']) {
    logApiService(obj, job_permission_data['error'], EventName.Audit_jobs, EventType.permissionSave)
    context.commit('apiError', job_permission_data['error'])
  } else {
    logApiService(obj, 'Job Permission Save', EventName.Audit_jobs, EventType.permissionSave)
    context.commit('JobRequestSave', job_permission_data)
  }
  return job_permission_data
}

function handleAuditJobSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const auditJob = response['data']
  if (auditJob['error']) {
    context.commit('apiError', auditJob['error'])
    logApiService(obj, auditJob['error'], EventName.Audit_jobs, EventType.auditJobSave)
  } else {
    logApiService(obj, 'Audit Job Save', EventName.Audit_jobs, EventType.auditJobSave)
    context.commit('auditJobSuccess', auditJob)
  }
  return auditJob
}

function handleReportExport(context, response, data){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const auditRes = response['data']
  if (auditRes['error']) {
    context.commit('apiError', auditRes['error'])
    logApiService(obj, auditRes['error'], EventName.Audit_jobs, EventType.reportExport)
  } else {
    // context.commit('auditJobSuccess', auditJob)
  }
  return auditRes
}

function handleUpdateRoomSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const room = response['data']
  if (room['error']) {
    context.commit('apiError', room['error'])
    logApiService(obj, room['error'], EventName.Audit_jobs, EventType.updateRoomSave)
  } else {
    context.commit('roomUserSuccess', room)
    logApiService(obj, 'Room Updated', EventName.Audit_jobs, EventType.updateRoomSave)
  }
  return room
}
function handleRoomSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const room = response['data']
  if (room['error']) {
    context.commit('apiError', room['error'])
    logApiService(obj, room['error'], EventName.Audit_jobs, EventType.roomSave)
  } else {
    context.commit('roomSuccess', room)
    logApiService(obj, 'Room Save', EventName.Audit_jobs, EventType.roomSave)
  }
  return room
}
function handleJobsSettingJSONSave(context,  response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const JobsSetting = response['data']
  if (JobsSetting['error']) {
    context.commit('apiError', JobsSetting['error'])
    logApiService(obj, JobsSetting['error'], EventName.Audit_jobs, EventType.jobsSettingJSONSave)
  } else {
    logApiService(obj, 'Job Setting Save', EventName.Audit_jobs, EventType.jobsSettingJSONSave)
    context.commit('JobsSettingSuccess', JobsSetting)
  }
  return JobsSetting
}
function handleUploadFileSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const fileres = response['data']
  if (fileres['error']) {
    context.commit('apiError', fileres['error'])
    logApiService(obj, fileres['error'], EventName.Audit_jobs, EventType.uploadFileSave)
  } else {
    logApiService(obj, 'Upload File Save', EventName.Audit_jobs, EventType.uploadFileSave)
    context.commit('fileuploadSuccess', fileres)
  }
  return fileres
}

function handleDeleteFile(data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const fileres = response['data']
  if (fileres['error']) {
    context.commit('apiError', fileres['error'])
    logApiService(obj, fileres['error'], EventName.Audit_jobs, EventType.deleteFile) 
  }
  logApiService(obj, 'File Delete', EventName.Audit_jobs, EventType.deleteFile) 
  return fileres
}

function handleMarkeSeen(data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const marke = response['data']
  if (marke['error']) {
    context.commit('apiError', marke['error'])
    logApiService(obj, marke['error'], EventName.Audit_jobs, EventType.markeSeen)
  }
  logApiService(obj, 'Marke Seen Save', EventName.Audit_jobs, EventType.markeSeen)
  return marke
}

function handleAuditJobDelete(context, id, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: id
  }
  const auditJob = response['data']
  if (auditJob['error']) {
    context.commit('apiError', auditJob['error'])
    logApiService(obj, auditJob['error'], EventName.Audit_jobs, EventType.auditJobDelete)
  } else {
    logApiService(obj, `AuditJob deleted`, EventName.Audit_jobs, EventType.auditJobDelete)
    context.commit('auditJobDeleted', id)
  }
}

function handleRoomDelete(context, id, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: id
  }
  const room = response['data']
  if (room['error']) {
    context.commit('apiError', room['error'])
    logApiService(obj, room['error'], EventName.Audit_jobs, EventType.roomDelete)
  } else {
    logApiService(obj, 'Room Deleted', EventName.Audit_jobs, EventType.roomDelete)
    context.commit('roomDeleted', id)
  }
}

function handleGetAuditJob(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  context.state.auditActiveJobLoading = false
  const auditJob = response['data']
  if (auditJob['error']) {
    context.commit('apiError', auditJob['error'])
    logApiService(obj, auditJob['error'], EventName.Audit_jobs, EventType.getAuditJob)
  } else {
    context.commit('setActiveAuditJob', auditJob)
    logApiService(obj, 'Fetch Audit Job', EventName.Audit_jobs, EventType.getAuditJob)
  }
  return auditJob
}

function handleGetauditRoom(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  context.state.auditroomloading = false
  const room = response['data']
  if (room['error']) {
    context.commit('apiError', room['error'])
    logApiService(obj, room['error'], EventName.Audit_jobs, EventType.getAuditRoom)
  } else {
    context.commit('setAuditRoom', room)
    logApiService(obj, 'Fetch Audit Room', EventName.Audit_jobs, EventType.getAuditRoom)
  }
  return room
}

function handleLeaderBoard(context, response){
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  context.state.leaderloading = false
  const leaderboard = response['data']
  if (leaderboard['error']) {
    context.commit('apiError', leaderboard['error'])
    logApiService(obj, leaderboard['error'], EventName.Audit_jobs, EventType.leaderBoard)
  } else {
    context.commit('setLeaderBoard', leaderboard)
    logApiService(obj, 'Fetch Leader Board', EventName.Audit_jobs, EventType.leaderBoard)
  }
  return leaderboard
}

function handleGetPermission(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  context.state.auditActiveJobLoading = false
  const job_permission = response['data']
  if (job_permission['error']) {
    context.commit('apiError', job_permission['error'])
    logApiService(obj, job_permission['error'], EventName.Audit_jobs, EventType.getPermission)
  } else {
    //context.commit('setActiveAuditJob', auditJob)
    logApiService(obj, 'Fetch Job Permission', EventName.Audit_jobs, EventType.auditJobsPermission)
    context.commit('jobpermissionLoaded', job_permission)
  }
  return job_permission
}

function handlePaymentSessionCreate(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  context.state.createPaymentSessionLoading = false
  const payment_res = response['data']
  if (payment_res['error']) {
    context.commit('apiError', payment_res['error'])
    logApiService(obj, payment_res['error'], EventName.Audit_jobs, EventType.paymentSessionCreate)
  }
  logApiService(obj, 'Payment Session Created', EventName.Audit_jobs, EventType.paymentSessionCreate)
  return payment_res
}

function handleProjectPaymentSessionCreate(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  context.state.createPaymentSessionLoading = false
  const payment_res = response['data']
  if (payment_res['error']) {
    context.commit('apiError', payment_res['error'])
    logApiService(obj, payment_res['error'], EventName.Audit_jobs, EventType.paymentSessionCreate)
  }
  logApiService(obj, 'Project Payment Session Created', EventName.Audit_jobs, EventType.projectPaymentSessionCreate)
  return payment_res
}

function handleAuditJobsSetting(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`
  }
  context.state.jobsSettingLoading = false
  const jobsSetting = response['data']
  if (jobsSetting['error']) {
    context.commit('apiError', auditJobs['error'])
    logApiService(obj, auditJobs['error'], EventName.Audit_jobs, EventType.auditJobsSetting)
  } else {
    logApiService(obj, 'Fetch Jobs Setting', EventName.Audit_jobs, EventType.auditJobsSetting)
    context.commit('jobsSettingLoad', jobsSetting)
  }
  return jobsSetting
}

const actions = {
  loadAuditJobs(context, id) {
    if (context.state.auditJobLoading) return
    context.state.auditJobLoading = true
    get(context, URL, handleAuditJobsLoad)
  },
  getAutitLog(context,url){
    get(context, url, handleAuditJobsLog)
  },
  loadUserroom(context, id) {
    if (context.state.roomloading) return
    context.state.roomloading = true
    get(context, USER_LIST, handleUserLoad)
  },
  loadroommessage(context, id) {
    // if (context.state.roomloading) return
    // context.state.roomloading = true
    get(context, GET_MESSAGE + '/' + id, handleRoommessageLoad)
  },
  loadOpenAuditJobs(context, id) {
    if (context.state.auditJobLoading) return
    context.state.auditJobLoading = true
    get(context, OPEN_URL, handleAuditJobsLoad)
  },
  loadjobpermission(context, id) {
    if (context.state.auditJobLoading) return
    context.state.auditJobLoading = true
    get(context, JOB_PERMISSION_URL, handleAuditJobsPermission)
  },
  createAndFetchJSON(context,name){
    if (context.state.jobsSettingLoading) return
    context.state.jobsSettingLoading = true
    get(context, "/job-settings/get-file/" + name, handleAuditJobsSetting)

    // "/job-settings/get-file",
    // ,"/job-settings/generate-setting-file
  },
  saveJobsSettingJSON(context, payload) {
    return post(context, '/job-settings/generate-setting-file', payload, handleJobsSettingJSONSave)
  },
  loadrequestedjobpermission(context, id) {
    if (context.state.JobrequestLoading) return
    context.state.JobrequestLoading = true
    get(context, GET_JOB_PERMISSSION_URL, handleAuditJobsPermissionRequest)
  },
  addroom(context, payload) {
    return post(context, ADD_ROOM_URL, payload, handleRoomSave)
  },
  uploadmessagefile(context, payload) {
    return post(context, UPLOAD_FILE, payload, handleUploadFileSave)
  },
  deletemessagefile(context, payload) {
    return post(context, DELETE_MESSAGE_FILE, payload, handleDeleteFile)
  },
  markmessageseen(context, payload) {
    return post(context, MAREK_SEEN, payload, handleMarkeSeen)
  },
  saveAuditJob(context, payload) {
    let auditJobData = {
      name: payload['name'],
      description: payload['description'],
      election_id: payload['election_id'],
    }
    if (payload['id']) {
      auditJobData['id'] = payload['id']
      return put(context, URL + '/' + payload['id'], auditJobData, handleAuditJobSave)
    } else {
      auditJobData['users'] = payload['users']
      auditJobData['lastUpdated'] = payload['lastUpdated']
      auditJobData['is_test'] = payload['is_test']
      return post(context, URL, auditJobData, handleAuditJobSave)
    }
  },
  reportexport(context,payload){
    return post(context, REPORT_EXPORT, payload, handleReportExport)
  },
  updateroomuser(context, payload) {
    let request = {
      users: payload['users'],
    }
    return put(context, UPDATE_ROOM_USER + '/' + payload['id'], request, handleUpdateRoomSave)
  },
  updateroom(context, payload) {
    let request = {
      lastUpdated: payload['lastUpdated'],
    }
    if (payload['name']) {
      request['name'] = payload['name']
    }
    return put(context, UPDATE_ROOM + '/' + payload['id'], request, handleUpdateRoomSave)
  },
  savePermissionRequest(context, payload) {
    if (context.state.JobrequestLoading) return
    context.state.JobrequestLoading = true
    if (payload['id']) {
      let jobpermission = {
        id: payload['id'],
        job_permission: payload['job_permission'],
      }
      return put(context, JOB_PERMISSION_URL + '/' + payload['id'], jobpermission, handelpermissionSave)
    } else {
      let jobpermission = {
        job_ids: payload['job_ids'],
      }
      return post(context, JOB_PERMISSION_URL, jobpermission, handelpermissionSave)
    }
  },
  deleteAuditJob(context, id) {
    return apiDelete(context, URL, id, handleAuditJobDelete)
  },
  deleteRoom(context, id) {
    return apiDelete(context, DELETE_ROOM, id, handleRoomDelete)
  },
  getjobroom(context, id) {
    if (context.state.auditroomloading) return
    context.state.auditroomloading = true
    return get(context, ADD_ROOM_URL + '/' + id, handleGetauditRoom)
  },
  getLeaderReport(context, id){
    if (context.state.leaderloading) return
    context.state.leaderloading = true
    return get(context, LEADER_BOARD, handleLeaderBoard)
  },
  getjobpermission(context, id) {
    return get(context, JOB_PERMISSION_URL + '/' + id, handleGetPermission)
  },
  getAuditJob(context, id) {
    return get(context, URL + '/' + id, handleGetAuditJob)
  },
  setAuditJob(context, id) {
    if (context.state.auditActiveJobLoading) return
    context.state.auditActiveJobLoading = true
    return get(context, GET_SINGLE_AUDIT_URL + '/' + id, handleGetAuditJob)
  },
  setSuccess(context, id) {
    if (context.state.auditActiveJobLoading) return
    context.state.auditActiveJobLoading = true
    return get(context, '/payment-success/' + id, handleGetAuditJob)
  },
  genratePaymentSession(context, data) {
    if (context.state.createPaymentSessionLoading) return
    context.state.createPaymentSessionLoading = true
    return createPaymentSession(context, PAYMENT_URL, data, handlePaymentSessionCreate)
  },
  genrateProjectPaymentSession(context, data) {
    if (context.state.createPaymentSessionLoading) return
    context.state.createPaymentSessionLoading = true
    return createPaymentSession(context, PROJECT_PAYMENT_URL, data, handleProjectPaymentSessionCreate)
  },
}

const mutations = {
  auditJobsLoaded(state, auditJobs) {
    state.auditJobs = []
    for (let i = 0; i < auditJobs.length; i++) {
      state.auditJobs.push(auditJobs[i])
    }
  },
  roomLoaded(state, users) {
    state.rooms_data = []
    for (let i = 0; i < users.length; i++) {
      state.rooms_data.push(users[i])
    }
  },
  roomMessages(state, message) {
    state.get_messages = message
    // for (let i = 0; i < message.length; i++) {
    //   state.get_messages.push(message[i])
    // }
  },
  setAuditRoom(state, room) {
    state.room_save = []
    state.room_save.push(room)
  },
  setLeaderBoard(state,leaderboard){
    state.leader_board = []
    for (const data in leaderboard) {
      state.leader_board.push(leaderboard[data])
    }
  },
  allauditjobLoded(state, auditJobs) {
    state.allauditJobs = auditJobs
  },
  job_permLoaded(state, auditJobper) {
    state.job_perm = auditJobper
  },
  jobpermissionLoaded(state, auditJobper) {
    state.jobpermission = auditJobper
  },
  auditJobSuccess(state, auditJob) {
    state.activeAuditJob = auditJob
    for (let i = 0; i < state.auditJobs.length; i++) {
      if (state.auditJobs[i].id == auditJob.id) {
        state.auditJobs.splice(i, 1, auditJob)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'AuditJob Updated',
        })
        return
      }
    }
    state.auditJobs.push(auditJob)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'AuditJob Created',
    })
  },
  roomSuccess(state, room) {
    state.room_save = []
    state.room_save.push(room)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Room Created',
    })
  },
  JobsSettingSuccess(state, JobsSetting) {
    state.auditJobsSetting = JobsSetting
    // state.auditJobsSetting.push(room)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'JSON file Updated.',
    })
  },
  fileuploadSuccess(state, fileres) {
    for (let i = 0; i < state.get_messages.length; i++) {
      if (state.get_messages[i].id == fileres.id) {
        state.get_messages.splice(i, 1, fileres)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'File upload successfully',
        })
        return
      }
    }
    state.get_messages.push(fileres)
  },
  roomUserSuccess(state, room) {
    state.room_save = []
    state.room_save.push(room)
  },
  roomDeleted(state, id) {
    for (let i = 0; i < state.room_save.length; i++) {
      if (state.room_save[i].id == id) {
        state.room_save.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Delete room successfully',
        })
      }
    }
  },
  JobRequestSave(state, job_permission) {
    state.jobpermission = job_permission
    for (let i = 0; i < state.job_permission.length; i++) {
      if (state.job_permission[i].id == job_permission.id) {
        state.job_permission.splice(i, 1, job_permission)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Job permission update successfully',
        })
        return
      }
    }
    state.job_permission.push(job_permission)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Send request to acess a job successfully',
    })
  },
  auditJobDeleted(state, id) {
    for (let i = 0; i < state.auditJobs.length; i++) {
      if (state.auditJobs[i].id == id) {
        state.auditJobs.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'AuditJob deleted',
        })
      }
    }
  },

  clearLoadedAuditJobs(state) {
    state.auditJobLoading = false
    state.auditJobs = []
  },

  setActiveAuditJob(state, auditJob) {
    state.activeAuditJob = auditJob
  },
  jobsSettingLoad(state, jobsSetting) {
    state.auditJobsSetting = jobsSetting
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  name: 'AuditJobStore',
}
