<template>
  <div>
    <v-dialog v-model="fileDeleteDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>Delete {{ selectedFile.file_name }} ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="fileDeleteDialog = false">Cancel</v-btn>
          <v-btn outlined color="error" @click="confirmDelete" :disabled="waiting">
            Ok
            <v-progress-circular v-if="waiting" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <UploadFilesDialog ref="UploadFilesDialog" />

    <v-card max-width="100%" class="mb-2">
      <v-card-title class="mb-6">
        Direct Uploads
        <v-spacer></v-spacer>
        <v-btn v-if="user && user.role !== 'Observer'" @click="$refs.UploadFilesDialog.show()" dark color="blue-grey darken-1" class="ml-2">
          <v-icon left>mdi-upload</v-icon> Upload Files
        </v-btn>
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="refreshUploadFiles" dark color="blue-grey darken-1" class="ml-2" v-bind="attrs" v-on="on">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh Upload Files</span>
        </v-tooltip>
      </v-card-title>

      <v-container fluid>
        <v-layout row wrap>
          <v-data-table :headers="headers" :items="files" :search="search" class="elevation-1"
            :loading="uploadFilesLoading" style="width: 100%;">
            <template v-slot:item.status="{ item }">
              <v-chip :color="getStatusColor(item.status)" dark>{{ item.status }}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="deleteFile(item)" v-bind="attrs" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete File</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-layout>
      </v-container>
    </v-card>

    <v-card max-width="100%">
      <v-card-title class="mb-6">
        Requested Uploads
        <v-spacer></v-spacer>
        <v-tooltip top color="primary">
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="refreshRequestedFiles" dark color="blue-grey darken-1" class="ml-2" v-bind="attrs" v-on="on">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh Requested Files</span>
        </v-tooltip>
      </v-card-title>

      <v-container fluid>
        <v-layout row wrap>
          <v-data-table :headers="headers" :items="requested_files" :search="search" class="elevation-1"
            :loading="requestedFilesLoading" style="width: 100%;">
            <template v-slot:item.status="{ item }">
              <v-chip :color="getStatusColor(item.status)" dark>{{ item.status }}</v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="deleteFile(item)" v-bind="attrs" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete File</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-layout>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DashLayout from '../../containers/Layout';
import UploadFilesDialog from './UploadFilesDialog';

export default {
  name: 'FileUploadContainer',
  components: {
    DashLayout,
    UploadFilesDialog,
  },
  data() {
    return {
      search: '',
      files: [],
      requested_files: [],
      headers: [
        { text: 'Name', value: 'file_name' },
        { text: 'Size', value: 'size' },
        { text: 'Started', value: 'start' },
        { text: 'Completed', value: 'end' },
        { text: 'Status', value: 'status' },
        { text: 'Uploaded By', value: 'uploaded_by' },
        { text: 'Actions', value: 'actions' },
      ],
      selectedFile: {},
      fileDeleteDialog: false,
      waiting: false,
    };
  },
  computed: {
    ...mapGetters(['uploadFiles', 'requestedFiles', 'activeElection', 'uploadFilesLoading', 'requestedFilesLoading', 'user'])
  },
  mounted() {
    if (this.activeElection && this.activeElection.id) {
      this.loadUploadFiles(this.activeElection.id);
      this.loadRequestedFiles(this.activeElection.id);
    }
  },
  methods: {
    ...mapActions(['loadUploadFiles', 'loadRequestedFiles', 'DeleteUploadFile', 'DeleteRequestedFile']),
    getStatusColor(status) {
      if (status === 'Success') return 'light-green accent-3';
      if (status === 'Error' || status === 'Failed') return 'red darken-1';
      return 'orange darken-1';
    },
    deleteFile(item) {
      this.selectedFile = item;
      this.fileDeleteDialog = true;
    },
    async confirmDelete() {
      this.waiting = true;
      if (this.selectedFile.category === 'requested') {
        await this.DeleteRequestedFile(this.selectedFile.id);
      } else {
        await this.DeleteUploadFile(this.selectedFile.id);
      }
      this.waiting = false;
      this.fileDeleteDialog = false;
    },
    refreshUploadFiles() {
      if (this.activeElection && this.activeElection.id) {
        this.loadUploadFiles(this.activeElection.id);
      }
    },
    refreshRequestedFiles() {
      if (this.activeElection && this.activeElection.id) {
        this.loadRequestedFiles(this.activeElection.id);
      }
    },
    formatSize(size) {
      if (size >= 1024 * 1024 * 1024) {
        return (size / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
      } else if (size >= 1024 * 1024) {
        return (size / (1024 * 1024)).toFixed(2) + 'MB';
      } else if (size >= 1024) {
        return (size / 1024).toFixed(2) + 'KB';
      } else {
        return size.toFixed(2) + 'B';
      }
    },
  },
  watch: {
    uploadFiles(newFiles) {
      this.files = newFiles.map(file => ({
        ...file,
        category: 'direct',
        start: file.created_at.replace('T', ' '),
        end: file.status !== 'Uploading' ? file.updated_at.replace('T', ' ') : 'N/A',
        size: this.formatSize(file.size),
      }));
    },
    requestedFiles(newRequestedFiles) {
      this.requested_files = newRequestedFiles.map(file => ({
        ...file,
        category: 'requested',
        start: file.created_at.replace('T', ' '),
        end: file.status !== 'Uploading' ? file.updated_at.replace('T', ' ') : 'N/A',
        size: this.formatSize(file.size),
        uploaded_by: file.request?.name || '',
      }));
    },
  },
};
</script>
