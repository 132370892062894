var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"mb-6"},[_c('v-text-field',{attrs:{"outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-spacer'),(_vm.userValue.role == 'Observer'? _vm.isHidden : !_vm.isHidden)?_c('v-btn',{attrs:{"color":"blue-grey darken-1"},on:{"click":function($event){$event.preventDefault();return _vm.addContact.apply(null, arguments)}}},[_vm._v(" New District Contact ")]):_vm._e()],1),_c('v-container',{attrs:{"grid-list-xl":"","fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.dis_data,"search":_vm.search,"loading":_vm.districtLoading,"loading-text":"Districts Data Loading ..."},scopedSlots:_vm._u([{key:"item.district_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.district_name)+", "+_vm._s(item.state_name)+", "+_vm._s(item.country_name)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var ttprops = ref.ttprops;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.onEditClick(item)}}},'v-btn',ttprops,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Election")])]),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var ttprops = ref.ttprops;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteElection(item)}}},'v-btn',ttprops,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete Election")])])]}}],null,true)})],1)],1)],1),_c('ContactOverview',{ref:"ContactOverview"}),_c('v-dialog',{attrs:{"max-width":"800px","persistent":""},model:{value:(_vm.districtDeleteDialog),callback:function ($$v) {_vm.districtDeleteDialog=$$v},expression:"districtDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Do you sure to delete this contact information?")]),_c('v-card-actions',{staticClass:"pa-8"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function () { return (_vm.districtDeleteDialog = false); }}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"outlined":"","color":"error","disabled":_vm.saving},on:{"click":_vm.districtDeleteConfirm}},[_vm._v(" Delete "),(_vm.saving)?_c('v-progress-circular',{attrs:{"width":3,"color":"green","indeterminate":""}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }