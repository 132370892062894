<template>
  <project-layout :id="id"></project-layout>
</template>

<script>
  import ProjectLayout from './ProjectLayout.vue'

  export default {

    name: 'CreateProject',

    props: ['id'],

    components: {
      ProjectLayout,
    },

    mounted() {
    }

  }
</script>