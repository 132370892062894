<template>
  <v-card max-width="100%">
    <v-dialog v-model="jobFileCreateErrorDialog" max-width="520px" persistent>
      <v-card>
        <v-card-title>Job file already exists. Please edit existing file or try again after deleting the
          file.</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="jobFileCreateErrorDialog = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="jobSettingsDeleteDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>Are you sure deleting {{ file_name }} ?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="jobSettingsDeleteDialog = false">Cancel</v-btn>
          <v-btn color="error" outlined @click="deleteFileConfirm" :disabled="waiting">
            Delete
            <v-progress-circular v-if="waiting" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <job-file-upload-dialog ref="JobFileUploadDialog" />
    <AdoptedJobSettingDialog ref="AdoptedJobSettingDialog" />
    <v-card-title class="mb-6">
      <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
        clearable></v-text-field>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>

        <v-data-table :headers="headers" :items="files" :search="search" class="elevation-1" :loading="adoptedFileLoading"
          loading-text="Loading Job Files ..." style="width: 100%;">
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, ttprops }">
                <v-btn v-if="item.type == 'JOB'" icon @click.prevent="showSettingsDialog(item)" v-bind="ttprops"
                  v-on="on">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-else icon :disabled="true">
                  <v-icon>mdi-none</v-icon>
                </v-btn>
              </template>
              <span>Show Settings Dialog</span>
            </v-tooltip>

            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, ttprops }">
                <v-btn icon @click.prevent="downloadFile(item)" v-bind="ttprops" v-on="on">
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
              <span>Download File</span>
            </v-tooltip>

            <v-tooltip top color="primary">
              <template v-slot:activator="{ on, ttprops }">
                <v-btn icon @click.prevent="deleteFile(item)" v-bind="ttprops" v-on="on">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Delete File</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-layout>
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vSelect from 'vue-select'

import DashLayout from '../../containers/Layout'
import JobFileUploadDialog from './JobFileUploadDialog'
import JobSettingDialog from './JobSettingDialog'
import AdoptedJobSettingDialog from './AdoptedjobsettingDialog'

export default {
  name: 'JonSettingContainer',
  components: {
    DashLayout,
    vSelect,
    JobFileUploadDialog,
    JobSettingDialog,
    AdoptedJobSettingDialog
  },
  computed: {
    ...mapGetters(['jobFiles', 'adoptedFiles', 'jobFilesLoading', 'activeAuditJob', 'user', 'adoptedFileLoading'])
  },
  mounted() {
    this.$store.dispatch('loadAdoptedFiles', this.activeAuditJob.id)

  },
  data() {
    return {
      overlay: false,
      search: '',
      file_name: '',
      waiting: false,
      jobSettingsDeleteDialog: false,
      headers: [
        { text: 'File Name', value: 'name', sortable: false, width: '20%' },
        { text: 'File Type', value: 'type', sortable: false, width: '10%' },
        { text: 'Size', value: 'size', sortable: false, width: '10%' },
        { text: 'Last Modified', value: 'last_modified', sortable: false, width: '20%' },
        { text: 'Actions', value: 'actions', sortable: false, width: '20%' },
      ],
      files: [],
      jobFileCreateErrorDialog: false,
    }
  },


  methods: {
    deleteFile(file) {
      this.file_name = file.name
      this.jobSettingsDeleteDialog = true
    },
    async deleteFileConfirm() {
      this.waiting = true
      const payload = {
        job_id: this.activeAuditJob.id,
        file_name: this.file_name
      }
      await this.$store.dispatch('deleteJobFile', payload)
      this.jobSettingsDeleteDialog = false
      this.waiting = false
    },

    async showSettingsDialog(item) {
      this.overlay = true

      this.$store.commit('setJobArgSpecs', [])
      const ret = await this.$store.dispatch('getJobArgSpecs')

      if (item.name) {
        const payload = {
          job_id: this.activeAuditJob.id,
          file_name: item.name
        }
        let ret = await this.$store.dispatch('getAdoptedJobFile', payload)
        await this.$store.dispatch('getJobFile', payload)
      }
      else {
        this.$store.commit('setActiveAdoptedFiles', {})
      }

      this.overlay = false
      this.$refs.AdoptedJobSettingDialog.show(item)
    },

    closeDialog() {
      this.jobSettingsDeleteDialog = false
    },

    downloadFile(item) {
      window.location.href = item.url
    },

    uploadNewFile() {
      this.$refs.JobFileUploadDialog.show()
    },
  },

  watch: {
    adoptedFiles() {
      this.files = []
      for (let i = 0; i < this.adoptedFiles.length; i++) {
        let item = {
          name: this.adoptedFiles[i].file_name,
          size: this.adoptedFiles[i].size,
          type: this.adoptedFiles[i].file_type,
          url: this.adoptedFiles[i].url,
          last_modified: this.adoptedFiles[i].last_modified,
        }

        if (this.adoptedFiles[i].size >= 1024 * 1024 * 1024) {
          const size = Math.round(this.adoptedFiles[i].size * 100 / (1024 * 1024 * 1024)) / 100
          item.size = size.toString() + ' GB'
        }
        else if (this.adoptedFiles[i].size >= 1024 * 1024) {
          const size = Math.round(this.adoptedFiles[i].size * 100 / (1024 * 1024)) / 100
          item.size = size.toString() + ' MB'
        }
        else if (this.adoptedFiles[i].size >= 1024) {
          const size = Math.round(this.adoptedFiles[i].size * 100 / (1024)) / 100
          item.size = size.toString() + ' KB'
        }
        else {
          const size = Math.round(this.adoptedFiles[i].size * 100) / 100
          item.size = size.toString() + ' B'
        }
        this.files.push(item)
      }
    },
    jobFileLoading() {
    }
  }
}
</script>
