<template>
  <v-row dense class="reduce-row-gaps">    
    <v-col cols="12">
      <v-card max-width="100%">
          <v-card-title>
            <p class="font-weight-bold">
                We thank the following donors.
            </p>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
          <p class="description">
            Donors may ask to remain anonymous.
          </p>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-card-title>
          <v-text-field
            class="search"
            v-model="search"
            outlined
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            clearable
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap>

             <v-data-table
              :headers="headers"
              :items="donors"
              :search="search"
              class="elevation-1"
              :loading="donorLoading"
              loading-text="Donors Data Loading ..."
              style="width: 100%;"
            >
              <template v-slot:[`item.price`]="{ item }">
                  $ {{item.price}}
              </template>
              <template v-slot:[`item.name`]="{ item }">
                  {{item.name}}, {{item.state}}
              </template>
              <!-- <template v-slot:[`item.address`]="{ item }">
                  {{item.address}}, {{item.state}}, {{item.country}}, {{item.zip_code}}
              </template> -->
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectDonorList',

  components: {},

  computed: {
    ...mapGetters(['donors', 'donorLoading']),
  },

  async mounted() {
    //await this.$store.dispatch('getDonors', this.$router.params.name)
  },

  data() {
    return {
      search: '',
      donorList: [],
      headers: [
        { text: 'Donation', align: 'start', sortable: true, value: 'price' },      
        { text: 'Name', align: 'start', sortable: true, value: 'name' },      
        // { text: 'Email', value: 'email' },
        // { text: 'Address', value: 'address' },
      ],
    }
  },
  methods: {
  },

  watch: {
  },
}
</script>

<style>
.v-application p.font-weight-bold {
  margin: 0;
}
.v-application .v-input__control {
    padding: 0;
}
.v-application p.description{
  padding: 0px 16px;
}
</style>