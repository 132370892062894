<template>
  <v-row align="center" justify="center">
    <v-dialog v-model="dialog" max-width="70%" persistent>
      <v-card max-width="100%">

        <v-card-title>          
        </v-card-title>

        <v-container grid-list-xl fluid>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field 
                v-model="file_name" 
                label="File Name"
                :rules="[rules.required]"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                :items="file_types"
                label="File Type"
                v-model="file_type"
                item-text="description"
                item-value="value"
                readonly
              ></v-select>
            </v-col>
          </v-row>
           <v-row>
           <v-col cols="12">
              <label for="">Json Data</label>
              <v-jsoneditor
                v-model="jsonData"
                :options="options"
                :plus="false"
                :height="`300px`"
                :expandedOnStart="true"
                :rules="[rules.required]"
              />
            </v-col>
           </v-row>
        </v-container>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="close"
        >
          Close
        </v-btn>
        <v-btn
          outlined
          @click="saveJobSettingFile"
          :disabled="file_name.length == 0 || file_types.length == 0 || waiting"
        >
          Save
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="waiting"
        ></v-progress-circular>
        </v-btn>
      </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row> 
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import VJsoneditor from 'v-jsoneditor/src/index'
  export default {
    name: 'GenerateJsonDialog',
    components: {
      VJsoneditor
    },

    data: () => ({
      dialog: false,
      file_name:  '',
      old_file_name: '',
      file_type: 'JOB',
      isUpdate: false,
      waiting: false,
      fileId:0,
      jsonData:{},
      options: {
        mode: 'code'
      },
      job_tag:'',
      search: '',
      file_types: [
          {value: 'JOB', description: 'Job Configuration'},
          {value: 'EIF', description: 'Election Information'},
          {value: 'BOF', description: 'Ballot Options'},
          {value: 'STC', description: 'Manual Styles to Contests'},
          {value:'JSON',description:"Election Json"}
      ],
      types: [],      
      editDialog: false,
      rules: {
          required: value => !!value || 'Required.',
        },  
       editedIndex:-1    
    }),

    computed: {
      ...mapGetters(['activeJsonFile', 'jobFileLoading', 'jobArgSpecs', 'jobArgSpecsLoading', 'activeAuditJob', 'user']),
      formTitle () {
        return this.editedIndex === -1 ? 'New Argument' : 'Edit Argument'
      },
      formSubmitText() {
        return this.editedIndex === -1 ? 'Add' : 'Save'
      }
    },

    mounted() {
    },

    methods: {
      show(item){
        this.dialog = true  
        this.jsonData = this.activeJsonFile
        if(item.name){
          this.isUpdate = true
          this.file_name = item.name
          this.old_file_name = item.name
          this.file_type = item.type
        }else{
          this.isUpdate = false
          this.file_name = ''
          this.file_type = 'JSON'  
        }
      },
      close() {
        this.dialog=false
        this.waiting = false
      },
      async saveJobSettingFile(){
          let payload = {
            job_id: this.activeAuditJob.id,
            file_name: this.file_name,
            json_data: JSON.stringify(this.jsonData),
            file_type: this.file_type
          }        
          if(this.isUpdate){
            payload['isUpdate'] = this.isUpdate
          }
          this.waiting = true
          let ret = await this.$store.dispatch('generateJsonFile', payload)
          this.json_data = {}
          this.waiting = false

          if(ret['message']){
            this.dialog = false
            this.$store.dispatch('loadjobFiles', this.activeAuditJob.id)
          }          
      },  
    },

    watch: { 
    }
  }
</script>

<style>
.theme--dark.arguments-table.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none;
}
</style>