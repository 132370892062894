import Vue from 'vue'
import Router from 'vue-router'

import Home from 'Containers/Home'
import HomeBoard from 'Views/Home/HomeBoard'
import CreateProject from 'Views/Home/CreateProject'
import DonatinCanceled from 'Views/Home/Canceled'
import DonationSuccess from 'Views/Home/Success'
import AuditProject from 'Views/Home/AuditProject'
import DonationLayout from 'Views/Home/DonationLayout'

import Elections from 'Containers/Elections'
import ElectionsList from 'Views/Elections/ElectionsList'
import CreateElection from 'Views/Elections/CreateElection'

import Districts from 'Containers/Districts'
import DistrictsList from 'Views/Districts/DistrictsList'
import CreateDistrict from 'Views/Districts/CreateDistrict'

import Log from 'Containers/Log';

import ToolsLayout from 'Views/Tools/ToolLayout'

import AuditJobs from 'Containers/AuditJobs'
import AuditJobsList from 'Views/AuditJobs/AuditJobsList'
import CreateAuditJob from 'Views/AuditJobs/CreateAuditJob'

import AuditJobsDonation from 'Containers/AuditJobsDonation'
import AuditsDonation from 'Views/AuditsDonation/AuditsDonation'
import Success from 'Views/AuditsDonation/Success'
import Canceled from 'Views/AuditsDonation/Canceled'
import AuditsDetail from 'Views/AuditsDonation/AuditsDetail'
import DonationpaymentSuccess from 'Views/AuditsDonation/DonationSuccess'
import DonationCanceled from 'Views/AuditsDonation/DonationFail'

import JobSettings from 'Containers/JobSettings'
import CreateJobSetting from 'Views/JobSettings/CreateJobSetting'

import AdminBoard from 'Containers/AdminBoard'
import AdminBoardView from 'Views/AdminBoard/AdminBoardView'
import AuditTypes from 'Views/AdminBoard/AuditTypesEditor'

import LeaderBoard from 'Views/AdminBoard/LeaderBoard'
import auditPackage from 'Views/AuditPackage/AuditPackage'
import subscription from 'Views/PricingPlan/subscription'
import paymentFail from 'Views/PricingPlan/paymentFail'
import paymentSuccess from 'Views/PricingPlan/PaymentSuccess'
import subscriptionUser from "Views/PricingPlan/UserSubscription"

const AccountSettings = () => import('Views/AccountSettings/AccountSettings')
const LogPage = () => import('Views/LogPage')
// const AuditsDonation = () => import('Views/AuditsDonation/AuditsDonation')

const SignUp = () => import('Views/session/SignUp')
const Login = () => import('Views/session/Login')
const Registered = () => import('Views/session/Registered')
const ResetPassword = () => import('Views/session/ResetPassword')
const SetNewPassword = () => import('Views/session/SetNewpassword')
const Verify = () => import('Views/Verify')

const GenralUploadView = () => import('Views/GenralUpload/GenralUploadView')

const UploadView = () => import('Views/Upload/UploadView')
const UploadViewDistrict = () => import('Views/Upload/UploadViewDistrict')

const PageNotFound = () => import('Views/PageNotFound')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home',
    },
    {
      path: '/home',
      name: 'Home',
      components: { User: Home, Admin: Home, Observer:Home },
      meta: {
        requiresAuth: true,
        title: 'Home',
      },
      children: [
        {
          path: '',
          name: 'Home',
          components: { User: HomeBoard, Admin: HomeBoard, Observer:HomeBoard },
          props: true,
        },
        {
          path: '/projects',
          name: 'Home',
          components: { User: HomeBoard, Admin: HomeBoard, Observer:HomeBoard },
          props: true,
        },
        // {
        //   path: '/home/project/:id',
        //   name: 'ProjectView',
        //   components: { User: CreateProject, Admin: CreateProject },
        //   props: {
        //     User: true,
        //     Admin: true,
        //   },
        //   meta: {
        //     requiresAuth: true,
        //     title: 'Project Editor'
        //   },
        // },
        {
          path: '/projects/success/:id',
          name: 'DonationSuccess',
          components: { User: DonationSuccess, Admin: DonationSuccess, Observer:DonationSuccess },
          props: {
            User: true,
            Admin: true,
            Observer: true,
          },
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: '/projects/:name',
          name: 'AuditProject',
          components: { User: DonationLayout, Admin: DonationLayout, Observer:DonationLayout },
          props: {
            User: true,
            Admin: true,
            Observer: true,
          },
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path:'/districts',
      name:'Districts',
      components:{ User: Districts, Admin: Districts, Observer:Districts },
      props: true,
      meta:{
        requiresAuth: true,
        title:'Districts'
      },
      children: [
        {
          path: '/districts',
          name: 'DistrictsList',
          components: { User: DistrictsList, Admin: DistrictsList, Observer:DistrictsList },
          props: true,
          meta: {
            requiresAuth: true,
            title: 'Districts',
          },
        },
        {
          path: '/districts/:id',
          name: 'DistrictView',
          components: { User: CreateDistrict, Admin: CreateDistrict, Observer:CreateDistrict },
          props: {
            User: false,
            Admin: true,
            Observer: true,
          },
          meta: {
            requiresAuth: true,
            title: 'District Editor',
          },
        },
      ],
    },
    {
      path:'/logs',
      name:'Logs',
      components:{ User: Log, Admin: Log, Observer:Log },
      props: true,
      meta:{
        requiresAuth: true,
        title:'Log'
      },
      children: [
        {
          path: '/logs',
          name: 'LogList',
          components: { User: LogPage, Admin: LogPage, Observer:LogPage },
          props: true,
          meta: {
            requiresAuth: true,
            title: 'logs',
          },
        }
      ],
    },
    {
      path: '/elections',
      name: 'Elections',
      components: { User: Elections, Admin: Elections, Observer:Elections },
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Elections',
      },
      children: [
        {
          path: '/elections',
          name: 'ElectionList',
          components: { User: ElectionsList, Admin: ElectionsList, Observer: ElectionsList },
          props: true,
          meta: {
            requiresAuth: true,
            title: 'Elections',
          },
        },
        {
          path: '/elections/:id',
          name: 'ElectionView',
          components: { User: CreateElection, Admin: CreateElection, Observer: CreateElection},
          props: {
            User: true,
            Admin: true,
            Observer: true,
          },
          meta: {
            requiresAuth: true,
            title: 'Election Editor',
          },
        },
      ],
    },
    {
      path: '/audit-jobs',
      name: 'AuditJobs',
      components: { User: AuditJobs, Admin: AuditJobs, Observer:AuditJobs },
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Audit Jobs',
      },
      children: [
        {
          path: '/audit-jobs',
          name: 'AuditJobsList',
          components: { User: AuditJobsList, Admin: AuditJobsList, Observer: AuditJobsList },
          props: true,
          meta: {
            requiresAuth: true,
            title: 'Audit Jobs List',
          },
        },
        {
          path: '/audit-jobs/:id',
          name: 'AuditJobsView',
          components: { User: CreateAuditJob, Admin: CreateAuditJob, Observer:CreateAuditJob },
          props: {
            User: true,
            Admin: true,
            Observer: true,
          },
          meta: {
            requiresAuth: true,
            title: 'Audit Job Detail View',
          },
        },
      ],
    },
    {
      path: '/job-settings',
      name: 'Audit Jobs',
      components: { User: JobSettings, Admin: JobSettings, Observer:JobSettings },
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Job Settings',
      },
      children: [
        {
          path: '/job-settings/new',
          name: 'Audit Jobs',
          components: { User: CreateJobSetting, Admin: CreateJobSetting, Observer:CreateJobSetting },
          props: true,
          meta: {
            requiresAuth: true,
            title: 'Create Job Setting',
          },
        },
        {
          path: '/job-settings/:id',
          name: 'Audit Jobs',
          components: { User: CreateJobSetting, Admin: CreateJobSetting, Observer: CreateJobSetting},
          props: true,
          meta: {
            requiresAuth: true,
            title: 'Edit Job Setting',
          },
        },
      ],
    },
    {
      path: '/account-settings',
      name: 'Account Settings',
      components: { User: AccountSettings, Admin: AccountSettings, Observer: AccountSettings},
      meta: {
        requiresAuth: true,
        title: 'Account Settings',
      },
    },
    {
      path: '/audit-donation',
      name: 'Audit Donation',
      components: { User: AuditsDonation, Admin: AuditsDonation, Observer:AuditsDonation },
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Account Donation',
      },
      children: [
        {
          path: '/audit-donation/success',
          name: 'Success',
          component: Success,
          props: {
            User: true,
            Admin: true,
            Observer: true,
          },
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
    {
      path: '/admin-audit-types',
      name: 'Admin Audit Types',
      components: { Admin: AuditTypes, Observer: AuditTypes},
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Admin Audit Types',
      },
    },
    {
      path:'/tools',
      name:'Admin Tools',
      components:{Admin: ToolsLayout},
      props: true,
      meta:{
        requiresAuth: true,
        title:'Admin Tools'
      }
    },
    {
      path: '/admin-board',
      name: 'Admin Board',
      components: { Admin: AdminBoard, Observer: AdminBoard},
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Admin Board',
      },
      children: [
        {
          path: '/admin-board/',
          name: 'Admin Board',
          components: { Admin: AdminBoardView },
          props: true,
          meta: {
            requiresAuth: true,
            title: 'Admin Board View',
          },
        },
      ],
    },
    {
      path: '/session/login',
      name: 'login',
      component: Login,
      meta: {
        hideNavigation: true,
        restrictForFoginUser: true,
      },
    },
    {
      path: '/session/signup',
      name: 'signup',
      component: SignUp,
      meta: {
        hideNavigation: true,
        restrictForFoginUser: true,
      },
    },
    {
      path: '/session/registered',
      name: 'Registered',
      component: Registered,
      meta: {
        hideNavigation: true,
        restrictForFoginUser: true,
      },
    },
    {
      path: '/session/set-newpassword/:forgotkey/:userid',
      name: 'New Password',
      component: SetNewPassword,
      meta: {
        hideNavigation: true,
        restrictForFoginUser: true,
      },
    },
    {
      path: '/verify/:token',
      name: 'Verify',
      props: true,
      component: Verify,
      meta: {
        hideNavigation: true,
      },
      children: [],
    },
    {
      path: '/upload/:id/:county',
      name: 'Upload',
      props: true,
      component: UploadView,
      meta: {
        hideNavigation: true
      }
    },
    {
      path: '/upload-district/:id/:county',
      name: 'Upload',
      props: true,
      component: UploadViewDistrict,
      meta: {
        hideNavigation: true
      }
    },
    {
      path:'/general_upload',
      name:'genralUpload',
      props:true,
      component:GenralUploadView,
      meta: {
        requiresAuth: false,
        hideNavigation: true,
      },
      children: [],

    },
    {
      path: '/session/reset-password',
      name: 'reset-password',
      component: ResetPassword,
      meta: {
        hideNavigation: true,
      },
    },
    {
      path: '/public',
      name: 'AuditsDonation',
      component: AuditsDonation,
      props: true,
      meta: {
        requiresAuth: false,
        title: 'Account Donation',
      },
      children: [
        {
          path: '/public/success',
          name: 'Success',
          component: Success,
          props: {
            User: true,
            Admin: true,
          },
          meta: {
            requiresAuth: false,
          },
        },
      ],
    },
    {
      path: '/project/:name',
      name: 'Donation',
      component: AuditsDetail,
      props: true,
      meta: {
        requiresAuth: false,
        title: 'Donation',
      },
    },
    {
      path: '/project/success/:id',
      name: 'DonationpaymentSuccess',
      component: DonationpaymentSuccess,
      props: true,
      meta: {
        requiresAuth: false,
        title: 'Donation',
      },
    },
    {
      path: '/project/cancle/:id',
      name: 'DonationCanceled',
      component: DonationCanceled,
      props: true,
      meta: {
        requiresAuth: false,
        title: 'Donation',
      },
    },
    {
      path: '/canceled',
      name: 'Payment Canceled',
      component: Canceled,
      props: true,
      meta: {
        requiresAuth: false,
        title: 'Donation Canceled',
      },
    },
    {
      path: '/donation-canceled/:id',
      name: 'Donation Canceled',
      component: DonatinCanceled,
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Donation Canceled',
      },
    },
    {
      path: '/leaderboard',
      name: 'Leader Board',
      components: { User: LeaderBoard, Admin: LeaderBoard, Observer:LeaderBoard },
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Leader Board',
      },
    },
    {
      path: '/packages',
      name: 'Audit Packages',
      components: { Admin: auditPackage, Observer:auditPackage },
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Audit Packages',
      },
    },
    // {
    //   path:'/pricing',
    //   name:'Subscription',
    //   components:{User:subscription},
    //   props: true,
    //   meta: {
    //     requiresAuth: true,
    //     title: 'Subscription',
    //   },
    // },
    {
      path:'/subscription/cancle/:id',
      name:'Payment Fail',
      components:{User:paymentFail},
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Payment Fail',
      },
    },
    {
      path:'/subscription/success/:id',
      name:'Payment Success',
      components:{User:paymentSuccess},
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Payment Success',
      },
    },
    {
      path:'/subscription',
      name:'Subscription',
      components:{User:subscriptionUser},
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Subscription',
      },
    },
    {
      path: '*',
      name: 'PageNotFound',
      component: PageNotFound,
      meta: {
        hideNavigation: true,
      },
    },
  ],
})

export default router
