<template slot="dash-body">
  <div>
    <v-card max-width="100%">
      <v-card-title class="mb-6">
        <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
          clearable></v-text-field>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn  @click.prevent="addContact" v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="blue-grey darken-1"> New District Contact </v-btn>
      </v-card-title>

      <v-container grid-list-xl fluid>
        <v-layout row wrap> 
          <v-data-table :headers="headers" :items="dis_data" :search="search" class="elevation-1"
            :loading="districtLoading" loading-text="Districts Data Loading ..." style="width: 100%">
            <template v-slot:[`item.district_name`]="{ item }">
              {{ item.district_name }}, {{ item.state_name }}, {{ item.country_name }}
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, ttprops }">
                  <v-btn icon @click.prevent="onEditClick(item)" v-bind="ttprops" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit Election</span>
              </v-tooltip>

              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, ttprops }">
                  <v-btn icon @click="deleteElection(item)" v-bind="ttprops" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete Election</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-layout>
      </v-container>
    </v-card>
    <ContactOverview ref="ContactOverview" />
    <v-dialog v-model="districtDeleteDialog" max-width="800px" persistent>
      <v-card>
        <v-card-title>Do you sure to delete this contact information?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn outlined @click="() => (districtDeleteDialog = false)">Cancel</v-btn>
          <v-btn outlined color="error" @click="districtDeleteConfirm" :disabled="saving">
            Delete
            <v-progress-circular v-if="saving" :width="3" color="green" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactOverview from './ContactOverview'

export default {
  name: 'ContentList',
  props: ['districtId'],
  components: {
    ContactOverview,
  },
  computed: {
    ...mapGetters(['districtContacts', 'districtLoading', 'user', 'isEditMode']),
  },
  async mounted() {

    await this.$store.dispatch('loadContacts', this.districtId)
    this.userValue = this.user
    setTimeout(() => {
      this.dis_data = this.districtContacts
    }, 3000)
  },
  data() {
    return {
      waiting: false,
      saving: false,
      dis_data: [],
      userValue: {},
      isHidden: false,
      search: '',
      districtDeleteDialog: false,
      headers: [
        { text: 'Name', align: 'start', value: 'name' },
        { text: 'Title', value: 'title' },
        { text: 'Email', value: 'email' },
        { text: 'Access Date', value: 'date', sortable: true, defaultSort: 'desc' },
        { text: 'Date', value: 'created_at' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },
  methods: {
    deleteElection(dis) {
      this.id = dis.id
      this.name = dis.name
      this.districtDeleteDialog = true
    },
    async districtDeleteConfirm() {
      this.saving = true

      const data = {
        districtId: this.districtId,
        id: this.id,
      }
      await this.$store.dispatch('deleteDistrictContact', data)

      this.saving = false
      this.districtDeleteDialog = false
    },
    addContact() {
      this.$refs.ContactOverview.show()
    },
    onEditClick(item) {
      this.$refs.ContactOverview.show(item)
    },
  },
}
</script>
