<template>
  <audit-job-layout :id="id"></audit-job-layout>
</template>

<script>
  import AuditJobLayout from './AuditJobLayout'

  export default {

    name: 'CreateAuditJob',

    props: ['id'],

    components: {
      AuditJobLayout,
    },

    mounted() {
    }

  }
</script>