import api from 'Api';
import Vue from 'vue';
import ErrorDialog from '@/views/ErrorDialog';
import { EventName, EventType } from './LogEvent';

export function handleApiError(error, email) {
  if(error?.response?.status == 400 || error?.response?.status == 403){
    Vue.notify({
      group: 'messages',
      type: 'error',
      text: error?.response?.data?.error || 'You can not change role for super admin.'
    });
  }
  if(error?.response?.status == 500){
    let errMessage = error.response?.data?.data ? error.response?.data?.data : error.response?.data?.error
    Vue.notify({
      group: 'messages',
      type: 'error',
      duration: 5000,
      text: errMessage
    })
    let storageEmail = JSON.parse(localStorage.getItem('user'))?.email
    let userEmail = email ? email : storageEmail ? storageEmail : ''
      let payload = {
        email : userEmail,
        message : errMessage 
      }
      signInErrorApi(payload);
  }
}

export function signInErrorApi(payload) {
    let user = payload;
    return api(false).post('/auth/sign-in-error', user)
      .then(response => { 
        logApiService(user, 'Error report sent', EventName.Leader_board, EventType.signInError)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Error report sent successfully'
        });
        return response;
      })
      .catch(error => {
        logApiService(user, error, EventName.Leader_board, EventType.signInError)
        const errorDialog = Vue.extend(ErrorDialog);
        const instance = new errorDialog({
            propsData: {
            dialogVisible: true,
            errorText: user?.message ? user?.message : error?.response?.data?.data || 'Technical error occurred',
            },
        });
        instance.$mount();
        document.body.appendChild(instance.$el);
        return error.response;
      });
}

export function logApiService (payload, message, name, type) {
  const data = {
    name: name,
    type: type,
    description: message,
    additional_data: payload,
    site_id: 1,
  }
  api().post('/log', data)
    .then(function (response) {
     return response
    })
    .catch(function (error) {
      console.log(error)
      return error
    })
}