<template>
  <div>
    <v-row dense>
      <v-col md="8">
        <v-card class="reduce-row-gaps">
          <v-divider></v-divider>
          <v-card-text class="picker-popup">
            <v-row>
              <v-col cols="12" class="g-layout">
                <v-select
                  v-model="country"
                  :items="countries"
                  item-text="name"
                  item-value="code"
                  label="Country"
                  outlined
                  :readonly="true"
                ></v-select>
              </v-col>
              <v-col cols="6" class="g-layout">
                <v-select
                  v-if="country == 'US'"
                  v-model="US_state"
                  :items="US_states"
                  @change="changeUSState"
                  item-text="name"
                  item-value="value"
                  label="State"
                  outlined
                  :rules="[rules.required]"
                  :readonly="editNotAllowed || districtId > 0"
                ></v-select>
                <v-text-field v-else v-model="state" label="State" outlined :readonly="editNotAllowed"></v-text-field>
              </v-col>
              <v-col cols="6" class="g-layout">
                <v-select
                  v-if="country == 'US'"
                  v-model="county"
                  :items="jurisdictions"
                  label="Districts"
                  outlined
                  :readonly="editNotAllowed || districtId > 0"
                ></v-select>
                <v-text-field
                  v-else
                  v-model="county"
                  label="Districts"
                  outlined
                  :readonly="editNotAllowed || districtId > 0"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="address" label="Address" outlined> </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="city" label="City" outlined></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="statePostalCode" label="State Postal Code" outlined></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="phoneNumber" label="Phone Number" outlined></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="email" label="Email" outlined :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="website" label="Website" outlined></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions v-show="!editNotAllowed" class="pa-8">
            <v-spacer></v-spacer>
            <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" outlined @click="checkDistrict" :disabled="!canSave">
              {{ districtId > 0 ? 'Update' : 'Create' }}
              <v-progress-circular v-if="saving" :width="3" color="green" indeterminate></v-progress-circular>
            </v-btn>
            <template v-if="userValue.role == 'Observer'? isHidden : !isHidden">
              <v-btn   outlined @click="deleteDistrict" :disabled="!canSave" v-if="districtId > 0">
                Delete
                <v-progress-circular v-if="saving" :width="3" color="green" indeterminate></v-progress-circular>
              </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDialog" width="800">
      <v-card>
        <v-card-title class="headline warning"> Please confirm District properties. </v-card-title>

        <v-card-text>
          <v-list subheader two-line flat>
            <!-- <v-list-item>
              <v-list-item-content>
                <v-list-item-title>District Date: {{ this.date }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>District Path: {{ this.districtpath }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="primary" outlined @click="saveDistrict"> Confirm </v-btn>
          <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="primary" outlined @click="confirmDialog = false"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="districtDeleteDialog" max-width="800px" persistent>
        <v-card>
          <v-card-title>Are you sure deleting {{ county }} ?</v-card-title>
          <v-card-actions class="pa-8">
            <v-spacer></v-spacer>
            <v-btn outlined @click="districtDeleteDialog = false">Cancel</v-btn>
            <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" outlined color="error" @click="districtDeleteConfirm" :disabled="waiting">
              Delete
              <v-progress-circular v-if="waiting" :width="3" color="green" indeterminate></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AbbrCountries } from './../../services/AbbrCountries.js'
import { AbbrStates } from './../../services/AbbrStates.js'
// import { USjurisdictions } from '../Elections/jurisdictions.js'

export default {
  name: 'DistrictOverview',
  props: ['districtId'],

  components: {},

  computed: {
    ...mapGetters(['districts', 'districtData', 'districtLoading', 'user', 'jurisdictionsList']),
    canSave: function () {
      if (this.country === 'US') {
        return !(this.county.length == 0 || this.saving)
      } else {
        return !(this.state.length == 0 || this.county.length == 0 || this.saving)
      }
    },
    editNotAllowed: function () {
      return this.districtId > 0 && this.districtData.role != 'Auditor'
    },
  },

  async mounted() {
    await this.$store.dispatch('getJurisdiction')
    this.userValue = this.user

    if (this.districtId > 0) {
      this.$store.commit('setActiveDisctrict', {})
      this.$store.commit('setLoading', true)
      let ret = await this.$store.dispatch('getDisctrict', this.districtId)
      this.$store.commit('setLoading', false)
    } else {
      this.country = 'US'
      setTimeout(() => {
        this.jurisdictions = this.jurisdictionsList['CA']
        this.county = this.jurisdictions[0]
      }, 1000)
    }
  },
  data() {
    return {
      userValue: {},
      isHidden: false,
      search: '',
      confirmDialog: false,
      districtpath: '',
      id: 0,
      country: 'US',
      state: '',
      US_state: 'CA',
      county: '',
      date: new Date().toISOString().substr(0, 10),
      role: 'Oberver',
      dateModal: false,
      saving: false,
      waiting:false,
      countries: AbbrCountries,
      US_states: AbbrStates,
      jurisdictions: [],
      contactName: '',
      address: '',
      city: '',
      statePostalCode: '',
      phoneNumber: '',
      email: '',
      website: '',
      districtDeleteDialog: false,
      rules: {
        required: (value) => !!value || 'Required.',
      },
    }
  },
  methods: {
    editDistrict(district) {
      this.id = district.id
      this.state = district.state
      this.date = district.date
      this.role = district.role
      this.jurisdictions = this.jurisdictionsList[district.state_name]
      this.phoneNumber = district.phone_number
      this.statePostalCode = district.state_zipcode
      this.website = district.website
      this.email = district.email
      this.city = district.city
      this.address = district.address
      this.contactName = district.name
      this.country = district.country_name
      this.US_state = district.state_name
      this.county = district.district_name
    },
    changeUSState(state) {
      this.jurisdictions = this.jurisdictionsList[state]
      if (this.jurisdictions.length > 0) this.county = this.jurisdictions[0]
    },
    checkDistrict() {
      if (this.districtId > 0) {
        this.saveDistrict()
      } else {
        this.confirmDialog = true
        if (this.country == 'US') {
          this.districtpath =
            this.country +
            '/' +
            this.US_state +
            '/' +
            this.country +
            '_' +
            this.US_state +
            '_' +
            this.county.replaceAll(' ', '')
        } else {
          this.districtpath =
            this.country +
            '/' +
            this.state +
            '/' +
            this.country +
            '_' +
            this.state +
            '_' +
            this.county.replaceAll(' ', '')
        }
      }
    },
    async saveDistrict() {
      this.confirmDialog = false
      this.saving = true
      const payload = {
        name: this.country + '_' + this.US_state + '_' + this.county.replaceAll(' ', ''),
        country: this.country,
        state_name: this.US_state,
        district_name: this.county,
        address: this.address,
        city: this.city,
        statePostalCode: this.statePostalCode,
        phoneNumber: this.phoneNumber,
        email: this.email,
        date: this.date,
        website: this.website,
      }
      if (this.districtId > 0) {
        payload['id'] = this.districtId
      }
      const response = await this.$store.dispatch('saveDistrict', payload);
      console.log(response, "response");
      this.confirmDialog = false
      this.saving = false
      if (response?.id > 0) {
         this.$router.push(`/districts/${response.id}`).then(() => {
          setTimeout(() => {
            location.reload();
          }, 1500)
      });
      }
    },
    deleteDistrict() {
      this.districtDeleteDialog = true
    },
    async districtDeleteConfirm() {
      this.waiting = true
      await this.$store.dispatch('deleteDistrct',this.districtId)
      this.districtDeleteDialog = false
      this.waiting = false
      this.$router.push('/districts')
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },

  watch: {
    districtData(val) {
      if (Object.keys(val).length > 0) {
        this.editDistrict(val)
      }
    },
  },
}
</script>

<style>
.g-layout {
  padding: 0px 10px !important;
}
</style>
