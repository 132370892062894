import EventEmitter from 'eventemitter3'
import {store} from "../store/store.js";


class AuthService {

  constructor() {
    this.isAuthenticated = this.isAuthenticated.bind(this)
    this.authenticated = this.isAuthenticated()
    this.authNotifier = new EventEmitter()
  }

  isAuthenticated() {
    let expiresAtValue = localStorage.getItem('expires_at')
    const now = new Date().getTime()
    if(store.state.auth.user === null){
      if (!expiresAtValue || expiresAtValue == 'NaN') {
        localStorage.removeItem('expires_at')
        localStorage.removeItem('user')
        return false
      }
    }
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    if(now < expiresAt && store.state.auth.user){
      return true 
    }
    if(now < expiresAt && store.state.auth.user === null){
      store.dispatch('getUserProfile', null);
      return now < expiresAt
    }
    return false
  }
}

export default AuthService