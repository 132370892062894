<template>
  <div>
    <router-view :name="user.role"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  name: 'Home',

  computed: {
      ...mapGetters(['user']),
    },

  mounted() {
  },
  
};

</script>