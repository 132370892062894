<template>
  <dash-layout>
    <template slot="dash-title">Elections</template>

    <template slot="dash-body">
      <v-dialog v-model="electionDeleteDialog" max-width="800px" persistent>
        <v-card>
          <v-card-title>Are you sure deleting {{ name }} ?</v-card-title>
          <v-card-actions class="pa-8">
            <v-spacer></v-spacer>
            <v-btn outlined @click="electionDeleteDialog = false">Cancel</v-btn>
            <v-btn v-if="userValue.role == 'Observer' ? isHidden : !isHidden" outlined color="error"
              @click="deleteElectionConfirm" :disabled="saving">
              Delete
              <v-progress-circular v-if="saving" :width="3" color="green" indeterminate></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
            clearable></v-text-field>
          <v-select v-model="project" :items="p_data" item-text="name" @change="filterElectionList" return-object
            label="Project" class="ml-2" outlined></v-select>
          <v-select v-model="district" :items="dis_datas" item-text="name" @change="filterElectionList" return-object
            label="District" class="ml-2" outlined></v-select>
          <v-spacer></v-spacer>
          <v-btn v-if="userValue.role == 'Observer' ? isHidden : !isHidden"
            @click="$router.push({ name: 'ElectionView', params: { id: 'new', selectedDistrict: district } }).catch((err) => { })"
            color="blue-grey darken-1"> New Election
          </v-btn>
          <!-- <v-btn @click="$router.push(`/audit-jobs`).catch((err) => {})" class="ml-2" color="blue-grey darken-1">
            Go To Audits
          </v-btn> -->
        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap>
            <v-data-table :headers="headers" :items="el_data" :search="search" class="elevation-1"
              :loading="electionLoading" loading-text="Election Data Loading ..." style="width: 100%">
              
              <!-- Slot for the election name to make it clickable -->
              <template v-slot:[`item.name`]="{ item }">
                <v-btn text @click="openElection(item)" v-bind="ttprops" v-on="on">
                  {{ item.name }}
                </v-btn>
              </template>
          
              <template v-slot:[`item.county`]="{ item }">
                {{ item.county }}, {{ item.state }}, {{ item.country }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="openElection(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-location-enter</v-icon>
                    </v-btn>
                  </template>
                  <span>Open Election</span>
                </v-tooltip>

                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon
                      @click.prevent="deleteElection(item)" v-bind="ttprops"
                      v-on="on">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete Election</span>
                </v-tooltip>

              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </template>
  </dash-layout>
</template>

<script>
import DashLayout from '../../containers/Layout'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ElectionsList',
  components: {
    DashLayout,
  },
  computed: {
    ...mapGetters([
      'elections',
      'activeElection',
      'electionLoading',
      'electionUserLoading',
      'user',
      'projects',
      'districts',
    ]),
    canSave: function () {
      if (this.country === 'US') {
        return !(this.name.length == 0 || this.county.length == 0 || this.saving)
      } else {
        return !(this.name.length == 0 || this.state.length == 0 || this.county.length == 0 || this.saving)
      }
    },
  },
  async mounted() {
    await this.$store.dispatch('loadElections', this.user.id)
    this.userValue = this.user
    await this.$store.dispatch('loadProjects')
    if (this.districts.length === 0) {
      await this.$store.dispatch('loadDistricts', this.user.id)
    }
    setTimeout(() => {
      this.projects.unshift({ name: 'All Data', value: 0 })
      this.dis_datas = [...this.dis_datas, ...this.districts]
      this.p_data = this.projects
    }, 1500)

    if (this.$route.params.districtId != undefined) {
      this.dis_id = this.$route.params.districtId
      await setTimeout(() => {
        const dis = this.dis_datas.filter((item) => {
          if (item.id == this.$route.params.districtId) {
            return item
          }
        })
        this.district = dis[0]

        this.el_data = this.elections.filter((item) => item.districtId == this.district.id)
      }, 2000)
    } else {
      await setTimeout(() => {
        this.el_data = this.elections
      }, 2000)
    }
  },
  data() {
    return {
      userValue: {},
      isHidden: false,
      id: 0,
      dis_id: null,
      name: '',
      e_type: 'Primary',
      country: 'US',
      state: '',
      US_state: 'CA',
      county: '',
      date: new Date().toISOString().substr(0, 10),
      description: '',
      dateModal: false,
      electionSaveDialog: false,
      electionDeleteDialog: false,
      saving: false,
      search: '',
      project: null,
      p_data: [],
      el_data: [],
      dis_datas: [{ name: 'All Data', value: 0 }],
      headers: [
        { text: 'Name', align: 'start', sortable: true, value: 'name' },
        { text: 'Type', value: 'e_type' },
        { text: 'Privacy', value: 'privacy', mustSort: true },
        { text: 'Location', value: 'county' },
        { text: 'Access Date', value: 'date', sortable: true, defaultSort: 'desc' },
        { text: 'Date', value: 'created_at' },
        { text: 'Role', value: 'role' },
        { text: 'Actions', value: 'actions' },
      ],
      district: '',
    }
  },
  methods: {
    filterElectionList() {
      if (this.project && this.project.id && (!this.district || this.district.value === 0)) {
        this.el_data = this.elections.filter((item) => item.project_ids == this.project.id)
      } else if (this.district && this.district.id && (!this.project || this.project.value === 0)) {
        this.el_data = this.elections.filter((item) => item.districtId == this.district.id)
      } else if (this.project && this.project.id && this.district && this.district.id) {
        this.el_data = this.elections.filter(
          (item) => item.project_ids == this.project.id && item.districtId == this.district.id
        )
      } else {
        this.el_data = this.elections
      }
    },
    deleteElection(election) {
      this.id = election.id
      this.name = election.name
      this.electionDeleteDialog = true
    },
    async deleteElectionConfirm() {
      this.waiting = true
      await this.$store.dispatch('deleteElection', this.id)
      this.electionDeleteDialog = false
      this.waiting = false
    },
    closeDialog() {
      this.electionSaveDialog = false
    },
    async openElection(item) {
      this.$router.push({ name: 'ElectionView', params: { id: item.id, fromListing: 1 } })
    },
  },
  watch: {},
}
</script>
