import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store/store'
import vuetify from './plugins/vuetify'
import Notifications from 'vue-notification'
import velocity from 'velocity-animate'
import AuthService from './auth/AuthService'
import VueMask from 'v-mask'
import './plugins/filters'
import VueCookies from 'vue-cookies'
import Editor from 'v-markdown-editor'
import 'v-markdown-editor/dist/v-markdown-editor.css';
import VueResource from "vue-resource";
import './registerServiceWorker'
// import Markdown from 'vue3-markdown-it';
// import markdown from './directives/markdown' 

// Importing all css styles
// import './lib/globalCss'
router.beforeEach(async (to, from, next) => {
  await store.dispatch('routeInfo', {from: from, to: to})
  let authenticated = false;
  
  if(to.name == 'Upload' || to.name == 'genralUpload'){}
  else{
    const auth = new AuthService()
    authenticated = auth.authenticated
  }
  if(to.matched?.map(i=>i.path).includes('/projects/:name')){
    if(!authenticated){
      if (to.name !== 'Donation') {
        next ({
          name: 'Donation',
          params: {
            name: to.params.name,
          },
        })
      }
      return false
    }else{
      if (to.name !== 'AuditProject') {
        next ({
          name: 'AuditProject',
          params: {
            name: to.params.name,
          },
        })
      }
    }
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('user') === null || !authenticated) {
      next ({
        path: '/session/login',
        query: { redirect: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
  if (to.matched.some(record => record.meta.restrictForFoginUser)) {
    if (localStorage.getItem('user') === null && !authenticated) {
      next()
    } else {
      next ({
        path: '/elections'
      })
    }
  }
})

router.afterEach((to, from) => {
  setTimeout(() => {
    const contentWrapper = document.querySelector(".v-content__wrap");
		if (contentWrapper !== null) {
      contentWrapper.scrollTop = 0;
		}
		const boxedLayout = document.querySelector('.app-boxed-layout .app-content');
		if (boxedLayout !== null) {
      boxedLayout.scrollTop = 0;
		}
		const miniLayout = document.querySelector('.app-mini-layout .app-content');
		if (miniLayout !== null) {
      miniLayout.scrollTop = 0;
		}
	}, 200);
})

Vue.config.productionTip = false
Vue.use(VueResource);
// Vue.directive('makrdown', markdown)
Vue.use(Notifications, { velocity })
Vue.use(VueMask);
Vue.use(VueCookies)
Vue.use(Editor);
// Vue.use(Markdown);

Vue.$cookies.config(60, '')


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')