<template>
  <dash-layout>
    <template slot="dash-title">Subscription</template>
    <template slot="dash-body">
      <v-dialog v-model="paymentDetailDialog" max-width="600px" persistent>
        <v-card>
          <v-card-title>Payment Details</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" class="flex-box">
                  <lable for="">name</lable>
                  <span>{{ activeItem && activeItem.payment && activeItem.payment.name }}</span>
                </v-col>
                <v-col cols="6" class="flex-box">
                  <lable for="">Email</lable>
                  <span>{{ activeItem && activeItem.payment && activeItem.payment.email }}</span>
                </v-col>
                <v-col cols="6" class="flex-box">
                  <lable for="">Transaction Id</lable>
                  <span>{{ activeItem && activeItem.payment && activeItem.payment.transaction_id }}</span>
                </v-col>
                <v-col cols="6" class="flex-box">
                  <lable for="">Amount</lable>
                  <span>${{ activeItem && activeItem.payment && activeItem.payment.price }}</span>
                </v-col>
                <v-col cols="6" class="flex-box">
                  <lable for="">Payment Status</lable>
                  <span>{{ activeItem && activeItem.payment && activeItem.payment.payment_status }}</span>
                </v-col>
                <v-col cols="6" class="flex-box">
                  <lable for="">Currency</lable>
                  <span>{{ activeItem && activeItem.payment && activeItem.payment.currency }}</span>
                </v-col>

                <v-col cols="6" class="flex-box">
                  <lable for="">City</lable>
                  <span>{{ activeItem && activeItem.payment && activeItem.payment.city }}</span>
                </v-col>
                <v-col cols="6" class="flex-box">
                  <lable for="">State</lable>
                  <span>{{ activeItem && activeItem.payment && activeItem.payment.state }}</span>
                </v-col>
                <v-col cols="6" class="flex-box">
                  <lable for="">Country</lable>
                  <span>{{ activeItem && activeItem.payment && activeItem.payment.country }}</span>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="pa-8">
            <v-spacer></v-spacer>
            <v-btn class="btn-outline" outlined @click="closeDialog">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row dense class="reduce-row-gaps">
        <v-col cols="12">
          <v-card max-width="100%">
            <v-card-title class="mb-6">
              <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
                clearable></v-text-field>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <!-- <v-btn class="ml-1" dark color="blue-grey darken-1" @click.prevent="newPlan">
                <v-icon left>mdi-plus</v-icon> Upgrade Plan</v-btn
              > -->
            </v-card-title>
            <v-container grid-list-xl fluid>
              <v-layout row wrap>
                <v-data-table :headers="headers" :items="subscribPlans" :search="search" class="elevation-1"
                  :loading="subscribeLoading" loading-text="Subscription Loading ..." style="width: 100%"
                  :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                  <template v-slot:[`item.package_id`]="{ item }">
                    {{ item.package.name }}
                  </template>
                  <template v-slot:[`item.is_monthly`]="{ item }">
                    {{ item.is_monthly ? 'Monthly' : 'Yearly' }}
                  </template>
                  <template v-slot:[`item.startDate`]="{ item }">
                    {{ moment(item.startDate).format('MMMM Do YYYY') }}
                  </template>
                  <template v-slot:[`item.endDate`]="{ item }">
                    {{ moment(item.endDate).format('MMMM Do YYYY') }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top color="primary">
                      <template v-slot:activator="{ on, ttprops }">
                        <v-btn icon @click.prevent="paymentDetails(item)" v-bind="ttprops" v-on="on">
                          <v-icon>payment</v-icon>
                        </v-btn>
                      </template>
                      <span>Payment Details</span>
                    </v-tooltip>

                    <!-- <v-btn v-if="item.users.role == 'Admin'" icon @click.prevent="deleteAuditPackage(item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn> -->
                  </template>
                </v-data-table>
              </v-layout>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </dash-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import DashLayout from '../../containers/Layout'
import moment from 'moment'

export default {
  components: {
    DashLayout,
  },
  data() {
    return {
      search: '',
      sortBy: 'package_id',
      paymentDetailDialog: false,
      activeItem: null,
      sortDesc: true,
      moment: moment,
      headers: [
        { text: 'Package Name', align: 'start', sortable: true, value: 'package_id', width: '10%' },
        { text: 'Package Type', align: 'start', sortable: true, value: 'is_monthly', width: '10%' },
        { text: 'Start Date', align: 'start', sortable: true, value: 'startDate', width: '10%' },
        { text: 'End Date', align: 'start', sortable: true, value: 'endDate', width: '10%' },
        { text: 'Status', align: 'start', sortable: false, value: 'status', width: '10%' },
        { text: 'Actions', value: 'actions', width: '10%' },
      ],
    }
  },
  computed: {
    ...mapGetters(['subscribPlans', 'subscribeLoading', 'user']),
  },
  mounted() {
    if (this.user.id != 0) {
      this.$store.dispatch('getSubscriptionByUser', { user_id: this.user.id })
      if (this.subscribPlans.length > 0) {
        this.$store.dispatch('getUserProfile', null);
      }
    }
  },
  methods: {
    nextSort() {
      let index = this.headers.findIndex((h) => h.value === this.sortBy)
      index = (index + 1) % this.headers.length
      this.sortBy = this.headers[index].value
    },
    newPlan() {
      // this.$router.push(`/pricing`)
    },
    paymentDetails(item) {
      this.activeItem = item
      this.paymentDetailDialog = true
    },
    closeDialog() {
      this.activeItem = null
      this.paymentDetailDialog = false
    }
  },
}
</script>
<style>
.flex-box {
  display: flex;
  flex-direction: column;
  column-gap: 5px;
}

.flex-box lable {
  font-weight: 600;
}
</style>
