<template>    
    <v-row align="center" justify="center">
      <v-dialog v-model="fileUploadeErrorDialog" max-width="600px" persistent>
        <v-card>
          <v-card-title>The type of file already exist. Please try again after deleting the file.</v-card-title>
          <v-card-actions class="pa-8">
            <v-spacer></v-spacer>
            <v-btn class="btn-outline"  outlined @click="fileUploadeErrorDialog=false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Audit Control Files</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="file_type"
                    :items="file_types"
                    item-text="description"
                    item-value="value"
                    label="Audit Control File Type"   
                    :disabled="fileUploading"
                    outlined           
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                          clearable
                          clear-icon="cancel"
                          label="Audit Control File Additional Description"
                          v-model="description"
                          outlined
                  ></v-textarea>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <vue-dropzone
                          ref="myVueDropzone"
                          id="dropzone"
                          class="transparent-dropzone vue-dropzone-multiple"
                          @vdropzone-sending="sendingEvent"
                          @vdropzone-success="vsuccess"
                          @vdropzone-file-added="fileAdded"
                          @vdropzone-files-added="filesAdded"
                          @vdropzone-removed-file="fileRemoved"
                          :options="dropzoneOptions"
                  >
                  </vue-dropzone>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" outlined @click="close" :disabled="uploading">Close</v-btn>
            <v-btn v-if="userValue.role == 'Observer'? isHidden : !isHidden" color="blue darken-1" outlined @click="save" :disabled="!filesSelected || uploading"
            >
              Upload
              <v-progress-circular
                      indeterminate
                      color="primary"
                      v-if="uploading"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>    
</template>

<script>
  import Vue from 'vue'
  import { mapGetters, mapState } from 'vuex';
  import vue2Dropzone from 'vue2-dropzone';
  import 'vue2-dropzone/dist/vue2Dropzone.min.css';

  export default {
    name: 'JobFileUploadDialog',
    components: {
      vueDropzone: vue2Dropzone
    },
    computed: {
      ...mapGetters(['activeAuditJob', 'jobFiles', 'user']),
      uploadInProgress: function() {
        return this.fileUploading || this.saveInProgress
      }
    },
    mounted () {
      this.access_token = JSON.parse(localStorage.getItem('user'))?.access_token 
      this.dropzoneOptions.headers['Authorization'] = 'Bearer ' + this.access_token
      this.userValue = this.use
    },
    data() {
      return {
        userValue: {},
        isHidden: false,
        dialog: false,
        file_type: 'JOB',
        access_token:null,
        description: '',
        file_types: [
            {value: 'JOB', description: 'Job Configuration'},
            {value: 'EIF', description: 'Election Information'},
            {value: 'BOF', description: 'Ballot Options'},
            {value: 'STC', description: 'Manual Styles to Contests'},
        ],
        uploading: false,
        file_name: '',
        filesSelected: false,
        rules: {
          required: value => !!value || 'Required.',
        },
        saveInProgress: false,
        fileSelected: false,
        fileUploading: false,
        fileUploadeErrorDialog: false,
        dropzoneOptions: {
          url: process.env.VUE_APP_ROOT_API + '/job-settings/upload',
          headers: {'Authorization': ''},
          thumbnailWidth: 200,
          timeout: 3600000, // 3600s
          maxFilesize: 5,  //5MB
          addRemoveLinks: true,
          dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>UPLOAD HERE",
          maxFiles: 4,
          parallelUploads: 4,
          uploadMultiple: false,
          autoProcessQueue: false,
          acceptedFiles: '.csv',
        },
      }
    },
    methods: {
      show(){
        this.dialog = true
        this.file_type = 'JOB'
        this.fileSelected = false      
      },
      close(){
        this.dialog = false
        this.$refs.myVueDropzone.removeAllFiles()
      },
      async save(){
        
        for(let i = 0; i < this.jobFiles.length; i ++){
          if(this.jobFiles[i].file_type == this.file_type) {
            this.fileUploadeErrorDialog = true
            return
          }
        }

        this.uploading = true
        this.$refs.myVueDropzone.processQueue();
        this.dialog = false
      },
      sendingEvent (file, xhr, formData) {
        formData.append('job_id', this.activeAuditJob.id)
        formData.append('file_type', this.file_type)
      },      
      fileAdded(file) {
        this.filesSelected = true
      },
      filesAdded(file) {
        this.filesSelected = true
      },
      fileRemoved(file, error, xhr) {
        const files = this.$refs.myVueDropzone.getAcceptedFiles()
        if(files.length == 0) {
          this.filesSelected = false
        }        
      },
      vsuccess (file, response) {
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: file.name + ' uploaded!'
        })
        this.$store.dispatch('loadjobFiles', this.activeAuditJob.id)
        this.uploading = false
      },   
    },
    watch: {
    }
  }
</script>


<style>
.vue-dropzone-multiple {
    display: block !important;
  }
</style>