<template>
  <v-row dense class="reduce-row-gaps">
    <v-dialog v-model="editUserDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Project Attributes</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <v-text-field v-model="projectName" label="Project Name" outlined>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="description" label="Short Description" outlined>
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="date" label="Election Date" type="date" outlined></v-text-field>
                <!-- v-on="on" -->
              </v-col>
              <v-col cols="6">
                <v-select v-model="country" :items="countries" item-text="name" item-value="code" label="Country"
                  outlined></v-select>
              </v-col>
              <v-col cols="6">
                <v-select v-if="country == 'US'" v-model="US_state" :items="US_states" item-text="name" item-value="value"
                  label="State" outlined></v-select>
                <v-text-field v-else v-model="state" label="State" outlined></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field @keypress="isNumber($event)" v-on:keyup="handleChange()" min="0" v-model="donationTarget"
                  label="Donation Target" type="number" outlined>
                </v-text-field>
              </v-col>
              <!-- <v-col cols="12"> -->
              <!-- <v-textarea
                  outlined
                  v-model="projectIntroText"
                  label="Project Intro Text"
                ></v-textarea> -->

              <!-- <v-text-field
                  v-model="projectIntroText"
                  label="Project Intro Text" 
                  outlined >
                </v-text-field> -->
              <!-- </v-col> -->
              <v-col cols="12" sm="12" md="12">
                <label>Project Intro Text</label>
                <vue-simplemde :highlight="true" v-model="projectIntroText" />
              </v-col>
              <v-col cols="12">
                <v-select v-model="accessibility" :items="accessibilities" label="Accessibility" outlined></v-select>
                <!-- :readonly="editNotAllowed || projectId > 0" -->
              </v-col>
              <!-- <v-col cols="6">
                <v-select :items="roleItems" label="User Role" outlined ></v-select>
              </v-col> -->
              <!-- <v-col cols="6">
                <v-text-field
                  v-model="projectURL"
                  label="Project URL"
                  outlined>
                </v-text-field>
              </v-col> -->
              <v-col cols="12">
                <v-combobox :items="elections" v-model="selectedElections" label="Adopt Existing Election" multiple
                  item-text="name" item-value="id">
                  <template v-slot:selection="data">
                    <v-chip :key="JSON.stringify(data.item.id)" v-bind="data.attrs" :input-value="data.selected"
                      :disabled="data.disabled">
                      <v-avatar class="accent white--text" left
                        v-text="data.item.name.slice(0, 1).toUpperCase()"></v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6">
                <v-checkbox v-model="active" label="active" outlined>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="closeDialog()">CANCEL</v-btn>
          <v-btn color="blue darken-1" outlined @click="saveEditedUserData(isProjectData)">
            {{ !isProjectData ? 'Add' : 'Update' }}
            <v-progress-circular indeterminate color="primary" v-if="getUserUpdateLoading"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="comanConfirmDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title style="word-break: break-word;">Are you sure you want to close without Updating?</v-card-title>
        <v-card-actions class="pa-8">
          <v-spacer></v-spacer>
          <v-btn class="btn-outline" outlined @click="comanConfirmDialog = false">Cancel</v-btn>
          <v-btn color="error" outlined @click="con_dialog" :disabled="overlay">
            Ok
            <v-progress-circular v-if="overlay" :width="3" color="error" indeterminate></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-card max-width="100%">
        <v-card-title class="mb-6">
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="Search" single-line hide-details
            clearable></v-text-field>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn v-if="this.set_per" @click="createProject()" color="blue-grey darken-1"> Add Project </v-btn>

        </v-card-title>
        <v-container grid-list-xl fluid>
          <v-layout row wrap>

            <!-- :loading="electionLoading" -->
            <v-data-table :items="projects" :headers="headers" :search="search" :loading="projectLoading"
              class="elevation-1" loading-text="Project Data Loading ..." style="width: 100%;">

              <template v-slot:[`item.name`]="{ item }">
                <v-span style="border-bottom: 1px solid;" @click.prevent="openProject(item)">{{ item.name }}</v-span>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn icon @click.prevent="openProject(item)" v-bind="ttprops" v-on="on">
                      <v-icon>folder_open</v-icon>
                    </v-btn>
                  </template>
                  <span>Open Project</span>
                </v-tooltip>

                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, ttprops }">
                    <v-btn v-if="set_per" icon @click.prevent="editProjectSetData(item)" v-bind="ttprops" v-on="on">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Project</span>
                </v-tooltip>

                <!-- <v-btn v-if="item.role == 'Auditor'" icon @click.prevent="deleteElection(item)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn> -->
              </template>
            </v-data-table>
          </v-layout>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
import { AbbrCountries } from './../../services/AbbrCountries.js'
import { AbbrStates } from './../../services/AbbrStates.js'
import VueSimplemde from 'vue-simplemde'
import hljs from 'highlight.js';
window.hljs = hljs;
export default {
  name: 'ProjectsView',


  components: { VueSimplemde },

  computed: {
    ...mapGetters(['elections', 'projects', 'error', 'projectLoading', 'activeProject', 'user', 'getUserUpdateLoading']),
  },

  async mounted() {
    this.set_per = await JSON.parse(localStorage.getItem('user')).project_permission;
    await this.$store.dispatch('loadProjects')
    await this.$store.dispatch('loadElections')
    this.US_states.unshift({ name: 'Any', value: 'ANY' });
  },

  data() {
    return {
      set_per: null,
      search: '',
      id: '',
      projectName: '',
      description: '',
      date: new Date().toISOString().substr(0, 10),
      editUserDialog: false,
      countries: AbbrCountries,
      US_states: AbbrStates,
      country: 'US',
      state: '',
      US_state: 'ANY',
      county: '',
      donationTarget: 0,
      projectIntroText: '',
      accessibility: '',
      projectURL: '',
      active: false,
      comanConfirmDialog: false,
      accessibilities: ['Public', 'Private'],
      headers: [
        { text: 'Project', value: 'name', width: '40%' },
        { text: 'Description', align: 'start', value: 'description', width: '50%' },
        { text: 'Actions', value: 'actions', width: '10%' },
      ],
      isProjectData: false,
      updateProject: [],
      selectedElections: [],
    }
  },
  methods: {
    editProjectSetData(item) {
      this.isProjectData = true
      this.id = item.id
      this.projectName = item.name
      this.description = item.description
      this.date = item.election_date
      this.country = item.country
      this.US_state = item.state
      this.donationTarget = item.donation_target
      this.projectIntroText = item.pro_intro
      this.accessibility = item.avaliblity
      this.projectURL = item.project_url
      this.active = item.active
      if (item.election_ids) {
        let tempArray = [];
        let electionIds = item.election_ids.split(',');
        electionIds.forEach(element => {
          tempArray.push(this.elections.find(x => x.id == element))
        });
        this.selectedElections = tempArray;
      } else {
        this.selectedElections = [];
      }
      this.editUserDialog = true
    },
    async saveEditedUserData(item) {
      let idString = '';
      this.selectedElections.map((item, index) => {
        idString = idString + `${item.id}`
        if (this.selectedElections.length !== index + 1) {
          idString = idString + ','
        }
      })
      let payload = {
        id: this.id ? this.id : '',
        name: this.projectName,
        description: this.description,
        election_date: this.date,
        country: this.country,
        state: this.country !== 'US' ? this.state : this.US_state,
        donation_target: this.donationTarget,
        project_intro: this.projectIntroText,
        project_url: 'https://engine.auditengine.org/projects/' + this.projectName,
        election_ids: idString,
        avaliblity: this.accessibility,
        active: this.active,
      }
      await this.$store.dispatch('saveProject', payload)
      // this.$store.dispatch('loadUsers')
      if (this.error && this.error) {
        this.editUserDialog = true
      } else {
        this.editUserDialog = false
        this.isProjectData = false
        this.id = ''
        this.projectName = ''
        this.description = ''
        this.date = new Date().toISOString().substr(0, 10)
        this.country = 'US'
        this.US_state = 'CA'
        this.donationTarget = 0
        this.projectIntroText = ''
        this.accessibility = ''
        this.projectURL = ''
        this.active = false
      }
    },
    closeDialog() {
      this.comanConfirmDialog = true;
      // if(confirm("Are you sure you want to close without updating?")){
      //   this.editUserDialog = false
      //   this.isProjectData = false
      //   this.id = ''
      //   this.projectName = ''
      //   this.description = ''
      //   this.date =  new Date().toISOString().substr(0, 10)
      //   this.country = 'US'
      //   this.US_state = 'CA'
      //   this.donationTarget = ''
      //   this.projectIntroText = ''
      //   this.accessibility = ''
      //   this.projectURL = ''
      //   this.active = false
      //   this.selectedElections = []
      // }
    },
    con_dialog() {
      this.comanConfirmDialog = false
      this.editUserDialog = false
      this.id = ''
      this.projectName = ''
      this.description = ''
      this.date = new Date().toISOString().substr(0, 10)
      this.country = 'US'
      this.US_state = 'CA'
      this.donationTarget = 0
      this.projectIntroText = ''
      this.accessibility = ''
      this.projectURL = ''
      this.active = false
      this.selectedElections = []
    },
    setEditData() { },
    async createProject() {
      this.projectIntroText = ''
      this.editUserDialog = true
    },
    async openProject(item) {
      // console.log(item.id)
      // await this.$store.dispatch('getproject', item.id)
      await this.$store.dispatch('getDonors', item.name)
      this.$router.push(`/projects/${item.name}`)
    },
    handleChange(evt) {
      evt = (evt) ? evt : window.event
      var code = (evt.which) ? evt.which : evt.keyCode
      if (code == 189 || this.donationauditprice < 0) {
        this.donationauditprice = 0
        return false
      }
      return true
    },

    isNumber($evt) {
      if ($evt.keyCode != 45) {
        return true
      } else {
        $evt.preventDefault();
      }
    },
  },

  watch: {
  },
}
</script>
