import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/projects'
const SINGLE_URL = '/single-project'
const PUPLIC_URL = '/public-single-project'
const DONORS_URL = '/project-payment'
const FUNDRING_URL = '/funding-progress'
const OFFLINE_DONATION = '/get-manually-donation'
const ADD_OFFLINE_DONATION = '/add-manually-donation'
// const USER_URL = '/project-users/project'

const state = {
  projects: [],
  jurisdictions: [],
  donors: [],
  activeProject: {},
  projectLoading: false,
  donorLoading: false,
  fundLoading: false,
  progress: [],
  error: false,
  getManualDonors: [],
  addManualDonors: [],
}

const getters = {
  projects: (state) => {
    return state.projects
  },
  //   jurisdictions: state => {
  //     return state.jurisdictions
  //   },
  donors: (state) => {
    return state.donors
  },
  activeProject: (state) => {
    return state.activeProject
  },
  projectLoading: (state) => {
    return state.projectLoading
  },
  error: (state) => {
    return state.error
  },
  fundLoading: (state) => {
    return state.fundLoading
  },
  progress: (state) => {
    return state.progress
  },
  getManualDonors: (state) => {
    return state.getManualDonors
  },
  addManualDonors: (state) => {
    return state.addManualDonors
  },
}

function get(context, url, handler) {
  return api()
    .get(url)
    .then((response) => handler(context, response))
    .catch(error=>handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api()
    .post(url, data)
    .then((response) => handler(context, response, data))
    .catch(error=>handleApiError(error, ''))
}

function put(context, url, data, handler) {
  return api()
    .put(url, data)
    .then((response) => handler(context, response, data))
    .catch(error=>handleApiError(error, ''))
}

// function apiDelete(context, url, id, handler) {
//   return api().delete(url + '/' + id).then(response => handler(context, id, response))
// }

function handleProjectsLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const projects = response['data']
  context.state.projectLoading = false
  if (projects['error']) {
    context.commit('apiError', projects['error'])
    logApiService(obj, projects['error'], EventName.Project, EventType.projectsLoad)
  } else {
    logApiService(obj, 'Fetch Projects', EventName.Project, EventType.projectsLoad)
    context.commit('projectsLoaded', projects)
  }
  return projects
}

function handleProjectSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const project = response['data']
  if (project['error']) {
    Vue.notify({
      group: 'messages',
      type: 'error',
      text: project['error'],
    })
    logApiService(obj, project['error'], EventName.Project, EventType.projectSave)
    context.commit('apiError', project['error'])
  } else {
    if (project.id) {
      context.commit('projectSuccess', project)
    } else {
      logApiService(obj,'Project Save', EventName.Project, EventType.projectSave)
      context.commit('projectUpdateSuccess', project)
    }
  }
  return project
}

function handleManualDonationSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const project = response['data']
  if (project['error']) {
    Vue.notify({
      group: 'messages',
      type: 'error',
      text: project['error'],
    })
    context.commit('apiError', project['error'])
    logApiService(obj, project['error'], EventName.Project, EventType.manualDonationSave)
  } else {
    logApiService(obj,  'Manually donation save', EventName.Project, EventType.manualDonationSave)
    context.commit('manualDonationSuccess', project)
  }
  return project
}

// function handleProjectDelete(context, id, response) {
//   const project = response['data']
//   if (project['error']) {
//     context.commit('apiError', project['error'])
//   } else {
//     context.commit('projectDeleted', id)
//   }
// }

function handleGetProject(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const project = response['data']
  if (project['error']) {
    context.commit('apiError', project['error'])
    logApiService(obj, project['error'], EventName.Project, EventType.getProject)
  } else {
    logApiService(obj, 'Fetch Active Project', EventName.Project, EventType.getProject)
    context.commit('setActiveProject', project)
  }
  return project
}

function handleGetFundring(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const project = response['data']
  context.state.fundLoading = false
  if (project['error']) {
    logApiService(obj, project['error'], EventName.Project, EventType.getFundring)
    context.commit('apiError', project['error'])
  } else {
    logApiService(obj, 'Fetch Funding', EventName.Project, EventType.getFundring)
    context.commit('setProgress', project)
  }
  return project
}

function handleGetDonors(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const donor = response['data']
  context.state.donorLoading = false
  if (donor['error']) {
    context.commit('apiError', donor['error'])
    logApiService(obj, donor['error'], EventName.Project, EventType.getDonors)
  } else {
    context.commit('setActiveDonor', donor)
    logApiService(obj, 'Fetch Active Donor', EventName.Project, EventType.getDonors)
  }
  return donor
}

function handleGetManuallyDonors(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const donor = response['data']
  context.state.donorLoading = false
  if (donor['error']) {
    logApiService(obj, donor['error'], EventName.Project, EventType.getManuallyDonors)
    context.commit('apiError', donor['error'])
  } else {
    context.commit('setManuallyDonor', donor)
    logApiService(obj, 'Fetch Manually Donor', EventName.Project, EventType.getManuallyDonors)
  }
  return donor
}

// function handleGetJurisdiction(context, response) {
//   const jurisdictions = response['data']
//   if (jurisdictions['error']) {
//     context.commit('apiError', jurisdictions['error'])
//   } else {
//     context.commit('setJurisdictions', jurisdictions)
//   }
//   return jurisdictions
// }

const actions = {
  loadProjects(context, id) {
    if (context.state.projectLoading) return

    context.state.projectLoading = true
    get(context, URL, handleProjectsLoad)
  },

  saveProject(context, payload) {
    let projectData = {
      name: payload['name'],
      description: payload['description'],
      election_date: payload['election_date'],
      country: payload['country'],
      state: payload['state'],
      donation_target: payload['donation_target'],
      project_intro: payload['project_intro'],
      project_url: payload['project_url'],
      election_ids: payload['election_ids'],
      avaliblity: payload['avaliblity'],
      active: payload['active'],
    }
    if (payload['id']) {
      projectData['id'] = payload['id']
      return put(context, SINGLE_URL + '/' + payload['name'], projectData, handleProjectSave)
    } else {
      return post(context, URL, projectData, handleProjectSave)
    }
  },

  saveManuallyDonation(context, payload) {
    return post(context, ADD_OFFLINE_DONATION, payload, handleManualDonationSave)
  },

  setProjectDonation(context, id) {
    if (context.state.projectLoading) return
    context.state.projectLoading = true
    return get(context, '/projectdonation-success/' + id, handleGetProject)
  },

  //   deleteProject(context, id) {
  //     return apiDelete(context, URL, id, handleProjectDelete)
  //   },

  getproject(context, name) {
    var token_data = localStorage.getItem('user')
    if (token_data) {
      return get(context, SINGLE_URL + '/' + name, handleGetProject)
    } else {
      return get(context, PUPLIC_URL + '/' + name, handleGetProject)
    }
  },
  getfundring(context, name) {
    if (context.state.fundLoading) return
    context.state.fundLoading = true
    return get(context, FUNDRING_URL + '/' + name, handleGetFundring)
  },
  getDonors(context, id) {
    if (context.state.donorLoading) return
    context.state.donorLoading = true
    return get(context, DONORS_URL + '/' + id, handleGetDonors)
  },
  getManuallyDonation(context, name) {
    if (context.state.donorLoading) return
    context.state.donorLoading = true
    return get(context, OFFLINE_DONATION + '/' + name, handleGetManuallyDonors)
  },
  //   getJurisdiction(context, state) {
  //     return get(context, '/public/us/jurisdictions' + '/' + state, handleGetJurisdiction)
  //   },
}

const mutations = {
  projectsLoaded(state, projects) {
    state.projects = []
    for (let i = 0; i < projects.length; i++) {
      state.projects.push(projects[i])
    }
  },

  projectSuccess(state, project) {
    state.activeProject = project
    state.error = false
    // for (let i = 0; i < state.projects.length; i++) {
    //   if (state.projects[i].id && state.projects[i].id == project.id) {
    //     state.projects.splice(i, 1, project.project)
    //     Vue.notify({
    //       group: 'messages',
    //       type: 'success',
    //       text: 'Project Updated'
    //     })
    //     return
    //   }
    // }

    state.projects.push(project)
    logApiService(project, 'Project Created', EventName.Project, EventType.projectSave)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Project Created',
    })
  },

  manualDonationSuccess(state, donation) {
    state.addManualDonors = donation
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Manually donation save successfully',
    })
  },

  projectUpdateSuccess(state, project) {
    state.activeProject = project
    state.error = false
    for (let i = 0; i < state.projects.length; i++) {
      if (state.projects[i].id && state.projects[i].id == project.project.id) {
        state.projects.splice(i, 1, project.project)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Project Updated',
        })
        return
      }
    }

    // state.projects.push(project)
  },

  //   projectDeleted(state, id) {
  //     for (let i = 0; i < state.projects.length; i++) {
  //       if (state.projects[i].id == id) {
  //         state.projects.splice(i, 1)
  //         Vue.notify({
  //           group: 'messages',
  //           type: 'success',
  //           text: 'Project deleted'
  //         })
  //       }
  //     }
  //   },

  //   clearLoadedProjects(state){
  //     state.projectLoading = false
  //     state.projects = []
  //   },

  setActiveProject(state, project) {
    state.activeProject = project
  },
  setProgress(state, funding) {
    state.progress = []
    for (let i = 0; i < funding.data.length; i++) {
      state.progress.push(funding.data[i])
    }
  },

  setActiveDonor(state, donor) {
    state.donors = donor
  },

  setManuallyDonor(state, donor) {
    state.getManualDonors = donor
  },

  apiError(state, error) {
    state.error = true
  },

  //   setJurisdictions(state, jurisdictions) {
  //     state.jurisdictions = jurisdictions
  //   },
}

export default {
  state,
  getters,
  mutations,
  actions,
  name: 'ProjectStore',
}
