<template>
  <v-row dense class="reduce-row-gaps">

    <v-col cols="12">
      <v-card max-width="100%">
        <v-card-title class="mb-6">
            Manually Donation
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
            <v-container grid-list-xl fluid>
                <v-row>
                  <v-col cols="6">
                    <v-label>AMOUNT:</v-label>
                      <v-text-field
                      @keypress="isNumber($event)"
                      v-on:keyup="handleChange()"
                      min="0"
                      v-model="amount"
                      label="Amount Value"
                      type="number"
                      outlined>
                      </v-text-field>
                  </v-col>
                  <v-col cols="6">
                  <v-label>TEXT BOX:</v-label>
                  <v-textarea
                      clearable
                      clear-icon="cancel"
                      label="Description"
                      v-model="description"
                      outlined
                  ></v-textarea>
                      <!-- <div class="form-group" v-for="(input,k) in inputs" :key="k">
                          <v-row>
                              <v-col cols="8">
                                  <v-text-field
                                      v-model="input.description"
                                      type="text"
                                      outlined
                                  ></v-text-field>
                              </v-col>
                              <v-col cols="4" class="mt-5">
                                  <v-span>
                                      <v-btn icon @click.prevent="remove(k)" v-show="k || ( !k && inputs.length > 1)">
                                          <v-icon>mdi-minus</v-icon>
                                      </v-btn>
                                      <v-btn icon @click.prevent="add(k)"  v-show="k == inputs.length-1">
                                          <v-icon>mdi-plus</v-icon>
                                      </v-btn>
                                  </v-span>
                              </v-col>
                          </v-row>
                      </div> -->
                  </v-col>
                </v-row>
                
            </v-container>
        </v-card-text>
        <v-card-actions>
         <v-btn color="blue darken-1" outlined @click="addManyallyDonation()">
            Submit
            <v-progress-circular indeterminate color="primary" v-if="manullydonation"></v-progress-circular>
          </v-btn>
          <v-btn color="blue darken-1" outlined @click="cancleclick()">CANCEL</v-btn>
          
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FundingProgression',
  components: {
  },
  data() {
    return {
      instructionDialog: false,
      processProjectList: [],
      search: '',
      donation_id:0,
      amount:0,
      manullydonation:false,
      description:'',
      // inputs: [
      //       {
      //           description: ''
      //       }
      //   ]
    }
  },
  computed: {
    ...mapGetters(['fundLoading','activeProject','progress','SetProgress', 'getManualDonors']),
  },
  watch: {
  },
  mounted() {
    this.$store.dispatch('getManuallyDonation', this.activeProject.project.name)
    setTimeout(() => {
      this.amount = this.getManualDonors && this.getManualDonors.price
      this.description = this.getManualDonors && this.getManualDonors.description?this.getManualDonors.description:this.description
      this.donation_id = this.getManualDonors && this.getManualDonors.id
    }, 2000);
  },
  methods: {
        cancleclick(){
            this.amount = this.getManualDonors && this.getManualDonors.price?this.getManualDonors.price:0;
            this.description = this.getManualDonors && this.getManualDonors.description?this.getManualDonors.description:this.description = '';
        },
        // add(index) {
        //     this.inputs.push({ description: '' });
        // },
        // remove(index) {
        //     this.inputs.splice(index, 1);
        // },
        handleChange(evt){
          evt = (evt) ? evt : window.event
          var code = (evt.which) ? evt.which : evt.keyCode
          if(code == 189 || this.donationauditprice < 0) {
              this.donationauditprice = 0
              return false
          }
          return true
        },

        isNumber($evt){
          if($evt.keyCode != 45) {
              return true
          } else {
              $evt.preventDefault();
          }
        },

       async addManyallyDonation() {
         this.manullydonation = true
          const payload = {
            price: Number(this.amount), 
            project_id: this.activeProject.project.name, 
            description:this.description
            // description: JSON.stringify(this.inputs)
          }
          if(this.donation_id != 0){
            payload['donation_id'] = this.donation_id;
          }
          await this.$store.dispatch('saveManuallyDonation',payload)
          this.manullydonation = false
        }
  },
}
</script>

<style>
h3{
  float: right;
}
button.v-btn.v-btn--depressed.v-btn--flat.v-btn--outlined.theme--dark.v-size--default.blue--text.text--darken-1 {
    float: right;
    margin-right: 10px;
    margin-top: 10px;
}

.theme--dark.v-btn.donateValue {
  left: 5px;
  margin: 5px;
}

.v-application p.highlight{
  margin: 10px;
}
.v-application p.click{
  margin: 10px;
}
.v-application p.click:hover{
  cursor: pointer;
}
</style>
