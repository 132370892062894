import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/files'

const state = {
  uploadSets: [],
  uploadSetLoading: false,
  activeUploadSet: {},
  urls: [],
  MPUInProgress: false,
  adoptableFiles: [],
  uploadedFiles: []
}

const getters = {
  uploadSets: state => {
    return state.uploadSets
  },
  uploadSetLoading: state => {
    return state.uploadSetLoading
  },
  activeUploadSet: state => {
    return state.activeUploadSet
  },
  urls: state => {
    return state.urls
  },
  MPUInProgress: state => {
      return state.MPUInProgress
  },
  adoptableFiles: state => {
      return state.adoptableFiles
  },
  uploadedFiles: state => {
    return state.uploadedFiles
  }
}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response)).catch((error => {
    handleApiError(error, '');
    handler(context, error);
  }))
                                            
}

function post(context, url, data, handler) {
  return api().post(url, data).then(response => handler(context, response))
  .catch(function (error) {handleApiError(error, ''); return error.response})
}

function put(context, url, data, handler) {
  return api().put(url, data).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response)).catch(error=>handleApiError(error, ''))
}

function handleUploadSetsLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const uploadSets = response['data']
  context.state.uploadSetLoading = false
  if (response['message'] ? response['message'] : uploadSets['error'] ) {
    logApiService(obj, response['message'] ? response['message'] : uploadSets['error'], EventName.Upload, EventType.uploadSetsLoad)
    context.commit('apiError', uploadSets['error'])
  } else {
    logApiService(obj, "Fetch Upload Sets File", EventName.Upload, EventType.uploadSetsLoad)
    context.commit('uploadSetsLoaded', uploadSets)
  }
}

function handleUploadFiles(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const uploadSets = response
  if (response['message'] ? response['message'] : uploadSets['error']) {
    logApiService(obj, response['message'] ? response['message'] : uploadSets['error'], EventName.Upload, EventType.uploadSetsLoad)
    context.commit('apiError', uploadSets['error'])
  } else {
    logApiService(obj, 'Fetch Upload Files', EventName.Upload, EventType.uploadSetsLoad)
    context.commit('uploadFiles', uploadSets['data'])
  }
  return uploadSets;
}

function handleUploadSetDelete(context, id, response) {
  const uploadSet = response['data']
  if (uploadSet['error']) {
    context.commit('apiError', uploadSet['error'])
  } else {
    context.commit('uploadSetDeleted', id)
  }
}

function handleUploadInit(context, response) {
    const uploadSet = response['data']    
    if (uploadSet['error']) {
      context.commit('apiError', uploadSet['error'])
    } else {
      context.commit('setActiveUploadSet', uploadSet)
    }
    return uploadSet;
}

function handleUploadComplete(context, response) {
    const uploadSet = response['data']
    if (uploadSet['error']) {
      context.commit('apiError', uploadSet['error'])
    } else {
      context.commit('uploadSetSuccess', uploadSet)
    }
    return uploadSet;
}

function handleUploadAbort(context, response) {
    const uploadSet = response['data']
    if (uploadSet['error']) {
      context.commit('apiError', uploadSet['error'])
    } else {
      context.commit('uploadSetSuccess', uploadSet)
    }
    return uploadSet;
}

function handleAdoptFile(context, response) {
  const uploadSet = response['data']
  if (uploadSet['error']) {
    context.commit('apiError', uploadSet['error'])
  } else {
    context.commit('uploadSetSuccess', uploadSet)
  }
  return uploadSet;
}

function handleAdoptableFilesLoad(context, response) {
  const data = response['data']
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  if (data['error']) {
    logApiService(obj, data['error'], EventName.Elections, EventType.adopTableFilesLoad)
    context.commit('apiError', data['error'])
  } else {
    logApiService(obj, 'Fetch Adopt Table Files', EventName.Elections, EventType.adopTableFilesLoad)
    context.commit('adoptableFilesLoaded', data)
  } 
  return data
}

const actions = {
  loadUploadSets(context, election_id) {
    if(context.state.uploadSetLoading)return
    context.state.uploadSetLoading = true
    get(context, URL + '/' + election_id, handleUploadSetsLoad)
  },
  DeleteUploadSet(context, id) {
    return apiDelete(context, URL, id, handleUploadSetDelete)
  },
  CreateUploadSetDistrict(context, payload) {
    return post(context, URL, payload, handleUploadInit)
  },
  AdoptUploadSet(context, payload) {
    return post(context, URL + '/adopt', payload, handleAdoptFile)
  },
  UpdateUploadSet(context, payload) {    
    return put(context, URL + '/' + payload.id, payload, handleUploadComplete)
  },
  loadAdoptableFiles(context, election_id) {
    return get(context, URL + '/adoptable/' + election_id, handleAdoptableFilesLoad)
  },
  getAllLoadFiles(context, election_id){
    return get(context, URL + '/' + election_id, handleUploadFiles)
  }
}


const mutations = {
  uploadSetsLoaded(state, uploadSets) {
    state.uploadSets = []
    for (let i = 0; i < uploadSets.length; i++) {
      state.uploadSets.push(uploadSets[i])
    }
  },

  setActiveUploadSet(state, uploadSet) {
    state.activeUploadSet = uploadSet.file
    state.urls = uploadSet.urls

    for (let i = 0; i < state.uploadSets.length; i++) {
      if (state.uploadSets[i].id == uploadSet.file.id) {
        state.uploadSets.splice(i, 1, uploadSet.file)
        return
      }
    }
    state.uploadSets.push(uploadSet.file)
    
  },

  uploadSetSuccess(state, uploadSet) {
    state.MPUInProgress = false
   
    for (let i = 0; i < state.uploadSets.length; i++) {
      if (state.uploadSets[i].id == uploadSet.id) {
        state.uploadSets.splice(i, 1, uploadSet)
        return
      }
    }
    state.uploadSets.push(uploadSet)
  },

  uploadSetDeleted(state, id) {
    for (let i = 0; i < state.uploadSets.length; i++) {
      if (state.uploadSets[i].id == id) {
        state.uploadSets.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Upload log deleted!'
        })
      }
    }
  },  

  adoptableFilesLoaded(state, files) {
    state.adoptableFiles = []
    for (let i = 0; i < files.length; i++) {
      state.adoptableFiles.push(files[i])
    }
  },
  uploadFiles(state, data){
    state.uploadedFiles = [];
    for (let i = 0; i < data.length; i++) {
      state.uploadedFiles.push(data[i])
    }
  }
}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'UploadSetStore'
}
