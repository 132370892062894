<template>
  <dash-layout>
    <template slot="dash-title">{{ tabs[activeTab].name }}</template>

    <template slot="dash-body">
      <v-tabs show-arrows v-model="tab" class="mb-1">
        <v-tab v-for="tab in tabs" :key="tab.component" :ripple="false" :ref="'btn' + tab.component">{{ tab.name }}</v-tab>
      </v-tabs>

      <transition name="component-fade" mode="out-in">
        <keep-alive>
          <component v-bind:is="tabs[activeTab].component"></component>
        </keep-alive>
      </transition>
    </template>
  </dash-layout>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import DashLayout from '../../containers/Layout'  
  // import AuditsList from '../AdminBoard/AuditList'
  import ProjectsView from './ProjectsView.vue'
  import HomePage from './HomePage.vue'
  // import AuditDetail from './AuditProjectList.vue'

  export default {
    name: 'HomeBoard',
    props: ['component'],
    components: {
      DashLayout,
      // AuditsList,
      ProjectsView,
      HomePage
      // AuditDetail
    },
    computed: {
      ...mapGetters(['user']),
      tab: {
        get () {
          return this.activeTab
        },
        set (value) {
          this.activeTab = value
        }
      }
    },

    mounted() {
      let location = window.location.href.split('/')[3];
      // this.activeTab = 1;
      if(location === "projects") {
        this.activeTab = 1
      } else {
        this.activeTab = 0
      }
    },

    data() {
      return {
        currentTabComponent: '',
        activeTab: 0,
        tabs: [
          { name: 'Home', component: 'HomePage' },
          { name: 'Active Projects', component: 'ProjectsView' },
          // { name: 'Admin Dashboard', component: 'AdminDashboard'},
          // { name: 'Argument Specifications', component: 'ArgGroupEditor'},
          // { name: 'Action Specifications', component: 'ActionSpecsEditor'},
          // { name: 'Audit Types', component: 'AuditTypesEditor'},
          // { name: 'AWS Configurations', component: 'AWSConfigEditor'},
        ]
      }
    },

    methods: {
    },


    watch: {
      activeTab(tab){
      }
    }

  }
</script>