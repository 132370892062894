<template>
  <admin-board-layout></admin-board-layout>
</template>

<script>
  import AdminBoardLayout from './AdminBoardLayout'

  export default {

    name: 'AdminBoardView',

    props: ['id'],

    components: {
      AdminBoardLayout,
    },

    mounted() {
    }

  }
</script>