<template>
  <div>
    <div>
      <v-toolbar flat dark class="dash-app-bar" height="100px">
        <!-- <v-app-bar-nav-icon @click="drawerToggle()"></v-app-bar-nav-icon> -->

        <v-toolbar-title class="dash-headline">
          <slot name="dash-title"></slot>
        </v-toolbar-title>

        <slot name="bar-search"></slot>

        <v-spacer></v-spacer>

        <slot name="bar-content"></slot>

      </v-toolbar>

      <slot name="bar-divider"></slot>

      <v-container grid-list-xl fluid px-5 pt-2>
        <slot name="dash-body"></slot>
      </v-container>
    </div>
    <DonationNavbar />
    <router-view></router-view>
  </div>
</template>

<script>
import DonationNavbar from '.././components/DonationNavbar'
export default {
  name: 'Home',
  components: {
    DonationNavbar
  },

  mounted() {},

  methods: {
    drawerToggle() {
      this.$store.commit('setDrawer', !this.getDrawer)
    },
  },
}
</script>
