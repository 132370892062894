import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/district-contacts'

const state = {
    districtContacts: [],
    contactData: {},
    contactLoading: false,
    districtContactbyID: {}
}

const getters = {
    contactLoading: (state) => {
        return state.contactLoading
    },
    districtContacts: (state) => {
        return state.districtContacts
    },
    districtContactbyID: (state) => {
        return state.districtContactbyID
    },
    contactData: state => {
        return state.contactData
    }
}
function get(context, url, handler) {
    return api()
        .get(url)
        .then((response) => handler(context, response))
        .catch(error => handleApiError(error, ''))
}

function post(context, url, data, handler) {
    return api()
        .post(url, data)
        .then((response) => handler(context, response, data))
        .catch(function (error) {
            handleApiError(error, '');
            return handler(context, error.response)
        })
}

function put(context, url, data, handler) {
    return api()
        .put(url, data)
        .then((response) => handler(context, response, data))
        .catch(error => handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
    return api()
        .delete(url + '/' + id)
        .then((response) => handler(context, id, response))
        .catch(error => handleApiError(error, ''))
}


function handleCotantLoad(context, response) {
    let obj = {
        url: `${response.config.baseURL}${response.config.url}`,
      }
    const contactList = response['data']
    context.state.contactLoading = false
    if (contactList['error']) {
        logApiService(obj, contactList['error'], EventName.District_Contact, EventType.contactLoad)
        context.commit('apiError', contactList['error'])
    } else {
        logApiService(obj, `Fetch District Contacts`, EventName.District_Contact, EventType.contactLoad)
        context.commit('districtContactsLoaded', contactList)
    }
}

function handleGetDisContact(context, response) {
    let obj = {
        url: `${response.config.baseURL}${response.config.url}`,
      }
    const dis_contact = response['data']
    if (dis_contact['error']) {
        context.commit('apiError', dis_contact['error'])
        logApiService(obj, dis_contact['error'], EventName.District_Contact, EventType.getDisContact)
    } else {
        context.commit('setActiveDisContact', dis_contact)
        logApiService(obj, 'Fetch Active District Contact', EventName.District_Contact, EventType.getDisContact)
    }
    return dis_contact
}

function handleDisContactDelete(context, id, response) {
    let obj = {
        url: `${response.config.baseURL}${response.config.url}`,
        data: id
      }
    const districtContact = response['data']
    if (districtContact['error']) {
        context.commit('apiError', districtContact['error'])
        logApiService(obj, districtContact['error'], EventName.District_Contact, EventType.disContactDelete)
    } else {
        logApiService(obj, `District Contact Deleted`, EventName.District_Contact, EventType.disContactDelete)
        context.commit('districtContactDeleted', id)

    }
}

function handleDIsContactSave(context, response, data) {
    let obj = {
        url: `${response.config.baseURL}${response.config.url}`,
        data: data
      }
    const newContact = response['data']
    if (newContact['error']) {
        context.commit('apiError', newContact['error'])
        logApiService(obj, newContact['error'], EventName.District_Contact, EventType.disContactSave)
    }
    else {
        logApiService(obj, 'District contact Saved', EventName.District_Contact, EventType.disContactSave)
        context.commit('districtContactSuccess', newContact)
    }
    return newContact
}

const actions = {
    loadContacts(context, districtId) {
        if (context.state.contactLoading) return

        context.state.contactLoading = true
        get(context, URL + '/' + districtId, handleCotantLoad)
    },
    getDistrictContact(context, data) {
        return get(context, URL + '/' + data.districtId + '/' + data.id, handleGetDisContact)
    },
    saveDistrictContext(context, payload) {
        let districtId = payload['districtId'];

        let newContactData = {
            'name': payload['name'],
            'title': payload['title'],
            'address': payload['address'],
            'city': payload['city'],
            'state_zipcode': payload['state_zipcode'],
            'phone_number': payload['phone_number'],
            'email': payload['email'],
            'website': payload['website'],
            'date': payload['date']
        }
        if (payload['id']) {
            newContactData['id'] = payload['id']
            return put(context, URL + '/' + districtId + '/' + payload['id'], newContactData, handleDIsContactSave)
        } else {
            return post(context, URL + '/' + districtId, newContactData, handleDIsContactSave)
        }
    },
    deleteDistrictContact(context, data) {
        return apiDelete(context, URL + '/' + data.districtId, data.id, handleDisContactDelete)
    },
}


const mutations = {
    setContactLoading(state, value) {
        state.contactLoading = value
    },
    districtContactsLoaded(state, contactList) {
        state.districtContacts = []
        for (let i = 0; i < contactList.length; i++) {
            state.districtContacts.push(contactList[i])
        }
    },
    setActiveDisContact(state, dis_contact) {
        state.districtContactbyID = dis_contact
    },
    districtContactSuccess(state, newContact) {
        state.contactData = newContact
        for (let i = 0; i < state.districtContacts.length; i++) {
            if (state.districtContacts[i].id == newContact.id) {
                state.districtContacts.splice(i, 1, newContact)
                Vue.notify({
                    group: 'messages',
                    type: 'success',
                    text: 'District contact Updated'
                })
                return
            }
        }
        state.districtContacts.push(newContact)
        Vue.notify({
            group: 'messages',
            type: 'success',
            text: 'District contact Created'
        })
    },
    districtContactDeleted(state, id) {
        for (let i = 0; i < state.districtContacts.length; i++) {
            if (state.districtContacts[i].id == id) {
                state.districtContacts.splice(i, 1)
                Vue.notify({
                    group: 'messages',
                    type: 'success',
                    text: 'District contact deleted'
                })
            }
        }
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
    name: 'DistrictContactStore'
}