import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/arguments'

const state = {
  arguments: [],
  activeArgument: {},
  argumentsLoading: false
}


const getters = {
    arguments: state => {
    return state.arguments
  },
  activeArgument: state => {
    return state.activeArgument
  },
  argumentsLoading: state => {
    return state.argumentsLoading
  },

}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api().post(url, data).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function put(context, url, data, handler) {
  return api().put(url, data).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response)).catch(error=>handleApiError(error, ''))
}

function handleArgumentsLoad(context, response) {
  const data = response['data']
  context.state.argumentLoading = false
  if (data['error']) {
    logApiService(data, data['error'], EventName.Arg_specs, EventType.argumentsLoad)
    context.commit('apiError', data['error'])
  } else {
    logApiService(data, 'Fetch Arguments', EventName.Arg_specs, EventType.argumentsLoad)
    context.commit('argumentsLoaded', data)
  }
  return data
}

function handleArgumentSave(context, response) {
  const  data = response['data']
  if (data['error']) {
    context.commit('apiError', data['error'])
    logApiService(data, data['error'], EventName.Arg_specs, EventType.argumentSave)
  } else {
    context.commit('argumentSuccess', data)
    logApiService(data, 'Argument Updated', EventName.Arg_specs, EventType.argumentUpdated)
  }
  return data
}

function handleArgumentDelete(context, id, response) {
  const data = response['data']
  if (data['error']) {
    logApiService(data, data['error'], EventName.Arg_specs, EventType.argumentDelete)
    context.commit('apiError', data['error'])
  } else {
    logApiService(data, 'Argument deleted', EventName.Arg_specs, EventType.argumentDelete)
    context.commit('argumentDeleted', id)
  }
}

function handleGetArgument(context, response) {
  const argument = response['data']
  if (argument['error']) {
    context.commit('apiError', argument['error'])
    logApiService(argument, argument['error'], EventName.Arg_specs, EventType.getArgument)
  } else {
    context.commit('setActiveArgument', argument)
    logApiService(argument, 'Fetch Active Argument', EventName.Arg_specs, EventType.getArgument)
  }
  return argument
}

const actions = {
  loadArguments(context, payload) {
    if (context.state.argumentLoading) return
    context.state.argumentLoading = true
    get(context, URL + '/' + payload.audit_type_id + '/' + payload.group_id, handleArgumentsLoad)
  },

  saveArgument(context, payload) {
    if (payload.id) {
      return put(context, URL + '/' + payload.id, payload, handleArgumentSave)
    } else {
      return post(context, URL, payload, handleArgumentSave)
    }
  },

  deleteArgument(context, id) {
    return apiDelete(context, URL, id, handleArgumentDelete)
  },

  getArgument(context, id) {
    return get(context, URL + '/' + id, handleGetArgument)
  },
}


const mutations = {
  argumentsLoaded(state, data) {
    state.arguments = []
    for (let i = 0; i < data.length; i++) {
      state.arguments.push(data[i])
    }
  },

  argumentSuccess(state, data) {
    state.activeArgument = data
    for (let i = 0; i < state.arguments.length; i++) {
      if (state.arguments[i].id == data.id) {
        state.arguments.splice(i, 1, data)        
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Argument Updated'
        })
        return
      }
    }
    logApiService(data, 'Argument Created', EventName.Arg_specs, EventType.argumentCreated) 
    state.arguments.push(data)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Argument Created'
    })
  },

  argumentDeleted(state, id) {
    for (let i = 0; i < state.arguments.length; i++) {
      if (state.arguments[i].id == id) {
        state.arguments.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Argument deleted'
        })
      }
    }
  },

  clearLoadedArguments(state){
    state.argumentLoading = false
    state.arguments = []
  },

  setActiveArgument(state, data) {
    state.activeArgument = data
  },

}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'ArgumentStore'
}
