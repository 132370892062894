<template>
  <v-container>
    <v-card-text>
        <v-container>
          <div class="displayprice">
            <span class="donationprice">
              <span class="dollar-symbol">$</span>
              <v-text-field
                class="inputcontrol"
                type="number"
                min="0"
                @keypress="isNumber($event)"
                v-on:keyup="handleChange()"
                v-model="donationauditprice"
                style="width: 100px;"
                @change="handletotaldonationvalue(donationauditprice)"
              ></v-text-field>
            </span>
            <span class="selectdonateprice">
                <v-select
                  class="inputcontrol"
                  style="width: 250px;"
                  v-model="paymentitem"
                  :items="paymentitems"
                  item-text="audit_name"
                  item-value="audit_status"
                  label="Select Donation Method"
                  @change="handledonationvalue(paymentitem)"
                ></v-select>
            </span>
            <span class="btn-apply">
              <v-btn @click="processToApply()">
                APPLY
              </v-btn>
            </span>
            <span class="totaldonation">
              <div class="total">
                $ {{!totaldonation ? 0 : totaldonation }}
              </div>
            </span>
            <span class="btn-donate">
              <v-btn @click="processToCheckout()" :disabled="!totaldonation ? true : false">
                Checkout
              </v-btn>
            </span>
          </div>
        </v-container>
    </v-card-text>
    <div></div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-data-table
          :headers="headers"
          :items="auditJobs"
          class="elevation-1"
          :loading="auditJobLoading"
          loading-text="Job List Loading ..."
          style="width: 100%; position: relative"
        >
          <template v-slot:[`item.progress`]="{ item }">
            <div>
              <v-progress-linear
                color="amber"
                height="30"
                :value="
                  !isNaN(item.request_donate) && !isNaN(item.received_donation)
                    ? Number(item.request_donate) === 0 
                      ? 0 : (Number(item.received_donation) * 100 / Number(item.request_donate)) 
                    : 0
                "
              >
                <template v-slot="{ value }">
                  <strong>{{ value.toFixed(2) }} %</strong>
                </template>
              </v-progress-linear>
            </div>
          </template>
          <template v-slot:[`item.donation`]="{ item } " class="dollar-alignment">
            <span class="dollar-table">$</span>
            <v-text-field
              v-if="item.id"
              type="number"
              min="0"
              @keypress="isNumber($event)"
              :rules="numberRules"
              v-model="auditDonation[item.id]"
              style="width: 150px;"
              @change="singleAuditDonation(auditDonation, item)"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-layout>
    </v-container>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AuditsDonation',
  computed: {
    ...mapGetters(['auditJobs', 'auditJobLoading', 'createPaymentSessionLoading', 'auditJobsLoaded']),
  },
  data() {  
    return {
      singleAmount: [],
      numberRules: [
          v => !!v || 'input is required',
          v => /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(v) || 'input must be positive',
        ],
      auditDonation: [],
      donateValue: [],
      totaldonation: null,
      donation_status: 1,
      donationauditprice: null,
      perauditdonation: null,
      user_token:null,
      //stripeAPIToken: 'pk_test_7SB2QMpnvPKhyqVlDQNYgaLG',
      stripeAPIToken:'pk_live_8SN1v2fgMje93cz4CQCokc6m',
      stripe: '',
      sessionId:null,
      paymentitems: [ 
        { audit_status: 0, audit_name: 'Clear All' },
        { audit_status:3,audit_name:'General Donation, use as needed'},
        { audit_status: 1, audit_name: 'Donate Same to All' },
        { audit_status: 2, audit_name: 'Split Up to All' },
      ],
      paymentitem: 3,
      headers: [
        { text: 'Name', align: 'start', sortable: false, value: 'name', width: '20%' },
        { text: 'Funding Goal', value: 'request_donate', width: '15%' },
        { text: 'Received', value: 'received_donation', width: '10%' },
        { text: 'Progress', value: 'progress', width: '20%' },
        { text: 'Donation', sortable: false, value: 'donation', width: '15%' },
      ],
    }
  },
  mounted() {
    this.user_token = JSON.parse(localStorage.getItem('user')); 
    if(this.user_token != null)
    {
      this.$store.dispatch('loadAuditJobs')
    } else{
      this.$store.dispatch('loadOpenAuditJobs')
    } 
    this.includeStripe('js.stripe.com/v3/', function(){
        this.configureStripe();
    }.bind(this));

    /*setTimeout(() => {
    this.$store.dispatch('loadAuditJobs')
    }, 2000)*/
    //this.getStripePublishableKey()
    setTimeout(() => {
      this.donationaudit = this.auditJobs.length
      this.donationauditprice = this.auditJobs.length
      this.donateValue = {audit_status: 3, audit_name: 'General Donation, use as needed'}
      this.auditJobs.map((auditJob) => {
        this.auditDonation[auditJob.id] = 0
      })
    }, 2000);

    let uri = window.location.search.substring(1); 
    let params = new URLSearchParams(uri);
    // console.log(params.get("session_id"));
    if(params.get("session_id")) {
      this.$store.dispatch('setSuccess', params.get("session_id"))
      setTimeout(() => {
        if(localStorage.getItem('user')){
          setTimeout(() => {
            this.$router.push('/audit-donation')  
          }, 2000)
        } else{
          setTimeout(() => {
            this.$router.push('/public')
          }, 2000)
        }
      }, 2000)
    }
  },
  methods: {
    includeStripe( URL, callback ){
        let documentTag = document, tag = 'script',
            object = documentTag.createElement(tag),
            scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.src = '//' + URL;
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
        scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe(){
        this.stripe = Stripe(this.stripeAPIToken);            
    },
    singleAuditDonation(auditDonation, item) {
      if(!this.singleAmount.length) {
        this.auditJobs.map((auditJob) => {
          if(item.id === auditJob.id){
            this.auditDonation[auditJob.id] = auditDonation[item.id]
            this.totaldonation = this.totaldonation + Number(auditDonation[item.id])
          }
        })
      } else {
      this.totaldonation = Number(this.totaldonation) + Number(auditDonation[item.id]) - this.singleAmount[item.id]
      }
    },
    handledonationvalue(item) {
      
      this.paymentitems.map((paymentitem) => {
        if(paymentitem.audit_status === item) {
          this.donateValue = paymentitem
          if(this.donateValue.audit_status === 0){
            this.donationauditprice = 0
          }
        }
      })
      this.auditJobs.map((auditJob) => {
        this.auditDonation[auditJob.id] = 0
        this.singleAmount[auditJob.id] = 0
      })
      this.totaldonation = null
    },
    processToApply() {
      
      if(this.donateValue.audit_status === 1) {
        this.totaldonation = this.donationauditprice * this.auditJobs.length
        this.auditJobs.map((auditJob) => {
          this.auditDonation[auditJob.id] = this.donationauditprice
          this.singleAmount[auditJob.id] = this.donationauditprice
        })
      } else if (this.donateValue.audit_status === 2) {
        this.totaldonation = this.donationauditprice
        this.perauditdonation = this.donationauditprice
        let n = 1
        for(let i=this.perauditdonation; i >0; i--) {
          this.auditJobs.map((auditJob) => {
            if(this.perauditdonation !== 0){
              this.auditDonation[auditJob.id] = n + this.auditDonation[auditJob.id]
              this.singleAmount[auditJob.id] = n + this.singleAmount[auditJob.id]
              this.perauditdonation = this.perauditdonation - 1
            }
          })
        }
      }else if (this.donateValue.audit_status === 3){
        this.totaldonation = this.donationauditprice
        
        this.perauditdonation = this.donationauditprice
      } 
      else {
        this.totaldonation = 0
      }
    },
    async processToCheckout(item){
        let donation_list=[];
        $.each(this.auditDonation, function(key,value) {
            if(value != null)
            {
              Number(value) > 0 && donation_list.push({audit_id:key,amount:Number(value)})
            }
          });
          let payload = JSON.stringify({
              audit_ids: JSON.stringify(donation_list),
              price: this.totaldonation,
              host: window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2],
            });

          const res_pay_data = await this.$store.dispatch('genratePaymentSession',payload)
          if(res_pay_data?.sessionId)
          {
            return this.stripe.redirectToCheckout({ sessionId: res_pay_data?.sessionId })
          }
        this.auditJobs.map((auditJob) => {
          this.auditDonation[auditJob.id] = 0
          this.singleAmount[auditJob.id] = 0
        })
    },
    /*processToCheckout(item) {
      
      let donation_list=[];
      $.each(this.auditDonation, function(key,value) {
          if(value != null)
          {
            donation_list.push({audit_id:key,amount:Number(value)})
          }
        });

      // console.log("donation_list", donation_list)

      fetch(`${process.env.VUE_APP_ROOT_API}/donation-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          audit_ids: JSON.stringify(donation_list),
          price: this.totaldonation,
          host: window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2],
          // audit_id: this.selecteAuditId,
        }),
      })
        .then(result => result.json())
        .then(data => {
          // Redirect to Stripe Checkout
          return this.stripe.redirectToCheckout({ sessionId: data.sessionId })
        })
        .then(res => {
          this.$route.push('/public/success?session_id=' + data.sessionId)
        })

      this.auditJobs.map((auditJob) => {
        this.auditDonation[auditJob.id] = 0
        this.singleAmount[auditJob.id] = 0
      })
    },
    getStripePublishableKey() {
      /*this.stripe = Stripe(rice
      //this.stripe = Stripe('pk_test_51HVDbDKjPzH68xcJOjnWnf2o7Pn4qcc1qZHZhkZe8hVRHXCKZqtsa6jwb42q8qwmt7i57XKObnzdJbpIJFMBHDol00vjKXmSZy');
      //this.stripe = Stripe('pk_live_8SN1v2fgMje93cz4CQCokc6m');
      //  this.stripe = Stripe('pk_test_7SB2QMpnvPKhyqVlDQNYgaLG');
      
    //},*/
    handletotaldonationvalue(price) {
      if(price < 0) {
        price=0
      }
      this.auditJobs.map((auditJob) => {
        this.auditDonation[auditJob.id] = 0
        this.singleAmount[auditJob.id] = 0
      })
      this.totaldonation = null
    },
    handleChange(evt){
      evt = (evt) ? evt : window.event
      var code = (evt.which) ? evt.which : evt.keyCode
      if(code == 189 || this.donationauditprice < 0) {
        this.donationauditprice = 0
        return false
      }
      return true      
    },

    isNumber($evt){
      if($evt.keyCode != 45) {
        return true
      } else {
        $evt.preventDefault();
      }
    },
  },
}
</script>

<style>
.displayprice{
  position: relative;
  width: 100%;
  height: 60px;
}
.donationprice{
  position: absolute;
  right: 100px;  
  width: 600px;
  height: 20px;
}
.selectdonateprice{
  position: absolute;
  right: 50px;  
  width: 500px;
  height: 20px;
}
.btn-apply{
  position: absolute;
  right: 50px;  
  width: 220px;
  height: 20px;
}
.totaldonation{
  position: absolute;
  right: 50px;  
  width: 100px;
  height: 20px;
}
.total{
  font-weight: 700;
  font-size: 20px;
  padding: 5px;
}
.btn-donate{
  position: absolute;
  right: 0;
  width: 75px;
  height: 20px;
}
.v-input__control {
  padding: 10px;
}
.inputcontrol > .v-input__control{
  padding: 0px !important;
}
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
  padding: 0px 10px !important;
}
.v-text-field__details {
  max-width: 0px !important;
  min-height: 0px !important;
  display: none;
}
.v-input__slot {
  margin-bottom: 0px !important;
}
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.v-input__control .v-input__slot {
    margin: 15px 0px!important;
}
.dollar-symbol {
    position: absolute;
    top: 20px;
    left: -20px;
    font-weight: bold;
    font-size: 19px;
}
/* .list-item {
  position: relative;
} */
.dollar-table {
    position: absolute;
    margin: 20px -16px;
} 

</style>
