import Vue from 'vue'
import api from 'Api'
import { handleApiError } from '../../../services/apiService'

const URL = '/job-operations'
const AUDIT_URL = '/audit-jobs'

const state = {
  jobOperations: [],
  jobOperationsLoading: false,
  refstatusLoading: false,
  statusInformation: [],
  activeOperation: {},
  updateAuditDonationLoading: false,
}

const getters = {
  jobOperations: state => {
    return state.jobOperations
  },
  jobOperationsLoading: state => {
    return state.jobOperationsLoading
  },
  activeOperation: state => {
    return state.activeOperation
  },
  updateAuditDonationLoading: state => {
    return state.updateAuditDonationLoading
  },
  statusInformation: state => {
    return state.statusInformation
  },
  refstatusLoading: state => {
    return state.refstatusLoading
  },
}

function get(context, url, handler) {
  return api()
    .get(url)
    .then(response => handler(context, response))
    .catch(error => {
      handleApiError(error, '')
      handler(context, error['response'])
    })
}

function post(context, url, data, handler) {
  return api()
    .post(url, data)
    .then(response => handler(context, response))
    .catch(error => {
      handleApiError(error, '')
      handler(context, error['response'])
    })
}

function put(context, url, data, handler) {
  return api()
    .put(url, data)
    .then(response => handler(context, response))
    .catch(error => {
      handleApiError(error, '')
      handler(context, error['response'])
    })
}

function apiDelete(context, url, id, handler) {
  return api()
    .delete(url + '/' + id)
    .then(response => handler(context, id, response))
    .catch(error => {
      handleApiError(error, '')
      handler(context, error['response'])
    })
}

function apiDonationUpdate(context, url, data, handler) {
  return api()
    .put(url, data)
    .then(response => handler(context, response))
    .catch(error => {
      handleApiError(error, '')
      handler(context, error['response'])
    })
}

function handleJobOperationsLoaded(context, response) {
  context.state.jobOperationsLoading = false
  const data = response['data']
  if (data['error']) {
    context.commit('apiError', data['error'])
  } else {
    context.commit('jobOperationsLoaded', data)
  }
  return data
}

function handleJobOperationLaunched(context, response) {
  const operation = response['data']
  if (operation['error']) {
    context.commit('apiError', operation['error'])
  } else {
    context.commit('jobOperationSuccess', operation)
  }
  return operation
}

function handleJobOperationReseted(context, response) {
  const data = response['data']
  if (data['error']) {
    context.commit('apiError', data['error'])
  } else {
    context.commit('jobOperationReseted', data)
  }
  return data
}

function handleJobPipeline(context, response) {
  const data = response['data']
  if (data['error']) {
    context.commit('apiError', data['error'])
  } else {
    // context.commit('jobOperationReseted', data)
  }
  return data
}




function handleJobOperationStopped(context, response) {
  const data = response['data']
  if (data['error']) {
    context.commit('apiError', data['error'])
  } else {
    context.commit('jobOperationStopped', data)
  }
  return data
}

function handleUpdateAuditDonation(context, response) {
  context.state.updateAuditDonationLoading = false
  const data = response['data']
  if (response.status != 200) {
    context.commit('apiError', data['error'])
  } else {
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Audit Updated Successfully',
    })
    context.commit('jobOperationStopped', data)
  }
  return data
}

function handleRefreshStatus(context, response) {
  const data = response['data']
  context.state.jobOperationsLoading = false
  if (data['error']) {
    context.commit('apiError', data['error'])
  } else {
    context.commit('setStatus', data)
  }
  return data
}

const actions = {
  loadJobOperations(context, job_id) {
    if (context.state.jobOperationsLoading) return

    context.state.jobOperationsLoading = true
    return get(context, URL + '/' + job_id, handleJobOperationsLoaded)
  },

  LaunchJobOperation(context, payload) {
    let data = {
      job_id: payload['job_id'],
      group_id: payload['group_id'],
      operation: payload['operation'],
      action: payload['action'],
    }
    if (payload['force_rebuild']) {
      data['force_rebuild'] = payload['force_rebuild']
    }
    if(payload['clear_results']){
      data['clear_results'] = payload['clear_results']
    }
    return post(context, URL, data, handleJobOperationLaunched)
  },

  ResetJobOperation(context, id) {
    return get(context, URL + '/refresh/' + id, handleJobOperationReseted)
  },
  CreatePipeline(context, id){
    return get(context, URL + '/pipeline-status/' + id, handleJobPipeline)
  },
  RefreshStatus(context, id) {
    context.state.jobOperationsLoading = true
    return get(context, URL + '/refresh_status/' + id, handleRefreshStatus)
  },
  Refeshforcefully(context, id) {
    context.state.jobOperationsLoading = true
    return get(context, URL + '/refresh_status_force/' + id, handleRefreshStatus)
  },
  StopJobOperation(context, id) {
    return get(context, URL + '/stop/' + id, handleJobOperationStopped)
  },
  AuditDonationUpdate(context, data) {
    if (context.state.updateAuditDonationLoading) return
    context.state.updateAuditDonationLoading = true
    return apiDonationUpdate(context, AUDIT_URL + '/' + data.id, data, handleUpdateAuditDonation)
  },
}

const mutations = {
  jobOperationsLoaded(state, operations) {
    state.jobOperations = []
    for (let i = 0; i < operations.length; i++) {
      state.jobOperations.push(operations[i])
    }
  },

  jobOperationSuccess(state, operation) {
    state.activeOperation = operation
    for (let i = 0; i < state.jobOperations.length; i++) {
      if (state.jobOperations[i].operation == operation.operation) {
        state.jobOperations.splice(i, 1, operation)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Operation Launched',
        })
        return
      }
    }

    state.jobOperations.push(operation)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'Operation Launched',
    })
  },

  setActiveOperation(state, operation) {
    state.activeOperation = operation
  },

  jobOperationReseted(state, operation) {
    state.activeOperation = operation
    for (let i = 0; i < state.jobOperations.length; i++) {
      if (state.jobOperations[i].operation == operation.operation) {
        state.jobOperations.splice(i, 1, operation)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Operation has been reset',
        })
      }
    }
  },
  jobOperationStopped(state, operation) {
    state.activeOperation = operation
    for (let i = 0; i < state.jobOperations.length; i++) {
      if (state.jobOperations[i].operation == operation.operation) {
        state.jobOperations.splice(i, 1, operation)
        Vue.notify({
          group: 'messages',
          type: 'info',
          text: 'Operation has been Stop',
        })
      }
    }
  },
  setStatus(state, operations) {
    state.statusInformation = []
    for (let i = 0; i < operations.length; i++) {
      state.statusInformation.push(operations[i])
    }
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  name: 'JobOperationsStore',
}
