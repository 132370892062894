import Vue from 'vue'
import api from 'Api'
import { handleApiError } from '../../../services/apiService'

const URL = '/files-district'

const state = {
  uploadSetsDistrict: [],
  uploadSetLoadings: false,
  activeUploadSetDistrict: {},
  urlsDistrict: [],
  isMPUInProgress: false,
  adoptableFilesData: [],
}

const getters = {
  uploadSetsDistrict: state => {
    return state.uploadSetsDistrict
  },
  uploadSetLoadings: state => {
    return state.uploadSetLoadings
  },
  activeUploadSetDistrict: state => {
    return state.activeUploadSetDistrict
  },
  urlsDistrict: state => {
    return state.urlsDistrict
  },
  isMPUInProgress: state => {
      return state.isMPUInProgress
  },
  adoptableFilesData: state => {
      return state.adoptableFilesData
  }
}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api().post(url, data).then(response => handler(context, response))
  .catch(function (error) {handleApiError(error, ''); return error.response})
}

function put(context, url, data, handler) {
  return api().put(url, data).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response)).catch(error=>handleApiError(error, ''))
}

function handleuploadSetsDistrictLoad(context, response) {
  const uploadSetsDistrict = response['data']
  context.state.uploadSetLoadings = false
  if (uploadSetsDistrict['error']) {
    context.commit('apiError', uploadSetsDistrict['error'])
  } else {
    context.commit('uploadSetsDistrictLoaded', uploadSetsDistrict)
  }
}

function handleUploadSetDelete(context, id, response) {
  const uploadSet = response['data']
  if (uploadSet['error']) {
    context.commit('apiError', uploadSet['error'])
  } else {
    context.commit('uploadSetDeleted', id)
  }
}

function handleUploadInit(context, response) {
    const uploadSet = response['data']    
    if (uploadSet['error']) {
      context.commit('apiError', uploadSet['error'])
    } else {
      context.commit('setactiveUploadSetDistrict', uploadSet)
    }
    return uploadSet;
}

function handleUploadComplete(context, response) {
    const uploadSet = response['data']
    if (uploadSet['error']) {
      context.commit('apiError', uploadSet['error'])
    } else {
      context.commit('uploadSetsDistrictuccess', uploadSet)
    }
    return uploadSet;
}

function handleUploadAbort(context, response) {
    const uploadSet = response['data']
    if (uploadSet['error']) {
      context.commit('apiError', uploadSet['error'])
    } else {
      context.commit('uploadSetsDistrictuccess', uploadSet)
    }
    return uploadSet;
}

function handleAdoptFile(context, response) {
  const uploadSet = response['data']
  if (uploadSet['error']) {
    context.commit('apiError', uploadSet['error'])
  } else {
    context.commit('uploadSetsDistrictuccess', uploadSet)
  }
  return uploadSet;
}

function handleAdoptableFilesLoad(context, response) {
  const data = response['data']
  if (data['error']) {
    context.commit('apiError', data['error'])
  } else {
    context.commit('adoptableFilesLoaded', data)
  }
  return data
}

const actions = {
  loaduploadSetsDistrict(context, district_id) {
    if(context.state.uploadSetLoadings)return
    context.state.uploadSetLoadings = true
    get(context, URL + '/' + district_id, handleuploadSetsDistrictLoad)
  },
  DeleteDistrictUploadSet(context, id) {
    return apiDelete(context, URL, id, handleUploadSetDelete)
  },
  CreateUploadSet(context, payload) {
    return post(context, URL, payload, handleUploadInit)
  },
  AdoptUploadSet(context, payload) {
    return post(context, URL + '/adopt', payload, handleAdoptFile)
  },
  UpdateUploadSetDistrict(context, payload) {    
    return put(context, URL + '/' + payload.id, payload, handleUploadComplete)
  },
  loadAdoptableFilesDistrict(context, district_id) {
    return get(context, URL + '/adoptable/' + district_id, handleAdoptableFilesLoad)
  },
}


const mutations = {
  uploadSetsDistrictLoaded(state, uploadSetsDistrict) {
    state.uploadSetsDistrict = []
    for (let i = 0; i < uploadSetsDistrict.length; i++) {
      state.uploadSetsDistrict.push(uploadSetsDistrict[i])
    }
  },

  setactiveUploadSetDistrict(state, uploadSet) {
    state.activeUploadSetDistrict = uploadSet.file
    state.urlsDistrict = uploadSet.urlsDistrict

    for (let i = 0; i < state.uploadSetsDistrict.length; i++) {
      if (state.uploadSetsDistrict[i].id == uploadSet.file.id) {
        state.uploadSetsDistrict.splice(i, 1, uploadSet.file)
        return
      }
    }
    state.uploadSetsDistrict.push(uploadSet.file)
    
  },

  uploadSetsDistrictuccess(state, uploadSet) {
    state.isMPUInProgress = false
   
    for (let i = 0; i < state.uploadSetsDistrict.length; i++) {
      if (state.uploadSetsDistrict[i].id == uploadSet.id) {
        state.uploadSetsDistrict.splice(i, 1, uploadSet)
        return
      }
    }
    state.uploadSetsDistrict.push(uploadSet)
  },

  uploadSetDeleted(state, id) {
    for (let i = 0; i < state.uploadSetsDistrict.length; i++) {
      if (state.uploadSetsDistrict[i].id == id) {
        state.uploadSetsDistrict.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'Upload log deleted!'
        })
      }
    }
  },  

  adoptableFilesLoaded(state, files) {
    state.adoptableFilesData = []
    for (let i = 0; i < files.length; i++) {
      state.adoptableFilesData.push(files[i])
    }
  },
}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'uploadSetsDistricttoreDistrict'
}
