import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/districts'

const state = {
  districts: [],
  districtData: {},
  districtLoading: false,
  isEditMode: false,
  disctrictContactData: {}
}
const getters = {
  districts: (state) => {
    return state.districts
  },
  districtLoading: (state) => {
    return state.districtLoading
  },
  districtData: state => {
    return state.districtData
  },
  disctrictContactData: state => {
    return state.disctrictContactData
  },
  isEditMode: state => state.isEditMode,
}
function get(context, url, handler) {
  return api()
    .get(url)
    .then((response) => handler(context, response, url))
    .catch(error => handleApiError(error, ''))
}

 function post(context, url, data, handler) {
  return api()
    .post(url, data)
    .then((response) =>  handler(context, response, data))
    .catch(function (error) { handleApiError(error, ''); return handler(context, error.response, data) })
}

function put(context, url, data, handler) {
  console.log(url, "url");
  return api()
    .put(url, data)
    .then((response) => handler(context, response, data))
    .catch(error => handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api()
    .delete(url + '/' + id)
    .then((response) => handler(context, id, response))
    .catch(error => handleApiError(error, ''))
}

function handleDistrictLoad(context, response) {
  const districts = response['data']
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  context.state.districtLoading = false
  if (districts['error']) {
    logApiService(obj, districts['error'], EventName.District, EventType.districtLoad)
    context.commit('apiError', districts['error'])
  } else {
    logApiService(obj, 'Fetch Districts List', EventName.District, EventType.districtLoad)
    context.commit('districtsLoaded', districts)
  }
  return districts
}

 async function handleDistrictSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const district = response['data']
  if (district['error']) {
     logApiService(obj, district['error'], EventName.District, EventType.districtSaved)
     context.commit('apiError', district['error'])
  }
  else {
    await logApiService(obj, 'District Save', EventName.District, EventType.districtSaved)
    context.commit('districtSuccess', district)
  }
  return district
}

function handleDistrictDelete(context, id, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: id
  }
  const district = response['data']
  if (district['error']) {
    logApiService(obj, district['error'], EventName.District, EventType.districtDelete)
    context.commit('apiError', district['error'])
  } else {
    logApiService(obj, `District deleted`, EventName.District, EventType.districtDelete)
    context.commit('districtDeleted', id)
  }
}

function handleGetDisctric(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const district = response['data']
  if (district['error']) {
    logApiService(obj, district['error'], EventName.District, EventType.getDisctric)
    context.commit('apiError', district['error'])
  } else {
    logApiService(obj, `Fetch District`, EventName.District, EventType.getDisctric)
    context.commit('setActiveDisctrict', district)
  }
  return district
}

const actions = {
  loadDistricts(context, id) {
    if (context.state.districtLoading) return

    context.state.districtLoading = true
    get(context, URL, handleDistrictLoad)
  },
  saveDistrict(context, payload) {

    let districtListData = {
      'name': payload['name'],
      'district_name': payload['district_name'],
      'state_name': payload['state_name'],
      'country_name': payload['country'],
      'address': payload['address'],
      'city': payload['city'],
      'state_zipcode': payload['statePostalCode'],
      'phone_number': payload['phoneNumber'],
      'email': payload['email'],
      'website': payload['website'],
      'date': payload['date']
    }
    if (payload['id']) {
      districtListData['id'] = payload['id']
      return put(context, URL + '/' + payload['id'], districtListData, handleDistrictSave)
    } else {
      return post(context, URL, districtListData, handleDistrictSave)
    }
  },
  getDisctrict(context, id) {
    return get(context, URL + '/' + id, handleGetDisctric)
  },
  deleteDistrct(context, id) {
    return apiDelete(context, URL, id, handleDistrictDelete)
  },
  // deleteDistrctContact(context, data){
  //   return apiDelete(context ,URL + '/' + data.district_id , data.id, handleDistrictContactDelete)
  // }
}


const mutations = {
  setIsEdit(state, value) {
    state.isEditMode = value
  },
  districtsLoaded(state, districts) {
    state.districts = []
    for (let i = 0; i < districts.length; i++) {
      state.districts.push(districts[i])
    }
  },
  districtSuccess(state, district) {

    state.districtData = district
    for (let i = 0; i < state.districts.length; i++) {
      if (state.districts[i].id == district.id) {
        state.districts.splice(i, 1, district)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'District Updated'
        })
        return
      }
    }
    state.districts.push(district)
    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'District Created'
    })
  },
  districtDeleted(state, id) {
    for (let i = 0; i < state.districts.length; i++) {
      if (state.districts[i].id == id) {
        state.districts.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'District deleted'
        })
      }
    }
  },
  setActiveDisctrict(state, district) {
    state.districtData = district
  },
  setActiveDisctrictContact(state, disConatct) {
    state.disctrictContactData = disConatct
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  name: 'DistrictStore'
}