<template>
  <v-row align="center" justify="center">
    <v-dialog v-model="editDialog" max-width="50%" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-if="editedIndex > -1"
                  v-model="editedItem.argument"
                  label="Name"
                  readonly
                ></v-text-field>
                <v-select
                  v-else
                  :items="availableArguments"
                  label="Name"
                  menu-props="auto"
                  v-model="editedItem.argument"
                  :rules="[rules.required]"
                  @change="argumentChanged"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                  v-if="editedItem.choices.length > 0"
                  :items="editedItem.choices"
                  label="Value"
                  v-model="editedItem.value"
                  :rules="[rules.required]"
                ></v-select>
                <v-text-field v-else v-model="editedItem.value" label="Value" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-textarea label="Help" v-model="editedItem.help" readonly></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" outlined @click="closeEdit">Cancel</v-btn>
          <v-btn color="blue darken-1" outlined @click="updateStageItem" :disabled="editedItem.value.length == 0">{{
            formSubmitText
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="70%" persistent>
      <v-card max-width="100%">
        <v-card-title> </v-card-title>

        <v-container grid-list-xl fluid>
          <v-row>
            <v-col cols="12" md="6">
              <!-- <v-text-field v-model="file_name" label="Operation Name" :rules="[rules.required]"></v-text-field> -->
              <v-select
                v-if="stageList.length > 0"
                :items="stageList"
                label="Operation Name"
                v-model="selectedStage"
                item-text="name"
                item-value="name"
                @change="stageChanged()"
              ></v-select>
            </v-col>
          </v-row>

          <v-card flat>
            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="stageItems"
                :loading="actionLoading || argStageLoading"
                :search="search"
                sort-by="calories"
                class="arguments-table"
                :footer-props="{
                  'items-per-page-options': [5, 10, 20, 50],
                }"
                :items-per-page="5"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title></v-toolbar-title>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                      clearable
                    ></v-text-field>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="primary" dark class="mb-2" @click="newItem">Add Argument</v-btn> -->
                  </v-toolbar>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="editStageItem(item)"
                  >
                    mdi-pencil
                  </v-icon>
                    <!-- v-if="
                      item.stages && item.stages == '' ||
                      item.stages == 'session' ||
                      item.stages.split(',').includes(selectedStage) == true
                    " -->
                  <!-- <v-icon small v-if="item.stages && item.stages == '' || item.stages == 'session' || item.stages.split(',').includes(file_name) == true" @click="deleteItem(item)"> mdi-delete </v-icon> -->
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>

          <!-- <v-tabs v-model="tab" @change="groupChanged" dark>
            <v-tab v-for="(group, index) in argGroups" :key="index">
              {{ group.group }}
            </v-tab>
          </v-tabs> -->

          <!-- <v-tabs-items v-model="tab">
            <v-tab-item v-for="(jobSetting, index) in jobSettings" :key="index">
              <v-card flat>
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="jobSetting.arguments"
                    :loading="actionLoading || argStageLoading"
                    :search="search"
                    sort-by="calories"
                    class="arguments-table"
                    :footer-props="{
                      'items-per-page-options': [5, 10, 20, 50],
                    }"
                    :items-per-page="5"
                  >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-toolbar-title></v-toolbar-title>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                          clearable
                        ></v-text-field>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                      <v-btn color="primary" dark class="mb-2" @click="newItem">Add Argument</v-btn> -->
          <!-- </v-toolbar>
                    </template>

                    <template v-slot:item.actions="{ item }">
                      <v-icon small v-if="item.stages && item.stages == '' || item.stages == 'session' || item.stages.split(',').includes(file_name) == true" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
                      <v-icon small v-if="item.stages && item.stages == '' || item.stages == 'session' || item.stages.split(',').includes(file_name) == true" @click="deleteItem(item)"> mdi-delete </v-icon> -->
          <!-- </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-tab-item> 
           </v-tabs-items> -->
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="close"> Close </v-btn>
          <v-btn
          v-if="userValue.role == 'Observer'? isHidden : !isHidden"
            outlined
            @click="saveJobSettingFile"
            :disabled="file_name.length == 0 || jobSettings.length == 0 || waiting"
          >
            Save
            <v-progress-circular indeterminate color="primary" v-if="waiting"></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ActionSettingDialog',
  components: {},
  data: () => ({
    userValue: {},
    isHidden: false,
    dialog: false,
    file_name: '',
    stageList: [],
    selectedStage: null,
    old_file_name: '',
    file_type: 'JOB',
    isUpdate: false,
    waiting: false,
    search: '',
    file_types: [
      { value: 'JOB', description: 'Job Configuration' },
      { value: 'EIF', description: 'Election Information' },
      { value: 'BOF', description: 'Ballot Options' },
      { value: 'STC', description: 'Manual Styles to Contests' },
    ],
    types: [],
    editDialog: false,
    headers: [
      { text: 'Argument', value: 'argument', width: '40%' },
      { text: 'Value', value: 'value', sortable: false, width: '40%' },
      { text: 'Actions', value: 'actions', sortable: false, width: '15%' },
    ],
    rules: {
      required: (value) => !!value || 'Required.',
    },

    jobSettings: [],
    maineditindex: -1,
    availableArguments: [],
    stageItems: [],
    editGroup: -1,
    editedIndex: -1,
    editedItem: {
      argument: '',
      choices: [],
      format: '',
      help: '',
      multi: '',
      required: '',
      type: '',
      value: '',
    },
    editedMainItem: {
      argument: '',
      choices: [],
      format: '',
      help: '',
      multi: '',
      required: '',
      type: '',
      value: '',
    },
    group: '',
    defaultItem: {
      argument: '',
      choices: [],
      format: '',
      help: '',
      multi: '',
      required: '',
      type: '',
      value: '',
    },
    removeItem: [],
    // tab groups
    tab: 0,
    argGroups: [],
  }),

  computed: {
    ...mapGetters([
      'activeJobFile',
      'jobFileLoading',
      'actionLoading',
      'actionFiles',
      'jobArgSpecs',
      'jobArgSpecsLoading',
      'argStages',
      'argStageLoading',
      'activeAuditJob',
      'user',
    ]),
    formTitle() {
      return this.editedIndex === -1 ? 'New Argument' : 'Edit Argument'
    },
    formSubmitText() {
      return this.editedIndex === -1 ? 'Add' : 'Save'
    },
  },

  mounted() {
    this.userValue = this.user
  },

  methods: {
    stageChanged() {
      // if (this.argGroups.length > 0) {
      //   this.stageItems = []
      //   for (let i = 0; i < this.argGroups.length; i++) {
      //     if (this.argGroups[i].arguments && this.argGroups[i].arguments.length > 0) {
      //       this.argGroups[i].arguments.forEach((item,index) => {
      //         if (item.stages == 'session') {
      //           this.stageItems.push({ ...item, group: this.argGroups[i].group,"id":index })
      //         } else if (item.stages == '') {
      //           this.stageItems.push({ ...item, group: this.argGroups[i].group,"id":index })
      //         }else if(item.stages != '' && item.stages != 'session'){
      //            let stage_arr = item.stages.split(',')               
      //             if (stage_arr.includes(this.selectedStage)) {
      //              item.stages = this.selectedStage
      //              this.stageItems.push({ ...item, group: this.argGroups[i].group,"id":index })
      //             }
      //         }
             
      //       })

      //     }
      //   }
      // }
       if (this.jobSettings.length > 0) {
        this.stageItems = []
        for (let i = 0; i < this.jobSettings.length; i++) {
          if (this.jobSettings[i].arguments && this.jobSettings[i].arguments.length > 0) {
            this.jobSettings[i].arguments.forEach((item,index) => {
              if (item.stages == 'session') {
                this.stageItems.push({ ...item, group: this.jobSettings[i].group,"id":index })
              } else if (item.stages == '') {
                this.stageItems.push({ ...item, group: this.jobSettings[i].group,"id":index })
              }else if(item.stages != '' && item.stages != 'session'){
                 let stage_arr = item.stages.split(',')               
                  if (stage_arr.includes(this.selectedStage)) {
                   item.stages = this.selectedStage
                   this.stageItems.push({ ...item, group: this.jobSettings[i].group,"id":index })
                  }
              }
            })

          }
        }
      }
    },

    groupChanged() {
      this.availableArguments = []
      const specGroup = this.argGroups[this.tab]
      const group = this.jobSettings[this.tab]
      for (let i = 0; i < specGroup.arguments.length; i++) {
        let exist = false
        for (let j = 0; j < group.arguments.length; j++) {
          if (specGroup.arguments[i].argument == group.arguments[j].argument) {
            if (!specGroup.arguments[j].multi) exist = true
          }
        }
        if (specGroup.arguments[i].stages == 'session') {
          if (!exist) this.availableArguments.push(specGroup.arguments[i].argument)
        } else if (specGroup.arguments[i].stages == '') {
          if (!exist) this.availableArguments.push(specGroup.arguments[i].argument)
        } else if (specGroup.arguments[i].stages != '' && specGroup.arguments[i].stages != 'session') {
          let stage_arr = specGroup.arguments[i].stages.split(',')
          if (stage_arr.length > 0) {
            if (stage_arr.includes(this.file_name)) {
              specGroup.arguments[i].stages = this.file_name
              if (!exist) this.availableArguments.push(specGroup.arguments[i].argument)

            }
          }
        } else {
          if (specGroup.arguments[i].stages != 'DEPRECATED') {
            if (!exist) this.availableArguments.push(specGroup.arguments[i].argument)
          }
        }
      }
    },
    argumentChanged() {
      const selectedArgGroup = this.argGroups[this.tab]

      for (let i = 0; i < selectedArgGroup.arguments.length; i++) {
        if (this.editedItem.argument == selectedArgGroup.arguments[i].argument) {
          if(selectedArgGroup.arguments[i].stages && selectedArgGroup.arguments[i].stages == '' || selectedArgGroup.arguments[i].stages == 'session' || selectedArgGroup.arguments[i].stages.split(',').includes(this.file_name) == true){
            this.editedItem.choices = selectedArgGroup.arguments[i].choices
            this.editedItem.format = selectedArgGroup.arguments[i].format
            this.editedItem.help = selectedArgGroup.arguments[i].help
            this.editedItem.multi = selectedArgGroup.arguments[i].multi
            this.editedItem.required = selectedArgGroup.arguments[i].required
            this.editedItem.stages = selectedArgGroup.arguments[i].stages
            this.editedItem.type = selectedArgGroup.arguments[i].type
            this.editedItem.value = selectedArgGroup.arguments[i].value
          }
        }
      }
    },
    show(item, operations) {
      this.argGroups = this.argStages
      this.jobSettings = []

      this.dialog = true
      this.stageList = operations
      // if(item.name){
      this.isUpdate = true
      this.file_name = item.name
      this.selectedStage = item.name
      this.old_file_name = item.name
      this.file_type = 'JOB'
      this.jobSettings = this.actionFiles
      // this.tab = 0
      this.stageChanged()
      // this.groupChanged()
    },

    close() {
      this.dialog = false
      this.waiting = false
    },
    async saveJobSettingFile() {
      let payload = {
        is_update: this.isUpdate,
        job_id: this.activeAuditJob.id,
        file_name: 'JOB_' + this.activeAuditJob.name + '.csv',
        settings: this.jobSettings,
      }
     
      if (this.file_type != undefined) {
        payload['file_type'] = this.file_type
      } else {
        payload['file_type'] = this.file_name.split('_')[0]
      }

      if (this.isUpdate) {
        payload['old_file_name'] = 'JOB_' + this.activeAuditJob.name + '.csv'
      }

      if (this.removeItem.length > 0) {
        payload['deleted_item'] = this.removeItem
      }

      this.waiting = true
      let ret = await this.$store.dispatch('saveJobFile', payload)
      this.waiting = false

      if (ret['message']) {
        this.dialog = false
        this.$store.dispatch('loadjobFiles', this.activeAuditJob.id)
      }
    },

    newItem() {
      this.editDialog = true
    },

    editItem(item) {
      let group = this.jobSettings[this.tab]
      this.editedIndex = group.arguments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editDialog = true
    },

    editStageItem(item) {
      this.group = item.group
      var index = this.jobSettings.findIndex((p) => p.group == item.group)
      this.tab = index
      let group = this.jobSettings[this.tab]
      this.maineditindex = item.id
      this.editedIndex = this.stageItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      delete item.group
      delete item.id
      this.editedMainItem = Object.assign({}, item)
      this.editDialog = true
    },
    deleteItem(item) {
      this.removeItem.push(item)
      let group = this.jobSettings[this.tab]
      const index = group.arguments.indexOf(item)
      group.arguments.splice(index, 1)
    },

    closeEdit() {
      this.editDialog = false
      this.group = ''
      this.tab = 0
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedMainItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
         this.maineditindex = -1
      })
    },

    updateStageItem() {
      var index = this.jobSettings.findIndex((p) => p.group == this.group)
      let group = this.jobSettings[index]
      group.arguments[this.maineditindex].value = this.editedItem.value
      this.stageItems[this.editedIndex].value = this.editedItem.value
      this.closeEdit()
    },

    updateItem() {
      let group = this.jobSettings[this.tab]
      if (this.editedIndex < 0) {
        group.arguments.push(this.editedItem)
        if (!this.editedItem.multi) {
          const index = this.availableArguments.indexOf(this.editedItem.argument)
          this.availableArguments.splice(index, 1)
        }
      } else {
        group.arguments[this.editedIndex].value = this.editedItem.value
      }
      this.closeEdit()
    },
  },

  watch: {
    dialog(val) {
      val || this.closeEdit()
    },
  },
}
</script>

<style>
.theme--dark.arguments-table.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: none;
}
</style>