import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/arg-specs'

const state = {
  argSpecs: [],
  argSpecsLoading: false,
  argStageLoading:false,
  argStages:[]
}


const getters = {
  argSpecs: state => {
    return state.argSpecs
  },
  argSpecsLoading: state => {
    return state.argSpecsLoading
  },
  argStageLoading: state => {
    return state.argStageLoading
  },
  argStages: state => {
    return state.argStages
  }

}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api().post(url, data).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function put(context, url, data, handler) {
  return api().put(url, data).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response)).catch(error=>handleApiError(error, ''))
}

function handleArgSpecsLoad(context, response) {
  const argSpecs = response['data']
  context.state.argSpecLoading = false
  if (argSpecs['error']) {
    logApiService(argSpecs, argSpecs['error'], EventName.Arg_specs, EventType.argSpecsLoad)
    context.commit('apiError', argSpecs['error'])
  } else {
    logApiService(argSpecs, 'Fetch Arg Specs', EventName.Arg_specs, EventType.argSpecsLoad)
    context.commit('argSpecsLoaded', argSpecs)
  }
  return argSpecs
}


function handleArgStagesLoad(context, response){
  const argStages = response['data']
  context.state.argStageLoading = false
  if (argStages['error']) {
    context.commit('apiError', argStages['error'])
    logApiService(argStages, argStages['error'], EventName.Arg_specs, EventType.argStagesLoad)
  } else {
    logApiService(argStages, 'Fetch Arg Stages', EventName.Arg_specs, EventType.argStagesLoad)
    context.commit('argStagesLoaded', argStages)
  }
  return argStages
}


const actions = {
  loadArgSpecs(context, id) {
    if (context.state.argSpecLoading) return
    context.state.argSpecLoading = true
    return get(context, URL, handleArgSpecsLoad)
  },
  loadArgSpecsStages(context, id){
    if (context.state.argStageLoading) return
    context.state.argStageLoading = true
    return get(context, URL + '/stages', handleArgStagesLoad)
  }
}


const mutations = {
  argSpecsLoaded(state, argSpecs) {
    state.argSpecs = []
    for (let i = 0; i < argSpecs.length; i++) {
      state.argSpecs.push(argSpecs[i])
    }
  },
  argStagesLoaded(state,argStages){
    state.argStages = []
    for (let i = 0; i < argStages.length; i++) {
      state.argStages.push(argStages[i])
    }
  },
}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'ArgSpecsStore'
}
