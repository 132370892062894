<template>
  <dash-layout>
    <template slot="dash-title">Plans & Pricing</template>
    <template slot="dash-body">
      <v-container class="container" fluid>
        <div class="top">
          <div class="toggle-btn">
            <span style="margin: 0.8em">Annually</span>
            <label class="switch">
              <input type="checkbox" v-model="monthly"  @click="handlePlan" id="checbox" />
              <span class="slider round"></span>
            </label>
            <span style="margin: 0.8em">Monthly</span>
          </div>
        </div>
        <v-row justify="start" no-gutters>
            <v-col  class="pl-5 mt-3 col-md-3 col-sm-6 col-xs-12" v-for="item in auditActivePackage" :key="item.id">
                <v-card class="card-plan" light>
                    <v-card-title class="headline">{{item.name}}</v-card-title>
                    <v-container grid-list-xl fluid  class="pa-5">
                        <div class="price-text text-center">
                            <h2 v-if="monthly">&dollar;{{item.monthlyPrice}}</h2>
                            <h2 v-else>&dollar;{{item.yearlyPrice}}</h2>
                        </div>
                        <div class="description-part text-center">
                            {{item.description}}
                        </div>
                        <div class="btn-box">
                            <a @click="processToCheckout(item)" class="price-btn">Buy Plan</a>
                        </div>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
      </v-container>
    </template>
  </dash-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import DashLayout from '../../containers/Layout'
export default {
  name: 'Subscription',
  components: {
    DashLayout,
  },
  computed: {
    ...mapGetters(['auditActivePackage', 'auditPackageLoading']),
  },
  mounted() {
    this.$store.dispatch('loadAuditActivePackages')
    this.includeStripe('js.stripe.com/v3/', function(){
        this.configureStripe();
    }.bind(this));
  },
  data() {
    return {
        monthly:false,
        // stripeAPIToken: 'pk_test_7SB2QMpnvPKhyqVlDQNYgaLG',
        stripeAPIToken:'pk_live_8SN1v2fgMje93cz4CQCokc6m',
    }
  },
  methods: {
    includeStripe( URL, callback ){
        let documentTag = document, tag = 'script',
            object = documentTag.createElement(tag),
            scriptTag = documentTag.getElementsByTagName(tag)[0];
        object.src = '//' + URL;
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
        scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe(){
        this.stripe = Stripe(this.stripeAPIToken);            
    },
    async processToCheckout(item){
        let payload = {
            package_id: item.id,
            host: window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2],
            is_monthly:this.monthly
        }
        if(this.monthly){
            payload['price'] = item.monthlyPrice
        }else{
            payload['price'] = item.yearlyPrice
        }
        const res_pay_data = await this.$store.dispatch('genratePackagePaymentSession',payload)
        if(res_pay_data.sessionId)
        {
            return this.stripe.redirectToCheckout({ sessionId: res_pay_data.sessionId })
        }
    },
    handlePlan(){
        this.monthly = !this.monthly
    }
  },
}
</script>
<style>
.headline{
    justify-content: center;
}

.btn-box{
    display: flex;
    justify-content: center;
}
a.price-btn{
    display: inline-block;
    vertical-align: middle;
    width: 187px;
    height: 61px;
    font-size: 16px;
    line-height: 61px;
    border-radius: 30.5px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    color: #7A7F93;
    background: #EFF2FB;
    text-decoration: none;
    text-align: center;
}

a.price-btn:hover {
    color: #fff;
    background: #1e2321;
}

.card-plan{
    max-height: 400px;
    min-height: 350px;
    overflow: auto;
}


h1,
h2 {
  font-size: 2.2em;
}


.top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
label {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0;
}

.button {
  padding: 10px 30px;
  text-decoration: none;
  font-size: 1.4em;
  margin: 15px 15px;
  border-radius: 50px;
  color: #1e2321;
  transition: all 0.3s ease 0s;
}

.button:hover {
  transform: scale(1.2);
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;

  box-shadow: 2px 6px 25px #1e2321;
  transform: translate(0px, 0px);
  transition: 0.6s ease transform, 0.6s box-shadow;
}

.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #1e2321;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #1e2321;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1e2321;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #fff;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.description-part{
    height: 80px;
    overflow-y: auto;
}
</style>
