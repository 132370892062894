<template>
  <v-card>

    <v-navigation-drawer v-model="drawer" app v-if="user" width="260" :disable-resize-watcher="false">
      <v-list>
        <v-list-item class="justify-center mt-12 mb-6">
          <v-list-item-content>
            <v-list-item-title class="headline text-center">
              <a href='/'><v-img max-height="150" max-width="206"
                  src="../../assets/logos/AuditEngineLogo.png"></v-img></a>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item class="px-2 justify-center">
          <v-list-item-avatar class="justify-center text-center" color="indigo lighten-3" size="57">
            <span class="white--text headline">{{ user.first_name | avatar }}</span>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-center">{{ user.first_name }} {{ user.last_name }}</v-list-item-title>
            <v-list-item-subtitle class="text-center">{{ user.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-list>

      <v-divider></v-divider>


      <v-list nav dense class="mt-5">
        <v-list-item v-for="(link) in links[user.role]" :key="link.name" link :ripple="false" active-class="highlighted"
          :class="[$route.path.startsWith(link.path) ? 'nav-selected' : '', 'text-center', 'my-2', 'py-3']"
          @click="$router.push(`${link.path}`).catch(err => { })">


          <!-- <v-list-item-content class="pt-0"> -->
          <!-- <v-list-item-icon class="text-center mx-auto mb-0"> -->
          <!-- <v-icon v-text="link.icon" large></v-icon> -->
          <!-- </v-list-item-icon> -->
          <v-icon v-text="link.icon" large></v-icon>

          <v-list-item-title class="text-center" style="font-size: 1rem;line-height: 2rem;">{{ link.name
          }}</v-list-item-title>
          <!-- </v-list-item-content> -->
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn block @click="logoutUser()">Logout<v-icon right>mdi-logout</v-icon></v-btn>
        </div>
      </template>

    </v-navigation-drawer>

  </v-card>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Navbar',
  computed: {
    ...mapGetters(['user', 'getRouteInfo', 'getDrawer']),
    drawer: {
      get() {
        return this.$store.state.component.drawer
      },
      set(val) {
        this.$store.commit('setDrawer', val)
      }
    }
  },
  mounted() {
  },
  data() {
    return {
      links: {
        User: [
          { name: 'Home', icon: 'mdi-home', path: '/home' },
          { name: 'Districts', icon: 'mdi-target', path: '/districts' },
          { name: 'Elections', icon: 'mdi-ticket', path: '/elections' },
          // { name: 'Audit Jobs', icon: 'monitor', path: '/audit-jobs' },
          { name: 'Leaderboard', icon: 'leaderboard', path: '/leaderboard' },
          { name: 'Account Settings', icon: 'settings', path: '/account-settings' },
          { name: 'Subscriptions', icon: 'payment', path: '/subscription' },
        ],
        Admin: [
          { name: 'Home', icon: 'mdi-home', path: '/home' },
          { name: 'Districts', icon: 'mdi-target', path: '/districts' },
          { name: 'Elections', icon: 'mdi-ticket', path: '/elections' },
          { name: 'Audit Jobs', icon: 'monitor', path: '/audit-jobs' },
          { name: 'Audit Types', icon: 'folder_open', path: '/admin-audit-types' },
          { name: 'Donate', icon: 'folder_open', path: '/audit-donation' },
          { name: 'Leaderboard', icon: 'leaderboard', path: '/leaderboard' },
          { name: 'Admin Board', icon: 'admin_panel_settings', path: '/admin-board' },
          { name: 'Audit Packages', icon: 'mdi-package-variant', path: '/packages' },
          { name: 'Account Settings', icon: 'settings', path: '/account-settings' },
          { name: 'Tools', icon: 'mdi-tools', path: '/tools' },
          { name: 'LogPage', icon: 'mdi-format-list-bulleted', path: '/logs' }
        ],
        Observer: [
          { name: 'Home', icon: 'mdi-home', path: '/home' },
          { name: 'Districts', icon: 'mdi-target', path: '/districts' },
          { name: 'Elections', icon: 'mdi-ticket', path: '/elections' },
          { name: 'Audit Jobs', icon: 'monitor', path: '/audit-jobs' },
          { name: 'Donate', icon: 'folder_open', path: '/audit-donation' },
          { name: 'Leaderboard', icon: 'leaderboard', path: '/leaderboard' },
          { name: 'Audit Packages', icon: 'mdi-package-variant', path: '/packages' },
          { name: 'Account Settings', icon: 'settings', path: '/account-settings' },
        ],
      },
    }
  },
  methods: {
    logoutUser() {
      this.$store.dispatch('signOutUserFromApi', "user");
      setTimeout(()=>{
        window.location = '/session/login'
      },1000)
    }
  },
  watch: {
    $route(to, from) {
    },
    user() {
    },
  }
}
</script>

<style>
.nav-selected .v-icon {
  color: #B39DDB !important;
}

.nav-selected .v-list-item__title {
  color: #B39DDB !important;
}

.nav-selected::before {
  opacity: 0.08 !important;
}
</style>