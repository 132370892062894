import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const URL = '/upload-district-files'

const state = {
  uploadFilesDistrict: [],
  requestedFilesDistrict: [],
  uploadFilesLoadingDistrict: false,
  requestedFilesLoadingDistrict: false,
  activeUploadFileDistrict: {},
}

const getters = {
  uploadFilesDistrict: state => {
    return state.uploadFilesDistrict
  },
  requestedFilesDistrict: state => {
    return state.requestedFilesDistrict
  },
  uploadFilesLoadingDistrict: state => {
    return state.uploadFilesLoadingDistrict
  },
  requestedFilesLoadingDistrict: state => {
    return state.requestedFilesLoadingDistrict
  },
  activeUploadFileDistrict: state => {
    return state.activeUploadFileDistrict
  },
}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api().post(url, data).then(response => handler(context, response))
  .catch(function (error) {handleApiError(error, ''); return error.response})
}

function put(context, url, data, handler) {
  return api().put(url, data).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response)).catch(error=>handleApiError(error, ''))
}

function handleUploadFilesLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
  context.state.uploadFilesLoadingDistrict = false
  if (data['error']) {
    context.commit('apiError', data['error'])
    logApiService(obj, data['error'], EventName.File_upload_district, EventType.uploadFilesLoad)
  } else {
    logApiService(obj, 'Fetch Upload Files', EventName.File_upload_district, EventType.uploadFilesLoad)
    context.commit('uploadFilesLoaded', data)
  }
  return data
}

function handleRequestedFilesLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const data = response['data']
  context.state.requestedFilesLoadingDistrict = false
  if (data['error']) {
    context.commit('apiError', data['error'])
    logApiService(obj, data['error'], EventName.File_upload_district, EventType.requestedFilesLoad)
  } else {
    logApiService(obj, 'Fetch Requested Files', EventName.File_upload_district, EventType.requestedFilesLoad)
    context.commit('requestedFilesLoaded', data)
  }
  return data
}

function handleUploadFileDelete(context, id, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: id
  }
  const uploadFile = response['data']
  if (uploadFile['error']) {
    context.commit('apiError', uploadFile['error'])
    logApiService(obj, uploadFile['error'], EventName.File_upload_district, EventType.uploadFileDelete)
  } else {
    logApiService(obj, 'Upload File Deleted', EventName.File_upload_district, EventType.uploadFileDelete)
    context.commit('uploadFileDeleted', id)
  }
}

function handleRequestedFileDelete(context, id, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: id
  }
  const data = response['data']
  if (data['error']) {
    context.commit('apiError', data['error'])
    logApiService(obj, data['error'], EventName.File_upload_district, EventType.requestedFileDelete)
  } else {
    logApiService(obj, 'Requested File Deleted', EventName.File_upload_district, EventType.requestedFileDelete)
    context.commit('requestedFileDeleted', id)
  }
}

const actions = {
  loadUploadFilesDistrict(context, district_id) {
    if(context.state.uploadFilesLoadingDistrict)return
    context.state.uploadFilesLoadingDistrict = true
    get(context, URL + '/' + district_id, handleUploadFilesLoad)
  },

  loadRequestedFilesDistrict(context, district_id) {
    if(context.state.requestedFilesLoadingDistrict)return
    context.state.requestedFilesLoadingDistrict = true
    get(context, '/requested-district-files/' + district_id, handleRequestedFilesLoad)
  },

  DeleteUploadFileDistrict(context, id) {
    return apiDelete(context, URL, id, handleUploadFileDelete)
  },

  DeleteRequestedFileDistrict(context, id) {
    return apiDelete(context, '/requested-district-files', id, handleRequestedFileDelete)
  },

  
  createUploadDistrict(context, payload){
    return api(false).post( URL, payload).then(function (response) {      
      const responseData = response['data']
      if (responseData['error']) {
        context.commit('apiError', responseData['error'])
      }
      return responseData
    })
    .catch(error => {
      handleApiError(error, '')
      const responseData = error.response.data
      context.commit('apiError', responseData.error)
      return responseData
    });
  },
  updateUploadDistrict(context, payload){
    return api(false).put( URL + '/' + payload.id, payload).then(function (response) {      
      const responseData = response['data']
      if (responseData['error']) {
        context.commit('apiError', responseData['error'])
      }
      return responseData
    })
    .catch(error => {
      handleApiError(error, '')
      const responseData = error.response.data
      context.commit('apiError', responseData.error)
      return responseData
    });
  },
  deleteUpload(context, id){
    return api(false).delete( URL + '/' + id).then(function (response) {      
      const responseData = response['data']
      if (responseData['error']) {
        context.commit('apiError', responseData['error'])
      }
      return responseData
    })
    .catch(error => {
      handleApiError(error, '')
      const responseData = error.response.data
      context.commit('apiError', responseData.error)
      return responseData
    });
  },
}


const mutations = {
  uploadFilesLoaded(state, uploadFilesDistrict) {
    state.uploadFilesDistrict = []
    for (let i = 0; i < uploadFilesDistrict.length; i++) {
      state.uploadFilesDistrict.push(uploadFilesDistrict[i])
    }
  },

  requestedFilesLoaded(state, requestedFilesDistrict) {
    state.requestedFilesDistrict = []
    for (let i = 0; i < requestedFilesDistrict.length; i++) {
      state.requestedFilesDistrict.push(requestedFilesDistrict[i])
    }
  },

  uploadFileSuccess(state, uploadFile) {
    for (let i = 0; i < state.uploadFilesDistrict.length; i++) {
      if (state.uploadFilesDistrict[i].id == uploadFile.id) {
        state.uploadFilesDistrict.splice(i, 1, uploadFile)        
        return
      }
    }

    state.uploadFilesDistrict.push(uploadFile)
  },

  uploadFileDeleted(state, id) {
    for (let i = 0; i < state.uploadFilesDistrict.length; i++) {
      if (state.uploadFilesDistrict[i].id == id) {
        state.uploadFilesDistrict.splice(i, 1)        
      }
    }
  },

  requestedFileDeleted(state, id) {
    for (let i = 0; i < state.requestedFilesDistrict.length; i++) {
      if (state.requestedFilesDistrict[i].id == id) {
        state.requestedFilesDistrict.splice(i, 1)       
      }
    }
  },
}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'UploadFileStore'
}
