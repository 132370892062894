<template>
  <dash-layout>
    
    <template slot="dash-title" v-if="id != 'new'">
      <!-- <v-btn  outlined @click="$router.push(`/elections`).catch(err => {})">Go To Elections</v-btn > -->
      <span v-if="activeAuditJob.name">
        {{ activeAuditJob.name }}
        <v-chip
          small
          color="green"
          text-color="white"
          style="margin-bottom: 16px; margin-left: -8px;"
        >
          {{ activeAuditJob.role }}
        </v-chip>
      </span>        
      <span v-else>Loading...</span>
      
    </template>
    <template slot="dash-title" v-else>New Audit Job  </template>
   
    <template slot="dash-body">
     
      <v-tabs show-arrows v-model="tab" class="mb-1">
         <div class="back-btn" style="line-height: 50px;">
            <v-btn icon @click.prevent="backProject()" class="mb-1">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn><span>Back</span>
          </div>
        <v-tab :ripple="false" :ref="'btnAuditJobOverview'" >Audit Job Overview</v-tab>
        <v-tab
        v-show="activeAuditJob.role == 'Auditor' || activeAuditJob.role == 'Uploader'"
        :ripple="false" 
        :ref="'btnJobFiles'" 
        :disabled="id == 'new'"
        >Job Files</v-tab>
        
        <!-- <v-tab
        v-show="user_role == 'Admin'"
        :ripple="false" 
        :ref="'btnJobFiles'" 
        :disabled="id == 'new'"
        >Adopted Files</v-tab> -->
        <v-tab 
        :ripple="false" 
        :ref="'btnAuditOperations'" 
        :disabled="id == 'new'"
        >Audit Operations</v-tab>
        <!-- <v-tab :ripple="false" :ref="'Auditattech'" >Attached Files</v-tab> -->
         <v-tab :ripple="false" :ref="'Discussion'" :disabled="id == 'new'" >Discussion</v-tab>
          <v-tab :ripple="false" :ref="'Tools'" :disabled="id == 'new'" >Tools</v-tab>
         <v-tab :ripple="false"  :ref="'gotoelection'" @click="$router.push(`/elections/`+activeAuditJob.election_id).catch(err => {})">Election</v-tab>
      </v-tabs>
      

      <transition name="component-fade" mode="out-in">
        <keep-alive>
          <component v-bind:is="tabs[activeTab].component" :jobId="id"></component>
        </keep-alive>
      </transition>

    </template>
  </dash-layout>

</template>

<script>
  import { mapGetters } from 'vuex'
  import DashLayout from '../../containers/Layout'
  import Overview from './Overview'
  import JobSettings from './JobSettings'
  import JobControl from './JobControl'
  import AuditActions from './AuditActions'
  import AdoptedFiles from './AdoptedFiles'
  import AuditAttech from './AuditAttech'
  import Discusioncontainer from './Discusioncontainer'
  import AuditTools from './AuditTools'
  
  export default {
    name: 'AuditJobLayout',
    props: ['id'],

    data() {
      return {
        timer: null,
        isHidden:false,
        currentTabComponent: '',
        activeTab: 0,
        user_role:'',
        tabs: [
          { id: 0, name: 'Audit Job Overview', component: 'Overview'},
          { id: 1,  name: 'Job Files', component: 'JobSettings'},
        //  { id: 2,  name: 'Adopted Files', component: 'AdoptedFiles'},
          { id: 2,  name: 'Audit Operations', component: 'AuditActions'},
          // {id:5,name:'Attached Files',component:'AuditAttech'},
          {id:3,name:'Discussion',component:'Discusioncontainer'},
          { id:4,name:'Tools',component:'AuditTools'}
        ]
      }
    },
    created () {
        this.timer = setInterval(this.refreshJobOperationStatus,2000)
    },
    beforeDestroy () {
      clearInterval(this.timer)
    },

    mounted() {
      this.$store.dispatch('loadAuditTypes')
      //this.$store.dispatch('RefreshStatus',this.id) 
      this.user_role = JSON.parse(localStorage.getItem('user')).role 
      // if(this.activeTab == 0){
      //   this.isHidden = true;
      // }else{
      //    this.isHidden = false;
      // }
    },
    computed: {
      ...mapGetters(['activeAuditJob', 'user']),
      
      tab: {
        get () {
          return this.activeTab
        },
        set (value) {
          this.activeTab = value
          if(this.activeTab == 0){
            this.isHidden = true;
          }else{
            this.isHidden = false;
          }
        }
      }
    },

    components: {
      DashLayout,
      Overview,
      JobSettings,
      JobControl,
      AuditActions,
      AdoptedFiles,
      AuditAttech,
      Discusioncontainer,
      AuditTools
    },

    methods: {
      refreshJobOperationStatus() {        
        if(this.activeTab == 2 && this.activeAuditJob.id) {
           this.$store.dispatch('loadJobOperations', this.activeAuditJob.id)
          //this.$store.dispatch('RefreshStatus', this.activeAuditJob.id) 
        }
        if(this.activeTab == 4){
          this.$store.dispatch('loadToolsStatus')
        }
       /* else if (this.activeTab == 3 && this.activeAuditJob.id) {
          this.$store.dispatch('loadJobOperations', this.activeAuditJob.id)
        }*/
      },
       backProject() {
        this.$router.push('/audit-jobs');
      }
    },
    watch: {      
    }
  }
</script>
