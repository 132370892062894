import Vue from 'vue'
import api from 'Api'
import { handleApiError, logApiService } from '../../../services/apiService'
import { EventName, EventType } from '../../../services/LogEvent'

const USER_URL = '/election-users/election'
const URL = '/election-users'

const state = {
  electionUserLoading: false,
  electionUsers: [],
  activeElectionUser: {},
}


const getters = {
  electionUserLoading: state => {
    return state.electionUserLoading
  },

  electionUsers: state => {
    return state.electionUsers
  },

  activeElectionUser: state => {
    return state.activeElectionUser
  }

}

function get(context, url, handler) {
  return api().get(url).then(response => handler(context, response)).catch(error=>handleApiError(error, ''))
}

function post(context, url, data, handler) {
  return api().post(url, data).then(response => handler(context, response, data)).catch(error => {
    handleApiError(error, '');
    handler(context, error.response, data)
  })
}

function put(context, url, data, handler) {
  return api().put(url, data).then(response => handler(context, response, data)).catch(error => {
    handleApiError(error, '');
    handler(context, error.response, data)
  })
}

function apiDelete(context, url, id, handler) {
  return api().delete(url + '/' + id).then(response => handler(context, id, response)).catch(error=>handleApiError(error, ''))
}


function handleElectionUsersLoad(context, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
  }
  const users = response['data']
  context.state.electionUserLoading = false
  if (users['error']) {
    logApiService(obj, users['error'], EventName.Elections, EventType.electionUsersLoad)
    context.commit('apiError', users['error'])
  } else {
    logApiService(obj, 'Fetch Election Users', EventName.Elections, EventType.electionUsersLoad)
    context.commit('electionsUserLoaded', users)
  }
  return users
}

function handleElectionUserDelete(context, id, response) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: id
  }
  const user = response['data']
  if (user['error']) {
    context.commit('apiError', user['error'])
    logApiService(obj, user['error'], EventName.Elections, EventType.electionUserDelete)
  } else {
    logApiService(obj, 'User deleted', EventName.Elections, EventType.electionUserDelete)
    context.commit('electionDeleted', id)
  }
}

function handleElectionUserSave(context, response, data) {
  let obj = {
    url: `${response.config.baseURL}${response.config.url}`,
    data: data
  }
  const  user = response['data']
  if (user['error']) {
    logApiService(obj, user['error'], EventName.Elections, EventType.electionUserSave)
    context.commit('apiError', user['error'])
  } else {
    logApiService(obj, 'User Save', EventName.Elections, EventType.electionUserSave)
    context.commit('electionUserSuccess', user)
  }
  return user
}

const actions = {
  getElectionUsers(context, id) {
    if(context.state.electionUserLoading)return
    context.state.electionUserLoading = true
    return get(context,  USER_URL + '/' + id, handleElectionUsersLoad)
  },

  deleteElectionUser(context, id) {
    return apiDelete(context, URL, id, handleElectionUserDelete)
  },

  saveElectionUser(context, payload) {
    let userData = {      
      'election_id': payload['election_id'],
      'first_name': payload['first_name'],
      'last_name': payload['last_name'],
      'role': payload['role'],
      'email': payload['email'],
    }
    if (payload['id'] > 0) {
      userData['id'] = payload['id']
      return put(context, URL + '/' + payload['id'], userData, handleElectionUserSave)
    } else {
      return post(context, URL, userData, handleElectionUserSave)
    }
  },
}

const mutations = {
  setElectionUserPopup(state, value) {
    state.electionUserDialog = value
  },

  electionsUserLoaded(state, electionUsers) {
    state.electionUsers = []
    for (let i = 0; i < electionUsers.length; i++) {
      state.electionUsers.push(electionUsers[i])
    }
  },

  electionDeleted(state, id) {
    for (let i = 0; i < state.electionUsers.length; i++) {
      if (state.electionUsers[i].id === id) {
        state.electionUsers.splice(i, 1)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'User deleted'
        })
      }
    }
  },

  electionUserSuccess(state, user) {
    for (let i = 0; i < state.electionUsers.length; i++) {
      if (state.electionUsers[i].id == user.id) {
        state.electionUsers.splice(i, 1, user)
        Vue.notify({
          group: 'messages',
          type: 'success',
          text: 'User Updated'
        })
        return
      }
    }

    Vue.notify({
      group: 'messages',
      type: 'success',
      text: 'User Created'
    })
  },

  setActiveElectionUser (state, electionUser) {
      state.activeElectionUser = electionUser
  }


}


export default {
  state,
  getters,
  mutations,
  actions,
  name: 'ElectionUserStore'
}
