<template>
  <div>
    <v-card class="mx-auto my-10" max-width="1200">
      <v-card-text>
        <h2>Your project donation is successfully completed!</h2>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DonationSuccess',
  props: ['id'],
  data() {
    return {
      price: null,
    }
  },
  computed: {
    ...mapGetters(['activeProject']),
  },
  async mounted() {
    const id = new URLSearchParams(window.location.search).get('session_id');
    await this.$store.dispatch('setProjectDonation', id)
    setTimeout(() => {
        if(localStorage.getItem('user')) {
            this.$router.push(`/projects/${this.$route.params.id}`)
        }
    }, 2000);
  },
  
}
</script>