var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"500px","persistent":""},model:{value:(_vm.fileDeleteDialog),callback:function ($$v) {_vm.fileDeleteDialog=$$v},expression:"fileDeleteDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Delete "+_vm._s(_vm.selectedFile.file_name)+" ?")]),_c('v-card-actions',{staticClass:"pa-8"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){_vm.fileDeleteDialog = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"outlined":"","color":"error","disabled":_vm.waiting},on:{"click":_vm.confirmDelete}},[_vm._v(" Ok "),(_vm.waiting)?_c('v-progress-circular',{attrs:{"width":3,"color":"green","indeterminate":""}}):_vm._e()],1)],1)],1)],1),_c('UploadFilesDialog',{ref:"UploadFilesDialog"}),_c('v-card',{staticClass:"mb-2",attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"mb-6"},[_vm._v(" Direct Uploads "),_c('v-spacer'),(_vm.user && _vm.user.role !== 'Observer')?_c('v-btn',{staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":function($event){return _vm.$refs.UploadFilesDialog.show()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]),_vm._v(" Upload Files ")],1):_vm._e(),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":_vm.refreshUploadFiles}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Upload Files")])])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.files,"search":_vm.search,"loading":_vm.uploadFilesLoading},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteFile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete File")])])]}}])})],1)],1)],1),_c('v-card',{attrs:{"max-width":"100%"}},[_c('v-card-title',{staticClass:"mb-6"},[_vm._v(" Requested Uploads "),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","color":"blue-grey darken-1"},on:{"click":_vm.refreshRequestedFiles}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Requested Files")])])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.requested_files,"search":_vm.search,"loading":_vm.requestedFilesLoading},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"dark":""}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteFile(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete File")])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }